import { AppName } from '../../apps/definition';

/**
 * Centralize events for `postMessage`
 */
export enum PostMessageEvent {
  // For any successfully authenticated app
  AppAuthenticated = 'dash-app-authenticated',
  AppAuthenticationFailure = 'dash-app-authentication-failure',
}

export interface PostMessage<T = undefined> {
  error: string | undefined;
  action: PostMessageEvent | string;
  appName?: AppName;
  admin?: boolean;
  payload: T;
}
