import React, { useCallback, useState } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';
import { useUserSafe } from '../../scripts/hooks';
import { GeneralError } from '../generic/Components';
import { EmailCaptureStep } from '../generic/EmailCaptureStep';
import { useGoogleMarketPopup } from '../gworkspace-directory/AppInstallModal';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleAPIInstall, setInProgress }) => {
    const [popupError, showPopup] = useGoogleMarketPopup();

    const user = useUserSafe();
    const currentUsername = user.email.split('@').slice(0, -1).join('@');
    const orgDomain = user.orgByOrgId.domain;

    const [email, setEmail] = useState(`${currentUsername}@${orgDomain}`);

    const clickConnect = useCallback(async () => {
      setInProgress(true);
      if (!(await showPopup())) {
        setInProgress(false);
      }

      return handleAPIInstall({
        email,
        marketplace: true,
      });
    }, [email, handleAPIInstall, setInProgress, showPopup]);

    return (
      <>
        <EmailCaptureStep
          additionalDescription={
            <p>
              <ul>
                <li>
                  It must be on <strong>@{orgDomain}</strong> domain.
                </li>
                <li>
                  It must have Admin privileges in Google Workspace. Dashworks
                  will request read-only access.
                </li>
              </ul>
            </p>
          }
          onChange={setEmail}
          step={1}
          value={email}
        />
        <p className="firstNotice">
          This step allows Dashworks to connect with Drive, Mail, and Calendar,
          and to fetch your company directory.
        </p>
        {popupError && <GeneralError text={popupError} />}

        {connectInProgress(clickConnect)}
      </>
    );
  }
);
