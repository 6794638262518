import React, { FC } from 'react';
import airtableApiKeyScreen from '../../assets/images/admin/airtable_api.gif';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  open,
  onClose,
}) => {
  return (
    <SimpleAPIKeyInstall
      app={app}
      instructionSteps={
        <ConnectStep step={1} title="Generate API key">
          <p>Go to your Airtable account.</p>

          <div className="actionContainer">
            <UIButton href="https://airtable.com/account">
              Open Airtable account
            </UIButton>
          </div>

          <p>
            On the opened page, click on <strong>Generate API key</strong> (like
            below) or copy an existing key.
          </p>
          <div className="screenFrame">
            <img src={airtableApiKeyScreen} />
          </div>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
    />
  );
};
