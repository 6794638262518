import { GenericCRUD } from './GenericCrud';

interface IDObject {
  id: string;
}

interface GetAllOptions {
  title?: string;
  page?: number;
  count?: number;
}

export class GenericTitledViewCounted<
  TModel extends IDObject,
  TWritable extends object
> extends GenericCRUD<TModel, TWritable> {
  public async getAll(opts?: GetAllOptions): Promise<TModel[]> {
    return (
      await this.invoke<TModel[]>({
        query: opts as Record<string, number | string>,
      })
    ).map((d) => this.sanitize(d));
  }

  public async *getAllPaged(
    count?: number
  ): AsyncGenerator<TModel[], void, void> {
    let page = 0;
    const ids = new Set<string>();
    while (true) {
      // This is desired in generator functions!
      // eslint-disable-next-line no-await-in-loop
      const data = await this.getAll({
        page,
        count,
      });

      if (data.length === 0) {
        return;
      }

      for (const obj of data) {
        if (ids.has(obj.id)) {
          throw new Error('Potential infinite api fetch');
        }
      }

      yield data;
      if (count !== undefined && data.length !== count) {
        return;
      }

      page++;
    }
  }

  public async view(id: string): Promise<void> {
    await this.invoke({
      path: `${this.desanitizeId(id)}/view`,
      method: 'POST',
    });
  }
}
