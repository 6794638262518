import './MetaPairs.scss';
import React from 'react';
import { MetaPair } from '../../previews/PreviewItem/PreviewItem';

interface MetaPairsProps {
  pairs?: MetaPair[];
}

export const MetaPairs: React.FC<MetaPairsProps> = ({ pairs }) => {
  if (pairs?.length === 0) {
    return null;
  }

  return (
    <table className="metaPairs">
      <tbody>
        {pairs?.map((pair) => {
          return (
            <tr key={pair.name}>
              <td className="pairName">{pair.name}</td>
              <td className="pairValue">{pair.value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
