import React, { FC } from 'react';
import { AppName } from '../../apps/definition';
import { SystemAnswerResult } from '../../components/pageSearch/results/objectTypes/answers/SystemAnswerResult';
import { GoogleCalendarEventResult } from '../../components/pageSearch/results/objectTypes/calendar/gcal/GoogleCalendarEventResult/GoogleCalendarEventResult';
import { HubspotCompanyResult } from '../../components/pageSearch/results/objectTypes/contacts/hubspot/HubspotCompanyResult/HubspotCompanyResult';
import { HubspotContactResult } from '../../components/pageSearch/results/objectTypes/contacts/hubspot/HubspotContactResult/HubspotContactResult';
import { IntercomCompanyResult } from '../../components/pageSearch/results/objectTypes/contacts/intercom/IntercomCompanyResult/IntercomCompanyResult';
import { IntercomLeadResult } from '../../components/pageSearch/results/objectTypes/contacts/intercom/IntercomLeadResult/IntercomLeadResult';
import { IntercomUserResult } from '../../components/pageSearch/results/objectTypes/contacts/intercom/IntercomUserResult/IntercomUserResult';
import { SalesforceAccountResult } from '../../components/pageSearch/results/objectTypes/contacts/salesforce/SalesforceAccountResult';
import { SalesforceCaseResult } from '../../components/pageSearch/results/objectTypes/contacts/salesforce/SalesforceCaseResult';
import { SalesforceContactResult } from '../../components/pageSearch/results/objectTypes/contacts/salesforce/SalesforceContactResult';
import { SalesforceKnowledgeArticleResult } from '../../components/pageSearch/results/objectTypes/contacts/salesforce/SalesforceKnowledgeArticle';
import { SalesforceLeadResult } from '../../components/pageSearch/results/objectTypes/contacts/salesforce/SalesforceLeadResult';
import { SalesforceOpportunityResult } from '../../components/pageSearch/results/objectTypes/contacts/salesforce/SalesforceOpportunityResult';
import { AirtableBaseResult } from '../../components/pageSearch/results/objectTypes/files/airtable/AirtableBaseResult/AirtableBaseResult';
import { AirtableRecordResult } from '../../components/pageSearch/results/objectTypes/files/airtable/AirtableRecordResult/AirtableRecordResult';
import { AirtableTableResult } from '../../components/pageSearch/results/objectTypes/files/airtable/AirtableTableResult/AirtableTableResult';
import { BrandfolderAssetResult } from '../../components/pageSearch/results/objectTypes/files/brandfolder/BrandfolderAssetResult';
import { CodaDocResult } from '../../components/pageSearch/results/objectTypes/files/coda/CodaDocResult';
import { CodaPageResult } from '../../components/pageSearch/results/objectTypes/files/coda/CodaPageResult';
import { CodaTableResult } from '../../components/pageSearch/results/objectTypes/files/coda/CodaTableResult';
import { ConfluencePageResult } from '../../components/pageSearch/results/objectTypes/files/confluence/ConfluencePageResult/ConfluencePageResult';
import { ConfluenceSpaceResult } from '../../components/pageSearch/results/objectTypes/files/confluence/ConfluenceSpaceResult/ConfluenceSpaceResult';
import { DropboxFolderResult } from '../../components/pageSearch/results/objectTypes/files/dropbox/DropboxFolderResult/DropboxFolderResult';
import { DropboxPaperResult } from '../../components/pageSearch/results/objectTypes/files/dropbox/DropboxPaperResult/DropboxPaperResult';
import { GDriveDocResult } from '../../components/pageSearch/results/objectTypes/files/gdrive/GDriveDocResult/GDriveDocResult';
import { GDriveDrawingResult } from '../../components/pageSearch/results/objectTypes/files/gdrive/GDriveDrawingResult/GDriveDrawingResult';
import { GDriveDriveResult } from '../../components/pageSearch/results/objectTypes/files/gdrive/GDriveDriveResult/GDriveDriveResult';
import { GDriveFolderResult } from '../../components/pageSearch/results/objectTypes/files/gdrive/GDriveFolderResult/GDriveFolderResult';
import { GDriveFormResult } from '../../components/pageSearch/results/objectTypes/files/gdrive/GDriveFormResult/GDriveFormResult';
import { GDriveSheetResult } from '../../components/pageSearch/results/objectTypes/files/gdrive/GDriveSheetResult/GDriveSheetResult';
import { GDriveSiteResult } from '../../components/pageSearch/results/objectTypes/files/gdrive/GDriveSiteResult/GDriveSiteResult';
import { GDriveSlideResult } from '../../components/pageSearch/results/objectTypes/files/gdrive/GDriveSlideResult/GDriveSlideResult';
import { GeneralFileResult } from '../../components/pageSearch/results/objectTypes/files/general/GeneralFileResult/GeneralFileResult';
import { GithubDocumentResult } from '../../components/pageSearch/results/objectTypes/files/github/GithubDocumentResult';
import { GithubRepoResult } from '../../components/pageSearch/results/objectTypes/files/github/GithubRepoResult';
import { GitlabDocumentResult } from '../../components/pageSearch/results/objectTypes/files/gitlab/GitlabDocumentResult';
import { GitlabProjectResult } from '../../components/pageSearch/results/objectTypes/files/gitlab/GitlabRepoResult';
import { MiroBoardResult } from '../../components/pageSearch/results/objectTypes/files/miro/MiroBoardResult/MiroBoardResult';
import { NotionPageResult } from '../../components/pageSearch/results/objectTypes/files/notion/NotionPageResult/NotionPageResult';
import { OneDriveFileResult } from '../../components/pageSearch/results/objectTypes/files/onedrive/OneDriveFileResult/OneDriveFileResult';
import { OneDriveFolderResult } from '../../components/pageSearch/results/objectTypes/files/onedrive/OneDriveFolderResult/OneDriveFolderResult';
import { QuipFileResult } from '../../components/pageSearch/results/objectTypes/files/quip/QuipFileResult/QuipFileResult';
import { QuipFolderResult } from '../../components/pageSearch/results/objectTypes/files/quip/QuipFolderResult/QuipFolderResult';
import { ReadmeDocResult } from '../../components/pageSearch/results/objectTypes/files/readme/readmedocresult';
import { SharepointListItemResult } from '../../components/pageSearch/results/objectTypes/files/sharepoint/SharepointListItemResult';
import { SharepointListResult } from '../../components/pageSearch/results/objectTypes/files/sharepoint/SharepointListResult';
import { SharepointPageResult } from '../../components/pageSearch/results/objectTypes/files/sharepoint/SharepointPageResult';
import { SharepointSiteResult } from '../../components/pageSearch/results/objectTypes/files/sharepoint/SharepointSiteResult';
import { WebSitePageResult } from '../../components/pageSearch/results/objectTypes/files/website/WebsitePageResult/WebsitePageResult';
import { ZendeskArticleResult } from '../../components/pageSearch/results/objectTypes/files/zendesk/ZendeskArticleResult';
import { GmailEmailResult } from '../../components/pageSearch/results/objectTypes/messages/gmail/GmailEmailResult/GmailEmailResult';
import { IntercomConversationResult } from '../../components/pageSearch/results/objectTypes/messages/intercom/IntercomConversationResult/IntercomConversationResult';
import { MSTeamsMessageResult } from '../../components/pageSearch/results/objectTypes/messages/msteams/MSTeamsMessageResult/MSTeamsMessageResult';
import { SlackChannelResult } from '../../components/pageSearch/results/objectTypes/messages/slack/SlackChannelResult/SlackChannelResult';
import { SlackMessageResult } from '../../components/pageSearch/results/objectTypes/messages/slack/SlackMessageResult/SlackMessageResult';
import { ResultProps } from '../../components/pageSearch/results/objectTypes/resultProps';
import { AsanaProjectResult } from '../../components/pageSearch/results/objectTypes/tasks/asana/AsanaProjectResult/AsanaProjectResult';
import { AsanaTaskResult } from '../../components/pageSearch/results/objectTypes/tasks/asana/AsanaTaskResult/AsanaTaskResult';
import { ClickupListResult } from '../../components/pageSearch/results/objectTypes/tasks/clickup/ClickupListResult';
import { ClickupTaskResult } from '../../components/pageSearch/results/objectTypes/tasks/clickup/ClickupTaskResult';
import { FreshdeskTicketResult } from '../../components/pageSearch/results/objectTypes/tasks/freshdesk/FreshdeskTicketResult/FreshdeskTicketResult';
import { GithubIssueResult } from '../../components/pageSearch/results/objectTypes/tasks/github/GithubIssueResult';
import { GithubPullRequestResult } from '../../components/pageSearch/results/objectTypes/tasks/github/GithubPullRequestResult';
import { GitlabIssueResult } from '../../components/pageSearch/results/objectTypes/tasks/gitlab/GitlabIssueResult';
import { GitlabMilestoneResult } from '../../components/pageSearch/results/objectTypes/tasks/gitlab/GitlabMilestoneResult';
import { GitlabMergeRequestResult } from '../../components/pageSearch/results/objectTypes/tasks/gitlab/GitlabPullRequestResult';
import { JiraIssueResult } from '../../components/pageSearch/results/objectTypes/tasks/jira/JiraIssueResult/JiraIssueResult';
import { JiraProjectResult } from '../../components/pageSearch/results/objectTypes/tasks/jira/JiraProjectResult/JiraProjectResult';
import { MondayBoardResult } from '../../components/pageSearch/results/objectTypes/tasks/monday/MondayBoardResult';
import { MondayItemResult } from '../../components/pageSearch/results/objectTypes/tasks/monday/MondayItemResult';
import { ServiceNowCaseResult } from '../../components/pageSearch/results/objectTypes/tasks/servicenow/ServiceNowCaseResult';
import { ServiceNowKnowledgeArticle } from '../../components/pageSearch/results/objectTypes/tasks/servicenow/ServiceNowKnowledgeArticle';
import { TrelloBoardResult } from '../../components/pageSearch/results/objectTypes/tasks/trello/TrelloBoardResult/TrelloBoardResult';
import { TrelloCardResult } from '../../components/pageSearch/results/objectTypes/tasks/trello/TrelloCardResult/TrelloCardResult';
import { ZendeskTicketResult } from '../../components/pageSearch/results/objectTypes/tasks/zendesk/ZendeskTicketResult/ZendeskTicketResult';
import { PageSearchResult } from '../models/page-search-result';
import { isWebDev } from '../utils';

interface ResultComponentProps {
  result: PageSearchResult;
}

const seenTypes = new Set<string>();

const simpleResults: Partial<Record<AppName, Record<string, FC<ResultProps>>>> =
  {
    brandfolder: {
      asset: BrandfolderAssetResult,
    },
    coda: {
      table: CodaTableResult,
      page: CodaPageResult,
      doc: CodaDocResult,
    },
    monday: {
      item: MondayItemResult,
      board: MondayBoardResult,
    },
    notion: {
      page: NotionPageResult,
    },
    msteams: {
      message: MSTeamsMessageResult,
    },
    sharepoint: {
      site: SharepointSiteResult,
      list: SharepointListResult,
      list_item: SharepointListItemResult,
      page: SharepointPageResult,
    },
    onedrive: {
      file: OneDriveFileResult,
      folder: OneDriveFolderResult,
    },
    miro: {
      board: MiroBoardResult,
    },
    freshdesk: {
      ticket: FreshdeskTicketResult,
    },
    website: {
      page: WebSitePageResult,
    },
    readme: {
      doc: ReadmeDocResult,
    },
    airtable: {
      base: AirtableBaseResult,
      record: AirtableRecordResult,
      table: AirtableTableResult,
    },
    confluence: {
      page: ConfluencePageResult,
      space: ConfluenceSpaceResult,
    },
    gdrive: {
      drive: GDriveDriveResult,
      folder: GDriveFolderResult,
      drawing: GDriveDrawingResult,
    },
    salesforce: {
      account: SalesforceAccountResult,
      contact: SalesforceContactResult,
      lead: SalesforceLeadResult,
      opportunity: SalesforceOpportunityResult,
      case: SalesforceCaseResult,
      knowledgeArticle: SalesforceKnowledgeArticleResult,
    },
    intercom: {
      user: IntercomUserResult,
      lead: IntercomLeadResult,
      conversation: IntercomConversationResult,
      company: IntercomCompanyResult,
    },
    github: {
      issue: GithubIssueResult,
      repo: GithubRepoResult,
      pr: GithubPullRequestResult,
      markdown_doc: GithubDocumentResult,
    },
    gitlab: {
      issue: GitlabIssueResult,
      project: GitlabProjectResult,
      merge_request: GitlabMergeRequestResult,
      markdown_doc: GitlabDocumentResult,
      milestone: GitlabMilestoneResult,
    },
    dropbox: {
      folder: DropboxFolderResult,
      paper: DropboxPaperResult,
    },
    slack: {
      channel: SlackChannelResult,
      message: SlackMessageResult,
    },
    quip: {
      folder: QuipFolderResult,
      thread: QuipFileResult,
    },
    asana: {
      project: AsanaProjectResult,
      task: AsanaTaskResult,
    },
    clickup: {
      task: ClickupTaskResult,
      list: ClickupListResult,
    },
    gmail: {
      email: GmailEmailResult,
    },
    trello: {
      board: TrelloBoardResult,
      card: TrelloCardResult,
    },
    system: {
      answer: SystemAnswerResult,
    },
    zendesk: {
      article: ZendeskArticleResult,
      task: ZendeskTicketResult,
    },
    jira: {
      project: JiraProjectResult,
    },
    servicenow: {
      knowledgearticle: ServiceNowKnowledgeArticle,
      case: ServiceNowCaseResult,
    },
    hubspot: {
      company: HubspotCompanyResult,
      contact: HubspotContactResult,
    },
    gcal: {
      event: GoogleCalendarEventResult,
    },
  };

const GDriveResult: FC<ResultComponentProps> = ({ result }) => {
  // These are technically all 'file'
  const { mimeType, image_url: imageUrl } = result;

  switch (mimeType) {
    case 'site': {
      return <GDriveSiteResult result={result} />;
    }

    case 'gform': {
      return <GDriveFormResult result={result} />;
    }

    case 'drawing': {
      return <GDriveDrawingResult result={result} />;
    }

    case 'doc': {
      return <GDriveDocResult result={result} />;
    }

    default: {
      if (imageUrl) {
        if (imageUrl.includes('gslides')) {
          return <GDriveSlideResult result={result} />;
        }

        if (imageUrl.includes('gsheet')) {
          return <GDriveSheetResult result={result} />;
        }
      }

      return <GeneralFileResult appName="Google Drive" result={result} />;
    }
  }
};

export const ResultComponent: FC<ResultComponentProps> = ({ result }) => {
  const appResults = simpleResults[result.source];
  if (appResults) {
    const ResComp = appResults[result.type];
    if (ResComp) {
      return <ResComp result={result} />;
    }
  }

  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (result.source) {
    case 'confluence': {
      switch (result.type) {
        case 'file': {
          return (
            <GeneralFileResult
              appName="Confluence Attachment"
              result={result}
            />
          );
        }
      }

      break;
    }

    case 'dropbox': {
      if (result.type === 'file') {
        return <GeneralFileResult appName="Dropbox file" result={result} />;
      }

      break;
    }

    case 'gdrive': {
      return <GDriveResult result={result} />;
    }

    case 'jira': {
      switch (result.type) {
        case 'issue': {
          if (result.issue_type) {
            return (
              <JiraIssueResult
                appName={`Jira ${result.issue_type}`}
                result={result}
              />
            );
          }
        }
      }

      break;
    }

    case 'slack': {
      if (result.type === 'file') {
        return <GeneralFileResult appName="Slack File" result={result} />;
      }
    }
  }

  const typeHash = `${result.source} ${result.type}`;
  if (isWebDev() && !seenTypes.has(typeHash)) {
    // eslint-disable-next-line no-console
    console.warn(`Unhandled result type ${typeHash}`);
    seenTypes.add(typeHash);
  }

  return null;
};
