import { OrgMember } from '../../scripts/models/org-member';

export const DEFAULT_ORG_MEMBERS_STATE: OrgMembersState = {
  members: [],
  searchedQueries: [],
};

export interface OrgMembersState {
  members: OrgMember[];
  searchedQueries: OrgMembersSearchQuery[];
}

export interface OrgMembersSearchQuery {
  q: string;
  page: number;
}
