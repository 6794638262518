import React, { useEffect, useState } from 'react';
import { batch } from '../../../../../../redux/store';
import { getPreviewData } from '../../../../../../scripts/apis';
import { createdModifiedMetaPairs } from '../../../../../../scripts/page-search/result-utils';
import { AppIcons } from '../../../../../controls/ui/UIIcon/UIIcon';
import {
  IFrameData,
  PreviewIframeType,
  PreviewItem,
} from '../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../previewProps';

interface GeneralCreatedPreviewProps extends PreviewProps {
  icon?: AppIcons | JSX.Element;
  fetchPreview?: boolean;
  /**
   * If specified will treat the preview as an image and use the `result` `thumbnail_url` as an image source.
   */
  asImage?: boolean;
}

export const GeneralCreatedPreview: React.FC<GeneralCreatedPreviewProps> = ({
  icon,
  fetchPreview,
  result,
  asImage,
}) => {
  const [iframeData, setIframeData] = useState<IFrameData | undefined>();
  const [isWorking, setIsWorking] = useState(false);

  const reset = () => {
    batch(() => {
      setIframeData(undefined);
      setIsWorking(false);
    });
  };

  useEffect(() => {
    reset();

    if (!fetchPreview) {
      return;
    }

    setIsWorking(true);

    const { objectID } = result;
    getPreviewData({
      objectId: objectID,
    })
      .then((data) => {
        setIsWorking(false);

        // Handles race condition where user may have moved onto another result
        // eslint-disable-next-line unicorn/consistent-destructuring
        if (objectID !== result.objectID) {
          reset();
          return;
        }

        const type = (data.content_type ||
          data.type ||
          'html') as PreviewIframeType;

        if (!data.content) {
          return;
        }

        setIframeData({
          content: data.content,
          type,
        });
      })
      .catch(() => {
        // If preview request fails, just show basic metadata
        reset();
      });
  }, [fetchPreview, result]);

  return (
    <PreviewItem
      icon={icon}
      iframe={iframeData}
      imgSrc={asImage ? result.thumbnail_link : undefined}
      isWorking={isWorking}
      metaPairs={createdModifiedMetaPairs(result)}
      result={result}
      title={result.unhighlighted_name}
    />
  );
};
