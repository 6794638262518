import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useTimeoutCreator, useToaster } from '../../../scripts/hooks';
import { Toast, ToastType } from '../../../scripts/models/toast';
import { UIIcon } from '../ui/UIIcon/UIIcon';
import { UIIconButton } from '../ui/UIIconButton/UIIconButton';

// Needed for offline since we are not able to lazy fetch the error icon
import(/* webpackPrefetch: true */ '../../../assets/icons/ui/error.svg');

interface ToastProps {
  toast: Toast;
}

export const ToastItem: React.FC<ToastProps> = ({
  toast: { uid, timeout = 0, type, message, dismissible },
}) => {
  const toaster = useToaster();
  const setTimeout = useTimeoutCreator();

  const closeToast = useCallback(() => {
    toaster.delete(uid);
  }, [toaster, uid]);

  useEffect(() => {
    // Use "0" to indicate sticky toast
    if (timeout > 0) {
      setTimeout(closeToast, timeout);
    }
  }, [closeToast, setTimeout, timeout, toaster]);

  let icon: ReactNode;
  switch (type) {
    case ToastType.SUCCESS: {
      icon = (
        <div className="toastIcon success">
          <UIIcon name="check" size={12} />
        </div>
      );

      break;
    }

    case ToastType.FAILURE: {
      icon = (
        <div className="toastIcon failure">
          <UIIcon name="error" size={12} />
        </div>
      );

      break;
    }

    case ToastType.LOADING: {
      icon = (
        <div className="toastIcon loading">
          <UIIcon name="spinner-animated" size={12} />
        </div>
      );
    }
  }

  return (
    <div className={classNames('toast', { dismissible })}>
      {dismissible && (
        <UIIconButton
          className="closeIcon"
          name="cross"
          onClick={closeToast}
          size={16}
        />
      )}
      {icon}
      <div className="toastMessage">{message}</div>
    </div>
  );
};
