// In production, we register a service worker to serve assets from local cache.

import { deploymentOrigin, logDebug, logError } from './scripts/utils';

/*
 * This lets the app load faster on subsequent visits in production, and gives
 * it offline capabilities. However, it also means that developers (and users)
 * will only see deployed updates on the 'N+1' visit to a page, since previously
 * cached resources are updated in the background.
 */

/*
 * To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
 * This link also includes instructions on opting out of this behavior.
 */
let _triedRegisteringServiceWorker = false;
export function registerServiceWorker(): void {
  if (!('serviceWorker' in navigator) || _triedRegisteringServiceWorker) {
    logDebug(
      `Didn't invoke navigator.serviceWorker.register().\n_triedRegisteringServiceWorker is ${_triedRegisteringServiceWorker.toString()} or navigator.serviceworker isn't available on this platform`
    );

    return;
  }

  window.addEventListener('load', () => {
    const swUrl = `${deploymentOrigin}/service-worker.js`;

    if (window.location.hostname === 'testing.dashworks.dev') {
      // This is running on localhost. Lets check if a service worker still exists or not.
      checkValidServiceWorker(swUrl);

      /*
       * Add some additional logging to localhost, pointing developers to the
       * service worker/PWA documentation.
       */
      navigator.serviceWorker.ready
        .then(() => {
          logDebug(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://goo.gl/SC7cgQ'
          );
        })
        .catch(logError);
    } else {
      // Is not local host. Just register service worker
      registerValidSW(swUrl);
    }
  });

  _triedRegisteringServiceWorker = true;
}

function registerValidSW(swUrl: string) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.addEventListener('updatefound', () => {
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.addEventListener('statechange', () => {
            logDebug(
              'installing service worker state:',
              installingWorker.state
            );

            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                /*
                 * At this point, the old content will have been purged and
                 * the fresh content will have been added to the cache.
                 * It's the perfect time to display a 'New content is
                 * available; please refresh.' message in your web app.
                 */
                logDebug('New content is available; please refresh.');
              } else {
                /*
                 * At this point, everything has been precached.
                 * It's the perfect time to display a
                 * 'Content is cached for offline use.' message.
                 */
                logDebug('Content is cached for offline use.');
              }
            }
          });
        }
      });
    })
    .catch(logError);
}

function checkValidServiceWorker(swUrl: string) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        !response.headers.get('content-type')!.includes('javascript')
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready
          .then((registration) => {
            registration
              .unregister()
              .then(() => {
                window.location.reload();
              })
              .catch(logError);
          })
          .catch(logError);
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      logDebug('No internet connection found. App is running in offline mode.');
    });
}

export function unregisterServiceWorker(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(async (registration) => {
        logDebug('Unregistering service worker', registration);
        return registration.unregister();
      })
      .catch(logError);
  }
}
