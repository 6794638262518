import React from 'react';
import './UserProfile.scss';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { getInitials } from '../../jit-qa/QAMessageView';

interface UserProfileProps {
  icon?: string;
  name?: string;
  email?: string;
}

export const UserProfileIcon = ({
  icon,
  name,
  email,
}: UserProfileProps): JSX.Element => {
  if (icon) {
    return (
      <img
        alt={name ?? 'User'}
        className="w-8 object-cover rounded-[50%]"
        src={icon}
      />
    );
  }

  if (name) {
    const displayInitials = getInitials(name);
    return (
      <div className="userInitials flex items-center justify-center">
        {displayInitials}
      </div>
    );
  }

  if (email) {
    const displayInitials = getInitials(email.split('@')[0]);
    return (
      <div className="userInitials flex items-center justify-center">
        {displayInitials}
      </div>
    );
  }

  return (
    <div className="userInitials flex items-center justify-center">
      <UIIcon name="person" size={24} />{' '}
    </div>
  );
};
