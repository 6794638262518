import React from 'react';
import { UIIcon } from '../../../../../../controls/ui/UIIcon/UIIcon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

export const HubspotCompanyResult: React.FC<ResultProps> = ({ result }) => {
  const domain = result.domain && result.domain !== '' ? result.domain : null;
  const breadcrumbs = domain ? [domain] : null;

  return (
    <PageSearchResultItem
      appName="HubSpot Company"
      breadcrumbs={breadcrumbs}
      hideModified
      icon={<UIIcon className="resultCompany" name="company" size={20} />}
      result={result}
      showLastItemBreadcrumb
    />
  );
};
