export interface CommonTokens {
  expires_at: number;
  id_token: string;
  refresh_token: string;
  username: string;
}

export function isGoogleTokens(tokens: CommonTokens): boolean {
  try {
    return (
      (
        JSON.parse(
          // Only deprecated in node!
          // eslint-disable-next-line deprecation/deprecation
          atob(tokens.id_token.split('.')[1]!)
        ) as {
          iss: string;
        }
      ).iss === 'accounts.google.com'
    );
  } catch {
    return false;
  }
}
