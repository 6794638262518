import React, { FC, useState } from 'react';
import { LoadingSplash } from '../components/splash/LoadingSplash';
import { AuthMessageType } from '../pages/auth/LoginPage/AuthWindow';
import { useAuthNotifier } from './hooks/auth';

interface NotifyAuthProps {
  target?: Window;
}

export interface AuthMessage {
  type: AuthMessageType;
  payload?: object;
}

export interface UnauthorizedAuthMessage extends AuthMessage {
  payload?: {
    message: string;
  };
}

export const NotifyAuth: FC<NotifyAuthProps> = ({ target, children }) => {
  const [loading, setLoading] = useState(false);
  useAuthNotifier(target, setLoading);

  // false positive
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return loading ? <LoadingSplash /> : <>{children}</>;
};
