import React, { FC, useCallback } from 'react';
import { trackEvent } from '../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import {
  useBoolState,
  useEditParam,
  useHistoryPush,
  useToaster,
} from '../../../scripts/hooks';
import { capitalize, logError } from '../../../scripts/utils';
import { ConfirmDialog, ConfirmMode } from '../Dialog/ConfirmDialog';
import { DeleteButton } from '../buttons/DeleteButton/DeleteButton';
import { CopyButton } from '../ui/CopyButton/CopyButton';
import { UIIconButton } from '../ui/UIIconButton/UIIconButton';
import { UISquareButtonRow } from '../ui/UISquareButtonRow/UISquareButtonRow';
import { UITooltip } from '../ui/UIToolTip/UIToolTip';

interface CopyAndEditProps {
  id: string;
  size?: number;
  // If set treat the button like a toggle for the edit param
  toggle?: boolean;
  subject: string;
  className?: string;
  onCopy?: () => void;
  onEdit?: () => void;
  onEditUnset?: () => void;
  readonly?: boolean;
  isEditing?: boolean;
  onDeleteConfirmed(): void;
  deleteFn(id: string): Promise<void>;
}

export const CopyEditDelete: FC<CopyAndEditProps> = ({
  id,
  size = 24,
  toggle,
  subject,
  className,
  onDeleteConfirmed,
  onCopy,
  onEdit,
  isEditing,
  onEditUnset,
  deleteFn,
  readonly,
}) => {
  const [deleteConfirm, openDeleteConfirm, closeDeleteConfirm] =
    useBoolState(false);

  const [edit] = useEditParam();
  const toaster = useToaster();

  const capitalized = capitalize(subject);

  const prefix = `${subject}s`;

  const isEdit =
    isEditing === undefined ? (!toggle || !edit ? 1 : 0) : !isEditing;

  const push = useHistoryPush(`/${prefix}/${id}?edit=${isEdit ? 1 : 0}`);

  const handleCopy = useCallback(() => {
    onCopy?.();
    trackEvent(AnalyticsEvent.CopyLink, {
      subject,
      id,
    });
  }, [id, onCopy, subject]);

  const handleConfirmDelete = useCallback(() => {
    toaster
      .withPromise(
        deleteFn(id),
        `${capitalized} deleted`,
        `Failed to delete ${subject}`
      )
      .then(() => {
        onDeleteConfirmed();
        closeDeleteConfirm();
      }, logError);
  }, [
    toaster,
    deleteFn,
    id,
    capitalized,
    subject,
    onDeleteConfirmed,
    closeDeleteConfirm,
  ]);

  return (
    <UISquareButtonRow className={className}>
      <CopyButton
        onCopy={handleCopy}
        size={size}
        square
        subject="url"
        value={`${location.origin}/${prefix}/${id}`}
      />
      {!readonly && (
        <>
          <UITooltip title={`${isEdit ? 'Edit' : 'View'} ${subject}`}>
            <UIIconButton
              name={isEdit ? 'pencil' : 'doc'}
              onClick={(isEdit ? onEdit : onEditUnset) ?? push}
              size={size}
              square
            />
          </UITooltip>
          <DeleteButton onClick={openDeleteConfirm} size={size} />
          {deleteConfirm && (
            <ConfirmDialog
              confirmButtonType="delete"
              mode={ConfirmMode.Delete}
              onCancel={closeDeleteConfirm}
              onConfirm={handleConfirmDelete}
              subject={capitalized}
            />
          )}
        </>
      )}
    </UISquareButtonRow>
  );
};
