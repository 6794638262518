import React, { useCallback, useState } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { withInstallModal } from '../../hoc/withInstallModal';
import { useLowerCase } from '../../scripts/hooks';
import { ConnectStep } from '../generic/Components';

export const AppInstallModal = withInstallModal(
  ({ handleOAuthInstall, connectInProgress }): JSX.Element => {
    const [subdomain, setSubdomain] = useLowerCase('');
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');

    const handleClickDone = useCallback(async () => {
      return handleOAuthInstall({ subdomain, clientId, clientSecret });
    }, [handleOAuthInstall, subdomain, clientId, clientSecret]);

    const connectDisabled =
      subdomain.trim() === '' ||
      clientId.trim() === '' ||
      clientSecret.trim() === '';

    return (
      <>
        <ConnectStep
          step={1}
          title="Prepare ServiceNow instance for integration"
        >
          <p>
            <strong>Important:</strong> Ensure you have all the relevant users
            added to the dev instance. For details on how to add new user see
            the{' '}
            <a
              href="https://docs.servicenow.com/bundle/rome-platform-administration/page/administer/users-and-groups/task/t_CreateAUser.html"
              rel="noreferrer"
              target="_blank"
            >
              Create a User Guide
            </a>
            .
          </p>
          <div className="instructionSubGroup">
            Install the following plugins:
            <ul>
              <li>
                {/* eslint-disable-next-line @cspell/spellchecker */}
                <code>com.sn_customerservice</code>
              </li>
              <li>
                <code>sn_km_api</code>
              </li>
            </ul>
            These plugins are required for Dashworks to function. Please follow
            the{' '}
            <a
              href="https://docs.servicenow.com/bundle/rome-platform-administration/page/administer/plugins/task/t_ActivateAPlugin.html"
              rel="noreferrer"
              target="_blank"
            >
              guide
            </a>
            &nbsp;to install them.
          </div>
        </ConnectStep>

        <ConnectStep
          step={2}
          title="Retrieve required connect information for ServiceNow"
        >
          <div className="instructionSubGroup">
            In order to connect with ServiceNow we require 3 pieces of
            information:
            <ul>
              <li>Your dev instance subdomain</li>
              <li>The client id</li>
              <li>The client secret</li>
            </ul>
          </div>
          <p>
            Go to <strong>ServiceNow Platform</strong> and enter your
            credentials.
          </p>
          <UIButton href="https://developers.servicenow.com">
            <strong>ServiceNow Platform</strong>
          </UIButton>
          <p>
            Click on <strong>Start building</strong> and search for{' '}
            <strong>Application Registry</strong>.
          </p>
          <p>
            Click on the <code>New</code> button and select{' '}
            <strong>Create an OAuth API endpoint for external clients</strong>.
          </p>
          <p>
            Enter <strong>Dashworks</strong> in the <strong>Name field</strong>.
          </p>
          <p>
            Copy and paste the following redirect URL and enter your subdomain.
          </p>
          <UITextbox
            className="stepFieldInput"
            label="Redirect URL"
            readonly
            size="large"
            useCopy
            value={`${SEARCH_URL}/oauth/redirect/servicenow`}
          />
        </ConnectStep>

        <ConnectStep step={3} title="Enter the following information">
          <div className="stepField">
            <p>
              Open the newly created client and enter the following information.
              <br />
              <br />
              <strong>
                You must be an Administrator in ServiceNow. Dashworks will
                request read-only access to cases and knowledge articles.
              </strong>
            </p>
            <UITextbox
              className="stepFieldInput"
              fixedText=".service-now.com"
              label="Subdomain"
              onChange={setSubdomain}
              placeholder="subdomain"
              size="large"
              value={subdomain}
            />
          </div>
          <br />
          <div className="stepField">
            <UITextbox
              className="stepFieldInput"
              label="Client ID"
              onChange={setClientId}
              placeholder="client id"
              size="large"
              value={clientId}
            />
          </div>
          <br />
          <div className="stepField">
            <UITextbox
              className="stepFieldInput"
              label="Client secret"
              onChange={setClientSecret}
              placeholder="client secret"
              size="large"
              type="password"
              value={clientSecret}
            />
          </div>

          {connectInProgress(handleClickDone, connectDisabled)}
        </ConnectStep>
      </>
    );
  }
  // CAST: Temporary until fixes are fully fixed
);
