import React from 'react';
import { MetaPair, PreviewItem } from '../../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../../previewProps';

export const SlackChannelPreview: React.FC<PreviewProps> = ({ result }) => {
  const metaPairs: MetaPair[] = [
    {
      name: 'Location',
      value: <span>{result.unhighlighted_name}</span>,
    },
  ];

  return (
    <PreviewItem
      icon="slack"
      metaPairs={metaPairs}
      result={result}
      title={result.unhighlighted_name}
    />
  );
};
