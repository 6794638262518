import React, { useEffect } from 'react';
import { User } from '../../../models/User';
import {
  FeatureFlags,
  useAllFlags,
  useGlobalState,
  useUserSafe,
} from '../../../scripts/hooks';

const identifyForAnalytics = (
  user: User,
  displayName: string,
  featureFlags: Partial<FeatureFlags>
) => {
  /*
   * Connect user in tracking tools
   * We use a custom Intercom launcher button, so need to hide the default
   * launcher. Ref: https://segment.com/docs/connections/destinations/catalog/intercom/
   */
  const nameTokens = displayName.split(/\s+/);
  const firstName = nameTokens[0] ?? '';
  const lastName = nameTokens[nameTokens.length - 1] ?? '';

  analytics.identify(
    user.userId,
    {
      email: user.email,
      is_admin: user.admin,
      first_name: firstName,
      last_name: lastName,
      name: displayName,
      company_name: user.orgByOrgId.name,
      company_domain: user.orgByOrgId.domain,
      version: APP_VERSION,
      featureFlags,
    },
    {
      Intercom: { hideDefaultLauncher: true },
    }
  );

  // Associate user with group
  analytics.group(user.orgByOrgId.domain, {
    name: user.orgByOrgId.name,
    domain: user.orgByOrgId.domain,
  });

  // Full story
  FS.identify(user.userId, {
    email: user.email,
    displayName: user.email,
    version: APP_VERSION,
  });
};

export const AnalyticsIdentifier: React.FC = React.memo(() => {
  const localUser = useUserSafe();
  const displayName = useGlobalState((state) => state.meta.userDisplayName);
  const flags = useAllFlags();

  useEffect(() => {
    identifyForAnalytics(localUser, displayName ?? 'n/a', flags);
  }, [localUser, displayName, flags]);

  // Only used for side effects, not rendering
  return null;
});

AnalyticsIdentifier.displayName = 'AnalyticsIdentifier';
