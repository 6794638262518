import React, { FC } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const CaptchaContainer: FC = ({ children }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} useEnterprise>
      {children}
    </GoogleReCaptchaProvider>
  );
};
