import React from 'react';

export const invalidUrl = (
  <>
    Invalid URL, must be of format <strong>https://example.com</strong>
  </>
);

export const duplicateTitle = (
  <>This title already exists, please choose a different one.</>
);
