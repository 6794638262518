import './AutoSuggestDashAiSearch.scss';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import dashworksLogo from '../../../../assets/images/dashworks_logo.svg';

interface AutoSuggestDashAiSearchProps {
  searchQuery: string;
  selectedIndex: number;
  text: string;
  onMouseEnter: (index: number) => void;
  index: number;
  onClick: () => void;
}

export const AutoSuggestDashAiSearch: React.FC<
  AutoSuggestDashAiSearchProps
> = ({ searchQuery, selectedIndex, onMouseEnter, index, onClick, text }) => {
  const handleMouseEnter = useCallback(() => {
    onMouseEnter(index);
  }, [index, onMouseEnter]);

  searchQuery = searchQuery.trim() ? `${searchQuery} -` : '';

  return (
    <div
      className={classNames('dashAiSearch', {
        active: selectedIndex === index,
      })}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
    >
      <img src={dashworksLogo} style={{ width: 16 }} />
      <span
        className={classNames('query', {
          noQuery: searchQuery.length === 0,
        })}
      >
        {searchQuery}
      </span>
      <span className="label">{text}</span>
    </div>
  );
};
