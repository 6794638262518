import React, { FC } from 'react';
import { useAllFlags } from '../../scripts/hooks';
import { kebabCase } from '../../scripts/utils';
import { launchDarklyFlag, launchDarklyOverview } from '../links/links';
import './FeatureFlagTable.scss';

export const FeatureFlagTable: FC = () => {
  const flags = Object.entries(useAllFlags());

  return (
    <div className="featureFlagTable">
      <a
        href={launchDarklyOverview}
        style={{ color: 'black', fontSize: '12px' }}
      >
        Launch Darkly
      </a>
      <table style={{ fontSize: '10px' }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
            <th>Send to server</th>
          </tr>
        </thead>
        <tbody>
          {flags.map(([name, value]) => {
            const realName = kebabCase(name);
            return (
              <tr key={name}>
                <td>
                  <a href={launchDarklyFlag(realName)} title={name}>
                    {realName}
                  </a>
                </td>
                <td>{JSON.stringify(value)}</td>
                <td>{name.startsWith('search') ? 'Y' : 'N'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
