import React, { FC, useCallback } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { useToggle } from '../../../../scripts/hooks';
import { BackLink } from '../../../../scripts/models/page-search-result';
import { UIButton } from '../../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import './BackLinks.scss';

interface BacklinksProps {
  backlinks: BackLink[];
}

interface BackLinkProps {
  backlink: BackLink;
}

const BackLinkRow: FC<BackLinkProps> = ({
  backlink: { name, objectID, source, webview_link },
}) => {
  const handleClick = useCallback(() => {
    trackEvent(AnalyticsEvent.BacklinkClick, {
      objectID,
      source,
    });
  }, [objectID, source]);

  return (
    <div className="backlink">
      <UIIcon name={source} size={16} type="apps" />
      <a
        href={webview_link}
        onClick={handleClick}
        rel="noreferrer"
        target="_blank"
      >
        {name}
      </a>
    </div>
  );
};

export const Backlinks: FC<BacklinksProps> = ({ backlinks }) => {
  const [collapsed, toggleCollapsed] = useToggle(true);
  const collapsedCount = Math.max(0, backlinks.length - 3);
  const shouldOfferCollapsed = collapsedCount > 0;
  const showCollapse = collapsed && shouldOfferCollapsed;
  return (
    <div className="backlinks">
      <span className="heading">
        Mentioned in&nbsp;
        <UIIcon
          name="info"
          size={12}
          tooltip="See where this result is mentioned across your apps. We currently show mentions from limited apps and plan to support more over time."
        />
      </span>

      {backlinks.slice(0, showCollapse ? 3 : undefined).map((bl) => (
        <BackLinkRow backlink={bl} key={bl.objectID} />
      ))}
      {shouldOfferCollapsed && (
        <UIButton onClick={toggleCollapsed} size="small" type="flat">
          {collapsed ? `+${collapsedCount} more` : '- Show Less'}
        </UIButton>
      )}
    </div>
  );
};
