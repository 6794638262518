import './PinnedResultContainer.scss';
import React, { FC } from 'react';
import { UIInfoTip } from '../../../controls/ui/UIInfoTip/UIInfoTip';

export const PinnedResultsContainer: FC = ({ children }) => (
  <div className="pinnedResultsContainer">
    <div className="title">
      <span>Pinned by your team</span>
      <UIInfoTip title="Pinned by your team">
        <p>
          Everyone in the company can pin an item to be prioritized for related
          search queries.
        </p>
      </UIInfoTip>
    </div>
    {children}
  </div>
);
