import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ctorMap } from '../../../apps';
import { useApps } from '../../../apps/context';
import {
  AppCategory,
  AppName,
  useAppDefinitions,
} from '../../../apps/definition';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import {
  integrationInstallMessages,
  integrationUninstallMessages,
} from '../../../messages';
import { StaticAnswerType } from '../../../models/QAmodels';
import { AppConnectionStatus } from '../../../models/User';
import { useAppConnectionStatus } from '../../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';
import { useDispatch } from '../../../redux/store';
import { useQAController } from '../../../scripts/QAController';
import { App } from '../../../scripts/app';
import { useBoolState, useToaster, useUserSafe } from '../../../scripts/hooks';
import { useExtensionObserver } from '../../../scripts/hooks/extension';
import {
  ManualFetchDataType,
  useManualDataFetch,
} from '../../../scripts/hooks/manual-trigger-hooks';
import {
  extensionEnabledBrowser,
  installExtension,
  isChrome,
  logError,
} from '../../../scripts/utils';
import {
  AppInstallController,
  AppInstallControllerProvider,
  AppInstallEventArgs,
  AppInstallRequest,
} from '../../admin/AppsTable/AppInstallController';
import {
  ConnectButton,
  PersonalButton,
} from '../../admin/AppsTableRowOld/AppsTableRowOld';
import {
  ConfirmDialog,
  ConfirmMode,
} from '../../controls/Dialog/ConfirmDialog';
import { InstallChromeExtensionDialog } from '../../controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../controls/ui/UIIconButton/UIIconButton';
import { useBound } from '../../pageSearch/results/misc/hooks';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../shadcn/lib/components/card';

// eslint-disable-next-line max-lines-per-function
export const ConnectAppsModal: FC = () => {
  const [open, setOpen] = useState(true);

  const apps = useApps();
  const definitions = useAppDefinitions();
  const appInstallController = useMemo(() => new AppInstallController(), []);
  const toaster = useToaster();
  const userApps = useAppConnectionStatus();
  const { appPreferences } = useUserSafe((user) => user.orgByOrgId);

  const qaController = useQAController();
  const messages = qaController.useMessages();

  const extensionInstalled = useExtensionObserver();

  const [
    openInstallChromeExtensionDialog,
    setOpenInstallChromeExtensionDialog,
    setNotOpenInstallChromeExtensionDialog,
  ] = useBoolState(false);

  const filteredApps = useMemo(() => {
    if (
      appPreferences.recommended_apps_list &&
      appPreferences.recommended_apps_list.length >= 2
    ) {
      const recommendedApps: App[] =
        appPreferences.recommended_apps_list.reduce((acc: App[], curr) => {
          if (checkIfOrgAppConnected(curr.name, userApps)) {
            return acc;
          }

          const definition = definitions[curr.name];
          const AppConstructor = ctorMap.get(curr.name);

          if (definition && AppConstructor && definition.showInAppStore) {
            acc.push(new AppConstructor(definition));
          }

          return acc;
        }, []);

      return recommendedApps;
    }

    const popularApps = apps.filter(
      (app) =>
        app.definition.appCategory?.includes(AppCategory.POPULAR) &&
        !checkIfOrgAppConnected(app.definition.shortname, userApps)
    );

    popularApps.sort((appA, appB) => {
      const appASortOrder = appA.definition.popularAppSortOrder;
      const appBSortOrder = appB.definition.popularAppSortOrder;

      if (appASortOrder && appBSortOrder) {
        return appASortOrder - appBSortOrder;
      }

      if (appASortOrder) return -1;
      if (appBSortOrder) return 1;
      return 0;
    });

    return popularApps;
  }, [apps, appPreferences, definitions, userApps]);

  const connectedFilteredApps = useMemo(
    () =>
      filteredApps.filter((app) =>
        checkIfPersonalAppConnected(app.definition.shortname, userApps)
      ),
    [filteredApps, userApps]
  );

  const unconnectedFilteredApps = useMemo(
    () =>
      filteredApps
        .filter((app) => !connectedFilteredApps.includes(app))
        .slice(
          0,
          Math.min(filteredApps.length, 3) -
            Math.min(connectedFilteredApps.length, 3)
        ),
    [filteredApps, connectedFilteredApps]
  );

  const showModal = useMemo(() => {
    const isOnboardingMessageFetched = messages.some(
      (qaMessage) =>
        // eslint-disable-next-line @cspell/spellchecker
        qaMessage.extraData?.staticAnswerType ===
          StaticAnswerType.ONBOARDING_INITAL ||
        qaMessage.extraData?.staticAnswerType ===
          StaticAnswerType.ONBOARDING_ASK_QUESTION
    );

    const questionsAsked = messages.filter(
      (qaMessage) => qaMessage.sender === 'USER'
    );

    return (
      isOnboardingMessageFetched &&
      questionsAsked.length === 1 &&
      (unconnectedFilteredApps.length > 0 ||
        (extensionEnabledBrowser && !extensionInstalled)) &&
      open
    );
  }, [messages, unconnectedFilteredApps, extensionInstalled, open]);

  const [currentAppInstallRequest, setCurrentAppInstallRequest] = useState<
    AppInstallRequest | undefined
  >();

  const [currentAppUninstallRequest, setCurrentAppUninstallRequest] = useState<
    AppInstallRequest | undefined
  >();

  const unsetInstallApp = useBound(setCurrentAppInstallRequest, undefined);
  const unsetUninstallApp = useBound(setCurrentAppUninstallRequest, undefined);
  const dispatch = useDispatch();
  const manualTriggerAppRefresh = useManualDataFetch(
    dispatch,
    ManualFetchDataType.APPS
  );

  const InstallDialog = currentAppInstallRequest?.app.getInstallDialog(
    currentAppInstallRequest.isOrg
  );

  const handleHideAppsNotifications = useCallback(() => {
    setOpen(false);
  }, []);

  const showUninstallModal = useCallback(
    ({ appUninstallRequest }: Partial<AppInstallEventArgs>) => {
      setCurrentAppUninstallRequest(appUninstallRequest);
    },
    [setCurrentAppUninstallRequest]
  );

  const handleInstall = useCallback(
    ({ appInstallRequest }: Partial<AppInstallEventArgs>) => {
      if (!appInstallRequest) {
        return;
      }

      setCurrentAppInstallRequest(appInstallRequest);

      if (!appInstallRequest.app.getInstallDialog(appInstallRequest.isOrg)) {
        // there is no dialog for this app, install it now
        toaster
          .withPromise(
            appInstallRequest.app
              .install(appInstallRequest.isOrg)
              .then(manualTriggerAppRefresh),
            ...integrationInstallMessages(appInstallRequest.app.definition),
            6000
          )
          .catch(logError);
      }
    },
    [setCurrentAppInstallRequest, toaster, manualTriggerAppRefresh]
  );

  const handleUninstall = useCallback(() => {
    if (currentAppUninstallRequest?.app) {
      toaster
        .withPromise(
          currentAppUninstallRequest.app
            .uninstall(currentAppUninstallRequest.isOrg)
            .then(manualTriggerAppRefresh),
          ...integrationUninstallMessages(
            currentAppUninstallRequest.app.definition.displayName
          ),
          6000
        )
        .catch(logError);

      setCurrentAppUninstallRequest(undefined);
    }
  }, [currentAppUninstallRequest, manualTriggerAppRefresh, toaster]);

  useEffect(() => {
    const event_listener_handle_install = (
      eventArgs: Partial<AppInstallEventArgs>
    ) => {
      handleInstall(eventArgs);
    };

    const event_listener_handle_uninstall = (
      eventArgs: Partial<AppInstallEventArgs>
    ) => {
      showUninstallModal(eventArgs);
    };

    appInstallController.on(
      'handle_app_install',
      event_listener_handle_install
    );

    appInstallController.on(
      'handle_app_uninstall',
      event_listener_handle_uninstall
    );

    return () => {
      appInstallController.off(
        'handle_app_install',
        event_listener_handle_install
      );

      appInstallController.off(
        'handle_app_uninstall',
        event_listener_handle_uninstall
      );
    };
  }, [appInstallController, handleInstall, showUninstallModal]);

  useEffect(() => {
    qaController.listenEvent(
      'hideAppsNotifications',
      handleHideAppsNotifications
    );

    return () => {
      qaController.off('hideAppsNotifications', handleHideAppsNotifications);
    };
  }, [qaController, handleHideAppsNotifications]);

  return showModal ? (
    <>
      <AppInstallControllerProvider value={appInstallController}>
        <Card className="sm:block hidden max-h-64 fixed top-14 right-8 z-30 overflow-auto animate-in fade-in ease-in-out duration-1000">
          <CardHeader className="flex flex-row justify-between">
            <CardTitle className="text-lg">
              {unconnectedFilteredApps.length === 0 ? (
                <span>Dash AI works best when you have this installed</span>
              ) : (
                <span>Dash AI works best with 4 Apps connected</span>
              )}
            </CardTitle>
            <UIIconButton
              name="cross"
              onClick={() => {
                setOpen(false);
              }}
            />
          </CardHeader>
          <CardContent className="w-full flex items-start gap-2 flex-col flex-wrap flex-none order-none self-stretch grow-0">
            {unconnectedFilteredApps.map((app) => (
              <div
                className="flex items-center justify-between gap-8 p-2 flex-none order-none self-stretch grow rounded bg-cloud-10"
                key={app.definition.shortname}
              >
                <div className="flex items-center gap-2">
                  {app.definition.icon ?? (
                    <UIIcon
                      name={app.definition.shortname}
                      size={24}
                      type="apps"
                    />
                  )}
                  <span className="text-sm font-medium">
                    {app.definition.displayName.length > 20
                      ? `${app.definition.displayName.slice(0, 20)}...`
                      : app.definition.displayName}
                  </span>
                </div>
                <PersonalButton
                  app={app}
                  appConnectionType={app.definition.connectionType}
                />
              </div>
            ))}
            {extensionEnabledBrowser && !extensionInstalled && (
              <div className="flex items-center justify-between gap-8 p-2 flex-none order-none self-stretch grow rounded bg-cloud-10">
                <div className="flex items-center gap-2">
                  {isChrome ? (
                    <>
                      <UIIcon name="chrome" size={24} type="apps" />
                      <div>
                        <div className="text-sm font-medium">
                          Chrome Extension
                        </div>
                        <div className="text-sm text-gray-50">
                          Required in order to connect some apps
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <UIIcon name="firefox" size={24} type="apps" />
                      <span>Firefox Extension</span>
                    </>
                  )}
                </div>
                <ConnectButton
                  onClick={
                    isChrome
                      ? setOpenInstallChromeExtensionDialog
                      : installExtension
                  }
                  text="Install"
                />
              </div>
            )}
          </CardContent>
          <CardFooter className="mt-4">
            <NavLink
              className="text-xs underline text-amethys-10 hover:text-amethys-20"
              to={SidebarRoutes.AppsV2}
            >
              All Apps
            </NavLink>
          </CardFooter>
        </Card>
      </AppInstallControllerProvider>

      {InstallDialog && (
        <InstallDialog
          app={currentAppInstallRequest!.app}
          isOrg={currentAppInstallRequest!.isOrg}
          onClose={unsetInstallApp}
          open={!!currentAppInstallRequest}
        />
      )}
      {currentAppUninstallRequest && (
        <ConfirmDialog
          confirmButtonText="Disconnect"
          confirmButtonType="delete"
          mode={ConfirmMode.Delete}
          onCancel={unsetUninstallApp}
          onConfirm={handleUninstall}
          subject="app"
          title={`Disconnect ${currentAppUninstallRequest.app.definition.displayName}`}
          verb="disconnect"
        />
      )}
      <InstallChromeExtensionDialog
        onClose={setNotOpenInstallChromeExtensionDialog}
        open={openInstallChromeExtensionDialog}
      />
    </>
  ) : null;
};

const checkIfOrgAppConnected = (
  app: AppName,
  userApps: AppConnectionStatus[]
) => {
  return userApps.some((userApp) => userApp.appName === app && userApp.isOrg);
};

const checkIfPersonalAppConnected = (
  app: AppName,
  userApps: AppConnectionStatus[]
) => {
  return userApps.some((userApp) => userApp.appName === app && !userApp.isOrg);
};
