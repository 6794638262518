import { SxProps, Tooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';
import React, { useCallback } from 'react';

const CustomTooltip: React.FC<SxProps & TooltipProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const sx = {
    tooltip: {
      backgroundColor: 'black',
      color: 'white',
      opacity: 1,
      fontSize: 12,
      padding: '10px 14px',
      borderRadius: '8px',
    },
    arrow: {
      color: 'black',
      overflow: 'visible',
      '&::before': {
        borderBottomRightRadius: '2px',
      },
    },
  };

  return (
    <Tooltip
      {...props}
      onClick={handleClose}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      sx={sx}
    />
  );
};

interface Props {
  title: React.ReactFragment | string;
  placement?: TooltipProps['placement'];
  children: React.ReactElement;
  delay?: number;
  disableHover?: boolean;
}

export const UITooltip = ({
  title,
  placement = 'top',
  delay,
  children,
  disableHover = false,
}: Props): JSX.Element => {
  return (
    <CustomTooltip
      // Needs to be a JSX element, otherwise the regular tooltip is shown as well.
      arrow
      className="appTooltip"
      disableHoverListener={disableHover}
      enterDelay={delay}
      placement={placement}
      // eslint-disable-next-line react/jsx-no-useless-fragment
      title={typeof title === 'string' ? <>{title}</> : title}
    >
      {children}
    </CustomTooltip>
  );
};
