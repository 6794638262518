import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { UploadedFile } from '../../models/File';
import { SupportedLlm } from '../../models/User';
import { WorkflowModalTypes } from '../../models/Workflows';
import {
  QAControllerEventArgs,
  useQAController,
} from '../../scripts/QAController';
import { useUserSafe } from '../../scripts/hooks';
import { ConnectedApps } from '../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../scripts/models/answers';
import { Modal } from '../controls/ui/Modal/Modal';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../controls/ui/UIIconButton/UIIconButton';
import { CreateWorkflowTemplate } from './CreateWorkflowTemplate';
import { DeleteWorkflowTemplate } from './DeleteWorkflowTemplate';
import { EditWorkflowTemplate } from './EditWorkflowTemplate';
import { ViewWorkflowTemplate } from './ViewWorkflowTemplate';
import { WorkflowTemplateTable } from './WorkflowTemplateTable';

export interface IInnerComponent {
  onClose: () => void;
  workflowId?: string;
}

interface IQAWorkflowTemplateModalProps {
  refetchWorkflows?: () => Promise<void>;
  setOpenPaywallModal(): void;
}

// eslint-disable-next-line max-lines-per-function
export const QAWorkflowTemplateModal: FC<IQAWorkflowTemplateModalProps> = ({
  refetchWorkflows,
  setOpenPaywallModal,
}) => {
  const isAdmin = useUserSafe((user) => user.admin);

  const [heading, setHeading] = useState<string>('Workflows');
  const [showWorkflowModal, setShowWorkflowModal] = useState<boolean>(false);
  const [workflowId, setWorkflowId] = useState<string | undefined>(undefined);
  const [workflowModalType, setWorkflowModalType] = useState<
    string | undefined
  >(undefined);

  const [workflowTemplate, setWorkflowTemplate] = useState<
    string | undefined
  >();

  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [isAuthor, setIsAuthor] = useState<boolean>(false);
  const [isBaseLLM, setIsBaseLLM] = useState<boolean | undefined>(undefined);
  const [skillFilters, setSkillFilters] = useState<ConnectedApps[] | undefined>(
    undefined
  );

  const [fileFilters, setFileFilters] = useState<UploadedFile[] | undefined>(
    undefined
  );

  const [answerFilters, setAnswerFilters] = useState<Answer[] | undefined>(
    undefined
  );

  const [preferredLlm, setPreferredLlm] = useState<
    SupportedLlm | null | undefined
  >(undefined);

  const qaController = useQAController();

  const handleShowWorkflowModal = useCallback(
    ({
      isDefault: incomingIsDefault,
      isAuthor: incomingIsAuthor,
      workflowModalType: incomingWorkflowModalType,
      workflowId: incomingWorkflowId,
      workflowTemplate: incomingWorkflowTemplate,
      isBaseLLM: incomingIsBaseLLM,
      skillFilters: incomingSkillFilters,
      fileFilters: incomingFileFilters,
      answerFilters: incomingAnswerFilters,
      preferredLlm: incomingPreferredLlm,
    }: Partial<QAControllerEventArgs>) => {
      setWorkflowModalType(incomingWorkflowModalType);

      // eslint-disable-next-line unicorn/no-negated-condition
      if (incomingIsAuthor !== undefined) {
        setIsAuthor(incomingIsAuthor);
      } else {
        setIsAuthor(false);
      }

      // eslint-disable-next-line unicorn/no-negated-condition
      if (incomingIsDefault !== undefined) {
        setIsDefault(incomingIsDefault);
      } else {
        setIsDefault(false);
      }

      if (incomingWorkflowId !== undefined) {
        setWorkflowId(incomingWorkflowId);
      }

      if (incomingWorkflowTemplate !== undefined) {
        setWorkflowTemplate(incomingWorkflowTemplate);
      }

      if (incomingIsBaseLLM !== undefined) {
        setIsBaseLLM(incomingIsBaseLLM);
      }

      if (incomingSkillFilters !== undefined) {
        setSkillFilters(incomingSkillFilters);
      }

      if (incomingFileFilters !== undefined) {
        setFileFilters(incomingFileFilters);
      }

      if (incomingAnswerFilters !== undefined) {
        setAnswerFilters(incomingAnswerFilters);
      }

      if (incomingPreferredLlm !== undefined) {
        setPreferredLlm(incomingPreferredLlm);
      }

      setShowWorkflowModal(true);
    },
    []
  );

  const closeWorkflowModal = useCallback(() => {
    if (refetchWorkflows) {
      refetchWorkflows();
    }

    setShowWorkflowModal(false);
    setWorkflowModalType(undefined);
    setWorkflowId(undefined);
  }, [refetchWorkflows]);

  const handleEditButtonClick = useCallback(() => {
    setWorkflowModalType('EDIT');
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    setWorkflowModalType('DELETE');
  }, []);

  const handleDuplicateButtonClick = useCallback(() => {
    setWorkflowModalType('DUPLICATE');
  }, []);

  useEffect(() => {
    qaController.listenEvent('showWorkflowModal', handleShowWorkflowModal);
    return () => {
      qaController.off('showWorkflowModal', handleShowWorkflowModal);
    };
  }, [qaController, handleShowWorkflowModal]);

  const innerComponent = useMemo(() => {
    if (workflowModalType === WorkflowModalTypes.CREATE) {
      setHeading('New Workflow');
      return (
        <CreateWorkflowTemplate
          answerFilters={answerFilters}
          fileFilters={fileFilters}
          isBaseLLM={isBaseLLM}
          onClose={closeWorkflowModal}
          preferredLlm={preferredLlm}
          setOpenPaywallModal={setOpenPaywallModal}
          skillFilters={skillFilters}
          template={workflowTemplate}
        />
      );
    }

    if (workflowModalType === WorkflowModalTypes.DELETE) {
      setHeading('Delete Workflow');
      return (
        <DeleteWorkflowTemplate
          onClose={closeWorkflowModal}
          workflowId={workflowId}
        />
      );
    }

    if (workflowModalType === WorkflowModalTypes.DUPLICATE) {
      setHeading('Duplicate Workflow');
      return (
        <EditWorkflowTemplate
          duplicate
          onClose={closeWorkflowModal}
          setOpenPaywallModal={setOpenPaywallModal}
          workflowId={workflowId}
        />
      );
    }

    if (workflowModalType === WorkflowModalTypes.EDIT) {
      setHeading('Edit Workflow');
      return (
        <EditWorkflowTemplate
          duplicate={false}
          onClose={closeWorkflowModal}
          setOpenPaywallModal={setOpenPaywallModal}
          workflowId={workflowId}
        />
      );
    }

    if (workflowModalType === WorkflowModalTypes.VIEW_TEMPLATE) {
      setHeading('Workflow Preview');
      return (
        <ViewWorkflowTemplate
          onClose={closeWorkflowModal}
          workflowId={workflowId}
        />
      );
    }

    if (workflowModalType === WorkflowModalTypes.VIEW_ALL_TEMPLATES) {
      setHeading('Workflows');
      return <WorkflowTemplateTable onClose={closeWorkflowModal} />;
    }

    return null;
  }, [
    workflowModalType,
    answerFilters,
    fileFilters,
    isBaseLLM,
    closeWorkflowModal,
    preferredLlm,
    setOpenPaywallModal,
    skillFilters,
    workflowTemplate,
    workflowId,
  ]);

  const showTopActionButtons = useMemo(() => {
    return workflowModalType === 'VIEW_TEMPLATE';
  }, [workflowModalType]);

  if (!showWorkflowModal) {
    return null;
  }

  const isModalInEditorMode =
    workflowModalType === WorkflowModalTypes.CREATE ||
    workflowModalType === WorkflowModalTypes.EDIT ||
    workflowModalType === WorkflowModalTypes.DUPLICATE;

  return (
    <Modal
      modalClasses={classNames('h-[692px]', { 'pr-0': isModalInEditorMode })}
      onClose={closeWorkflowModal}
    >
      <div className="flex justify-between mb-6">
        <div className="flex gap-2 items-center">
          <UIIcon name="bolt" size={32} />
          <h2 className="text-2xl m-0 font-bold">{heading}</h2>
        </div>
        {showTopActionButtons && (
          <div className="flex gap-1">
            <div className="flex items-center py-1 px-2 rounded bg-cloud-15 hover:bg-cloud-20">
              <UIIconButton
                name="copy"
                onClick={handleDuplicateButtonClick}
                size={16}
                tooltip="Duplicate workflow"
              />
            </div>
            {!isDefault && (isAuthor || isAdmin) && (
              <>
                <div className="flex items-center py-1 px-2 rounded bg-cloud-15 hover:bg-cloud-20">
                  <UIIconButton
                    name="trash-can"
                    onClick={handleDeleteButtonClick}
                    size={16}
                    tooltip="Delete workflow"
                  />
                </div>
                <div className="flex items-center py-1 px-2 rounded bg-cloud-15 hover:bg-cloud-20">
                  <UIIconButton
                    name="pencil"
                    onClick={handleEditButtonClick}
                    size={16}
                    tooltip="Edit workflow"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {innerComponent}
    </Modal>
  );
};
