import React, { FC } from 'react';
import './PreviewContainer.scss';
import { usePageSearch } from '../../../../scripts/hooks';
import { ResultPreview } from '../../../../scripts/page-search/preview-lookup';

export const PreviewContainer: FC = () => {
  const result = usePageSearch((pageSearch) => pageSearch.preview?.result);

  return (
    <div className="previewContainer">
      {result && <ResultPreview result={result} />}
    </div>
  );
};
