import {
  AccessDiscoveryResult,
  PageSearchResult,
} from '../../scripts/models/page-search-result';
import { PersonResult } from '../../scripts/models/person-result';
import {
  AutosuggestResultEntry,
  CloudIndex,
  HitCounts,
  QueryAutosuggestResultEntry,
  RecentVisitsAutosuggestResultEntry,
  SearchPayload,
} from '../../search/CloudIndex';
import { SearchLoadState } from './actions';

export const DEFAULT_PAGE_SEARCH_STATE: PageSearchState = {
  results: [],
  pinnedResults: [],
  load: SearchLoadState.None,
  hasMore: false,
  page: 0,
  showFeedback: false,
  feedbackShown: false,
  suggestions: [],
  querySuggestions: [],
  recentVisitsSuggestions: [],
  accessDiscoveryResults: [],
  messages: [],
  hasMoreMessages: false,
  queryId: '',
};

export interface PageSearchPreviewState {
  result: PageSearchResult;
  offsetY: number;
}

export interface PageSearchState {
  /**
   * This property is only relevant when showing messages in the general search and should not be used for pagination.
   */
  hasMoreMessages: boolean;
  messages: PageSearchResult[];
  page: number;
  hitCounts?: HitCounts;
  cloudIndex?: CloudIndex;
  results: PageSearchResult[];
  pinnedResults: PageSearchResult[];
  personResult?: PersonResult;
  accessDiscoveryResults: AccessDiscoveryResult[];
  load: SearchLoadState;
  hasMore: boolean;
  version?: string;
  preview?: PageSearchPreviewState;
  showFeedback: boolean;
  feedbackShown: boolean;
  suggestions: AutosuggestResultEntry[];
  querySuggestions: QueryAutosuggestResultEntry[];
  recentVisitsSuggestions: RecentVisitsAutosuggestResultEntry[];
  querySpellCorrection?: string;
  queryId: string;
  searchRequest?: SearchPayload;
}
