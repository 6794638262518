import { invokeFastApi } from './fastapi';

export interface FeedbackRequestBody {
  query_id: string;
  response_id: string;
  rating?: number;
  comment?: string;
  references_feedback: Record<string, number>;
}

export const sendFeedback = async (
  feedback: FeedbackRequestBody
): Promise<void> => {
  await invokeFastApi({
    path: '/feedback',
    method: 'POST',
    body: feedback,
  });
};
