import React from 'react';
import { PageSearchResultItem } from '../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../resultProps';

export const SharepointListItemResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="SharePoint List Item"
      modifiedLabel="Last Modified"
      result={result}
    />
  );
};
