import React, { FC, useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import { UIButton } from '../../../components/controls/ui/UIButton/UIButton';
import { UITextbox } from '../../../components/controls/ui/UITextbox/UITextbox';
import { usePromiseState } from '../../../components/pageSearch/results/misc/hooks';
import {
  initUserCreation,
  InitUserCreationResult,
} from '../../../scripts/apis/auth';
import { QueryParams, useLoginEmail, useToaster } from '../../../scripts/hooks';
import { useAuthListener } from '../../../scripts/hooks/auth';
import { QueryPreservingLink } from '../../../scripts/routing/QueryPerservingLink';
import { isValidEmail, logError } from '../../../scripts/utils';
import { AuthActionContainer } from '../AuthActionContainer/AuthActionContainer';
import { AuthContainer } from '../AuthContainer/AuthContainer';
import { CaptchaContainer } from '../Captcha/Captcha';
import { GoogleAuthButton } from '../GoogleAuthButton/GoogleAuthButton';

export const SignupPage: FC = () => {
  const forcedSSO = useAuthListener();
  const toaster = useToaster();
  const history = useHistory();

  useEffect(() => {
    if (!forcedSSO) {
      return;
    }

    toaster.failure(
      'Your email address is associated with an organization, please login instead.'
    );

    history.push('/login');
  }, [forcedSSO, history, toaster]);

  return (
    <AuthContainer unauthorized={false}>
      <AuthActionContainer title="Sign up on Dashworks">
        <GoogleAuthButton>Continue with Google</GoogleAuthButton>
        <span className="self-center">or</span>

        <CaptchaContainer>
          <SignupForm />
        </CaptchaContainer>

        <span>
          Already have an account?{' '}
          <QueryPreservingLink to="/login">Sign in</QueryPreservingLink>
        </span>
      </AuthActionContainer>
    </AuthContainer>
  );
};

const SignupForm = () => {
  const [queryEmail] = useLoginEmail();

  const [email, setEmail] = useState(queryEmail);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const history = useHistory();
  const [working, workWrap] = usePromiseState();

  const toaster = useToaster();
  const captchaContext = useGoogleReCaptcha();

  const valid = isValidEmail(email) && firstName.trim() && lastName.trim();

  const handleEmailSignup = useCallback(() => {
    if (!valid) {
      return;
    }

    workWrap(
      (async () => {
        let captchaRes: string;
        try {
          captchaRes = await captchaContext.executeRecaptcha!('login');
        } catch (error) {
          logError(error);
          toaster.failure('CAPTCHA verification failed.');
          return;
        }

        const userRes = await initUserCreation({
          email,
          firstName,
          lastName,
          verification: captchaRes,
        });

        switch (userRes) {
          case InitUserCreationResult.AlreadyExists: {
            toaster.success('This account already exists, please login!');
            history.push({
              search: new URLSearchParams({
                [QueryParams.LoginEmail]: email,
              }).toString(),
              pathname: '/login',
            });

            break;
          }

          case InitUserCreationResult.Success: {
            toaster.success('Please check your inbox for the login link!');
            history.push({
              pathname: '/login',
              search: new URLSearchParams({
                [QueryParams.LoginEmail]: email,
                [QueryParams.LoginProvider]: 'email',
                [QueryParams.LoginStage]: '1',
              }).toString(),
            });

            break;
          }

          case InitUserCreationResult.Failed: {
            toaster.failure(
              'Failed to create new account! Please make sure you enter a valid work email.'
            );

            break;
          }
        }
      })()
    ).catch(logError);
  }, [
    captchaContext.executeRecaptcha,
    email,
    firstName,
    history,
    lastName,
    toaster,
    valid,
    workWrap,
  ]);

  return (
    <>
      <UITextbox
        autoComplete="email"
        autoFocus
        label="Work email"
        onChange={setEmail}
        placeholder="you@organization.com"
        type="email"
        value={email}
      />
      <div className="w-[70%] lgr:w-full contents lgr:flex gap-4">
        <div className="grow">
          <UITextbox
            autoComplete="given-name"
            label="First name"
            onChange={setFirstName}
            placeholder="First name"
            value={firstName}
          />
        </div>
        <div className="grow">
          <UITextbox
            autoComplete="family-name"
            label="Last name"
            onChange={setLastName}
            placeholder="Last name"
            value={lastName}
          />
        </div>
      </div>
      <UIButton
        disabled={!valid}
        onClick={handleEmailSignup}
        processing={working}
        size="large"
      >
        Sign up with Email
      </UIButton>
    </>
  );
};
