import { useMemo } from 'react';
import {
  setFeedbackShown,
  setShowFeedback,
} from '../../redux/pageSearch/actions';
import { useDispatch, useStore } from '../../redux/store';

interface Feedback {
  show(): void;
  hide(): void;
  reset(): void;
}

export const useFeedback = (): Feedback => {
  const dispatch = useDispatch();
  // we are using the store here to avoid a circular render issue
  const store = useStore();
  return useMemo(
    () => ({
      show() {
        const { pageSearch } = store.getState();
        // Only allow feedback to be shown once per search session
        if (pageSearch.showFeedback || pageSearch.feedbackShown) {
          return;
        }

        dispatch(setShowFeedback(true));
        dispatch(setFeedbackShown(true));
      },
      hide() {
        dispatch(setShowFeedback(false));
      },
      reset() {
        // On new search
        dispatch(setShowFeedback(false));
        dispatch(setFeedbackShown(false));
      },
    }),
    [dispatch, store]
  );
};
