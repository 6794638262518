import React, { FC, useCallback, useState } from 'react';
import { trackEvent } from '../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useToaster } from '../../../scripts/hooks';
import { Dialog } from '../Dialog/Dialog';
import { UIButton } from '../ui/UIButton/UIButton';
import { UITextArea } from '../ui/UITextArea/UITextArea';

interface ProductFeedbackDialogProps {
  open: boolean;
  onClose(): void;
}

export const ProductFeedbackDialog: FC<ProductFeedbackDialogProps> = ({
  open,
  onClose,
}) => {
  const [feedback, setFeedback] = useState('');

  const toaster = useToaster();

  const handleSubmit = useCallback(() => {
    trackEvent(AnalyticsEvent.ProductFeedback, { feedback });
    onClose();
    setFeedback('');
    toaster.success('Feature Request sent to the team');
  }, [feedback, toaster, onClose]);

  return (
    <Dialog
      content={
        <div>
          <div className="text-xl font-semibold">
            We would love your feedback
          </div>
          <div className="mt-6 text-sm text-gray-50">
            Share feedback, ideas, feature requests or anything you have in mind
          </div>
          <UITextArea
            autoFocus
            className="mt-2"
            defaultWhiteInputField
            maxRows={6}
            minRows={4}
            multilineExpandable
            onChange={setFeedback}
            placeholder="Enter details"
            value={feedback}
          />
          <div className="mt-6 text-sm">
            Prefer to do a quick feedback call instead?{' '}
            <a
              className="underline text-amethys-10 hover:text-amethys-20"
              href="https://calendly.com/dashworks-gtm/meet-product-team"
              rel="noreferrer"
              target="_blank"
            >
              Schedule time
            </a>
          </div>
        </div>
      }
      footer={
        <UIButton disabled={!feedback} onClick={handleSubmit}>
          Submit
        </UIButton>
      }
      onClose={onClose}
      open={open}
      showCancel
    />
  );
};
