import { setSearchHistory } from '../../redux/actions';
import { store } from '../../redux/store';
import { AllSearchRelatedParams } from '../hooks';
import { getStorageItem, StorageKey } from '../storage';
import { uniqBy } from '../utils';

const MAX_SEARCH_ITEMS = 1000;

export const pushSearchHistory = (item: AllSearchRelatedParams): void => {
  let searchHistory = getSearchHistory();

  // If the last search query is the same string, we simply replace (de-duping)
  if (searchHistory[0] && searchHistory[0].q === item.q) {
    searchHistory[0] = item;
  } else {
    searchHistory.unshift(item);
  }

  // We only keep the last 1000 items
  searchHistory = searchHistory.slice(0, MAX_SEARCH_ITEMS);
  store.dispatch(setSearchHistory(searchHistory));

  window.localStorage.setItem(
    StorageKey.SearchHistory,
    JSON.stringify(searchHistory)
  );
};

export const getSearchHistory = (): AllSearchRelatedParams[] => {
  const searchHistory =
    (getStorageItem(StorageKey.SearchHistory) as
      | AllSearchRelatedParams[]
      | null) ?? [];

  // Remove items with duplicate queries
  return uniqBy(searchHistory, 'q');
};
