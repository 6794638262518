import React from 'react';
import dashworksLogo from '../../../assets/images/dashworks_logo.svg';
import { UserImage } from '../UserImage/UserImage';
import { UITooltip } from '../ui/UIToolTip/UIToolTip';

interface IAuthorDetailsProps {
  icon?: string;
  isDefault?: boolean;
  displayName: string;
}

export const AuthorDetails: React.FC<IAuthorDetailsProps> = ({
  icon,
  isDefault,
  displayName,
}: IAuthorDetailsProps) => {
  if (isDefault) {
    return (
      <div className="flex items-center gap-2">
        <UserImage imageUrl={dashworksLogo} size={24} />
        <div className="font-normal">Dashworks</div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2 whitespace-nowrap">
      <UserImage
        displayName={displayName}
        fontSize={12}
        imageUrl={icon}
        size={24}
      />
      {displayName && displayName.length > 15 ? (
        <UITooltip title={displayName}>
          <div className="font-normal">{`${displayName.slice(0, 15)}...`}</div>
        </UITooltip>
      ) : (
        <div className="font-normal">{displayName}</div>
      )}
    </div>
  );
};
