import { Skeleton } from '@mui/material';
import React, { FC } from 'react';
import './QASkeleton.scss';

export const QaLoadingSkeletons: FC = () => (
  <div>
    <Skeleton sx={{ borderRadius: '8px' }} width="100%" />
    <div className="skeletonRow">
      <Skeleton sx={{ borderRadius: '8px' }} width="60%" />
      <Skeleton height={16} variant="circular" width={16} />
      <Skeleton sx={{ borderRadius: '8px' }} width="20%" />
      <Skeleton height={16} variant="circular" width={16} />
    </div>
    <div className="skeletonRow">
      <Skeleton sx={{ borderRadius: '8px' }} width="20%" />
      <Skeleton sx={{ borderRadius: '8px' }} width="20%" />
    </div>
  </div>
);
