import { makeLazyComponent } from '../../../scripts/utils';

export const LazyDirectorySyncPage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "directory-sync-page" */ './DirectorySyncPage'
      )
    ).DirectorySyncPage
);
