import React, { useEffect, useMemo, useState } from 'react';
import { batch } from '../../../../../../redux/store';
import { getPreviewData } from '../../../../../../scripts/apis';
import { Timestamp } from '../../../../../controls/Timestamp';
import { PersonAvatar } from '../../../../results/PersonAvatar/PersonAvatar';
import { IFrameData, PreviewItem } from '../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../previewProps';

export const NotionPagePreview: React.FC<PreviewProps> = ({ result }) => {
  const [iframeData, setIframeData] = useState<IFrameData | undefined>();
  const [isWorking, setIsWorking] = useState(false);
  const [contentUrl, setContentUrl] = useState<string | undefined>();

  const [owner] = result.owners;

  useEffect(() => {
    const reset = () => {
      batch(() => {
        setIframeData(undefined);
        setIsWorking(false);
      });
    };

    setIsWorking(true);

    getPreviewData({
      objectId: result.objectID,
    })
      .then((data) => {
        batch(() => {
          reset();
          setIframeData({
            content: data.content,
            type: 'html',
            contentCSS: NOTION_PAGE_CSS,
          });

          setContentUrl(data.full_url);
        });
      })
      .catch(reset);
  }, [result]);

  const metaPairs = useMemo(
    () => [
      {
        name: 'Creator',
        value: <span className="heavier">{owner?.name ?? 'Unknown'}</span>,
      },
      {
        name: 'Modified',
        value: (
          <span className="heavier">
            <Timestamp unixTime={result.modified_time} />
          </span>
        ),
      },
    ],
    [result, owner]
  );

  return (
    <PreviewItem
      contentUrl={contentUrl}
      icon="notion"
      iframe={iframeData}
      isWorking={isWorking}
      metaPairs={metaPairs}
      result={result}
      title={result.unhighlighted_name}
      titleIcon={
        owner?.photo ? (
          <PersonAvatar imageUrl={owner.photo} source={result.source} />
        ) : undefined
      }
    />
  );
};

const NOTION_PAGE_CSS = `
body {
  font-size: 80%;
  font-family: sans-serif;
  padding: 0;
  margin: 0;
}

.block {
  margin: 10px;
}
.t-bold {
  font-weight: bold;
}
.t-italics {
  font-style: italics;
}
.t-underline {
  text-decoration: underline;
}
.t-strikethrough {
  text-decoration: line-through;
}
.t-code {
  font-family: 'SFMono-Regular', Menlo, Consolas, 'PT Mono', 'Liberation Mono',
    Courier, monospace;
  line-height: normal;
  background: rgba(135, 131, 120, 0.15);
  color: #eb5757;
  border-radius: 3px;
  font-size: 85%;
  padding: 0.2em 0.4em;
}
.t-anchor {
  text-decoration: underline;
  color: gray;
}
.page {
  margin-top: 0;
}

.text_page {
  display: none;
}

.header {
  margin-top: 20px;
  font-size: 1.25rem;
  font-weight: 400;
}

.sub_header {
  margin-top: 16px;
  font-size: 1.2rem;
  font-weight: 500;
}

.sub_sub_header {
  margin-top: 10px;
  font-size: 1.1rem;
  font-weight: 400;
}

.bulleted_list,
.numbered_list,
.to_do {
  display: list-item;
  margin-left: 30px;
}

.numbered_list {
  list-style-type: decimal;
}

.to_do {
  list-style-type: '▢  ';
}

.callout {
  border-radius: 3px;
  background: rgb(241, 241, 239);
  padding: 10px;
}

.code,
.pre {
  border-radius: 3px;
  background: #eee;
  padding: 10px;
  font-family: 'SFMono-Regular', Menlo, Consolas, 'PT Mono', 'Liberation Mono',
    Courier, monospace;
}
.code .txt,
.callout .txt {
  display: block;
}
`;
