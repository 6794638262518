import React from 'react';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

export const MSTeamsMessageResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="MSTeams Message"
      breadcrumbs={result.file_path}
      hideModified
      name={result.owners[0]?.name}
      result={result}
      sentTime={result.created_time}
      showLastItemBreadcrumb
    />
  );
};
