import Autoplay from 'embla-carousel-autoplay';
import React, { FC } from 'react';
import dashAiUseCase1 from '../../../assets/images/dash_ai_use_case_1.webp';
import dashAiUseCase2 from '../../../assets/images/dash_ai_use_case_2.webp';
import dashAiUseCase3 from '../../../assets/images/dash_ai_use_case_3.webp';
import { privacyPolicy, termsOfService } from '../../../components/links/links';
import { BookCallStep } from '../../../components/onboarding/steps/BookCallStep/BookCallStep';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '../../../components/shadcn/lib/components/carousel';

export const AuthContainer: FC<{
  unauthorized: boolean;
}> = ({ children, unauthorized }) => {
  return (
    <div className="h-screen cursor-default flex">
      <div className="w-full sm:w-1/2 bg-cloud-10 relative flex justify-center items-center">
        <div>{unauthorized ? <BookCallStep /> : children}</div>
        <div className="absolute bottom-4 text-xs sm:text-sm text-center m-1">
          By continuing, you acknowledge that you have read, and agree to our{' '}
          <a
            className="font-medium text-amethys-10 hover:text-amethys-20"
            href={termsOfService}
            rel="noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            className="font-medium text-amethys-10 hover:text-amethys-20"
            href={privacyPolicy}
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy.
          </a>
        </div>
      </div>

      <div className="w-1/2 pt-6 overflow-y-auto hidden sm:flex flex-col justify-evenly items-center bg-gradient-to-b from-amethys-10 to-white">
        <Carousel
          className="w-[300px] md:w-[380px] lg:w-[450px] 2xl:w-[580px]"
          opts={{
            loop: true,
          }}
          plugins={[
            // eslint-disable-next-line new-cap
            Autoplay({
              delay: 3000,
              stopOnInteraction: false,
            }),
          ]}
        >
          <CarouselContent>
            <CarouselItem className="basis-1/24">
              <img
                className="w-[300px] md:w-[380px] lg:w-[450px] 2xl:w-[580px]"
                src={dashAiUseCase1}
              />
            </CarouselItem>
            <CarouselItem className="basis-1/24">
              <img
                className="w-[300px] md:w-[380px] lg:w-[450px] 2xl:w-[580px]"
                src={dashAiUseCase2}
              />
            </CarouselItem>
            <CarouselItem className="basis-1/24">
              <img
                className="w-[300px] md:w-[380px] lg:w-[450px] 2xl:w-[580px]"
                src={dashAiUseCase3}
              />
            </CarouselItem>
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  );
};
