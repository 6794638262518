import React, { FC } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  open,
  onClose,
}) => {
  return (
    <SimpleAPIKeyInstall
      app={app}
      instructionSteps={
        <ConnectStep step={1} title="Generate API key">
          <p>
            Go to your Clickup account.{' '}
            <strong>
              The user providing the token must be an Admin in Clickup.
            </strong>
          </p>

          <div className="actionContainer">
            <UIButton href="https://app.clickup.com/settings/apps">
              Open Account settings
            </UIButton>
          </div>

          <p>
            On the opened page, click on <strong>Regenerate</strong> or copy an
            existing key.
          </p>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
    />
  );
};
