import { makeLazyComponent } from '../../../scripts/utils';

export const LazyWorkspaceSetupPage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "workspace-setup-page" */ './WorkspaceSetupPage'
      )
    ).WorkspaceSetupPage
);
