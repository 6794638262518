import './UIButton.scss';
import classNames from 'classnames';
import { LocationDescriptor } from 'history';
import React, { FC, forwardRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { UIIcon } from '../UIIcon/UIIcon';
import { UITooltip } from '../UIToolTip/UIToolTip';

export type UIButtonType =
  | 'badge'
  | 'caution'
  | 'delete'
  | 'disabled'
  | 'flat'
  | 'ghost'
  | 'primary'
  | 'secondary'
  | 'status'
  | 'success'
  | 'warning';

export interface UIButtonProps {
  type?: UIButtonType;

  size?: 'large' | 'long' | 'normal' | 'small' | 'tiny' | 'xLarge';
  // Whether to show spinner
  processing?: boolean;

  // Custom classes
  className?: string;

  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  id?: string;
  tabIndex?: number;

  /**
   * If specified navigates to the specified url.
   * This should be used for external links.
   */
  href?: string;

  noBlank?: boolean;

  /**
   * If specified uses react router linking.
   */
  link?: LocationDescriptor | string;
  /**
   * By default the click event is stopped from propagating, this re-enables it.
   */
  propagate?: boolean;

  disabled?: boolean;
  disabledReason?: string;
}

export const UIButton: FC<UIButtonProps> = forwardRef<
  HTMLButtonElement,
  UIButtonProps
>(
  (
    {
      type = 'primary',
      size = 'normal',
      className = '',
      tabIndex,
      onClick,
      children,
      href,
      id,
      processing,
      noBlank,
      link,
      propagate,
      disabled,
      disabledReason,
    },
    ref
  ) => {
    if (disabled) {
      type = 'disabled';
    }

    const elProps = {
      id,
      className: classNames('uiButton', type, size, className, {
        processing,
      }),
      tabIndex,
      disabled,
    };

    const elChildren = (
      <>
        {processing && <UIIcon className="loader" name="spinner-animated" />}
        {children}
      </>
    );

    const handleClick = useCallback(
      (ev: React.MouseEvent<HTMLButtonElement>) => {
        if (!propagate) {
          ev.stopPropagation();
        }

        onClick?.(ev);
      },
      [onClick, propagate]
    );

    if (href) {
      if (noBlank) {
        return (
          <a href={href} {...elProps}>
            {children}
          </a>
        );
      }

      return (
        <a {...elProps} href={href} rel="noreferrer" target="_blank">
          {children}
        </a>
      );
    }

    if (link) {
      return (
        <Link {...elProps} to={link}>
          {elChildren}
        </Link>
      );
    }

    if (disabled && !!disabledReason) {
      return (
        <UITooltip title={disabledReason}>
          <div className="inline-flex">
            <button type="button" {...elProps} onClick={handleClick}>
              {elChildren}
            </button>
          </div>
        </UITooltip>
      );
    }

    return (
      <button ref={ref} type="button" {...elProps} onClick={handleClick}>
        {elChildren}
      </button>
    );
  }
);

UIButton.displayName = 'UIButton';
