import React, { useCallback, useMemo, useRef, useState } from 'react';
import { v1 as uuidV1 } from 'uuid';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { withInstallModal } from '../../hoc/withInstallModal';
import { FileUpload } from '../../pages/files/FileUpload';
import { UploadStatus, useFiles } from '../../scripts/hooks/files';
import { ConnectStep } from '../generic/Components';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleAPIInstall }) => {
    const textboxRef = useRef<HTMLInputElement>(null);

    const [url, setUrl] = useState('');
    const [apiError, setApiError] = useState('');

    const site_id = useMemo(() => uuidV1(), []);

    const handleClickDone = useCallback(async () => {
      return handleAPIInstall({ url, site_id }).catch(() => {
        setApiError('Please enter a valid URL.');
        textboxRef.current?.focus();
      });
    }, [handleAPIInstall, url, site_id]);

    const { uploadStatus, uploadFile } = useFiles();

    const connectDisabled =
      url.trim().length === 0 || uploadStatus !== UploadStatus.DONE;

    return (
      <>
        <ConnectStep step={1} title="Enter Google Sites URL">
          <div className="stepField">
            <UITextbox
              className="stepFieldInput"
              defaultWhiteInputField
              error={apiError}
              onChange={setUrl}
              placeholder="https://sites.google.com/company/your-site"
              ref={textboxRef}
              size="large"
              value={url}
            />
          </div>
        </ConnectStep>
        <ConnectStep step={2} title="Upload Site Export">
          <p>
            Follow{' '}
            <a
              href="https://support.dashworks.ai/get-started/add-knowledge/apps#google-sites"
              rel="noreferrer"
              target="_blank"
            >
              these
            </a>{' '}
            instructions on how to export a site using{' '}
            <strong>Google Takeout</strong>.
          </p>
          <FileUpload
            isZipFile
            siteId={site_id}
            uploadFile={uploadFile}
            uploadStatus={uploadStatus}
          />
        </ConnectStep>
        {connectInProgress(handleClickDone, connectDisabled)}
      </>
    );
  }
);
