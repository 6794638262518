import { createContext, useContext } from 'react';
import { EventEmitter } from '../../../emitter';
import { App } from '../../../scripts/app';

export interface AppInstallRequest {
  app: App;
  isOrg: boolean;
  id?: number;
}
export interface AppInstallEventArgs {
  appInstallRequest?: AppInstallRequest;
  appUninstallRequest?: AppInstallRequest;
}

enum APP_INSTALL_EVENTS {
  HANDLE_APP_INSTALL = 'handle_app_install',
  HANDLE_APP_UNINSTALL = 'handle_app_uninstall',
}

// eslint-disable-next-line unicorn/prefer-event-target
export class AppInstallController extends EventEmitter<AppInstallEventArgs> {
  public triggerAppInstallRequest(app: App, isOrg: boolean, id?: number): void {
    this.emit(APP_INSTALL_EVENTS.HANDLE_APP_INSTALL, {
      appInstallRequest: { app, isOrg, id },
    });
  }
  public triggerAppUninstallRequest(
    app: App,
    isOrg: boolean,
    id?: number
  ): void {
    this.emit(APP_INSTALL_EVENTS.HANDLE_APP_UNINSTALL, {
      appUninstallRequest: { app, isOrg, id },
    });
  }
}

const context = createContext<AppInstallController | undefined>(undefined);

export const useAppInstallController = (): AppInstallController => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

export const AppInstallControllerProvider = context.Provider;
