import React from 'react';
import { PageSearchResultItem } from '../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../resultProps';

export const ReadmeDocResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="API Documentation"
      result={result}
      status={result.status}
    />
  );
};
