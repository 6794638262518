import React from 'react';
import { PageResultAttachment } from '../../../../../scripts/models/page-search-result';
import { sortBy } from '../../../../../scripts/utils';
import { UIButton } from '../../../../controls/ui/UIButton/UIButton';
import { ResultAttachment } from '../Attachment/Attachment';

export const Attachments: React.FC<{
  attachments: PageResultAttachment[];
  onClick: (attachment: PageResultAttachment) => void;
  maxAttachments: number;
}> = ({ attachments, onClick, maxAttachments }) => {
  // Order attachments by number of highlights, top first
  const orderedAttachments = sortBy(attachments, (attachment) => {
    return attachment.highlightCount ? -attachment.highlightCount : 0;
  });

  return orderedAttachments.length > 0 ? (
    <div className="resultAttachments">
      {orderedAttachments.slice(0, maxAttachments).map((attachment) => (
        <ResultAttachment
          attachment={attachment}
          key={attachment.objectID || attachment.url}
          onClick={onClick}
        />
      ))}
      {orderedAttachments.length > maxAttachments && (
        <UIButton type="secondary">
          +{orderedAttachments.length - maxAttachments}
        </UIButton>
      )}
    </div>
  ) : null;
};
