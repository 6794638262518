import React, { ReactNode } from 'react';
import { AppDefinition } from './apps/definition';
import { isInstantAvailable } from './components/admin/AppsTable/AppUtils';

export const integrationInstallMessages = (
  definition: AppDefinition
): [ReactNode, string] => [
  isInstantAvailable(definition) ? (
    <>
      {definition.displayName} connected. You can now search{' '}
      {definition.description}.
    </>
  ) : (
    <>
      Started connecting {definition.displayName} - we&apos;ll let you know when
      it&apos;s complete.
      <br />
      You may search part of your content while the app is still connecting.
    </>
  ),
  `Failed to install ${definition.displayName}.`,
];

export const integrationUninstallMessages = (
  name: string
): [string, string] => [
  `Disconnected ${name}.`,
  `Failed to disconnect ${name}.`,
];
