import React from 'react';
import './PreviewIframe.scss';
import fontSrc from '../../../../assets/fonts/Web/gt-walsheim/GT-Walsheim-Regular.otf';
import { sanitize } from '../../../../scripts/html';
import { IFrameData } from '../PreviewItem/PreviewItem';

const userAgent =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/85.0.4183.121 Safari/537.36';

const defaultIframeProps = {
  name: 'previewIframe',
  /* eslint-disable react/iframe-missing-sandbox */
  sandbox: 'allow-popups allow-popups-to-escape-sandbox',
  className: 'previewIframe',
  frameBorder: '0',
  // eslint-disable-next-line @cspell/spellchecker
  enableremotemodule: 'false',
  seamless: true,
  useragent: userAgent,
};

export const PreviewIframe: React.FC<IFrameData> = ({
  content,
  type,
  contentCSS,
}) => {
  if (
    content.startsWith('data:') &&
    content.slice(0, 100).includes(';base64,')
  ) {
    if (content.startsWith('data:image/')) {
      return (
        <iframe
          {...defaultIframeProps}
          srcDoc={`
<html lang='en'>
<head>
<base target='_blank'>
<style>
  body {
    padding: 0;
    margin: 0;
  }
  img {
    max-width: 100%;
  }
  ${contentCSS ?? ''}
</style>
</head>
<body>
  <img src='${content}' />
</body>
</html>`}
        />
      );
    }

    // Other base64 types
    return <iframe {...defaultIframeProps} src={content} />;
  }

  switch (type) {
    case 'application/pdf': {
      return (
        <iframe
          {...defaultIframeProps}
          src={`data:application/pdf;base64,${content}`}
        />
      );
    }

    default: {
      return (
        <iframe
          {...defaultIframeProps}
          srcDoc={`
<html lang='en'>
<head>
  <script type='text/javascript'>
    window.onclick = function(e) {
      const origin = e.target.closest('a');
      if (origin) {
        top.postMessage({event: 'dash-preview-link-click', text: origin.href}, '*');
      }
    };
  </script>
  <base target='_blank'>
  <style>
    body {
      padding: 1px 8px;
    }

    @font-face {
      font-family: 'GT Walsheim';
      src: url('${fontSrc}')
        format('opentype');
      font-style: normal;
      font-weight: 400;
    }

    :not(pre):not(code) mark {
      font-family: 'Basier Circle', 'Arial', sans-serif;
      background-color: #ffef62;
    }
    ${contentCSS ?? ''}
  </style>
</head>
<body oncopy="top.postMessage({event: 'dash-copy-clipboard', text: document.getSelection().toString()}, '*');">
  ${sanitize(content)}
</body>
</html>`}
        />
      );
    }
  }
};
