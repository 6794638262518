import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import './QAHeaderConnectApps.scss';
import { Link } from 'react-router-dom';
import { AppState, errorAppStates } from '../../constants';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { trackEvent } from '../../extra/sharedMethods';
import { useAppConnectionStatus } from '../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';
import { useQAController } from '../../scripts/QAController';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useUserSafe } from '../../scripts/hooks';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';

export const QAHeaderConnectApps: FC = () => {
  const [open, setOpen] = useState(true);

  const { isAdmin } = useUserSafe((u) => ({
    isAdmin: u.admin,
  }));

  const qaController = useQAController();

  const connectedApps = useAppConnectionStatus();

  const sortedConnectedApps = useMemo(() => {
    const filteredConnectedApps = connectedApps.filter(
      (app) => app.showInAppStore && app.statusCode !== AppState.ErrorReconnect
    );

    const uniqueConnectedApps = filteredConnectedApps.filter(
      (app, index, self) =>
        index === self.findIndex((t) => t.appName === app.appName)
    );

    uniqueConnectedApps.sort((appA, appB) => {
      return appA.appName.localeCompare(appB.appName);
    });

    return uniqueConnectedApps;
  }, [connectedApps]);

  const isAppInErrorState = useMemo(() => {
    // Checks if any app is in an error state.
    return connectedApps.some((app) => {
      if (isAdmin) {
        return errorAppStates.has(app.statusCode) && app.isInstant;
      }

      return errorAppStates.has(app.statusCode) && app.isInstant && !app.isOrg;
    });
  }, [isAdmin, connectedApps]);

  const handleClick = useCallback(() => {
    trackEvent(AnalyticsEvent.QaConnectMoreAppsButtonClick);
  }, []);

  const handleHideAppsNotifications = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    qaController.listenEvent(
      'hideAppsNotifications',
      handleHideAppsNotifications
    );

    return () => {
      qaController.off('hideAppsNotifications', handleHideAppsNotifications);
    };
  }, [qaController, handleHideAppsNotifications]);

  if (!open) {
    return null;
  }

  return (
    <div>
      {/* desktop only */}
      <div className="hidden lg:flex items-center fixed top-0 right-0 z-[130] bg-white shadow p-2 rounded mt-1.5 mr-8">
        {sortedConnectedApps.length > 0 && (
          <div className="">
            <div className="flex items-center gap-3">
              {sortedConnectedApps.slice(0, 3).map((app) => (
                <div className="flex" key={app.shortName}>
                  <UIIcon
                    name={app.shortName}
                    tooltip={`Searches across ${app.description as string}`}
                    type="apps"
                  />
                </div>
              ))}
              {sortedConnectedApps.length > 3 && (
                <div className="flex-shrink-0 bg-cloud-15 text-xs font-semibold w-6 h-6 flex items-center justify-center rounded-full border-2 border-gray-300">
                  {sortedConnectedApps.length - 3}+
                </div>
              )}

              <div className="border-l border-r-0 border-solid border-cloud-15 h-6" />
            </div>
          </div>
        )}
        <UITooltip
          title={sortedConnectedApps.length > 0 && 'Connect more apps'}
        >
          <Link
            className="ml-2 p-1 border border-cloud-15 border-solid rounded-md flex text-black hover:text-black hover:border-black"
            to={SidebarRoutes.AppsV2}
          >
            <div className="flex">
              <UIIcon className="block align-top" name="app" stroke />
            </div>
            {isAppInErrorState && (
              <div className="text-mahogany-20 absolute top-[-6px] right-[-2px]">
                <ErrorOutlinedIcon fontSize="small" />
              </div>
            )}
          </Link>
        </UITooltip>
      </div>

      {/* mobile only */}
      <div className="lg:hidden connectMoreAppsOuterContainer">
        <div className="connectMoreAppsInnerContainer">
          <div className="connectMoreApps">
            {sortedConnectedApps.length > 0 && (
              <div className="appsDividerContainer">
                <div className="instantApps">
                  {sortedConnectedApps.slice(0, 3).map((app) => (
                    <div className="instantApp" key={app.shortName}>
                      <UIIcon
                        name={app.shortName}
                        tooltip={`Searches across ${app.description as string}`}
                        type="apps"
                      />
                    </div>
                  ))}
                  {sortedConnectedApps.length > 3 && (
                    <div className="flex-shrink-0 bg-cloud-15 text-xs font-semibold w-7 h-7 flex items-center justify-center rounded-full border-2 border-gray-300 mx-1.5">
                      {sortedConnectedApps.length - 3}+
                    </div>
                  )}
                </div>
                <div className="divider" />
              </div>
            )}
            <UITooltip
              title={sortedConnectedApps.length > 0 && 'Connect more apps'}
            >
              <div className="connectMoreAppsButtonContainer">
                <UIButton
                  className={
                    sortedConnectedApps.length > 0
                      ? 'connectMoreAppsButton'
                      : ''
                  }
                  link={SidebarRoutes.AppsV2}
                  onClick={handleClick}
                  type="secondary"
                >
                  <UIIcon name="app" stroke />
                  {sortedConnectedApps.length === 0 && <div>Connect apps</div>}
                </UIButton>
                {isAppInErrorState && (
                  <div className="text-mahogany-20 errorIcon">
                    <ErrorOutlinedIcon fontSize="small" />
                  </div>
                )}
              </div>
            </UITooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
