import debounce from 'lodash/debounce';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TopicType } from '../../../models/QAmodels';
import { setSidebarNotificationsOpen } from '../../../redux/sidebar/actions';
import { useDispatch } from '../../../redux/store';
import { useQAController } from '../../../scripts/QAController';
import { logError, truncateString } from '../../../scripts/utils';
import { Loading, LoadingSize } from '../../controls/Loading/Loading';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import { SidebarLink } from './Sidebar';

interface TopicsListProps {
  topicsOpen: boolean;
  topicsType: TopicType;
  managedBotId?: string;
}

export const TopicsList: FC<TopicsListProps> = ({
  topicsOpen,
  topicsType,
  managedBotId,
}: TopicsListProps) => {
  const hasFetchedInitialTopics = useRef(false);

  const dispatch = useDispatch();

  const closeNotificationsSidebar = useCallback(() => {
    dispatch(setSidebarNotificationsOpen(false));
  }, [dispatch]);

  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const qaController = useQAController();

  const allTopics = qaController.useTopics();
  const { allTopicsLoaded } = qaController.useProgressStage();

  const topics = useMemo(() => {
    if (topicsType === TopicType.MANAGED_BOTS && managedBotId) {
      return allTopics[managedBotId];
    }

    return allTopics[topicsType];
  }, [allTopics, managedBotId, topicsType]);

  const haveAllTopicsLoaded = useMemo(() => {
    if (topicsType === TopicType.MANAGED_BOTS && managedBotId) {
      return allTopicsLoaded[managedBotId];
    }

    return allTopicsLoaded[topicsType];
  }, [allTopicsLoaded, managedBotId, topicsType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce((newQuery: string) => {
      qaController
        .fetchTopics({
          page: 0,
          query: newQuery,
          topicsType,
          managedBotId,
        })
        .catch((error) => {
          logError(error);
        });
    }, 150),
    [qaController, logError]
  );

  const handleSearch = useCallback(
    (newQuery: string) => {
      setQuery(newQuery);
      setPage(0);

      debouncedFetch(newQuery);
    },
    [debouncedFetch]
  );

  const fetchMoreTopics = useCallback(() => {
    qaController
      .fetchTopics({
        page: page + 1,
        query,
        topicsType,
        managedBotId,
        fetchMore: true,
      })
      .catch((error) => {
        logError(error);
      });

    setPage(page + 1);
  }, [qaController, page, query, topicsType, managedBotId]);

  useEffect(() => {
    if (topicsOpen && !hasFetchedInitialTopics.current) {
      qaController
        .fetchTopics({
          page: 0,
          query: '',
          topicsType,
          managedBotId,
        })
        .catch(logError);

      hasFetchedInitialTopics.current = true;
    }
  }, [managedBotId, qaController, topicsOpen, topicsType]);

  if (!topicsOpen) {
    return null;
  }

  return (
    <div
      className="flex-grow mt-2 overflow-auto scrollbar scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin"
      id="topicScrollableDiv"
    >
      <div className="px-2 sticky top-0 z-50">
        <UITextbox
          onChange={handleSearch}
          placeholder="Search topics ..."
          value={query}
        />
      </div>
      {(topics ?? []).length > 0 && (
        <div className="px-2">
          <InfiniteScroll
            dataLength={(topics ?? []).length}
            hasMore={!haveAllTopicsLoaded}
            loader={<Loading inline size={LoadingSize.XSmall} />}
            next={fetchMoreTopics}
            scrollableTarget="topicScrollableDiv"
            style={{
              height: '100%',
            }}
          >
            {(topics ?? []).map((topic) => (
              <div
                className="text-xs relative"
                key={topic.createdAt}
                onClick={() => {
                  if (topic.unreadCount)
                    qaController.markTopicAsRead(topic.topicId);
                  closeNotificationsSidebar();
                }}
              >
                <SidebarLink
                  logName="individualTopicLink"
                  textColor="text-gray-50 !font-normal"
                  title={truncateString(topic.topicTitle, 30)}
                  to={`/topic/${topic.topicId}`}
                />
                {Boolean(topic.unreadCount) && (
                  <div className=" bg-destructive-40 w-2 h-2 rounded-[50%] absolute top-1/2 -translate-y-1/2 right-[7px]" />
                )}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
