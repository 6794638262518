import Picker from '@emoji-mart/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';

interface IEmojiPickerProps {
  disabled?: boolean;
  onSelect: (emoji: string) => void;
  emoji: string;
}

export const EmojiPicker: React.FC<IEmojiPickerProps> = ({
  disabled = false,
  emoji,
  onSelect,
}) => {
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const pickerRef = useRef<HTMLDivElement>(null);

  const fetchEmojis = useCallback(async () => {
    const response = await fetch(
      'https://cdn.jsdelivr.net/npm/@emoji-mart/data'
    );

    return response.json();
  }, []);

  const handleEmojiSelect = useCallback(
    (newEmoji: { native: string }) => {
      onSelect(newEmoji.native);
      setEmojiPickerOpen(false);
    },
    [onSelect]
  );

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      pickerRef.current &&
      !pickerRef.current.contains(event.target as Node)
    ) {
      setEmojiPickerOpen(false);
    }
  }, []);

  useEffect(() => {
    if (emojiPickerOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPickerOpen, handleClickOutside]);

  return (
    <div
      className="relative flex flex items-center gap-1 p-1 border border-solid  border-gray-30 rounded-lg cursor-pointer"
      onClick={() => {
        setEmojiPickerOpen(true);
      }}
    >
      <div className="text-2xl leading-7	">{emoji}</div>
      {!disabled && <UIIcon name="arrow-down" size={16} />}
      {emojiPickerOpen && !disabled && (
        <div className="absolute top-4 z-[200]" ref={pickerRef}>
          <Picker
            data={fetchEmojis}
            onClickOutside={() => {
              setEmojiPickerOpen(false);
            }}
            onEmojiSelect={handleEmojiSelect}
            previewPosition="none"
            theme="light"
          />
        </div>
      )}
    </div>
  );
};
