import { AccessDiscoveryResult } from '../models/page-search-result';
import { getStorageItem, StorageKey } from '../storage';

export const pushAccessDiscoveryRequest = (
  result: AccessDiscoveryResult
): void => {
  const accessDiscoveryRequests = getAccessDiscoveryRequests();

  accessDiscoveryRequests.unshift(result.id_hash);

  window.localStorage.setItem(
    StorageKey.AccessDiscoveryRequests,
    JSON.stringify(accessDiscoveryRequests)
  );
};

export const getAccessDiscoveryRequests = (): string[] => {
  const accessDiscoveryRequests =
    (getStorageItem(StorageKey.AccessDiscoveryRequests) as string[] | null) ??
    [];

  return accessDiscoveryRequests;
};
