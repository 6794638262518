import React, { FC } from 'react';
import { NotifyAuth } from '../../../scripts/NotifyAuth';

export const enum AuthMessageType {
  LOGGED_IN = 'AUTH_SUCCESS',
  AUTH_UNAUTHORIZED = 'AUTH_UNAUTHORIZED',
  SSO_CONFIGURED_ERR = 'SSO_CONFIGURED_ERR',
}

export const AuthWindow: FC = () => {
  // Used only by the login popup window
  return <NotifyAuth target={window.opener as Window | undefined} />;
};
