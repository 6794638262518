import React from 'react';
import './MetadataMatches.scss';
import { MetadataMatches } from '../../../../scripts/models/page-search-result';
import { HighlightedText } from '../../../controls/HighlightedText/HighlightedText';

export const ResultMetadataMatches: React.FC<{
  metadataMatches: MetadataMatches;
}> = React.memo(({ metadataMatches }) => {
  const { owner, domain } = metadataMatches;
  const matchedOwner: string | undefined = owner?.name ?? owner?.email;

  return (
    <div className="metadataMatches">
      {matchedOwner && (
        <div className="matchItem">
          <strong>Owner:</strong> <HighlightedText text={matchedOwner} />
        </div>
      )}
      {domain && (
        <div className="matchItem">
          <strong>Domain:</strong> <HighlightedText text={domain} />
        </div>
      )}
    </div>
  );
});

ResultMetadataMatches.displayName = 'ResultMetadataMatches';
