import React, { FC } from 'react';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <SimpleAPIKeyInstall
      app={app}
      instructionSteps={
        <ConnectStep step={1} title="Generate API key">
          <p>
            Sign in with the Helpjuice account you want to connect with
            Dashworks and copy the API key.{' '}
            <strong>
              The user providing the token must be an Admin in Helpjuice.
            </strong>
          </p>
          <p>
            Refer to these
            <a
              href="https://help.helpjuice.com/API/284079-how-do-i-get-my-api-key"
              rel="noreferrer"
              target="_blank"
            >
              {' '}
              instructions{' '}
            </a>{' '}
            for more help.
          </p>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
      subdomainCapture={{
        hostname: 'helpjuice.com',
        loginURL: 'https://helpjuice.com/users/sign_in',
      }}
    />
  );
};
