import React, { useCallback } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleOAuthInstall, setInProgress }) => {
    const clickConnect = useCallback(async () => {
      setInProgress(true);
      return handleOAuthInstall({});
    }, [handleOAuthInstall, setInProgress]);

    return (
      <>
        {/* TODO(DAS-25459): use app.definition.displayName */}
        <p className="firstNotice">
          Requirement to connect Attio:
          <ul>
            <li>
              <strong>
                This integration is only available for workspace admins. Click
                the Connect button below to grant consent.
              </strong>
            </li>
          </ul>
        </p>
        {connectInProgress(clickConnect)}
      </>
    );
  }
);
