import React, { useCallback } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleOAuthInstall, app, setInProgress }) => {
    const clickConnect = useCallback(async () => {
      setInProgress(true);
      return handleOAuthInstall({});
    }, [handleOAuthInstall, setInProgress]);

    return (
      <>
        <p className="firstNotice">
          Requirement to connect {app.definition.displayName}:
          <ul>
            <li>
              <strong>
                If you are a {app.definition.displayName} admin, you must grant
                consent on behalf of the organization to allow other members in
                your workspace to connect the integration. Click the Connect
                button below to grant consent.
              </strong>
            </li>
            <li>
              <strong>
                If you are not a {app.definition.displayName} admin, you can
                connect the integration once your {app.definition.displayName}{' '}
                admin has granted consent. Click the Connect button below to
                check if your admin has granted consent.
              </strong>
            </li>
          </ul>
        </p>

        {connectInProgress(clickConnect)}
      </>
    );
  }
);
