import { WorkflowType } from '../../models/workflow';
import { Action } from '../actions';

export enum WorkflowActionType {
  SET_STEP = 'WORKFLOW_SET_STEP',
  SET_STATE = 'WORKFLOW_SET_STATE',
}

export interface WorkflowStepAction<U extends string | undefined>
  extends Action {
  workflowType: WorkflowType;
  step: U;
}

export function setWorkflowStepAction<U extends string>(
  workflowType: WorkflowType,
  step: U
): WorkflowStepAction<U> {
  return {
    type: WorkflowActionType.SET_STEP,
    workflowType,
    step,
  };
}

export interface WorkflowStateAction<
  S extends object | undefined,
  U extends string | undefined
> extends Action {
  workflowType: WorkflowType;
  workflowState?: S;
  step?: U;
}

export function setWorkflowStateAction<S extends object, U extends string>(
  workflowType: WorkflowType,
  workflowState: S,
  step?: U
): WorkflowStateAction<S, U> {
  return {
    type: WorkflowActionType.SET_STATE,
    workflowType,
    workflowState,
    step,
  };
}
