import React, { Suspense } from 'react';
import { calendarTimeRange } from '../../../../../../../scripts/page-search/result-utils';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

let momentTzLoaded = false;

async function loadMomentTz() {
  return import(
    /* webpackChunkName: "moment-timezone" */ 'moment-timezone'
  ).then(() => {
    momentTzLoaded = true;
  });
}

const InnerCalendarEventResult: React.FC<ResultProps> = ({ result }) => {
  if (!momentTzLoaded) {
    // Part of react API
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw loadMomentTz();
  }

  const timeRange = calendarTimeRange(result);

  const miscMeta = (
    <>
      <div>{timeRange}</div>
      <div className="divider" />
      <div>{result.from}</div>
    </>
  );

  return (
    <PageSearchResultItem
      appName="Google Calendar Event"
      hideModified
      miscMeta={miscMeta}
      result={result}
    />
  );
};

export const GoogleCalendarEventResult: React.FC<ResultProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <InnerCalendarEventResult {...props} />
    </Suspense>
  );
};
