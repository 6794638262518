import './Actions.scss';
import React from 'react';
import { PageSearchResult } from '../../../../../scripts/models/page-search-result';
import { CopyButton } from '../../../../controls/ui/CopyButton/CopyButton';
import { PinResult } from './PinResult/PinResult';

interface ActionProps {
  result: PageSearchResult;
  searchQuery: string;
  onCopy: () => void;
}

export const Actions: React.FC<ActionProps> = ({
  result,
  searchQuery,
  onCopy,
}) => {
  return (
    <div className="actions">
      <CopyButton
        hoverEffect={false}
        onCopy={onCopy}
        size={24}
        square
        value={result.webview_link}
      />
      <PinResult result={result} searchQuery={searchQuery} square />
    </div>
  );
};
