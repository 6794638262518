import './QAPageView.scss';
import React, { FC, useEffect, useState } from 'react';
import { trackEvent } from '../../extra/sharedMethods';
import { batch } from '../../redux/store';
import {
  QAControllerEventArgs,
  useQAController,
} from '../../scripts/QAController';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useBoolState, useFlag, useToaster } from '../../scripts/hooks';
import { AnswerDialog } from '../admin/AnswersTable/AnswerDialog';
import { PaywallModal } from '../admin/EnterprisePaywall/EnterprisePaywall';
import { ConnectAppsModal } from '../general/ConnectAppsModal/ConnectAppsModal';
import { ReconnectAppsModal } from '../general/ReconnectAppsModal/ReconnectAppsModal';
import { QAWorkflowTemplateModal } from '../workflowModal/QAWorkflowTemplateModal';
import { QAFeedbackModal } from './QAFeedbackModal';
import { QAHeaderConnectApps } from './QAHeaderConnectApps';
import { QAMessageListView } from './QAMessageListView';
import { QAReferencesSidecar } from './references/QAReferencesSidecar/QAReferencesSidecar';
import { QATextInputView } from './textInputBox/QATextInputView';

export const QAPageView: FC = () => {
  const toaster = useToaster();
  const isDashAiDown = useFlag('dashAiDown');

  useEffect(() => {
    const handleBlur = () => {
      trackEvent(AnalyticsEvent.QAPageBlur);
    };

    trackEvent(AnalyticsEvent.QADashAiPageOpened);
    window.addEventListener('blur', handleBlur);

    if (isDashAiDown) {
      const timeout = 30_000;
      toaster.failure(
        'We are experiencing issues with Dash AI. We will be posting updates on status.dashworks.ai',
        timeout
      );
    }

    return () => {
      trackEvent(AnalyticsEvent.QADashAiPageClosed);
      window.removeEventListener('blur', handleBlur);
    };
  }, [isDashAiDown, toaster]);

  return <QAPageViewInner />;
};

const QAPageViewInner: FC = () => {
  const [openPaywallModal, setOpenPaywallModal, setNotOpenPaywallModal] =
    useBoolState(false);

  return (
    <div className="qaPageViewContainer">
      <div className="qaPageView">
        <QAHeaderConnectApps />
        <div className="spacer lg:hidden" />
        <QAMessageListView />
        <QATextInputView setOpenPaywallModal={setOpenPaywallModal} />
        <QAPageAnswerView />
        <QAWorkflowTemplateModal setOpenPaywallModal={setOpenPaywallModal} />
        <QAFeedbackModal />
        <ConnectAppsModal />
        <ReconnectAppsModal />
      </div>
      <QAReferencesSidecar />

      {openPaywallModal && (
        <PaywallModal allowUpgrade closeModal={setNotOpenPaywallModal} />
      )}
    </div>
  );
};

export const QAPageAnswerView: FC = () => {
  const qaPageController = useQAController();
  const [isAnswerDialogShown, showAnswerDialog, hideAnswerDialog] =
    useBoolState(false);

  const [editModalTitle, setEditModalTitle] = useState<string | undefined>(
    undefined
  );

  const [newAnswerTitle, setNewAnswerTitle] = useState<string | undefined>(
    undefined
  );

  const [answerId, setAnswerId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const onShowAnswerDialogEvent = ({
      answerId: _answerId,
      answerCreateModalTitle: _editModalTitle,
      answerCreatePrefillQuestion,
    }: Partial<QAControllerEventArgs>) => {
      batch(() => {
        showAnswerDialog();
        setAnswerId(_answerId);
        setEditModalTitle(_editModalTitle);
        setNewAnswerTitle(answerCreatePrefillQuestion);
      });
    };

    qaPageController.listenEvent('showAnswerDialog', onShowAnswerDialogEvent);
    return () => {
      qaPageController.off('showAnswerDialog', onShowAnswerDialogEvent);
    };
  }, [qaPageController, showAnswerDialog]);

  if (!isAnswerDialogShown) {
    return null;
  }

  return (
    <AnswerDialog
      editModalTitle={editModalTitle}
      id={answerId}
      key={answerId}
      newAnswerTitle={newAnswerTitle}
      onClose={hideAnswerDialog}
      open={isAnswerDialogShown}
    />
  );
};
