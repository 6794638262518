import React, { FC, useCallback } from 'react';
import { StageVariables } from '../../models/QAmodels';

interface DebugLogsProps {
  item: StageVariables;
  index: number;
}

interface ObjectListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any;
  keyName: number | string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getItemTitle(item: any, index: number): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  return item.title ?? item.role ?? item.model_name ?? String(index);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const chunkKeys = (keys: any[]) => {
  const chunkSize = 30;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chunks: any[][] = [];

  for (let i = 0; i < keys.length; i += chunkSize) {
    chunks.push(keys.slice(i, i + chunkSize));
  }

  return chunks;
};

const ObjectList: FC<ObjectListProps> = ({ obj, keyName }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleToggle = useCallback(
    (e: React.SyntheticEvent<HTMLDetailsElement>) => {
      if (e.currentTarget.open) {
        setIsExpanded(true);
      } else {
        setIsExpanded(false);
      }
    },
    []
  );

  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const keys = Object.keys(obj);
    const keyChunks = chunkKeys(keys);
    return (
      <div>
        {keyChunks.map((keyChunk, index) => {
          const start = index * 30 + 1;
          const end = start + keyChunk.length - 1;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <details key={index} onToggle={handleToggle}>
              <summary style={{ marginLeft: '2em' }}>
                {keys.length > 30 ? `${start}-${end}` : keyName}
              </summary>

              {isExpanded && (
                <div style={{ marginLeft: '2em' }}>
                  {keyChunk.map((key, keyIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={keyIndex + index}>
                      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */}
                      <ObjectList keyName={key} obj={obj[key]} />
                    </div>
                  ))}
                </div>
              )}
            </details>
          );
        })}
      </div>
    );
  }

  if (obj && Array.isArray(obj)) {
    const keyChunks = chunkKeys(obj);
    return (
      <div>
        {keyChunks.map((keyChunk, index) => {
          const start = index * 30 + 1;
          const end = start + keyChunk.length - 1;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <details key={index} onToggle={handleToggle}>
              <summary style={{ marginLeft: '2em' }}>
                {obj.length > 30 ? `${start}-${end}` : keyName}
              </summary>
              {isExpanded && (
                <div style={{ marginLeft: '2em' }}>
                  {keyChunk.map((key, keyIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={keyIndex + index}>
                      <ObjectList
                        keyName={getItemTitle(key, keyIndex + index)}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        obj={key}
                      />
                    </div>
                  ))}
                </div>
              )}
            </details>
          );
        })}
      </div>
    );
  }

  return (
    <div style={{ marginLeft: '2em' }}>
      <strong>{keyName}: </strong>
      <i>{String(obj)}</i>
    </div>
  );
};

export const DebugLogs: FC<DebugLogsProps> = ({ item, index }) => {
  const {
    stage_name,
    inputs,
    llm_interactions,
    outputs,
    extra,
    execution_time,
  } = item;

  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleToggle = useCallback(
    (e: React.SyntheticEvent<HTMLDetailsElement>) => {
      if (e.currentTarget.open) {
        setIsExpanded(true);
      } else {
        setIsExpanded(false);
      }
    },
    []
  );

  return (
    <div>
      <details onToggle={handleToggle}>
        <summary>
          {index}. {stage_name}
        </summary>
        {isExpanded && (
          <details style={{ marginLeft: '1em' }}>
            <ObjectList keyName="Stage inputs" obj={inputs} />
            <ObjectList keyName="LLM calls" obj={llm_interactions} />
            <ObjectList keyName="Stage outputs" obj={outputs} />
            <ObjectList keyName="Extra logs" obj={extra} />
            <details>
              <summary>Execution Time</summary>
              <div style={{ marginLeft: '1em' }}>{execution_time}</div>
            </details>
          </details>
        )}
      </details>
    </div>
  );
};
