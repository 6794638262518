import { makeLazyComponent } from '../../../scripts/utils';

export const LazyBrowserExtensionPage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "browser-extension-page" */ './BrowserExtensionPage'
      )
    ).BrowserExtensionPage
);
