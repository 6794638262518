import { PageSearchResult } from '../../../../../../scripts/models/page-search-result';

/**
 * Return specific breadcrumbs for Intercom user/lead.
 */
export const intercomPersonBreadcrumbs = (
  result: PageSearchResult
): string[] => {
  const crumbs = result.email && result.email.length > 0 ? [result.email] : [];
  if (result.company_name) {
    crumbs.unshift(result.company_name);
  }

  return crumbs;
};
