import React from 'react';
import './WebsiteFavicon.scss';

interface WebsiteFaviconProps {
  imageUrl: string;
}

export const WebsiteFavicon: React.FC<WebsiteFaviconProps> = ({ imageUrl }) => {
  return <img className="websiteFavicon" src={imageUrl} />;
};
