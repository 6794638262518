/* eslint-disable max-params */
import { UploadedFile } from '../../models/File';
import { Sources } from '../../models/User';
import { ConnectedApps } from '../hooks/sortedInstantApps';
import { Answer } from '../models/answers';
import { checkAiOnlyModeFromSources, checkWebModeFromSources } from './common';

export const getFiltersFromBotSources = (
  sources: Sources,
  allSkills: ConnectedApps[],
  allFiles: UploadedFile[],
  allAnswers: Answer[]
): [ConnectedApps[], UploadedFile[], Answer[]] => {
  const skillFilters = sources.skills.search_all
    ? allSkills
    : allSkills.filter((skill) => {
        const matchedFilter = sources.skills.filters?.find(
          (skillFilter) => skill.id === skillFilter.app_id
        );

        if (matchedFilter?.app_details) {
          skill.appSpecificFilters = matchedFilter.app_details;
        }

        return !!matchedFilter;
      });

  const fileFilters = sources.files.search_all
    ? allFiles
    : allFiles.filter((file) =>
        sources.files.filters?.some((fileId) => file.id === fileId)
      );

  const answerFilters = sources.answers.search_all
    ? allAnswers
    : allAnswers.filter((answer) =>
        sources.answers.filters?.some((answerId) => answer.id === answerId)
      );

  return [skillFilters, fileFilters, answerFilters];
};

export const setFiltersFromBotSources = (
  setIsBaseLLM: (value: boolean) => void,
  setSkillFilters: (value: ConnectedApps[]) => void,
  setFileFilters: (value: UploadedFile[]) => void,
  setAnswerFilters: (value: Answer[]) => void,
  sources: Sources,
  allSkills: ConnectedApps[],
  allFiles: UploadedFile[],
  allAnswers: Answer[]
): void => {
  if (checkAiOnlyModeFromSources(sources)) {
    // AI only mode
    setIsBaseLLM(true);
    setSkillFilters([]);
    setFileFilters([]);
    setAnswerFilters([]);
  } else if (checkWebModeFromSources(sources)) {
    // Web mode
    setIsBaseLLM(false);
    setSkillFilters([{ appName: 'web', displayName: 'Web' }]);
    setFileFilters([]);
    setAnswerFilters([]);
  } else {
    // Knowledge mode
    const [skillSources, fileSources, answerSources] = getFiltersFromBotSources(
      sources,
      allSkills,
      allFiles,
      allAnswers
    );

    setIsBaseLLM(false);
    setSkillFilters(skillSources);
    setFileFilters(fileSources);
    setAnswerFilters(answerSources);
  }
};
