import React, { FC } from 'react';
import freshdeskApiKeyScreen from '../../assets/images/admin/freshdesk_api_key.gif';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <SimpleAPIKeyInstall
      additionalApiKeyInstructions={
        <strong>
          {' '}
          The user providing the token must be an Admin in Freshdesk.
        </strong>
      }
      app={app}
      instructionSteps={
        <ConnectStep step={1} title="Generate API key">
          <p>
            After logging in to your Freshdesk account, Click on your profile
            picture on the top right and select{' '}
            <strong>Profile Settings</strong>. From the sidebar on the right,
            copy the API Key
          </p>
          <div className="screenFrame">
            <img src={freshdeskApiKeyScreen} />
          </div>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
      subdomainCapture={{
        hostname: 'freshdesk.com',
        loginURL: 'https://freshdesk.com/login',
      }}
    />
  );
};
