import './FilterBar.scss';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ObjectType } from '../../../../scripts/constants/filters';
import { searchObjectTypes } from '../../../../scripts/constants/search-object-types';
import {
  useFilterParam,
  useOrgPreference,
  usePageSearch,
} from '../../../../scripts/hooks';
import { FilterBarItem } from '../FilterBarItem/FilterBarItem';

const VALID_FILTER_KEYS = Object.values(ObjectType) as string[];

const scrollDownHideDistance = 120;
const scrollUpShowDistance = 24;

export const FilterBar: React.FC = () => {
  const [filterParam, setFilterParam] = useFilterParam();
  const [hide, setHide] = useState(false);
  const counts = usePageSearch((ps) => ps.hitCounts?.types) ?? [];
  const answersEnabled = useOrgPreference('answers', true);

  /*
   * Hide the filter bar when we scroll down `scrollDownHideDistance`px without reserving.
   * Show the filter bar when we go up `scrollUpShowDistance`px  without reserving.
   */
  useEffect(() => {
    let isDown = true;
    let alternationPosition = window.scrollY;
    let lastScrollPosition = window.scrollY;
    const onScroll = () => {
      const { scrollY: newPos } = window;

      if (!newPos) {
        setHide(false);
        return;
      }

      const newDirection = newPos > lastScrollPosition;
      if (newDirection !== isDown) {
        alternationPosition = newPos;
      }

      if (
        !hide &&
        isDown &&
        newPos > alternationPosition + scrollDownHideDistance
      ) {
        setHide(true);
      } else if (
        hide &&
        !isDown &&
        newPos < alternationPosition - scrollUpShowDistance
      ) {
        setHide(false);
      }

      isDown = newDirection;
      lastScrollPosition = newPos;
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [hide]);

  useEffect(() => {
    // If no param set or is unknown, then default to "Files"
    if (!VALID_FILTER_KEYS.includes(filterParam)) {
      setFilterParam(ObjectType.All);
    }
  }, [filterParam, setFilterParam]);

  const filterItems = searchObjectTypes.map((item) => {
    const count = counts.find((c) => c.name === item.key)?.count;
    if (count === 0 && item.key !== ObjectType.All) {
      return null;
    }

    if (item.key === ObjectType.Answers && !answersEnabled) {
      return null;
    }

    return (
      <FilterBarItem
        count={count}
        filterKey={item.key}
        icon={item.icon}
        key={item.key}
        title={item.title}
      />
    );
  });

  return (
    <div className={classNames('filterBar', { outOfScroll: hide })}>
      {filterItems}
    </div>
  );
};
