import React from 'react';
import { getMembers } from '../../../../../scripts/apis';
import { FilterDefaults } from '../../../../../scripts/constants/filters';
import { usePersonParam } from '../../../../../scripts/hooks';
import { OrgMember } from '../../../../../scripts/models/org-member';
import { UserImage } from '../../../../controls/UserImage/UserImage';
import { UIIcon } from '../../../../controls/ui/UIIcon/UIIcon';
import { UISelectItem } from '../../../../controls/ui/UISelect/UISelect';
import { SearchFilter } from '../SearchFilter/SearchFilter';

const EVERYONE_ITEM: UISelectItem = {
  label: 'Everyone',
  value: FilterDefaults.Person,
  icon: <UIIcon className="headerIcon" name="people" />,
  isDefault: true,
};

const fuzzyMatch = (member: OrgMember, query: string) => {
  return (
    !!member.name?.toLowerCase().includes(query) ||
    member.email.toLowerCase().includes(query)
  );
};

const itemToResultItem = (member: OrgMember): UISelectItem => {
  return {
    label: member.name ?? 'Unnamed User',
    value: member.email,
    icon: <UserImage imageUrl={member.icon} size={16} />,
  };
};

const exactMatch = (member: OrgMember, paramValue: string): boolean => {
  return member.email === paramValue;
};

const wrappedGetMember = async (search: string) => {
  return (await getMembers(search)).members;
};

export const PersonFilter: React.FC = () => {
  return (
    <SearchFilter<OrgMember>
      dataSource={wrappedGetMember}
      defaultItem={EVERYONE_ITEM}
      exactMatch={exactMatch}
      fuzzyMatch={fuzzyMatch}
      itemToResultItem={itemToResultItem}
      searchParamHook={usePersonParam}
      searchPrompt="Search for a person in your company"
    />
  );
};
