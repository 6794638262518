import React, { FC, useCallback } from 'react';
import { setIsAdminInternal } from '../../redux/actions';
import { useDispatch } from '../../redux/store';
import { useUserSafe } from '../../scripts/hooks';
import './Overrides.scss';

export const Overrides: FC = () => {
  const dispatch = useDispatch();
  const isAdmin = useUserSafe((user) => user.admin);
  const handleClick = useCallback(() => {
    dispatch(setIsAdminInternal(!isAdmin));
  }, [dispatch, isAdmin]);

  return (
    <div className="overrides">
      <p className="hint">
        This action will NOT actually change your role but simulates it on the
        frontend only, changing any setting on the user/org may reset this.
      </p>
      <button onClick={handleClick} type="button">
        View site as {isAdmin ? 'Member' : 'Admin'}
      </button>
    </div>
  );
};
