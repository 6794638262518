import * as Sentry from '@sentry/react';
import { SpanStatusType } from '@sentry/react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import LRU from 'lru-cache';
import { AppName } from '../apps/definition';
import {
  addMessages,
  addResults,
  clearHitCounts,
  SearchLoadState,
  setAccessDiscoveryResults,
  setHasMore,
  setHasMoreMessages,
  setMessages,
  setPage,
  setPersonResult,
  setPinnedResults,
  setQueryId,
  setQuerySpellCorrection,
  setRecentVisitsSuggestions,
  setResults,
  setSearchLoad,
  setSearchRequest,
  setSuggestions,
  setVersion,
  updateHitCounts,
} from '../redux/pageSearch/actions';
import { Sorting, SortOrder, SortProperty } from '../redux/state';
import { batch, Dispatcher } from '../redux/store';
import { refreshAllTokens } from '../scripts/authentication';
import { SentryTransaction } from '../scripts/constants/sentry-transaction';
import {
  AccessDiscoveryResult,
  PageSearchResult,
  RecentVisitsDocObject,
  SearchHit,
} from '../scripts/models/page-search-result';
import { PersonResult } from '../scripts/models/person-result';
import { setStorageItem, StorageKey } from '../scripts/storage';
import { isEqual, logError, updateArrayByKey } from '../scripts/utils';
import { ResultTransformer } from './ResultTransformer';

interface HitWrapper<T> {
  hits: T[];
}

interface ErrorResponse {
  e?: string;
}

export interface AutosuggestResultEntry {
  mimeType?: string;
  contentSnippet?: string;
  deepLink?: string;
  link?: string;
  source?: string;
  title: string;
  type?: string;
}

interface AutoSuggestRawResult extends ErrorResponse {
  hits: AutosuggestResultEntry[] | string[];
}

export type HintType =
  | 'answer'
  | 'pinned'
  | 'popular_document'
  | 'popular_query'
  | 'profile';

export interface QueryAutosuggestResultEntry {
  title: string;
  type: string;
  source: AppName;
  display_hint?: string;
}

export interface RecentVisitsAutosuggestResultEntry {
  name: string;
  webview_link: string;
  source: AppName;
  type: string;
  objectID: string;
  content_snippet?: string;
  person_filter?: string;
  modified_time: number;
  last_click_time?: number;
}

interface RecentVisitsAutoSuggestRawResult extends ErrorResponse {
  hits: RecentVisitsAutosuggestResultEntry[];
  has_more?: boolean;
}

const isStringArray = (arr: unknown[]): arr is string[] => {
  return arr.length > 0 && typeof arr[0] === 'string';
};

interface HitCount {
  name: string;
  count: number;
}

export interface HitCounts {
  apps?: {
    name: AppName;
    count: number;
  }[];
  types?: HitCount[];
}

interface HitCountsResponse extends ErrorResponse {
  hitsCounts: HitCounts;
}

interface SearchResponse extends ErrorResponse {
  people: PersonResult[];
  has_more: boolean;
  hits: HitWrapper<SearchHit>;
  emails?: {
    hits: SearchHit[];
    has_more: boolean;
  };
  pins?: HitWrapper<SearchHit>;
  timestamp: number;
  _version: string;
  hitCounts?: HitCounts;
  query_spell_corrected?: string;
  access_discovery_results: AccessDiscoveryResult[];
}

interface CloudIndexInit {
  idToken: string;
  aclKey: string;
  parameters?: Record<string, object>;
  dispatcher: Dispatcher;
}

interface SearchRequest {
  query?: string;
  filters?: Record<string, string[] | undefined>;
  sortOrder?: Sorting;
  parameters?: object[];
  queryId?: string;
}

interface CloudIndexRequestPayload {
  aclKey: string;
  q?: string;
  page?: number;
  parameters?: Record<string, object>;
}

export interface SearchPayload {
  q?: string;
  filters?: Record<string, string[] | undefined>;
  page: number;
  sort?: SortProperty;
  order?: SortOrder;
}

interface DispatchData {
  accessDiscoveryResults: AccessDiscoveryResult[];
  pinnedResults: PageSearchResult[];
  personResult?: PersonResult;
  results: PageSearchResult[];
  version: string;
  pageNum: number;
  hasMore: boolean;
  newSearch: boolean;
  messages: PageSearchResult[];
  hasMoreMessages: boolean;
  hitCounts?: HitCounts;
  querySpellCorrection?: string;
  queryId: string;
}

export class CloudIndex {
  private readonly aclKey: string;
  private readonly idToken: string;
  private readonly parameters?: Record<string, object>;
  private readonly dispatcher: Dispatcher;

  private lastQuery?: string;
  private lastFilters?: Record<string, string[] | undefined>;
  private lastSort?: SortProperty;
  private lastOrder?: SortOrder;
  private hasMore = false;
  private pageNum = 0;
  private queryId = '';
  private searchCancel = axios.CancelToken.source();
  private recentVisitsCancel = axios.CancelToken.source();
  private suggestCancel = axios.CancelToken.source();

  private readonly resultTransformer = new ResultTransformer();

  // Cache with max of 1000 items, or 15 minutes
  private readonly suggestionsCache = new LRU<string, AutosuggestResultEntry[]>(
    {
      max: 1000,
      ttl: 1000 * 60 * 15,
    }
  );

  public constructor(init: CloudIndexInit) {
    const { idToken, aclKey, parameters, dispatcher: store } = init;

    this.aclKey = aclKey;
    this.idToken = idToken;
    this.parameters = parameters;
    this.dispatcher = store;
  }

  public static update(
    init: CloudIndexInit,
    existing?: CloudIndex
  ): CloudIndex {
    const newIndex = existing ?? new CloudIndex(init);
    return Object.assign(newIndex, { ...existing, ...init });
  }

  // eslint-disable-next-line complexity
  public async search({
    sortOrder,
    filters,
    query,
    queryId = this.queryId,
  }: SearchRequest): Promise<void> {
    this.searchCancel.cancel();
    let sort: SortProperty | undefined = SortProperty.Relevance;
    let order: SortOrder | undefined = SortOrder.Descending;
    if (
      (sortOrder && this.lastOrder !== sortOrder.order) ||
      (sortOrder && this.lastSort !== sortOrder.sort)
    ) {
      ({ sort, order } = sortOrder);
      this.lastOrder = sortOrder.order;
      this.lastSort = sortOrder.sort;
    }

    const newQuery = query !== undefined && !!filters;

    if (this.queryId !== queryId) {
      this.queryId = queryId;
    }

    if (newQuery) {
      this.pageNum = 0;
    } else {
      query = this.lastQuery;
      filters = this.lastFilters;
      sort = this.lastSort;
      order = this.lastOrder;
    }

    if (sortOrder) {
      ({ sort, order } = sortOrder);
    }

    const noQuery = query === undefined || query.trim().length === 0;
    const noFilters = Object.keys(filters ?? {}).length === 0;

    if (noQuery && noFilters) {
      return;
    }

    const searchRequest: SearchPayload = {
      q: query,
      filters,
      page: this.pageNum,
      sort: this.lastSort ?? sort,
      order: this.lastOrder ?? order,
    };

    // Indicates searching state
    batch(() => {
      this.dispatcher(
        setSearchLoad(
          this.pageNum > 0
            ? SearchLoadState.LoadingMore
            : SearchLoadState.Loading
        )
      );

      this.dispatcher(setSearchRequest(searchRequest));
    });

    // Track performance of search query on Sentry
    const transaction = Sentry.startTransaction({
      name: SentryTransaction.ApiSearch,
      // TODO: Remove assertion once https://github.com/getsentry/sentry-javascript/issues/4731 is fixed
    });

    const span = transaction.startChild({ op: 'request' });

    try {
      this.searchCancel = axios.CancelToken.source();

      if (
        // Only reload when filter query changes
        !isEqual(filters, this.lastFilters) ||
        query !== this.lastQuery
      ) {
        this.populateSearchStats(searchRequest).catch(logError);
      }

      const {
        pins,
        hits,
        has_more,
        _version: version,
        people,
        hitCounts,
        emails: messages,
        query_spell_corrected,
        access_discovery_results,
      } = await this.requestWithRetry<SearchResponse, object>({
        url: `${SEARCH_URL}/_search`,
        data: searchRequest,
        cancelToken: this.searchCancel.token,
      });

      const [personResult] = people;

      this.hasMore = has_more;

      if (newQuery) {
        this.lastQuery = query;
        this.lastFilters = filters;
        this.pageNum = 0;
        this.lastSort = sortOrder ? sortOrder.sort : SortProperty.Relevance;
        this.lastOrder = sortOrder ? sortOrder.order : SortOrder.Descending;
      }

      const results = this.processHits(hits.hits);

      if (hitCounts?.types) {
        hitCounts.types = this.processTypeCounts(hitCounts.types);
      }

      this.dispatchRedux({
        accessDiscoveryResults: access_discovery_results,
        hasMore: this.hasMore,
        newSearch: newQuery,
        pageNum: this.pageNum,
        personResult,
        pinnedResults: pins ? this.processHits(pins.hits) : [],
        results,
        version,
        hitCounts,
        messages: messages ? this.processHits(messages.hits) : [],
        hasMoreMessages: messages?.has_more ?? false,
        querySpellCorrection: query_spell_corrected,
        queryId: this.queryId,
      });

      span.setStatus('ok' as SpanStatusType);
    } catch (error) {
      if (axios.isCancel(error)) {
        span.setStatus('aborted' as SpanStatusType);
        return;
      }

      span.setStatus('unknown_error' as SpanStatusType);
      throw error;
    } finally {
      span.finish();
      transaction.finish();
    }
  }

  public showMore(): void {
    if (!this.hasMore) {
      this.pageNum = 0;
      this.search({}).catch(logError);

      return;
    }

    this.pageNum++;
    this.search({}).catch(logError);
  }

  public async autosuggest(query: string): Promise<void> {
    this.suggestCancel.cancel();
    const cached = this.suggestionsCache.get(query);
    if (cached) {
      // Found query in cache, just resolve
      this.dispatcher(setSuggestions(cached));
      return;
    }

    try {
      this.suggestCancel = axios.CancelToken.source();

      const { hits } = await this.requestWithRetry<
        AutoSuggestRawResult,
        { q: string; page: number }
      >({
        url: `${SEARCH_URL}/_autosuggest`,
        data: {
          q: query,
          page: 0,
        },
        cancelToken: this.suggestCancel.token,
      });

      const out: AutosuggestResultEntry[] = isStringArray(hits)
        ? hits.map((hit) => ({ title: hit }))
        : hits;

      this.suggestionsCache.set(query, out);
      this.dispatcher(setSuggestions(out));
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw error;
      }
    }
  }

  public async logDocVisit(
    searchResultDocSource: RecentVisitsDocObject
  ): Promise<void> {
    this.recentVisitsCancel.cancel();
    try {
      this.recentVisitsCancel = axios.CancelToken.source();

      const { hits } = await this.requestWithRetry<
        RecentVisitsAutoSuggestRawResult,
        object
      >({
        url: `${SEARCH_URL}/_RecordRecentVisit`,
        data: {
          docSource: searchResultDocSource,
        },
        cancelToken: this.recentVisitsCancel.token,
      });

      const out: RecentVisitsAutosuggestResultEntry[] = hits.map((hit) => ({
        name: hit.name,
        webview_link: hit.webview_link,
        source: hit.source,
        type: hit.type,
        objectID: hit.objectID,
        content_snippet: hit.content_snippet,
        person_filter: hit.person_filter,
        modified_time: hit.modified_time,
        last_click_time: hit.last_click_time,
      }));

      this.dispatcher(setRecentVisitsSuggestions(out));
      setStorageItem(StorageKey.recentVisitsAutosuggest, out);
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw error;
      }
    }
  }

  private processTypeCounts(hitCounts: HitCount[]): HitCount[] {
    let allCount = 0;
    hitCounts = hitCounts.map(({ name, count }) => {
      allCount += count;
      return {
        name: name.replace('_v2', ''),
        count,
      };
    });

    hitCounts = updateArrayByKey(
      hitCounts,
      [
        {
          name: 'all',
          count: allCount,
        },
      ],
      'name'
    );

    return hitCounts;
  }

  private async populateSearchStats(searchRequest: SearchPayload) {
    this.dispatcher(clearHitCounts());
    const { hitsCounts } = await this.requestWithRetry<
      HitCountsResponse,
      object
    >({
      url: `${SEARCH_URL}/_hitsStat`,
      data: searchRequest,
      cancelToken: this.searchCancel.token,
    });

    // TODO(DAS-3672): Remove this
    if (hitsCounts.types) {
      hitsCounts.types = this.processTypeCounts(hitsCounts.types);
    }

    this.dispatcher(updateHitCounts(hitsCounts));
  }

  private processHits(rawHits: SearchHit[]): PageSearchResult[] {
    return rawHits.map((hit, index) =>
      this.resultTransformer.transform(hit, { index })
    );
  }

  private dispatchRedux({
    accessDiscoveryResults,
    pinnedResults,
    results,
    version,
    personResult,
    pageNum,
    messages,
    hasMoreMessages,
    hasMore,
    newSearch,
    hitCounts,
    querySpellCorrection,
    queryId,
  }: DispatchData) {
    // New search UI sets different redux state
    batch(() => {
      this.dispatcher(setQueryId(queryId));
      this.dispatcher((newSearch ? setResults : addResults)(results));
      this.dispatcher((newSearch ? setMessages : addMessages)(messages));
      if (pageNum === 0) {
        this.dispatcher(setHasMoreMessages(hasMoreMessages));
      }

      this.dispatcher(setSearchLoad(SearchLoadState.Loaded));
      this.dispatcher(setHasMore(hasMore));
      this.dispatcher(setVersion(version));
      this.dispatcher(setPinnedResults(pinnedResults));
      this.dispatcher(setAccessDiscoveryResults(accessDiscoveryResults));
      this.dispatcher(setPersonResult(personResult));
      this.dispatcher(setPage(pageNum));
      this.dispatcher(setQuerySpellCorrection(querySpellCorrection));
      if (hitCounts) {
        this.dispatcher(updateHitCounts(hitCounts));
      }
    });
  }

  private async requestWithRetry<T extends ErrorResponse, D extends object>({
    url,
    data,
    cancelToken,
    timeout,
  }: AxiosRequestConfig<D>): Promise<T> {
    if (!this.idToken || !this.aclKey) {
      throw new Error('Client not set');
    }

    let isRetry = false;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        // Retry logic
        // eslint-disable-next-line no-await-in-loop
        const { data: responseData } = await axios.request<
          T,
          AxiosResponse<T>,
          CloudIndexRequestPayload
        >({
          url,
          data: {
            aclKey: this.aclKey,
            parameters: this.parameters,
            ...data,
          },
          method: 'POST',
          headers: { authorization: `Bearer ${this.idToken}` },
          cancelToken,
          timeout,
          transitional: {
            clarifyTimeoutError: true,
          },
        });

        if (responseData.e) {
          throw new Error(responseData.e);
        }

        return responseData;
      } catch (error) {
        if (
          !isRetry &&
          axios.isAxiosError(error) &&
          error.response?.status === 403
        ) {
          // Retry logic
          // eslint-disable-next-line no-await-in-loop
          await refreshAllTokens();
          isRetry = true;
          continue;
        }

        throw error;
      }
    }
  }
}
