import React, { FC, useCallback, useEffect, useState } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { usePromiseState } from '../../components/pageSearch/results/misc/hooks';
import { trackEvent } from '../../extra/sharedMethods';
import { sendConfirmationCode } from '../../scripts/apis/auth';
import { RequestError } from '../../scripts/apis/request';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useToaster } from '../../scripts/hooks';
import { logError } from '../../scripts/utils';

// eslint-disable-next-line unicorn/no-unsafe-regex
const shortcodeRegex = /^(?:\w{5}-){3}\w{5}$/;

export const isValidLoginShortCode = (str: string): boolean =>
  shortcodeRegex.test(str);

export const ShortCodeAuth: FC<{ loginCode: string }> = ({ loginCode }) => {
  const [code, setCode] = useState(loginCode);
  const [working, workWrap] = usePromiseState();

  const toaster = useToaster();

  const enabled = isValidLoginShortCode(code);

  const performConfirmationCodeLogin = useCallback(
    (_code: string): void => {
      workWrap(
        sendConfirmationCode(_code).then((loginUrl) => {
          trackEvent(AnalyticsEvent.LoginClick);
          window.location.replace(loginUrl);
        })
      ).catch((error) => {
        if (error instanceof RequestError && error.statusCode === 500) {
          toaster.failure(
            'The temporary code you entered was invalid. Please try again.'
          );
        } else {
          toaster.failure();
        }

        logError(error);
      });
    },
    [workWrap, toaster]
  );

  const handleSubmit = useCallback(() => {
    if (!enabled) {
      return;
    }

    performConfirmationCodeLogin(code);
  }, [code, enabled, performConfirmationCodeLogin]);

  useEffect(() => {
    if (!isValidLoginShortCode(loginCode)) {
      return;
    }

    performConfirmationCodeLogin(loginCode);
  }, [performConfirmationCodeLogin, loginCode]);

  return (
    <>
      We have sent you a temporary login code. Please check your inbox for the
      email. If you don't see it within a few minutes, please check your spam
      folder. For any issues in receiving the code, feel free to contact our
      support team.
      <UITextbox
        error={code && !enabled && 'Invalid code'}
        onChange={setCode}
        onEnter={handleSubmit}
        placeholder="00000-00000-00000-00000"
        value={code}
      />
      <UIButton
        disabled={!enabled || working}
        onClick={handleSubmit}
        processing={working}
      >
        Login
      </UIButton>
    </>
  );
};
