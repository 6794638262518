import React, { FC } from 'react';
import './Components.scss';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { SupportLink } from '../../components/links/links';

interface ConnectStepProps {
  step: number;
  title: string;
}

export const ConnectStep: FC<ConnectStepProps> = ({
  step,
  children,
  title,
}) => {
  return (
    <div className="connectStep">
      <div className="stepTitle">
        <div className="stepNum">{step}</div>
        <h2>{title}</h2>
      </div>
      {children}
    </div>
  );
};

export const FirstNotice: FC<{ name: string }> = ({ name }) => {
  return (
    <p className="firstNotice">
      Follow the set-up process in order to connect {name} to Dashworks. If you
      run into difficulty reach out to <SupportLink />.
    </p>
  );
};

export const GeneralError: FC<{ text: string }> = ({ text }) => {
  return (
    <div className="generalError">
      <UIIcon name="error-solid" />
      <div>{text}</div>
    </div>
  );
};
