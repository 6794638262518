import './QuerySpellCorrectionView.scss';
import React, { useCallback } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import {
  usePageSearch,
  useSearchParam,
  useTrackSearchImpression,
} from '../../../../scripts/hooks';

export const QuerySpellCorrectionView: React.FC = () => {
  const version = usePageSearch((pageSearch) => pageSearch.version);
  const queryId = usePageSearch((pageSearch) => pageSearch.queryId);
  const [currentQuery, setSearchParam] = useSearchParam();
  const querySpellCorrection = usePageSearch(
    (pageSearch) => pageSearch.querySpellCorrection
  );

  const onQuerySpellCorrectClick = useCallback(() => {
    if (!querySpellCorrection) {
      return;
    }

    trackEvent(AnalyticsEvent.QuerySpellCorrectClick, {
      query: currentQuery,
      queryId,
      correctedQuery: querySpellCorrection,
      version,
    });

    setSearchParam(querySpellCorrection);
  }, [querySpellCorrection, currentQuery, queryId, version, setSearchParam]);

  const ref = useTrackSearchImpression(
    AnalyticsEvent.QuerySpellCorrectImpression,
    { correctedQuery: querySpellCorrection }
  );

  if (!querySpellCorrection) {
    return null;
  }

  return (
    <div className="querySpellCorrection" ref={ref}>
      Did you mean:{' '}
      <a className="correctionLink" onClick={onQuerySpellCorrectClick}>
        {querySpellCorrection}
      </a>
    </div>
  );
};

QuerySpellCorrectionView.displayName = 'QuerySpellCorrectionView';
