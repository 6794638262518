import React from 'react';
import { UploadedFile } from '../../../models/File';
import { UserApp } from '../../../models/User';
import { ConnectedApps } from '../../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../../scripts/models/answers';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';

interface IKnowledgeSourceTemplateProps<T> {
  sourceFilters: T[];
  setSourceFilters: React.Dispatch<React.SetStateAction<T[]>>;
  allSources: T[];
  allSourcesUserApps?: UserApp[];
  isSourceChecked: (source: T) => boolean;
  handleSourceCheckChange: (source: T, prevChecked: boolean) => void;
  SourceComponent: React.ComponentType<{
    source: T;
    allSourcesUserApps?: UserApp[];
    showConfigureAppSettings?: boolean;
    configureAppSettingsCallback?: (app: ConnectedApps) => void;
  }>;
  showConfigureAppSettings?: boolean;
  configureAppSettingsCallback?: (app: ConnectedApps) => void;
}

export const KnowledgeSourceTemplate = <
  T extends Answer | ConnectedApps | UploadedFile
>({
  sourceFilters,
  setSourceFilters,
  allSources,
  allSourcesUserApps,
  isSourceChecked,
  handleSourceCheckChange,
  SourceComponent,
  showConfigureAppSettings = false,
  configureAppSettingsCallback,
}: IKnowledgeSourceTemplateProps<T>): JSX.Element => {
  return (
    <>
      <div className="border-b max-h-[300px] w-[270px] overflow-y-auto">
        {allSources.map((source) => {
          const checked = isSourceChecked(source);

          return (
            <li
              className="flex gap-2 justify-between items-center p-3 cursor-pointer hover:bg-cloud-10"
              key={source.id}
              onClick={() => {
                handleSourceCheckChange(source, checked);
              }}
            >
              <div className="flex items-center gap-2">
                <SourceComponent
                  allSourcesUserApps={allSourcesUserApps}
                  configureAppSettingsCallback={configureAppSettingsCallback}
                  showConfigureAppSettings={checked && showConfigureAppSettings}
                  source={source}
                />
              </div>
              <div>
                <UIIcon className={checked ? '' : 'invisible'} name="check" />
              </div>
            </li>
          );
        })}
      </div>

      <div className="flex gap-2 py-2 px-4 text-sm">
        <div
          className={
            sourceFilters.length === allSources.length ? 'text-cloud-20' : ''
          }
          onClick={() => {
            setSourceFilters(allSources);
          }}
        >
          Select all
        </div>
        <div className="text-cloud-15">|</div>
        <div
          className={sourceFilters.length === 0 ? 'text-cloud-20' : ''}
          onClick={() => {
            setSourceFilters([]);
          }}
        >
          Clear all
        </div>
      </div>
    </>
  );
};
