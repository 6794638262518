import { logWarning } from './scripts/utils';

export type Listener<T extends object> = (eventParams: T) => void;

export class EventEmitter<T = object> {
  private listeners: Record<string, Listener<Partial<T>>[]> = {};

  public on(eventName: string, listener: Listener<Partial<T>>): void {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }

    this.listeners[eventName]?.push(listener);
  }

  public off(eventName: string, listener: Listener<Partial<T>>): void {
    if (!this.listeners[eventName]) {
      logWarning('[off] No listeners for', eventName);
      return;
    }

    const index = this.listeners[eventName]?.indexOf(listener);
    if (index === undefined) {
      logWarning(
        '[off] Index is undefined for ',
        eventName,
        this.listeners[eventName]?.length
      );

      return;
    }

    if (index !== -1) {
      this.listeners[eventName]?.splice(index, 1);
    }
  }

  public emit(eventName: string, eventArgs: Partial<T>): void {
    if (!this.listeners[eventName]) {
      return;
    }

    for (const listener of this.listeners[eventName] ?? []) {
      listener(eventArgs);
    }
  }

  public removeAllListeners(): void {
    this.listeners = {};
  }

  public removeAllListenersForEvent(eventName: string): void {
    if (!this.listeners[eventName]) {
      return;
    }

    this.listeners[eventName] = [];
  }
}
