import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Bot } from '../../../models/Bots';
import { ProfileInfoHover } from '../../bots/ProfileInfoHover';
import { RadioList } from '../../controls/RadioList/RadioList';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';

interface BotsSelectButtonProps {
  bots: Bot[];
  botId: string;
  setBotId(botId: string): void;
}

export const BotsSelectButtonSettings: FC<BotsSelectButtonProps> = ({
  bots,
  botId,
  setBotId,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const currentBot = useMemo(
    () => bots.find((bot) => bot.id === botId),
    [bots, botId]
  );

  const handleBotSelect = useCallback(
    (newBotId: string) => {
      setBotId(newBotId);
      setPopoverOpen(false);
    },
    [setBotId]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!currentBot) {
    return null;
  }

  return (
    <div
      className="inline-flex relative mt-2"
      onClick={() => {
        setPopoverOpen(true);
      }}
    >
      <UITooltip title="Select Bot">
        <div className="max-w-[200px] flex gap-2 justify-between items-center hover:bg-cloud-10 px-2 py-1 rounded-md cursor-pointer border-gray-30 border-solid border">
          <div className="flex gap-2 items-center truncate">
            <div className="text-lg">{currentBot.icon}</div>
            <div className="truncate">{currentBot.bot_name}</div>
          </div>
          <div>
            <UIIcon className="sm:inline hidden hover:pt-1" name="arrow-down" />
          </div>
        </div>
      </UITooltip>
      {popoverOpen && (
        <BotsSelectSettings
          bots={bots}
          currentBot={currentBot}
          handleBotSelect={handleBotSelect}
          ref={selectRef}
        />
      )}
    </div>
  );
};

interface BotsRadioListData {
  displayName: string;
  icon: ReactNode;
  value: string;
  additionalMetadata: ReactNode;
}

interface BotsSelectProps {
  bots: Bot[];
  currentBot: Bot;
  handleBotSelect: (newBotId: string) => void;
}

const BotsSelectSettings = React.forwardRef<HTMLDivElement, BotsSelectProps>(
  ({ bots, currentBot, handleBotSelect }, ref) => {
    const botsRadioListData: BotsRadioListData[] = useMemo(
      () =>
        bots.map((bot) => ({
          displayName: bot.bot_name,
          icon: <span className="text-lg">{bot.icon}</span>,
          value: bot.id,
          additionalMetadata: (
            <ProfileInfoHover
              description={bot.description}
              icon={bot.icon}
              name={bot.bot_name}
            />
          ),
        })),
      [bots]
    );

    return (
      <div
        className="z-50 absolute top-8 left-0 bg-white border border-solid border-gray-30 rounded-md mt-2 min-w-[286px] max-h-[300px] overflow-y-auto !cursor"
        ref={ref}
      >
        <RadioList
          listItemClass="hover:cursor-pointer"
          nonEnterpriseOnlyListItemClass="hover:bg-cloud-10"
          onChange={handleBotSelect}
          options={botsRadioListData}
          selected={currentBot.id}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setPaywallModalOpen={() => {}}
        />
      </div>
    );
  }
);

BotsSelectSettings.displayName = 'BotsSelectSettings';
