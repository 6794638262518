import React, { useCallback, useEffect, useState } from 'react';
import { FilterDefaults } from '../../../../../scripts/constants/filters';
import { useModifiedParam } from '../../../../../scripts/hooks';
import {
  UISelect,
  UISelectItem,
} from '../../../../controls/ui/UISelect/UISelect';

const MODIFIED_OPTIONS: UISelectItem[] = [
  {
    label: 'Any time',
    value: FilterDefaults.Modified,
    isDefault: true,
  },
  {
    label: 'Past day',
    value: 'day',
  },
  {
    label: 'Past week',
    value: 'week',
  },
  {
    label: 'Past month',
    value: 'month',
  },
  {
    label: 'Past year',
    value: 'year',
  },
];

export const ModifiedFilter: React.FC = () => {
  const [modifiedParam, setModifiedParam] = useModifiedParam();

  const defaultValue = FilterDefaults.Modified;
  const [selectedValue, setSelectedValue] = useState(defaultValue as string);

  useEffect(() => {
    // If param isn't valid on load, set to default
    const validValues = MODIFIED_OPTIONS.map((option) => option.value);
    if (!validValues.includes(modifiedParam)) {
      setModifiedParam(defaultValue);
    }
  }, [defaultValue, modifiedParam, setModifiedParam]);

  useEffect(() => {
    setSelectedValue(modifiedParam);
  }, [modifiedParam]);

  const selectLabelPrefix = <span>Modified: </span>;

  const handleOnSelect = useCallback(
    (item: UISelectItem) => {
      setModifiedParam(item.value);
    },
    [setModifiedParam]
  );

  return (
    <UISelect
      items={MODIFIED_OPTIONS}
      onSelect={handleOnSelect}
      selectLabelPrefix={selectLabelPrefix}
      selectedValue={selectedValue}
    />
  );
};
