import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { FeatureFlags, useFlag } from '../../../scripts/hooks';

interface GatedRouteProps extends RouteProps {
  flag: keyof FeatureFlags;
}

/**
 * Use in place of <Route> with a specific feature flag.
 */
export const GatedRoute: React.FC<GatedRouteProps> = ({
  flag,
  ...routeProps
}) => {
  return useFlag(flag) ? <Route {...routeProps} /> : null;
};
