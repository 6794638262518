import './UITabs.scss';
import { Box, Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

export interface TabDefinition {
  key: string;
  label: string;
  icon?: ReactElement;
  content?: ReactElement;
}

export interface UITabController {
  changeSelectedTab: (index: number) => void;
}

export const UITabs: FC<{
  tabs: TabDefinition[];
  className?: string;
  lowTitleWidth?: boolean;
  firstLastTitleMargin?: number;
  initialSelectedTabIndex?: number;
  getTabController?: (controller: UITabController) => void;
  getTabContent?: (index: number) => ReactElement;
  handleTabChange?: () => void;
}> = ({
  tabs,
  getTabContent,
  className,
  lowTitleWidth,
  firstLastTitleMargin,
  getTabController,
  handleTabChange,
  initialSelectedTabIndex = 0,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    initialSelectedTabIndex
  );

  const onTabChange = useCallback(
    (_ev, value: number) => {
      setSelectedTabIndex(value);

      if (handleTabChange) {
        handleTabChange();
      }
    },
    [setSelectedTabIndex, handleTabChange]
  );

  useEffect(() => {
    if (getTabController) {
      getTabController({ changeSelectedTab: setSelectedTabIndex });
    }
  }, [getTabController]);

  return (
    <div className={classNames('uiTabs', className)}>
      <Box
        className="uiTabs MuiTabs-root Mui-selected"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs
          onChange={onTabChange}
          value={selectedTabIndex}
          variant="scrollable"
        >
          {tabs.map((t) => (
            <Tab
              icon={t.icon}
              iconPosition="start"
              key={t.key}
              label={t.label}
              sx={{
                textTransform: 'none',
                '&:first-of-type': {
                  marginLeft: firstLastTitleMargin,
                },
                '&:last-of-type': {
                  marginRight: firstLastTitleMargin,
                },
                ...(lowTitleWidth && {
                  minWidth: 'fit-content',
                }),
              }}
            />
          ))}
        </Tabs>
      </Box>
      <div className="uiTabPanels">
        {tabs.map((t, index) => (
          <UITabPanel key={t.key} shown={index === selectedTabIndex}>
            {t.content ?? getTabContent?.(index)}
          </UITabPanel>
        ))}
      </div>
    </div>
  );
};

const UITabPanel: FC<{ shown: boolean }> = ({ children, shown }) => {
  return (
    <div className="uiTabpanel" hidden={!shown} role="tabpanel">
      {shown && children}
    </div>
  );
};
