import {
  BooleanParam,
  NumberParam,
  QueryParamConfig,
  StringParam,
  UrlUpdateType,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { FileType, FilterDefaults, ObjectType } from '../constants/filters';

export enum QueryParams {
  // Used by search
  App = 'app',
  Filter = 'filter',
  Modified = 'modified',
  Person = 'person',
  Sort = 'sort',
  Search = 'q',
  Omnibox = 'omnibox',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  IsWeb = 'is_web',
  IsBaseLLM = 'is_base_llm',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  FileType = 'filetype',
  // Used by login page
  Unauthorized = 'unauthorized',
  UnauthorizedMessage = 'message',
  Edit = 'edit',
  SSOConfigured = 'ssoConfigured',
  ObjectID = 'objectID',
  CustomDomainEmail = 'customDomainEmail',
  Suggested = 'suggested',
  SemanticToggleState = 'semanticToggleState',
  LoginCode = 'loginCode',
  LoginEmail = 'loginEmail',
  LoginProvider = 'loginProvider',
  LoginStage = 'loginStage',
  // used by admin billing portal
  Redirect = 'redirect',
  Error = 'err',
  Src = 'src',
}

/**
 * Uses default value when query param is nullable and hides it from the search.
 *
 * @see {@link https://github.com/pbeshai/use-query-params/issues/138}
 */
function withSquashedDefault<T, Default extends NonNullable<T>>(
  paramConfig: QueryParamConfig<T>,
  defaultValue: Default
): QueryParamConfig<T, Default | NonNullable<T>> {
  const defaulted = withDefault(paramConfig, defaultValue);

  // Types are wonky here, this needs a re-write

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    encode: (value: Default | T) => {
      return defaulted.encode(
        (value === defaultValue ? undefined : value) as Default | T
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    decode: defaulted.decode as any,
  };
}

const defaultEmpty = withSquashedDefault(StringParam, '');
const defaultAppFilterParam = withSquashedDefault(
  StringParam,
  FilterDefaults.Apps
);

const defaultFilterParam = withSquashedDefault(
  StringParam,
  FilterDefaults.ObjectType
);

const defaultModifiedParam = withSquashedDefault(
  StringParam,
  FilterDefaults.Modified
);

const defaultPersonParam = withSquashedDefault(
  StringParam,
  FilterDefaults.Person
);

const defaultSortParam = withSquashedDefault(StringParam, FilterDefaults.Sort);
const defaultFileTypeParam = withSquashedDefault(
  StringParam,
  FilterDefaults.FileType
);

const defaultSearchQuery = withSquashedDefault(StringParam, '');
const defaultBoolFalse = withSquashedDefault(BooleanParam, false);

const defaultAllFilters = {
  [QueryParams.App]: defaultAppFilterParam,
  [QueryParams.Modified]: defaultModifiedParam,
  [QueryParams.Person]: defaultPersonParam,
  [QueryParams.Sort]: defaultSortParam,
  [QueryParams.FileType]: defaultFileTypeParam,
  [QueryParams.Filter]: defaultFilterParam,
};

const defaultAllSearchRelatedParams = {
  ...defaultAllFilters,
  [QueryParams.Search]: defaultSearchQuery,
  [QueryParams.Suggested]: defaultBoolFalse,
  [QueryParams.SemanticToggleState]: defaultBoolFalse,
};

export type StateTuple<T> = [
  val: T,
  setVal: (val: T, replaceState?: UrlUpdateType) => void
];

export function useAppParam(): StateTuple<string> {
  return useQueryParam(QueryParams.App, defaultAppFilterParam);
}

export function useFilterParam(): StateTuple<string> {
  return useQueryParam(QueryParams.Filter, defaultFilterParam);
}

export function useModifiedParam(): StateTuple<string> {
  return useQueryParam(QueryParams.Modified, defaultModifiedParam);
}

export function usePersonParam(): StateTuple<string> {
  return useQueryParam(QueryParams.Person, defaultPersonParam);
}

export function useSortParam(): StateTuple<string> {
  return useQueryParam(QueryParams.Sort, defaultSortParam);
}

export function useSearchParam(): StateTuple<string> {
  return useQueryParam(QueryParams.Search, defaultSearchQuery);
}

export function useFileTypeParam(): StateTuple<string> {
  return useQueryParam(QueryParams.FileType, defaultFileTypeParam);
}

export function useUnauthorizedParam(): StateTuple<boolean> {
  return useQueryParam(QueryParams.Unauthorized, defaultBoolFalse);
}

export function useErrorQueryParam(): StateTuple<string | undefined> {
  return useQueryParam(QueryParams.Error, undefined);
}

export function useSrcQueryParam(): StateTuple<string | undefined> {
  return useQueryParam(QueryParams.Src, undefined);
}

export function useUnauthorizedMessageParam(): StateTuple<string> {
  return useQueryParam(
    QueryParams.UnauthorizedMessage,
    withDefault(StringParam, '')
  );
}

export function useCustomDomainEmailParam(): StateTuple<string> {
  return useQueryParam(QueryParams.CustomDomainEmail, defaultEmpty);
}

export const useEditParam = (): StateTuple<boolean> => {
  return useQueryParam(QueryParams.Edit, defaultBoolFalse);
};

export const useObjectIDParam = (): StateTuple<string> => {
  return useQueryParam(QueryParams.ObjectID, defaultEmpty);
};

export const useSSOConfiguredParam = (): StateTuple<boolean> => {
  return useQueryParam(QueryParams.SSOConfigured, defaultBoolFalse);
};

export const useOmniboxParam = (): StateTuple<boolean> => {
  return useQueryParam(QueryParams.Omnibox, defaultBoolFalse);
};

export const useLoginCode = (): StateTuple<string> => {
  return useQueryParam(QueryParams.LoginCode, defaultEmpty);
};

export const useLoginStage = (): StateTuple<number> => {
  return useQueryParam(
    QueryParams.LoginStage,
    withSquashedDefault(NumberParam, 0)
  );
};

export const useLoginEmail = (): StateTuple<string> => {
  return useQueryParam(QueryParams.LoginEmail, defaultEmpty);
};

export const useLoginProvider = (): StateTuple<string> => {
  return useQueryParam(QueryParams.LoginProvider, defaultEmpty);
};

export const useRedirectParam = (): StateTuple<string> => {
  return useQueryParam(QueryParams.Redirect, defaultEmpty);
};

interface SecondaryFilters {
  app: string;
  person: string;
  modified: string;
  filetype: string;
}

interface Filters extends SecondaryFilters {
  filter: string;
}

interface FiltersWithSort extends Filters {
  sort: string;
}

export function useAllFilters(): [
  FiltersWithSort,
  (params: Partial<FiltersWithSort>) => void
] {
  return useQueryParams(defaultAllFilters) as [
    FiltersWithSort,
    (params: Partial<FiltersWithSort>) => void
  ];
}

export interface AllSearchRelatedParams extends FiltersWithSort {
  q: string;
  suggested: boolean;
  semanticToggleState: boolean;
}

/**
 * Returns the query args used for page search UI.
 */
export function useAllSearchRelatedParams(): [
  AllSearchRelatedParams,
  (params: Partial<AllSearchRelatedParams>, updateType?: UrlUpdateType) => void
] {
  return useQueryParams(defaultAllSearchRelatedParams) as [
    AllSearchRelatedParams,
    (
      params: Partial<AllSearchRelatedParams>,
      updateType?: UrlUpdateType
    ) => void
  ];
}

/**
 * Checks if any secondary filters, eg. any
 * options on the filter bar (except sorting) have been altered by the user.
 */
function isCustomSecondaryFilter({
  app,
  modified,
  person,
  filetype,
}: SecondaryFilters): boolean {
  return !!(
    (app && app !== FilterDefaults.Apps) ||
    (modified && modified !== FilterDefaults.Modified) ||
    (person && person !== FilterDefaults.Person) ||
    (filetype && filetype !== FilterDefaults.FileType)
  );
}

/**
 * Checks if any secondary filters or modifiers (sort), eg. any
 * options on the filter bar have been altered by the user.
 */
export function isCustomSecondaryModifier(
  params: SecondaryFilters & { sort: string }
): boolean {
  return (
    isCustomSecondaryFilter(params) ||
    !!(params.sort && params.sort !== FilterDefaults.Sort)
  );
}

/**
 * Returns true if no filters have been applied.
 */
export const anyFiltersApplied = (
  params: SecondaryFilters & { filter: string }
): boolean => {
  return (
    isCustomSecondaryFilter(params) &&
    !!(params.filter && params.filter !== FilterDefaults.ObjectType)
  );
};

/**
 * Returns query with all defaults except for name.
 */
export function getPersonSearchParams(name: string): AllSearchRelatedParams {
  return {
    q: name,
    filter: ObjectType.All,
    app: FilterDefaults.Apps,
    person: FilterDefaults.Person,
    modified: FilterDefaults.Modified,
    filetype: FileType.Any,
    sort: FilterDefaults.Sort,
    suggested: false,
    semanticToggleState: false,
  };
}
