import './AuthActionContainer.scss';
import React, { FC, ReactNode, useMemo } from 'react';
import dashworksLogo from '../../../assets/images/dashworks_logo.svg';
import swiggyLogo from '../../../assets/images/swiggy_logo.svg';
import { UINotice } from '../../../components/controls/ui/UINotice/UINotice';
import { Newrelic } from '../../../external-references';
import { useRedirectParam } from '../../../scripts/hooks';

interface AuthActionContainerProps {
  title: string;
  warningMessage?: ReactNode;
}

export const AuthActionContainer: FC<AuthActionContainerProps> = ({
  title,
  children,
  warningMessage,
}) => {
  const [redirectParam] = useRedirectParam();
  const inSidePanel = useMemo(
    () => redirectParam === '/side-panel',
    [redirectParam]
  );

  return (
    <div
      className="max-lgr:w-full max-lgr:m-auto w-[440px] relative flex flex-col items-stretch gap-4 text-sm"
      data-newrelic-id={Newrelic.AuthActionContainer}
    >
      {!!redirectParam && !inSidePanel && (
        <UINotice centered>You need to sign in to view this content</UINotice>
      )}
      {location.origin === 'https://searchit.swiggy.in' ? (
        <img src={swiggyLogo} width={30} />
      ) : (
        <img src={dashworksLogo} width={40} />
      )}
      <div className="flex flex-col gap-1">
        <div className="text-3xl font-semibold">{title}</div>
        <div className="text-gray-50">
          Meet your AI knowledge assistant for work
        </div>
      </div>
      {warningMessage && (
        <h3 className="text-mahogany-10 m-0">
          <strong>{warningMessage}</strong>
        </h3>
      )}
      {children}
    </div>
  );
};
