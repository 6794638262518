import React from 'react';
import { UploadedFile } from '../../../models/File';
import { UserApp } from '../../../models/User';
import { ConnectedApps } from '../../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../../scripts/models/answers';

interface IBotKnowledgeSourceTemplateProps<T> {
  allSources: T[];
  allSourcesUserApps?: UserApp[];
  SourceComponent: React.ComponentType<{
    source: T;
    allSourcesUserApps?: UserApp[];
  }>;
}

export const BotKnowledgeSourceTemplate = <
  T extends Answer | ConnectedApps | UploadedFile
>({
  allSources,
  allSourcesUserApps,
  SourceComponent,
}: IBotKnowledgeSourceTemplateProps<T>): JSX.Element => {
  return (
    <div className="max-h-[300px] w-[270px] overflow-y-auto">
      {allSources.map((source) => {
        return (
          <li
            className="flex gap-2 justify-between items-center p-3"
            key={source.id}
          >
            <div className="flex items-center gap-2">
              <SourceComponent
                allSourcesUserApps={allSourcesUserApps}
                source={source}
              />
            </div>
          </li>
        );
      })}
    </div>
  );
};
