import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ConversationMember } from '../../../models/QAmodels';
import { useQAController } from '../../../scripts/QAController';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { useToaster } from '../../../scripts/hooks/toast';
import { uniqBy } from '../../../scripts/utils';
import { Loading } from '../../controls/Loading/Loading';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UserProfileIcon } from '../../general/UserProfile/UserProfile';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

interface MembersPopoverProps {
  conversationId?: string;
  showDivider?: boolean;
}

export const MembersPopover: FC<MembersPopoverProps> = ({
  conversationId,
  showDivider,
}) => {
  const toaster = useToaster();

  const [members, setMembers] = useState<ConversationMember[]>([]);
  const [loading, setLoading] = useState(false);
  const [showConversationMembersPopover, setShowConversationMembersPopover] =
    useState(false);

  const qaController = useQAController();

  const newlyAddedConversationMembers =
    qaController.useNewlyAddedConversationMembers();

  const allMembers: ConversationMember[] = useMemo(() => {
    return uniqBy(
      [
        ...members,
        ...newlyAddedConversationMembers.filter(
          (member) => member.conversation_id === conversationId
        ),
      ],
      'email'
    );
  }, [members, newlyAddedConversationMembers, conversationId]);

  const loadMembers = useCallback(async () => {
    if (!conversationId) {
      toaster.failure('Failed to load members');
      return;
    }

    try {
      setLoading(true);
      const result = await invokeFastApi<{ members: ConversationMember[] }>({
        path: `/topics/topic/members/${conversationId}`,
        method: 'GET',
      });

      setMembers((prevMembers) =>
        uniqBy([...prevMembers, ...result.members], 'email')
      );
    } catch {
      toaster.failure('Failed to load members');
    } finally {
      setLoading(false);
    }
  }, [conversationId, toaster]);

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  const commonAvatarClassNames = 'rounded-[50%] w-8 h-8';
  const commonCardClassNames = 'flex gap-2 mb-2';
  const iconContainerClassNames = `${commonAvatarClassNames} min-w-[32px] min-h-[32px] bg-gray-20 overflow-hidden relative`;
  const centerClassName = `absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`;

  return (
    <Popover
      onOpenChange={setShowConversationMembersPopover}
      open={showConversationMembersPopover}
    >
      <PopoverTrigger asChild>
        <div className="flex gap-1">
          <div
            className="flex p-0.5 rounded hover:bg-cloud-15"
            onClick={() => {
              setShowConversationMembersPopover(
                !showConversationMembersPopover
              );
            }}
          >
            <UIIcon name="group" tooltip="Share settings" />
          </div>
          {showDivider && (
            <div className="text-cloud-20 text-sm hidden sm:block">|</div>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="z-10 bg-white sm:w-[424px] w-[250px]">
        <div className="font-semibold mb-2">You shared this topic</div>

        <div className={commonCardClassNames}>
          <div className={iconContainerClassNames}>
            <UIIcon className={centerClassName} name="messages" />
          </div>{' '}
          <div> Just type to message members added to this topic</div>
        </div>

        <div className={commonCardClassNames}>
          <div className={iconContainerClassNames}>
            <UIIcon className={centerClassName} name="at-sign" stroke />
          </div>{' '}
          <div>
            Type <span>@Dashworks</span> to ask Dashworks a question
          </div>
        </div>

        <div className={commonCardClassNames}>
          <div className={iconContainerClassNames}>
            <UIIcon className={centerClassName} name="group" />
          </div>
          <div>Invite more members to this topic by adding @Name</div>
        </div>

        <div className="flex justify-between">
          <div className="font-semibold mb-2">Members</div>

          {allMembers.length > 0 && loading && (
            <UIIcon name="spinner-animated" size={16} />
          )}
        </div>

        {allMembers.length === 0 && loading && <Loading />}

        <div className="custom-scrollbar overflow-y-auto max-h-[200px]">
          {allMembers.map((member) => (
            <div className={commonCardClassNames} key={member.email}>
              <div>
                <UserProfileIcon
                  email={member.email}
                  icon={member.icon}
                  name={member.display_name}
                />
              </div>
              <div>{member.display_name}</div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
