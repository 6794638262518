import { AppName } from '../../apps/definition';
import { UserPreferences } from '../../models/User';
import { HomepageLink } from '../models/homepage-link';
import { invokeAWSGatewayAPI } from './aws';

export const getUserPhoto = async (): Promise<{ photoUrl: string }> => {
  return (
    await invokeAWSGatewayAPI<{ photoUrl: string }>({
      path: '/users/self/photo',
      method: 'GET',
    })
  ).data;
};

interface UserUpdatePayload {
  has_seen_web_onboarding?: boolean;
}

export const updateUser = async (
  userId: string,
  data: UserUpdatePayload
): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: `/users/${encodeURIComponent(userId)}`,
    method: 'PATCH',
    data,
  });
};

export const updateUserBookmarks = async (
  userId: string,
  homepageLinks: HomepageLink[]
): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: `/users/${encodeURIComponent(userId)}/bookmarks`,
    method: 'PATCH',
    data: {
      bookmarks: homepageLinks,
    },
  });
};

export const updateUserPreference = async (
  userId: string,
  data: Partial<UserPreferences>
): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: `/users/${encodeURIComponent(userId)}/preferences`,
    method: 'PATCH',
    data,
  });
};

export const updateUserReconnectedApps = async (
  userId: string,
  data: Record<AppName, number>
): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: `/users/${encodeURIComponent(userId)}/reconnected_apps`,
    method: 'PATCH',
    data,
  });
};

/**
 * Update onboarding state for user.
 */
interface UpdateOnboardingStateArgs {
  onboardingStep: string;
  force?: boolean;
}

export const updateOnboardingState = async (
  userId: string,
  args: UpdateOnboardingStateArgs
): Promise<void> => {
  const apiArgs = {
    onboarding_step: args.onboardingStep,
    force: args.force,
  };

  await invokeAWSGatewayAPI({
    path: `/onboarding-state/${encodeURIComponent(userId)}`,
    method: 'PATCH',
    data: apiArgs,
  });
};
