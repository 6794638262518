import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SidebarContainer, SidebarRoutes } from './containers/SidebarContainer';
import { LazyWorkspaceSetupPage } from './pages/onboarding/WorkspaceSetupPage';
import { QueryPreservingRedirect } from './scripts/routing/QueryPreservingRedirect';
const routes = Object.values(SidebarRoutes);

export const PageRouter: FC = () => {
  return (
    <Switch>
      <Route component={LazyWorkspaceSetupPage} exact path="/workspace-setup" />
      <Route component={SidebarContainer} exact path={routes} />

      <Redirect from="/admin/browser-extension" to="/my-settings" />
      <Redirect from="/admin/hris" to="/apps/org/finch" />
      <QueryPreservingRedirect to="/" />
    </Switch>
  );
};
