import React, { FC } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <SimpleAPIKeyInstall
      app={app}
      instructionSteps={
        <ConnectStep step={1} title="Generate API key">
          <p>
            Sign in with the {app.definition.displayName} account you want to
            connect with Dashworks and copy the API key.
          </p>
          <div className="actionContainer">
            <UIButton href="https://app.shortcut.com/settings/account/api-tokens">
              Sign in with {app.definition.displayName}
            </UIButton>
          </div>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
    />
  );
};
