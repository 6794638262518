import React from 'react';
import { FileTypeIcon } from '../../../../../../controls/FileTypeIcon/FileTypeIcon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

interface GeneralFileResultProps extends ResultProps {
  appName: string;
}

export const GeneralFileResult: React.FC<GeneralFileResultProps> = ({
  result,
  appName,
}) => {
  return (
    <PageSearchResultItem
      appName={appName}
      icon={
        <FileTypeIcon
          fileName={result.name}
          mimeType={result.mimeType}
          type={result.type}
        />
      }
      miscMeta={result.owners[0] && <div>@{result.owners[0].name}</div>}
      result={result}
    />
  );
};
