import { useEffect, useState } from 'react';

export class WatchedValue<T> {
  private readonly _listeners: ((value: T) => void)[] = [];

  public constructor(private _value: T) {}

  /**
   *
   * @param cb apply update to old value and return new value
   */
  public applyUpdate(cb: (old: T) => T): void {
    this._value = cb(this._value);
    this.notifyListeners();
  }

  public updateObject(updatedProperties: Partial<T>): void {
    this.applyUpdate((old) => ({ ...old, ...updatedProperties }));
  }

  public getValue(): T {
    return this._value;
  }

  public useHook(): T {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [hookValue, setHookValue] = useState<T>(this._value);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const valueChanged = () => {
        setHookValue(this._value);
      };

      this.addListener(valueChanged);
      return () => {
        this.removeListener(valueChanged);
      };
    }, [setHookValue]);

    return hookValue;
  }

  public addListener(listener: (value: T) => void): void {
    this._listeners.push(listener);
  }

  public removeListener(listener: (value: T) => void): void {
    const index = this._listeners.indexOf(listener);
    if (index !== -1) {
      this._listeners.splice(index, 1);
    }
  }

  private notifyListeners() {
    for (const listener of this._listeners) {
      listener(this._value);
    }
  }
}
