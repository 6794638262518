import React from 'react';
import { PageSearchResult } from '../../../../../../scripts/models/page-search-result';
import {
  PageSearchResultItem,
  PageSearchResultItemProps,
} from '../../../PageSearchResultItem/PageSearchResultItem';

export const HashIndexResult: React.FC<
  Partial<PageSearchResultItemProps> & {
    result: PageSearchResult;
    appName: string;
  }
> = ({ result, ...rest }) => {
  return (
    <PageSearchResultItem
      breadcrumbs={[...result.file_path, `#${result.key!}`]}
      result={result}
      showLastItemBreadcrumb
      {...rest}
    />
  );
};
