/* eslint-disable line-comment-position */
/* eslint-disable no-inline-comments */
import React, {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './PreviewItem.scss';
import { trackEvent } from '../../../../extra/sharedMethods';
import { setPreview } from '../../../../redux/pageSearch/actions';
import { useDispatch } from '../../../../redux/store';
import { composeRefs } from '../../../../scripts/compose-refs';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import {
  useAllSearchRelatedParams,
  usePageSearch,
} from '../../../../scripts/hooks';
import { useFeedback } from '../../../../scripts/hooks/feedback';
import { sanitize } from '../../../../scripts/html';
import { PageSearchResult } from '../../../../scripts/models/page-search-result';
import { useTrackMouseEnter } from '../../../../scripts/page-search/use-track-mouse-enter';
import { elementIndex } from '../../../../scripts/utils';
import { AppIcons, UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../../controls/ui/UIIconButton/UIIconButton';
import { LazyContentAwareImage } from '../../../general/ContentAwareImage';
import { MetaPairs } from '../../results/misc/MetaPairs';
import { useResize } from '../../results/misc/hooks';
import { Backlinks } from '../BackLinks/BackLinks';
import { PreviewIframe } from '../PreviewIframe/PreviewIframe';

export interface MetaPair {
  name: string;
  value: ReactNode;
}

export type PreviewIframeType = 'application/pdf' | 'base64' | 'html';

export interface IFrameData {
  content: string;
  type?: PreviewIframeType;
  contentCSS?: string;
}

interface PreviewItemProps {
  icon?: AppIcons | JSX.Element;

  result: PageSearchResult;
  title: string;
  titleIcon?: ReactNode;
  /**
   * Mutually exclusive with `imgSrc`.
   */
  iframe?: IFrameData;
  imgSrc?: string;
  metaPairs: MetaPair[];
  isWorking?: boolean;
  content?: ReactNode;
  contentUrl?: string;
}

const TOP_OFFSET = 0;

const getElTopOffset = (el: Element): number => {
  return el.getBoundingClientRect().top + window.scrollY;
};

// eslint-disable-next-line max-lines-per-function
export const PreviewItem: React.FC<PreviewItemProps> = ({
  title,
  icon,
  result,
  iframe,
  metaPairs,
  isWorking,
  imgSrc,
  titleIcon,
  content: previewContent,
  contentUrl,
}) => {
  const offsetY = usePageSearch((ps) => ps.preview?.offsetY) ?? 0;
  const dispatch = useDispatch();
  const feedback = useFeedback();

  const previewRef = useRef<HTMLDivElement>(null);
  const [searchState] = useAllSearchRelatedParams();
  const version = usePageSearch((pageSearch) => pageSearch.version);
  const queryId = usePageSearch((pageSearch) => pageSearch.queryId);
  const [showImage, setShowImage] = useState(true);

  const onWindowScroll = useCallback(() => {
    if (!previewRef.current) {
      return;
    }

    const previewContainer = document.querySelector('.previewContainer');
    if (!previewContainer) {
      return;
    }

    const active = document.querySelector('.resultsList .previewActive');
    if (!active) {
      return;
    }

    const topHeader = document.querySelector('.topHeader');
    if (!topHeader) {
      return;
    }

    // By default, preview is anchored to the top of the result
    const topPx = offsetY + TOP_OFFSET;

    const previewOffset = getElTopOffset(previewContainer);
    const scrollTop = window.scrollY;
    const previewHeight = previewRef.current.offsetHeight;
    const previewTop = topPx + previewOffset - scrollTop;
    const previewBottom = topPx + previewOffset + previewHeight - scrollTop;

    const headerHeight = (topHeader as HTMLElement).offsetHeight;
    const heightLimit = window.innerHeight;

    let topOverride = 0;

    if (previewTop < headerHeight) {
      // If preview is hidden by top header, we offset it to be anchored just after the header
      topOverride = headerHeight - previewTop + topPx + 5;
    } else if (previewBottom > heightLimit) {
      // If preview gets hidden by the bottom, anchor to either result bottom or page bottom
      const activeOffsetTop = getElTopOffset(active);
      const activeOuterHeight = (active as HTMLElement).offsetHeight;
      const activeBottom = activeOffsetTop + activeOuterHeight - scrollTop;

      topOverride =
        activeBottom > heightLimit
          ? // If part of the result exceeds screen height, anchor preview to screen bottom

            heightLimit + scrollTop - previewOffset - previewHeight - 10
          : // If result is still on screen, anchor to bottom of result
            topPx - (previewHeight - activeOuterHeight);
    }

    previewRef.current.style.top =
      topOverride > 0 ? `${topOverride}px` : `${topPx + topOverride}px`;
  }, [offsetY]);

  useEffect(() => {
    onWindowScroll();
    window.addEventListener('scroll', onWindowScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, [onWindowScroll]);

  useResize(previewRef, onWindowScroll);

  const rawContent =
    result.raw_content && result.raw_content.length > 0
      ? sanitize(result.raw_content)
      : null;

  const richText = rawContent && rawContent.length > 0 && (
    <div
      className="richText"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: rawContent,
      }}
    />
  );

  if (iframe?.content.length) {
    previewContent =
      iframe.type === 'base64' ? (
        showImage && (
          <LazyContentAwareImage
            className="thumbnailImage"
            onData={setShowImage}
            src={iframe.content}
          />
        )
      ) : (
        <PreviewIframe
          content={iframe.content}
          contentCSS={iframe.contentCSS}
          type={iframe.type}
        />
      );
  }

  if (imgSrc && showImage) {
    previewContent = (
      <LazyContentAwareImage
        className="thumbnailImage"
        onData={setShowImage}
        src={imgSrc}
      />
    );
  }

  const footerUI = metaPairs.length > 0 && (
    <div className="footer">
      <MetaPairs pairs={metaPairs} />
      {result.backLinks.length > 0 && (
        <Backlinks backlinks={result.backLinks} />
      )}
    </div>
  );

  const content = isWorking ? (
    <div className="workingContainer">
      <UIIcon name="spinner-animated" size={24} />
    </div>
  ) : (
    <>
      {richText}
      {previewContent}
      {footerUI}
    </>
  );

  const analyticsProps = useCallback(() => {
    return {
      object_id: result.objectID,
      position: elementIndex(
        document.querySelector('.resultsList .previewActive'),
        '.resultsListContainer .resultItem'
      ),
      query: searchState.q,
      ...searchState,
      pinned: result.pins && result.pins.length > 0,
      queryId,
      version,
    };
  }, [queryId, result, searchState, version]);

  const applyTrackMouseEnter = useTrackMouseEnter(
    useCallback(() => {
      trackEvent(AnalyticsEvent.PreviewMouseEnter, analyticsProps());
      feedback.show();
    }, [analyticsProps, feedback])
  );

  const trackPreviewEvent = useCallback(
    (analyticsEvent: AnalyticsEvent, data: string, source: string) => {
      trackEvent(analyticsEvent, {
        ...analyticsProps(),
        data,
        source,
      });
    },
    [analyticsProps]
  );

  const handleCopy = useCallback(() => {
    const selection = document.getSelection();
    if (!selection) {
      return;
    }

    trackPreviewEvent(
      AnalyticsEvent.CopyClipboard,
      selection.toString(),
      'non-iframe'
    );
  }, [trackPreviewEvent]);

  const handleClick = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      const origin = (evt.target as Element).closest('a');
      if (origin) {
        trackPreviewEvent(
          AnalyticsEvent.PreviewLinkClick,
          origin.href,
          'non-iframe'
        );

        feedback.show();
      }
    },
    [feedback, trackPreviewEvent]
  );

  const handleLeave = useCallback(
    ({ relatedTarget }: React.MouseEvent<HTMLDivElement>) => {
      if (
        relatedTarget instanceof Element &&
        relatedTarget.classList.contains('previewActive')
      ) {
        // If cursor leaves preview to hover over result item, we do not hide the preview
        return;
      }

      dispatch(setPreview());
    },
    [dispatch]
  );

  const isTitleEmpty = title.trim().length === 0;

  const onIconClick = useCallback(() => {
    window.open(contentUrl);
  }, [contentUrl]);

  return (
    <div
      className="previewItem"
      onClick={handleClick}
      onCopy={handleCopy}
      onMouseLeave={handleLeave}
      ref={
        composeRefs(
          previewRef,
          applyTrackMouseEnter
        ) as RefObject<HTMLDivElement>
      }
    >
      <header>
        {titleIcon && <>{titleIcon} &nbsp;</>}
        {isTitleEmpty ? <i>Untitled</i> : <span>{title}</span>}
        <div className="iconContainer">
          {typeof icon === 'string' ? (
            contentUrl ? (
              <UIIconButton name={icon} onClick={onIconClick} type="apps" />
            ) : (
              <UIIcon name={icon} type="apps" />
            )
          ) : (
            icon
          )}
        </div>
      </header>
      {content}
    </div>
  );
};
