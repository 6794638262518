import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getPersonSearchParams, useAllSearchRelatedParams } from './query';

export const enum ArrowNavMode {
  Vertical,
  Horizontal,
  // Reserved
  Grid,
}

export const useArrowNav = <T extends HTMLElement>(
  selector: string,
  onEndAction?: () => void,
  mode = ArrowNavMode.Vertical
): React.RefObject<T> => {
  const elRef = useRef<T>(null);
  useEffect(() => {
    const isVertical = mode === ArrowNavMode.Vertical;
    const prevKey = isVertical ? 'ArrowUp' : 'ArrowLeft';
    const nextKey = isVertical ? 'ArrowDown' : 'ArrowRight';
    const listener = (event: KeyboardEvent): void => {
      if (event.key !== prevKey && event.key !== nextKey) {
        return;
      }

      if (!elRef.current) {
        return;
      }

      const currentFocused = document.activeElement;
      if (
        currentFocused &&
        currentFocused !== document.body &&
        !currentFocused.closest(selector)
      ) {
        return;
      }

      event.preventDefault();
      const elements = Array.from(
        elRef.current.querySelectorAll<HTMLElement>(selector)
      );

      const [firstEl] = elements;

      if (!firstEl) {
        return;
      }

      const currentIdx = elements.indexOf(currentFocused as T);
      let nextIndex = currentIdx;
      if (event.key === nextKey) {
        nextIndex++;
        if (nextIndex === elements.length && onEndAction) {
          onEndAction();
          return;
        }
      } else if (event.key === prevKey) {
        nextIndex--;
      }

      if (currentIdx === -1) {
        firstEl.focus();
      }

      nextIndex = (nextIndex + elements.length) % elements.length;
      elements[nextIndex]?.focus();
    };

    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [selector, onEndAction, mode]);

  return elRef;
};

function isKeyboardEvent(ev: React.SyntheticEvent): ev is React.KeyboardEvent {
  return 'key' in ev;
}

/**
 * Returns a function that will handle both click and keyboard events to react the same way as a regular `<button>` element.
 */
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export function useButtonEmulation<T = void>(
  callback: (event: React.KeyboardEvent | React.MouseEvent, val: T) => void,
  ...deps: unknown[]
): (ev: React.KeyboardEvent | React.MouseEvent, val: T) => void {
  return useCallback(
    (ev: React.KeyboardEvent | React.MouseEvent, val: T) => {
      if (!isKeyboardEvent(ev) || ev.key === ' ' || ev.key === 'Enter') {
        callback(ev, val);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ...deps]
  );
}

const focusPrioritySelector = 'input, textarea, [contenteditable]';

/**
 * Hooks into any global key press, ignored when current focused element is input or textarea.
 */
export const useGlobalKeyPress = (key: string, callback: () => void): void => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key !== key) {
        return;
      }

      if (document.activeElement?.matches(focusPrioritySelector)) {
        return;
      }

      event.preventDefault();
      callback();
    };

    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keypress', listener);
    };
  }, [key, callback]);
};

export const useHistoryPush = (path: string): (() => void) => {
  const history = useHistory();
  return useCallback(() => {
    history.push(path);
  }, [history, path]);
};

export const useNavigateToPerson = (): ((email: string) => void) => {
  const history = useHistory();
  const [, setQuery] = useAllSearchRelatedParams();

  return (email: string) => {
    history.push('/');

    setQuery(getPersonSearchParams(email), 'replace');
  };
};
