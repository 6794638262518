import { EXCLUDED_DEEP_LINK_APPS } from '../components/pageSearch/results/misc/constants';
import { User } from '../models/User';
import {
  AnalyticsEvent,
  EventSubSurface,
  EventSurface,
} from '../scripts/constants/analytics-event';
import { openDeeplink } from '../scripts/deep-linker';
import { getStorageItem, StorageKey } from '../scripts/storage';
import {
  getCookie,
  inExtension,
  inSidePanel,
  isWebDev,
  isWebStaging,
  logDebug,
  logError,
} from '../scripts/utils';

export const attemptOpenDeeplink = (
  source: string,
  webviewLink: string,
  deepLink?: string
): void => {
  if (deepLink && !EXCLUDED_DEEP_LINK_APPS.includes(source)) {
    /*
     * If deep link exists, then open app
     * otherwise, fall back to webview link
     */
    openDeeplink(deepLink, webviewLink);
  } else {
    window.open(webviewLink);
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const trackEvent = (
  event: AnalyticsEvent,
  params: object = {},
  sensitiveParams: object = {},
  overrideSurface?: EventSurface,
  overrideSubSurface?: EventSubSurface
): void => {
  try {
    const userData = getStorageItem(
      StorageKey.GraphQLData,
      true,
      true
    ) as User | null;

    const org = userData?.orgByOrgId.domain;
    const orgId = userData?.orgByOrgId.id;
    const chatRetentionDays = userData?.orgByOrgId.chatRetentionDays;
    const showChatData = chatRetentionDays === null;

    const userEmail = userData?.email;

    let surface = EventSurface.WEB;
    let subSurface = EventSubSurface.WEB;

    if (inExtension()) {
      surface = EventSurface.EXTENSION;
      subSurface = inSidePanel()
        ? EventSubSurface.SIDE_PANEL
        : EventSubSurface.NEW_TAB;
    }

    if (overrideSurface) {
      surface = overrideSurface;
    }

    if (overrideSubSurface) {
      subSurface = overrideSubSurface;
    }

    let environment = 'production';
    if (isWebDev()) {
      environment = 'development';
      logDebug(
        'Sending analytics event:',
        event,
        params,
        sensitiveParams,
        surface,
        subSurface
      );
    } else if (isWebStaging()) {
      environment = 'staging';
    }

    // List of cookie names to be sent with the analytics event
    const cookieNames = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content',
      // eslint-disable-next-line @cspell/spellchecker
      'gclid',
    ];

    const cookies = {} as Record<string, string>;
    // Retrieve cookies and add them to the params object
    for (const cookieName of cookieNames) {
      const cookieValue = getCookie(cookieName);
      if (cookieValue) {
        cookies[cookieName] = cookieValue;
      }
    }

    analytics.track(event, {
      ...params,
      ...(showChatData ? sensitiveParams : {}),
      ...(org ? { org } : {}),
      ...(orgId ? { orgId } : {}),
      ...(userEmail ? { userEmail } : {}),
      inExtension: inExtension(),
      inSidePanel: inSidePanel(),
      surface,
      sub_surface: subSurface,
      platform: 'web',
      environment,
      path: window.location.pathname,
      ...cookies,
    });
  } catch (error) {
    logError(error);
  }
};
