import React, { FC, useCallback } from 'react';
import { AccessDiscoveryResult } from '../../../../scripts/models/page-search-result';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { AccessRequestDialogProps } from '../../AccessRequest/AccessRequestDialog';
import { Footer } from '../PageSearchResultItem/Footer/Footer';
import { BreadcrumbsUI } from '../PageSearchResultItem/PageSearchResultItem';
import { Title } from '../PageSearchResultItem/TItle/TItle';
import { ContentSnippet } from '../misc/ContentSnippet';
import './AccessDiscovery.scss';

interface AccessDiscoveryProps {
  hiddenResult: AccessDiscoveryResult;
  accessRequestDialogProps: AccessRequestDialogProps;
}

interface InnerResultContentProps {
  result: AccessDiscoveryResult;
}

const mockData = {
  name: 'lorem ipsum dolor sit amex',
  content_snippet:
    'Description of the change <zwnj> Type of change <zwnj> Related issues <zwnj> Checklists <zwnj> Development <zwnj> Code review lorem ipsum dolor sit amen',
  file_path: ['src', 'webApp', 'src', 'scripts'],
  modified_time: 1_000_000_000,
};

const InnerResultContent: FC<InnerResultContentProps> = ({ result }) => {
  return (
    <div className="activeContainer">
      <div className="resultLeft">
        <div className="appIcon">
          <UIIcon name={result.source} type="apps" />
        </div>
      </div>
      <div className="resultRight">
        <div className="titleContainer">
          <Title content={mockData.name} />
        </div>
        <div className="meta">
          <div className="appName">{result.app_name}</div>
          <BreadcrumbsUI
            file_path={mockData.file_path}
            showLastItemBreadcrumb
          />
        </div>
        <ContentSnippet content={mockData.content_snippet} />

        <Footer
          backLinkCount={0}
          commentCount={0}
          modifiedLabel="Last modified"
          modifiedTime={mockData.modified_time}
          viewCount={0}
        />
      </div>
    </div>
  );
};

export const AccessDiscovery: React.FC<AccessDiscoveryProps> = ({
  hiddenResult,
  accessRequestDialogProps,
}) => {
  const searchItem = (
    <div className="pageSearchResultItem resultItem">
      <InnerResultContent result={hiddenResult} />
    </div>
  );

  const onClick = useCallback(() => {
    accessRequestDialogProps.setAccessDiscoveryResult(hiddenResult);
    accessRequestDialogProps.setAccessRequestDialogOpen();
  }, [accessRequestDialogProps, hiddenResult]);

  return (
    <div className="accessDiscovery" onClick={onClick}>
      {searchItem}
      <UIIcon className="lock" name="lock" size={28} tooltip="Dash Access" />
    </div>
  );
};
