import React, { FC } from 'react';
import { UploadedFile } from '../../../models/File';
import { FileInfoPopover } from '../../../pages/files/FileInfoPopover';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';

export const FileComponent: FC<{
  source: UploadedFile;
}> = ({ source: file }) => {
  return (
    <>
      <UIIcon name="doc" size={20} />
      <span>
        {file.title.length > 18 ? `${file.title.slice(0, 18)}...` : file.title}
      </span>
      <FileInfoPopover file={file} />
    </>
  );
};
