import './UISelect.scss';
import { Popover } from '@mui/material';
import classNames from 'classnames';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useButtonEmulation } from '../../../../scripts/hooks';
import { UIIcon } from '../UIIcon/UIIcon';
import { UITextbox } from '../UITextbox/UITextbox';

export interface UISelectItem {
  label: string;

  // Machine-readable value (also used for item key)
  value: string;

  icon?: ReactNode;

  // Used to determine whether in selected state
  isDefault?: boolean;
}

interface ItemProps {
  item: UISelectItem;
  selected: boolean;
  onSelect: (item: UISelectItem) => void;
}

const SelectItem: React.FC<ItemProps> = ({ item, selected, onSelect }) => {
  const handleSelect = useButtonEmulation(() => {
    onSelect(item);
  }, [item, onSelect]);

  return (
    <li
      className={classNames({
        selected,
      })}
      key={item.value}
      onClick={handleSelect}
      onKeyDown={handleSelect}
      tabIndex={selected ? -1 : 0}
    >
      <div>
        {item.icon}
        <span>{item.label}</span>
      </div>
      {selected && <UIIcon className="selectedIcon" name="check" size={14} />}
    </li>
  );
};

interface SearchResultItemProps {
  item: UISelectItem;
  onClick(item: UISelectItem): void;
}

const SearchResultItem: React.FC<SearchResultItemProps> = ({
  item,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return (
    <li key={item.value} onClick={handleClick} tabIndex={0}>
      <div>
        {item.icon}
        <span>{item.label}</span>
      </div>
    </li>
  );
};

interface UISelectProps {
  items: UISelectItem[];
  selectedValue?: string;

  // Let consumer know when user clicks on item
  onSelect: (item: UISelectItem) => void;

  // For the selected label container
  selectorClassName?: string;

  // For the dropdown
  dropdownClassName?: string;

  // Optional width of dropdown
  dropdownWidth?: number;

  // Prefix to select label
  selectLabelPrefix?: ReactNode;

  // Do not show with selection state
  disableSelectionState?: boolean;
  // Incorporate a search box to filter down items list
  searchMode?: {
    onQueryChange: (value: string) => void;

    // Let consumer know when user clicks on a result
    onResultSelect: (item: UISelectItem) => void;

    results?: UISelectItem[];

    // Placeholder text for textbox
    placeholder?: string;

    // Show in results area if no search query
    emptyQueryMessage?: string;

    // Show in results area if no results
    noResultsMessage?: string;
  };
  onOpen?(): void;
}

export const UISelect: React.FC<UISelectProps> = ({
  onOpen,
  searchMode,
  items,
  selectLabelPrefix,
  selectorClassName,
  dropdownClassName,
  disableSelectionState,
  selectedValue,
  onSelect,
  dropdownWidth,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const searchBoxRef = useRef<HTMLInputElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const isOpen = !!anchorEl;

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    onOpen?.();
    // Doesn't detect optional call correctly
    // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/unbound-method
  }, [isOpen, onOpen]);

  useEffect(() => {
    if (!isOpen || !searchMode) {
      return;
    }

    /*
     * When in search mode, we focus on the textbox on open.
     * Wait for animation and UI to update.
     */
    setTimeout(() => {
      searchBoxRef.current?.focus();
    }, 100);
  }, [isOpen, searchMode]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const selected = items.find((item) => item.value === selectedValue);

  const arrowName = isOpen ? 'arrow-up' : 'arrow-down';
  const arrow = <UIIcon className="toggleIcon" name={arrowName} />;

  const uiSelectClasses: Record<string, boolean> = {
    uiSelect: true,
  };

  if (selectorClassName) {
    uiSelectClasses[selectorClassName] = true;
  }

  const dropdownClasses: Record<string, boolean> = {
    uiSelectDropdown: true,
  };

  if (dropdownClassName) {
    dropdownClasses[dropdownClassName] = true;
  }

  const onSearchQueryChange = useCallback(
    (value: string) => {
      setSearchQuery(value);

      if (searchMode) {
        searchMode.onQueryChange(value);
      }
    },
    [searchMode]
  );

  const onItemClick = useCallback(
    (item: UISelectItem) => {
      onSelect(item);

      // Also reset search query if using search
      if (searchMode) {
        onSearchQueryChange('');
      }

      handleClose();
    },
    [handleClose, onSearchQueryChange, onSelect, searchMode]
  );

  const onResultClick = useCallback(
    (item: UISelectItem) => {
      searchMode!.onResultSelect(item);

      // Reset search query
      onSearchQueryChange('');

      handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClose, onSearchQueryChange, searchMode?.onResultSelect]
  );

  const itemNodes: ReactNode[] = items.map((item: UISelectItem) => {
    return (
      <SelectItem
        item={item}
        key={item.value}
        onSelect={onItemClick}
        selected={item.value === selected?.value}
      />
    );
  });

  let searchBox: ReactNode;
  let resultItems: ReactNode;
  let searchEmpty: ReactNode;
  let resultsEmpty: ReactNode;

  if (searchMode) {
    const {
      results = [],
      placeholder,
      emptyQueryMessage,
      noResultsMessage,
    } = searchMode;

    searchBox = (
      <div className="searchContainer">
        <UITextbox
          icon={<UIIcon name="search" />}
          onChange={onSearchQueryChange}
          placeholder={placeholder ?? 'Search'}
          ref={searchBoxRef}
          useClear
          value={searchQuery}
        />
      </div>
    );

    if (searchQuery.trim().length === 0) {
      searchEmpty = (
        <div className="searchEmpty">
          {emptyQueryMessage ?? 'Enter a search query to view results'}
        </div>
      );
    } else {
      if (results.length === 0) {
        resultsEmpty = (
          <div className="searchEmpty">
            {noResultsMessage ?? 'No results found'}
          </div>
        );
      }

      resultItems = (
        <ul className="searchResults">
          {results.map((item: UISelectItem) => {
            return (
              <SearchResultItem
                item={item}
                key={item.value}
                onClick={onResultClick}
              />
            );
          })}
        </ul>
      );
    }
  }

  useEffect(() => {
    if (isOpen) {
      dropdownContentRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <div className={classNames(uiSelectClasses)}>
      <button
        className={classNames({
          selectLabel: true,
          open: isOpen,
          withSelection:
            !disableSelectionState && selected ? !selected.isDefault : false,
        })}
        onClick={handleClick}
        type="button"
      >
        {selectLabelPrefix}
        {selected?.icon}
        {selected?.label}
        {arrow}
      </button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className={classNames(dropdownClasses)}
        keepMounted
        onClose={handleClose}
        open={isOpen}
        tabIndex={-1}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div
          className="dropdownContent"
          ref={dropdownContentRef}
          style={{ width: dropdownWidth ? `${dropdownWidth}px` : '200px' }}
        >
          {searchBox}
          <ul>{itemNodes}</ul>
          {searchMode && (
            <div className="uiSelectResultsContainer">
              {resultItems}
              {searchEmpty}
              {resultsEmpty}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};
