import React from 'react';
import { UIButton } from '../../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { bookCallUrl } from '../../../links/links';
import { OnboardingStepContainer } from '../../OnboardingStepContainer/OnboardingStepContainer';

export const BookCallStep: React.FC = () => {
  return (
    <OnboardingStepContainer title="Sorry, that account does not exist yet">
      <div className="title">Interested in a demo?</div>
      <p>Schedule a call with us to learn more.</p>
      <div className="controls">
        <UIButton href={bookCallUrl} size="large">
          <span>Find a time</span>
          <UIIcon name="arrow-right" />
        </UIButton>
      </div>
    </OnboardingStepContainer>
  );
};
