import { useEffect, useState } from 'react';
import { UploadedFile } from '../../models/File';
import { invokeFastApi } from '../apis/fastapi';
import { ToastType } from '../models/toast';
import { logError } from '../utils';
import { useLoginTokens } from './redux';
import { useToaster } from './toast';

export interface UploadedFileResponse {
  id: string;
  name: string;
}

interface FilesResponse {
  files: UploadedFile[];
  loading: boolean;
  uploadStatus: UploadStatus;
  getFiles: () => Promise<void>;
  uploadFile: (file: File, isZipFile: boolean, siteId: string) => Promise<void>;
  deleteFile: (id: string) => Promise<void>;
}

export const enum UploadStatus {
  PENDING = 0,
  TRANSIT = 1,
  DONE = 2,
}

export const enum FileVisibility {
  ORG = 'ORG',
  CHAT = 'CHAT',
}

export const useFiles = (): FilesResponse => {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(UploadStatus.PENDING);
  const idToken = useLoginTokens();

  const toaster = useToaster();

  const getFiles = async () => {
    setLoading(true);
    setFiles([]);
    try {
      const res = await invokeFastApi({
        path: '/document_uploads',
        method: 'GET',
      });

      const { documents } = (await res) as { documents: UploadedFile[] };
      setFiles(documents);
    } catch (fetchError) {
      logError(fetchError);
      toaster.failure('Failed to fetch files');
    }

    setLoading(false);
  };

  const uploadFile = async (file: File, isZipFile: boolean, siteId: string) => {
    setUploadStatus(UploadStatus.TRANSIT);

    const formData = new FormData();
    formData.append('file', file);
    if (!isZipFile) formData.append('visibility', FileVisibility.ORG);

    const url = isZipFile
      ? `${SEARCH_URL}/document_uploads/zip`
      : `${SEARCH_URL}/document_uploads`;

    try {
      toaster.create({
        type: ToastType.LOADING,
        message: 'Uploading File. Please keep tab open',
        uid: 'uploading-file',
      });

      const response = await fetch(url, {
        headers: {
          ...(idToken
            ? { authorization: `Bearer ${idToken}`, 'site-id': siteId }
            : { 'site-id': siteId }),
        },
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const errorData = (await response
          .json()
          .catch(() => ({ error: undefined }))) as { error?: string };

        if (
          response.status === 400 &&
          errorData.error === 'No content was parsed from the file.'
        ) {
          throw new Error(
            'The document appears to be empty or its content could not be parsed. Please check the file and try again.'
          );
        }

        throw new Error('Failed to upload file');
      }

      toaster.delete('uploading-file');
      toaster.success('File uploaded successfully');
      getFiles();

      setUploadStatus(UploadStatus.DONE);
    } catch (error) {
      logError(error);
      toaster.delete('uploading-file');
      toaster.failure(
        error instanceof Error ? error.message : 'Failed to upload file'
      );

      setUploadStatus(UploadStatus.PENDING);
    }
  };

  const deleteFile = async (id: string) => {
    try {
      await invokeFastApi({
        path: `/document_uploads/${id}`,
        method: 'DELETE',
      });

      setFiles((prevFiles) => {
        const fileIndex = prevFiles.findIndex((file) => file.id === id);
        if (fileIndex === -1) {
          // File not found, return the previous state
          return prevFiles;
        }

        // Create a new array without the deleted file
        const newFiles = [
          ...prevFiles.slice(0, fileIndex),
          ...prevFiles.slice(fileIndex + 1),
        ];

        return newFiles;
      });

      toaster.success('File deleted successfully');
    } catch (error) {
      logError(error);
      toaster.failure('Failed to delete file');
    }
  };

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    files,
    loading,
    uploadStatus,
    getFiles,
    uploadFile,
    deleteFile,
  };
};
