import React, { FC, ReactNode } from 'react';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { ConnectStep } from './Components';

interface EmailCaptureStepProps {
  step: number;
  value: string;
  additionalDescription?: ReactNode;
  onChange(value: string): void;
}

export const EmailCaptureStep: FC<EmailCaptureStepProps> = ({
  step,
  onChange,
  value,
  additionalDescription,
}) => {
  return (
    <ConnectStep step={step} title="Enter email">
      {additionalDescription ?? (
        <p>Enter the email associated with the API key.</p>
      )}
      <div className="stepField">
        <UITextbox
          className="stepFieldInput"
          onChange={onChange}
          placeholder="Enter email"
          size="large"
          type="email"
          value={value}
        />
      </div>
    </ConnectStep>
  );
};
