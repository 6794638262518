import React, { ReactNode } from 'react';
import { Timestamp } from '../../../../controls/Timestamp';
import { ResultProps } from '../../objectTypes/resultProps';
import { Title } from '../TItle/TItle';
import { Actions } from '../actions/Actions';

interface CollatedResultProps extends ResultProps {
  name?: string;
  breadCrumbsUI: ReactNode;
  appName: string;
  resultIcon: ReactNode;
  searchQuery: string;
  onCopy: () => void;
  applyTrackImpression: (el: HTMLElement | null) => void;
}

export const CollatedResult: React.FC<CollatedResultProps> = ({
  result,
  name,
  appName,
  searchQuery,
  resultIcon,
  breadCrumbsUI,
  onCopy,
  applyTrackImpression,
}) => {
  return (
    <div className="activeContainer">
      <div className="collatedIcon">{resultIcon}</div>
      <Title content={name ?? ''} ref={applyTrackImpression} />
      <div className="meta">
        <div className="appName">{appName}</div>
        {breadCrumbsUI}
        <Timestamp unixTime={result.modified_time} />
      </div>
      <Actions onCopy={onCopy} result={result} searchQuery={searchQuery} />
    </div>
  );
};
