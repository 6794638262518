import { OrgMember } from '../models/org-member';
import { invokeFastApi } from './fastapi';

interface MessageUser {
  id: string;
  name?: string;
  email: string;
  avatar?: string;
}

interface MessagePayload {
  message: string;
  conversation_id?: string;
  tagged_users: MessageUser[];
  sender: MessageUser;
  tagged_emails: string[];
}

const processOrgMemberToSendMessage = (user: OrgMember): MessageUser => {
  return {
    id: user.email,
    name: user.name,
    email: user.email,
    avatar: user.icon,
  };
};

export const sendMessage = async (
  message: string,
  sender: MessageUser,
  conversation_id: string,
  taggedUsers: OrgMember[] = [],
  taggedEmails: string[] = []
): Promise<string | null> => {
  const payload: MessagePayload = {
    message,
    conversation_id,
    sender,
    tagged_users: taggedUsers.map(processOrgMemberToSendMessage),
    tagged_emails: taggedEmails,
  };

  const { topic_title } = await invokeFastApi<{ topic_title: string | null }>({
    path: '/messages/create',
    method: 'POST',
    body: payload,
  });

  return topic_title;
};
