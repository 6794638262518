import React from 'react';
import { PageSearchResultItem } from '../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../resultProps';

export const ClickupListResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Clickup List"
      result={result}
      showLastItemBreadcrumb
    />
  );
};
