import './PersonCardDetails.scss';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  useButtonEmulation,
  useNavigateToPerson,
} from '../../../scripts/hooks';
import {
  LinkedPerson,
  PersonResult,
} from '../../../scripts/models/person-result';
import { UserImage } from '../../controls/UserImage/UserImage';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';

const DetailsBox: FC<{ title: string; value: ReactNode }> = ({
  title,
  value,
}) => {
  return (
    <div className="detailsBox">
      <div className="boxTitle">{title}</div>
      <div className="boxValue">{value}</div>
    </div>
  );
};

const ReportButton: FC<{ report: LinkedPerson }> = ({ report }) => {
  const navigateToPerson = useNavigateToPerson();

  const onClick = useCallback(() => {
    navigateToPerson(report.email!);
  }, [navigateToPerson, report.email]);

  return (
    <button className="reportPerson" onClick={onClick} type="button">
      <UserImage imageUrl={report.profileImage} />
      <div className="reportPersonDetails">
        <div className="reportName">{report.name}</div>
        {report.title && <div className="reportTitle">{report.title}</div>}
      </div>
    </button>
  );
};

const maxReportImages = 5;
const maxReports = 10;

export const PersonCardDetails: FC<{ person: PersonResult }> = ({ person }) => {
  const { department, manager, reports } = person;
  const navigateToPerson = useNavigateToPerson();

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onManagerClick = useButtonEmulation(() => {
    navigateToPerson(manager.email!);
  }, []);

  useEffect(() => {
    setExpanded(false);
  }, [person]);

  // `manager.email` is null or '' if teammate has no manager
  const hasManager = manager.email !== null && manager.email !== '';
  const hasReports = reports && reports.length > 0;

  if (!hasManager && !hasReports) {
    return null;
  }

  return (
    <div className="personCardDetails">
      <div className="detailsSummary">
        <div className="left">
          {department && <DetailsBox title="Department" value={department} />}
          {hasManager && (
            <DetailsBox
              title="Manager"
              value={
                <div className="managerBox">
                  <UserImage imageUrl={manager.profileImage} size={20} />
                  <span onClick={onManagerClick}>{manager.name}</span>
                </div>
              }
            />
          )}
          {hasReports && (
            <DetailsBox
              title="Reports"
              value={
                <div className="teamMembers">
                  <div className="reportFaces">
                    {reports.slice(0, maxReportImages).map((report) => (
                      <UserImage
                        imageUrl={report.profileImage}
                        key={report.email}
                        size={20}
                      />
                    ))}
                  </div>
                  {reports.length > maxReportImages && (
                    <span className="moreReports">
                      +{reports.length - maxReportImages}
                    </span>
                  )}
                </div>
              }
            />
          )}
        </div>
        {hasReports && (
          <UIButton onClick={toggleExpanded} size="small" type="ghost">
            <UIIcon name={expanded ? 'arrow-up' : 'arrow-down'} size={18} />
          </UIButton>
        )}
      </div>
      {expanded && hasReports && (
        <div className="reportsExpanded">
          {reports.slice(0, maxReports).map((report) => (
            <ReportButton key={report.email} report={report} />
          ))}
        </div>
      )}
    </div>
  );
};
