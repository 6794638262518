import React, { FC, useCallback, useEffect, useState } from 'react';
import { workflowAPI } from '../../../scripts/apis';
import { useFlag } from '../../../scripts/hooks';
import { logError } from '../../../scripts/utils';
import { Modal } from '../../controls/ui/Modal/Modal';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';

interface EnterprisePaywallProps {
  cardClasses?: string;
}

export const EnterprisePaywall: FC<EnterprisePaywallProps> = ({
  cardClasses,
}: EnterprisePaywallProps) => {
  const scheduleDemoLink = useFlag('scheduleDemoLink');

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-blur-sm bg-[#B3B3B366] bg-opacity-90 flex justify-center items-start">
      <div
        className={`bg-purple-50 p-6 rounded-lg mx-10 max-w-3xl w-full z-50 ${
          cardClasses ?? ''
        }`}
      >
        <div className="flex flex-col items-center sm:px-20">
          <div className="flex p-2 bg-sunrise-10 rounded-full">
            <UIIcon className="text-white" name="star" type="ui" />
          </div>
          <div className="text-lg font-semibold mt-3">
            Enterprise Exclusive Feature
          </div>
          <div className="whitespace-normal	text-center">
            Upgrade to the Enterprise Plan to unlock the full potential of Dash
            AI. This gives you access to features like SSO, Analytics, Insights,
            API, and HRIS
          </div>
          <div className="mt-6 flex gap-4">
            <UIButton href="https://www.dashworks.ai/pricing" type="secondary">
              View plans
            </UIButton>
            <UIButton href={scheduleDemoLink} type="primary">
              Contact sales
            </UIButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EnterpriseIcon = ({
  size = 12,
}: {
  size?: number;
}): JSX.Element => (
  <div className="flex p-1 bg-sunrise-10 rounded-full mr-2">
    <UIIcon className="text-white" name="star" size={size} type="ui" />
  </div>
);

const BulletPoint = ({ description }: { description: string }) => {
  return (
    <div className="flex gap-2 items-center justify-between">
      <div>{description}</div>
      <UIIcon name="check" />
    </div>
  );
};

export const PaywallModal: React.FC<{
  closeModal: () => void;
  modalClasses?: string;
  allowUpgrade?: boolean;
}> = ({ closeModal, modalClasses, allowUpgrade = false }) => {
  const scheduleDemoLink = useFlag('scheduleDemoLink');
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState<string>();

  const handleUpgradeClick = useCallback(() => {
    setIsLoading(true);
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    }

    setIsLoading(false);
  }, [checkoutUrl]);

  useEffect(() => {
    if (allowUpgrade) {
      workflowAPI
        .getStripeBusinessPlanCheckoutLink(window.location.origin)
        .then((response) => {
          setCheckoutUrl(response.url);
        })
        .catch(logError);
    }
  }, [allowUpgrade]);

  return (
    <Modal
      hasMaxZIndex
      modalClasses={`max-w-sm p-8 ${modalClasses ?? ''}`}
      onClose={closeModal}
    >
      <div className="flex flex-col gap-4">
        <div className="w-8 flex p-2 bg-sunrise-10 rounded-full">
          <UIIcon className="text-white" name="star" type="ui" />
        </div>

        <div className="font-semibold text-2xl">
          {allowUpgrade ? 'Upgrade to Business' : 'Upgrade to Enterprise'}
        </div>
        {allowUpgrade ? (
          <>
            <BulletPoint description="Custom bots" />
            <BulletPoint description="Choose between top LLMs" />
            <BulletPoint description="Org-wide integrations" />
            <BulletPoint description="AI customization" />
            <BulletPoint description="Multiple connections per app" />
            <BulletPoint description="Priority support" />
          </>
        ) : (
          <>
            <BulletPoint description="Multiple connections per app" />
            <BulletPoint description="Org-wide Dash AI training" />
            <BulletPoint description="HRIS integration" />
            <BulletPoint description="Custom LLM selection" />
            <BulletPoint description="Custom Dash AI disclaimer" />
            <BulletPoint description="Detailed admin analytics and insights" />
            <BulletPoint description="SSO and SCIM" />
            <BulletPoint description="Prioritized new integration requests" />
          </>
        )}
        <div className="flex justify-between">
          <UIButton href="https://dashworks.ai/pricing" type="secondary">
            Compare Plans
          </UIButton>
          {allowUpgrade ? (
            <UIButton
              onClick={handleUpgradeClick}
              processing={isLoading || !checkoutUrl}
              type="primary"
            >
              Upgrade
            </UIButton>
          ) : (
            <UIButton href={scheduleDemoLink} type="primary">
              Contact Sales
            </UIButton>
          )}
        </div>
      </div>
    </Modal>
  );
};
