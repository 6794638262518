import React, { useCallback } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';
import { useLowerCase } from '../../scripts/hooks';
import { SubdomainCaptureStep } from '../generic/SubdomainCaptureStep';

export const AppInstallModal = withInstallModal(
  ({ handleOAuthInstall, connectInProgress, isOrg }) => {
    const [subdomain, setSubdomain] = useLowerCase('');

    const handleClickDone = useCallback(async () => {
      await handleOAuthInstall({ subdomain });
    }, [handleOAuthInstall, subdomain]);

    const connectDisabled = subdomain.trim().length === 0;

    return (
      <>
        <SubdomainCaptureStep
          additionalDescription={
            <strong>
              {isOrg && 'You must be an Administrator in Zendesk.'}
              Dashworks will request read-only access to tickets. Make sure your
              account is added to all the organization&apos;s groups.
            </strong>
          }
          hostname="zendesk.com"
          loginURL="https://www.zendesk.com/login/#loginReminder"
          name="Zendesk"
          onChange={setSubdomain}
          step={1}
          subdomain={subdomain}
        />

        {connectInProgress(handleClickDone, connectDisabled)}
      </>
    );
  }
);
