import React, { useCallback } from 'react';
import { FileType } from '../../../../../scripts/constants/filters';
import { useFileTypeParam } from '../../../../../scripts/hooks';
import { FileTypeIcon } from '../../../../controls/FileTypeIcon/FileTypeIcon';
import { UIIcon } from '../../../../controls/ui/UIIcon/UIIcon';
import {
  UISelect,
  UISelectItem,
} from '../../../../controls/ui/UISelect/UISelect';

const ALL_FILTER_TYPES: UISelectItem[] = [
  {
    label: 'Any Type',
    value: FileType.Any,
    icon: <UIIcon name="files" />,
    isDefault: true,
  },
  {
    label: 'Documents',
    value: FileType.Document,
    icon: <FileTypeIcon type="doc" />,
  },
  {
    label: 'Presentations',
    value: FileType.Presentation,
    icon: <FileTypeIcon type="slide" />,
  },
  {
    label: 'Spreadsheets',
    value: FileType.Spreadsheet,
    icon: <FileTypeIcon type="sheet" />,
  },
  {
    label: 'PDFs',
    value: FileType.PDF,
    icon: <FileTypeIcon type="pdf" />,
  },
  {
    label: 'Images',
    value: FileType.Image,
    icon: <UIIcon name="image" />,
  },
  {
    label: 'Folders',
    value: FileType.Folder,
    icon: <UIIcon name="folder" />,
  },
  {
    label: 'Others',
    value: FileType.Other,
    icon: <UIIcon name="file-other" />,
  },
];

export const FileTypeFilter: React.FC = () => {
  const [filterType, setFilterType] = useFileTypeParam();

  const handleOnSelect = useCallback(
    ({ value }: UISelectItem) => {
      setFilterType(value);
    },
    [setFilterType]
  );

  return (
    <UISelect
      dropdownClassName="fileFilterTypeUISelectDropdown"
      items={ALL_FILTER_TYPES}
      onSelect={handleOnSelect}
      selectedValue={filterType}
      selectorClassName="fileFilterTypeUISelect"
    />
  );
};
