import React, { ChangeEvent, useEffect, useState } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { useToaster } from '../../scripts/hooks';
import { UploadStatus } from '../../scripts/hooks/files';

interface FileUploadProps {
  uploadStatus: UploadStatus;
  uploadFile: (
    file: File,
    isZipFile: boolean,
    siteId: string,
    shouldRefetchFile?: boolean
  ) => void;
  isZipFile?: boolean;
  siteId?: string;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  uploadStatus,
  uploadFile,
  isZipFile = false,
  siteId = '',
}) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const toaster = useToaster();
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    if (file) {
      if (file.size > 1_000_000_000) {
        toaster.failure('File size is too large. Max size is 1GB');
        setFile(undefined);
        return;
      }

      uploadFile(file, isZipFile, siteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <UIButton
      className="relative"
      disabled={uploadStatus === UploadStatus.DONE && isZipFile}
      processing={uploadStatus === UploadStatus.TRANSIT}
    >
      Upload {isZipFile ? 'Zip File' : 'File'}
      <input
        // eslint-disable-next-line max-len, @cspell/spellchecker
        accept="application/msword,application/pdf,application/rtf,application/vnd.google-apps.document,application/vnd.google-apps.presentation,application/vnd.google-apps.spreadsheet,application/vnd.ms-excel,application/vnd.ms-excel.sheet.macroEnabled.12,application/vnd.ms-excel.template.macroEnabled.12,application/vnd.ms-powerpoint,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.spreadsheetml.template,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/x-vnd.oasis.opendocument.spreadsheet,application/vnd.apple.numbers,application/zip,image/gif,image/jpeg,image/png,image/svg+xml,text/csv,text/html,text/plain,text/tab-separated-values"
        className="absolute opacity-0 cursor-pointer"
        onChange={handleFileChange}
        type="file"
      />
    </UIButton>
  );
};
