import React from 'react';
import { UIIcon } from '../../../../../../controls/ui/UIIcon/UIIcon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

export const GDriveDrawingResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Google Drive Drawing"
      icon={<UIIcon className="resultFile" name="file" size={20} />}
      result={result}
    />
  );
};
