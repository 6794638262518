import classNames from 'classnames';
import React, { FC, RefObject } from 'react';
import { useUserSafe } from '../../../../scripts/hooks';
import { PageSearchResult } from '../../../../scripts/models/page-search-result';
import {
  HighlightedText,
  highlightToHTML,
} from '../../../controls/HighlightedText/HighlightedText';
import { Timestamp } from '../../../controls/Timestamp';
import { CopyButton } from '../../../controls/ui/CopyButton/CopyButton';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../../controls/ui/UIToolTip/UIToolTip';
import { ContentSnippet } from '../../results/misc/ContentSnippet';
import {
  PositionSelector,
  useInstrumentedResultActions,
  usePreviewControl,
} from '../../results/misc/hooks';
import './MessageResult.scss';

export const MessageResult: FC<{
  result: PageSearchResult;
  showIcon?: boolean;
}> = ({ result, showIcon }) => {
  const email = useUserSafe((u) => u.email);
  // TODO: Should be identifier by backend!
  let isReceived = false;
  if (result.labels) {
    if (result.labels.some((l) => l.name === 'INBOX')) {
      isReceived = true;
    }
  } else {
    isReceived = result.from !== email;
  }

  const participant = (isReceived ? result.from : result.to) ?? 'n/a';
  const cleanedEmail = participant.replace(/<.*?>/g, '');

  const { handleNav, elementRefFn, onCopy, visibleImpression } =
    useInstrumentedResultActions(result, PositionSelector.List, false);

  const { handleEnter, handleFocus, handleLeave, isActive } =
    usePreviewControl(result);

  return (
    <a
      className={classNames('messageResult resultItem', {
        previewActive: isActive,
      })}
      href={result.webview_link}
      onBlur={handleLeave}
      onClick={handleNav}
      onFocus={handleFocus}
      onKeyDown={handleNav}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      // Hack: TS doesn't quite like the way refs are done base classes
      ref={elementRefFn as RefObject<HTMLAnchorElement>}
    >
      {showIcon && <UIIcon name={result.source} size={20} type="apps" />}
      <div className="content">
        <div className="header">
          <UITooltip
            title={result.name ? <HighlightedText text={result.name} /> : ''}
          >
            <span className="title" ref={visibleImpression}>
              <HighlightedText text={result.name ?? ''} />
            </span>
          </UITooltip>
          <span className="direction">{isReceived ? 'From' : 'To'}</span>
          <UITooltip title={participant}>
            <span className="participant">{cleanedEmail}</span>
          </UITooltip>
          <Timestamp unixTime={result.created_time} />
        </div>
        <div className="footer">
          <ContentSnippet content={highlightToHTML(result.content_snippet)} />
          <CopyButton
            hoverEffect={false}
            onCopy={onCopy}
            size={24}
            square
            value={result.webview_link}
          />
        </div>
      </div>
    </a>
  );
};
