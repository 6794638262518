import { Action as ReduxAction } from 'redux';
import { AllowedDomain, OrgByOrg, User, UserApp } from '../models/User';
import { AllSearchRelatedParams } from '../scripts/hooks';
import { Tokens } from './state';

export const enum RootActions {
  SetToken = 'SET_TOKENS',
  SetSearchHistory = 'SET_SEARCH_HISTORY',
  SetUser = 'SET_USER',
  SetPartialUser = 'SET_PARTIAL_USER',
  SetPartialOrg = 'SET_PARTIAL_ORG',
  SetPartialApps = 'SET_PARTIAL_APPS',
  SetAllowedDomains = 'SET_ALLOWED_DOMAINS',
  SetUserIsAdminInternal = 'SET_USER_IS_ADMIN_INTERNAL',
  FeatureFlagsFullyLoaded = 'SET_FEATURE_FLAGS_FULLY_LOADED',
  SetRecommendedQueries = 'SET_RECOMMENDED_QUERIES',
}

export interface Action extends ReduxAction<string> {
  payload?: unknown;
}

export function setSearchHistory(payload: AllSearchRelatedParams[]): Action {
  return { type: RootActions.SetSearchHistory, payload };
}

export function setTokens(tokens?: Tokens): Action {
  return {
    type: RootActions.SetToken,
    payload: tokens,
  };
}

export function setUser(user: User): Action {
  return {
    type: RootActions.SetUser,
    payload: user,
  };
}

export function setPartialUser(user: Partial<User>): Action {
  return {
    type: RootActions.SetPartialUser,
    payload: user,
  };
}

export function setPartialOrg(org: Partial<OrgByOrg>): Action {
  return {
    type: RootActions.SetPartialOrg,
    payload: org,
  };
}

export function setAppsData(payload: {
  orgApps: UserApp[];
  userApps: UserApp[];
}): Action {
  return {
    type: RootActions.SetPartialApps,
    payload,
  };
}

export function setAllowedDomains(allowedDomains: AllowedDomain[]): Action {
  return {
    type: RootActions.SetAllowedDomains,
    payload: allowedDomains,
  };
}

export function setRecommendedQueries(queries: string[]): Action {
  return {
    type: RootActions.SetRecommendedQueries,
    payload: queries,
  };
}

/**
 * This doesn't actually make you an admin/user, but the frontend will think you are unlocking certain UI experiences.
 * The API will still behave as if it is a regular user.
 */
export function setIsAdminInternal(admin: boolean): Action {
  return {
    type: RootActions.SetUserIsAdminInternal,
    payload: admin,
  };
}

export function setFeatureFlagsFullyLoaded(loaded: boolean): Action {
  return {
    type: RootActions.FeatureFlagsFullyLoaded,
    payload: loaded,
  };
}
