import './UITextbox.scss';
import { TextareaAutosize } from '@mui/material';
import classNames from 'classnames';
import React, { KeyboardEvent, ReactNode, useCallback, useRef } from 'react';
import { composeRefs } from '../../../../scripts/compose-refs';
import { CopyButton } from '../CopyButton/CopyButton';
import { UIIcon } from '../UIIcon/UIIcon';
import { UIIconButton } from '../UIIconButton/UIIconButton';
import { UITextInputProps } from '../common/TextInput';

interface HomePageSearchInputProps extends UITextInputProps {
  size?: 'large' | 'normal';

  // Optional icon shown on left
  icon?: React.ReactNode;

  // Optional controls which appear between icon and input
  controls?: React.ReactNode | null;

  // Optional controls which appear in the end of input text box
  rightControls?: React.ReactNode | null;

  // Show clear button if input has value
  useClear?: boolean;

  // Show copy button to copy input value
  useCopy?: boolean;

  // Called on key down
  onKeyDown?: (evt: React.KeyboardEvent) => void;

  onFocus?: (evt: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLTextAreaElement>) => void;
  onClick?: (evt: React.MouseEvent<HTMLTextAreaElement>) => void;
  onEnter?: () => void;

  // Show hint UI if user has typed in some text
  hint?: ReactNode;

  // Show spinner while working
  isWorking?: boolean;

  fixedText?: string;

  inputClassName?: string;

  plain?: boolean;

  defaultWhiteInputField?: boolean;
}

export const HomePageSearchInput = React.forwardRef<
  HTMLTextAreaElement,
  HomePageSearchInputProps
>(
  (
    {
      autoComplete,
      autoFocus,
      className,
      controls,
      error,
      fixedText,
      hint,
      icon,
      inputClassName,
      inputId,
      isWorking,
      label,
      maxLength,
      onBlur,
      onChange,
      onClick,
      onEnter,
      onFocus,
      onKeyDown,
      placeholder,
      plain,
      readonly,
      rightControls,
      size = 'normal',
      subLabel,
      tabIndex,
      useClear,
      useCopy,
      value = '',
      defaultWhiteInputField,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const readonlyClass = readonly ? 'readonly' : '';

    const showClearButton = useClear && !isWorking && value;

    const iconClickHandler = useCallback(() => {
      inputRef.current?.focus();
    }, []);

    const handleChange = useCallback(
      (val: React.ChangeEvent<HTMLTextAreaElement> | string) => {
        if (typeof val !== 'string') {
          val = val.currentTarget.value;
        }

        if (onChange) {
          onChange(val);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onChange]
    );

    const clearHandler = useCallback(() => {
      handleChange('');
      inputRef.current?.focus();
    }, [handleChange]);

    const inputClickHandler = useCallback(
      (evt: React.MouseEvent<HTMLTextAreaElement>) => {
        // Only select when using copy
        if (useCopy) {
          inputRef.current?.select();
        }

        if (onClick) {
          onClick(evt);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [useCopy, onClick]
    );

    const inputClass = classNames(
      {
        withIcon: !!icon,
        showWhiteField: defaultWhiteInputField,
      },
      inputClassName,
      'resize-none',
      'border-0'
    );

    const classes = classNames(
      {
        withError: !!error,
        plain,
      },
      '!items-start',
      'uiTextInput',
      'py-2',
      'border border-solid border-gray-30',
      className,
      readonlyClass
    );

    const handleKeyDown = useCallback(
      (ev: KeyboardEvent<HTMLTextAreaElement>) => {
        if (ev.key === 'Enter' && !ev.shiftKey && onEnter) {
          onEnter();
          ev.preventDefault();
        }

        onKeyDown?.(ev);
      },
      [onEnter, onKeyDown]
    );

    return (
      <div className={`uiTextInputContainer uiTextboxContainer ${size}`}>
        {label && (
          <label>
            {label}
            {subLabel && <span className="subLabel">{subLabel}</span>}
          </label>
        )}
        <div className={classes}>
          {icon && (
            <div className="flex mt-3 ml-2" onClick={iconClickHandler}>
              {icon}
            </div>
          )}
          {controls && <div className="leftControls">{controls}</div>}
          <TextareaAutosize
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            className={inputClass}
            id={inputId}
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={handleChange}
            onClick={inputClickHandler}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            readOnly={readonly}
            ref={composeRefs(ref, inputRef)}
            tabIndex={tabIndex}
            value={value}
          />
          {fixedText && <div className="fixedText">{fixedText}</div>}
          <div className="flex mt-3 mr-2">
            {hint && !isWorking && value.length > 0 && hint}
            {showClearButton && (
              <UIIconButton
                name="cross"
                onClick={clearHandler}
                tooltip="Clear"
              />
            )}
            {isWorking && <UIIcon name="spinner-animated" />}
            {useCopy && <CopyButton value={value} />}
            {!!rightControls && rightControls}
          </div>
        </div>
        {/* Only show this if the error is an actual node */}
        {error && error !== true && <div className="inputError">{error}</div>}
      </div>
    );
  }
);

HomePageSearchInput.displayName = 'HomePageSearchInput';
