import React, { FC, useCallback } from 'react';
import {
  AppDefinition,
  AppName,
  useAppDefinition,
} from '../../../../apps/definition';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { useAppParam, usePageSearch } from '../../../../scripts/hooks';
import { PageSearchResult } from '../../../../scripts/models/page-search-result';
import { UIButton } from '../../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { MessageResult } from './MessageResult';
import './MessageResults.scss';

interface ShowMoreButtonProps {
  appDef: AppDefinition;
}

const ShowMoreButton: FC<ShowMoreButtonProps> = ({ appDef }) => {
  const [, setAppParam] = useAppParam();
  const handleClick = useCallback(() => {
    setAppParam(appDef.shortname);
    trackEvent(AnalyticsEvent.MessageSectionClickMore);
  }, [setAppParam, appDef]);

  return (
    <UIButton
      className="showMoreButton"
      onClick={handleClick}
      size="small"
      type="flat"
    >
      <span>View all results</span>
      <UIIcon name="arrow-right" size={16} />
    </UIButton>
  );
};

interface MessageResultsProps {
  // Technically multiple different types can show up here, this is not a use case at this time.
  source: AppName;
  messages: PageSearchResult[];
}

export const MessageResults: FC<MessageResultsProps> = ({
  source,
  messages,
}) => {
  const [appParam] = useAppParam();
  const appDef = useAppDefinition(source);
  const hasMoreMessages = usePageSearch((ps) => ps.hasMoreMessages);

  if (!appDef || messages.length === 0) {
    return null;
  }

  const isFiltered = appParam === source;

  const list = (
    <div className="messageResultsList">
      {messages.map((m) => (
        <MessageResult key={m.objectID} result={m} showIcon={isFiltered} />
      ))}
    </div>
  );

  return (
    <div className="messageResults">
      {isFiltered ? (
        list
      ) : (
        <>
          <div className="title">
            <UIIcon name={source} type="apps" />
            <strong>{appDef.displayName}</strong>
            {hasMoreMessages && <ShowMoreButton appDef={appDef} />}
          </div>
          {list}
        </>
      )}
    </div>
  );
};
