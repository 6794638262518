import React, { FC } from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';

interface QueryPreservingRedirectProps extends RedirectProps {
  to: string;
}

export const QueryPreservingRedirect: FC<QueryPreservingRedirectProps> = ({
  to,
  ...props
}) => {
  const { search } = useLocation();
  let newTo = to;
  if (search) {
    newTo += search;
  }

  return <Redirect {...props} push to={newTo} />;
};
