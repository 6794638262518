import { Action } from '../actions';
import { GlobalState } from '../state';
import { DebugAction } from './actions';

export const reducer = (state: GlobalState, action: Action): GlobalState => {
  const debug = { ...state.debug };

  switch (action.type) {
    case DebugAction.SET_SEARCH_RESULT: {
      debug.resultDebug = action.payload as boolean;
      break;
    }
  }

  return {
    ...state,
    debug,
  };
};
