import React from 'react';
import { AppName } from '../../../../apps/definition';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import './PersonAvatar.scss';

interface PersonAvatarProps {
  imageUrl?: string;
  source?: AppName;
  size?: number;
}

export const PersonAvatar: React.FC<PersonAvatarProps> = ({
  imageUrl,
  source,
  size = 20,
}) => {
  if (!imageUrl) {
    return null;
  }

  if (source) {
    return (
      <div className="personAvatarWrapper">
        <img
          className="personAvatar"
          height={size}
          src={imageUrl}
          width={size}
        />
        <UIIcon name={source} size={size / 2} type="apps" />
      </div>
    );
  }

  return (
    <img className="personAvatar" height={size} src={imageUrl} width={size} />
  );
};
