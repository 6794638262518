import Knock, { Feed, FeedItem, FeedMetadata } from '@knocklabs/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQAController } from '../../scripts/QAController';
import {
  useFlag,
  useLoginTokens,
  useToaster,
  useUserSafe,
} from '../../scripts/hooks';
import {
  getKnockNotificationsToken,
  markFeedItemsAsRead,
  mergeFeedItems,
  NotificationsData,
  NotificationsFeedItem,
  processFeedItems,
  updateMetaDataAfterNotificationsRead,
} from './utils';

interface NotificationsProps {
  notificationItems: NotificationsFeedItem[] | null;
  metaData: FeedMetadata | null;
  markNotificationAsRead: (feedItem: FeedItem) => void;
  markAllNotificationsAsRead: () => void;
}

const NotificationsContext = createContext<NotificationsProps | undefined>(
  undefined
);

export const useNotifications = (): NotificationsProps => {
  const ctx = useContext(NotificationsContext);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

interface NotificationsProviderProps {
  children: ReactNode;
}

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps): JSX.Element => {
  const userEmail = useUserSafe((u) => u.email);
  const idToken = useLoginTokens();
  const [knockToken, setKnockToken] = useState<string | null>(null);
  const [notificationsFeed, setNotificationsFeed] = useState<Feed | null>(null);
  const [notificationItems, setNotificationItems] = useState<
    NotificationsFeedItem[] | null
  >(null);

  const qaController = useQAController();
  const { isNewTopic } = qaController.getIsNewTopic();

  const messages = qaController.useMessages();
  const currentTopicMessages = qaController.useCurrentTopicMessages();

  const lastMessage =
    currentTopicMessages[currentTopicMessages.length - 1] ??
    messages[messages.length - 1];

  const currentConversationId = !isNewTopic && lastMessage?.conversation_id;

  const toaster = useToaster();

  const multiplayerEnabled = useFlag('multiplayerEnabled');
  const knockNotificationsEnabled = useFlag('knockNotificationsEnabled');

  const [metaData, setMetaData] = useState<FeedMetadata | null>(null);

  useEffect(() => {
    async function getKnockToken() {
      if (idToken) {
        const knockTokenRes = await getKnockNotificationsToken();
        setKnockToken(knockTokenRes);
      }
    }

    if (multiplayerEnabled && knockNotificationsEnabled) {
      getKnockToken();
    }
  }, [idToken, multiplayerEnabled, knockNotificationsEnabled]);

  useEffect(() => {
    if (knockToken) {
      const knock = new Knock(KNOCK_PUBLIC_API_KEY);
      knock.authenticate(userEmail, knockToken);

      const knockFeed = knock.feeds.initialize(KNOCK_FEED_CHANNEL_ID);
      setNotificationsFeed(knockFeed);

      return () => {
        knock.teardown();
      };
    }
  }, [userEmail, knockToken]);

  useEffect(() => {
    if (notificationsFeed) {
      notificationsFeed.listenForUpdates();

      notificationsFeed.fetch();

      notificationsFeed.on('items.read', (data: { items: FeedItem[] }) => {
        setNotificationItems((prev) =>
          mergeFeedItems(prev ?? [], markFeedItemsAsRead(data.items))
        );

        setMetaData((prev) =>
          updateMetaDataAfterNotificationsRead(prev, data.items)
        );
      });

      notificationsFeed.on('items.all_read', (data: { items: FeedItem[] }) => {
        setNotificationItems((prev) =>
          mergeFeedItems(prev ?? [], markFeedItemsAsRead(data.items))
        );

        setMetaData((prev) =>
          updateMetaDataAfterNotificationsRead(prev, data.items)
        );
      });

      notificationsFeed.on(
        'items.received.page',
        ({
          items,
          metadata,
        }: {
          items: FeedItem[];
          metadata: FeedMetadata;
        }) => {
          setNotificationItems(processFeedItems(items));
          setMetaData(metadata);
        }
      );
    }

    return () => notificationsFeed?.teardown();
  }, [notificationsFeed]);

  useEffect(() => {
    if (!notificationsFeed) return;

    const notificationsRealtimeEventListener = (data: {
      items: FeedItem[];
      metadata: FeedMetadata;
    }) => {
      setNotificationItems((prev) => {
        return [...(prev ?? []), ...processFeedItems(data.items)].sort((a, b) =>
          a.knockItem.inserted_at > b.knockItem.inserted_at ? -1 : 1
        );
      });

      setMetaData(data.metadata);

      if (data.items.length > 0) {
        for (const feedItem of data.items) {
          const notificationsData = feedItem.data as NotificationsData | null;

          if (notificationsData?.topic_id === currentConversationId) {
            notificationsFeed.markAsRead(feedItem);
          }
        }
      }
    };

    notificationsFeed.on(
      'items.received.realtime',
      notificationsRealtimeEventListener
    );

    return () => {
      notificationsFeed.off(
        'items.received.realtime',
        notificationsRealtimeEventListener
      );
    };
  }, [notificationsFeed, currentConversationId]);

  const markNotificationAsRead = useCallback(
    (feedItem: FeedItem) => {
      if (!notificationsFeed) return;
      notificationsFeed.markAsRead(feedItem);
    },
    [notificationsFeed]
  );

  const markAllNotificationsAsRead = useCallback(async () => {
    if (!notificationsFeed) return;
    await notificationsFeed.markAllAsRead();
    toaster.success('Marked all notifications as read');
  }, [notificationsFeed, toaster]);

  const contextValue = useMemo(
    () => ({
      markAllNotificationsAsRead,
      markNotificationAsRead,
      notificationItems,
      metaData,
    }),
    [
      markAllNotificationsAsRead,
      markNotificationAsRead,
      notificationItems,
      metaData,
    ]
  );

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};
