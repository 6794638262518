import { OrgMember } from '../../scripts/models/org-member';
import { Action } from '../actions';
import { OrgMembersSearchQuery } from './state';

export enum OrgMembersAction {
  ADD_ORG_MEMBERS = 'ORG_MEMBERS_ADD_ORG_MEMBERS',
  ADD_SEARCH_QUERY = 'ORG_MEMBERS_ADD_SEARCH_QUERY',
}

export function addOrgMembers(payload: OrgMember[]): Action {
  return {
    type: OrgMembersAction.ADD_ORG_MEMBERS,
    payload,
  };
}

export function addOrgMembersSearchedQuery(
  payload: OrgMembersSearchQuery
): Action {
  return {
    type: OrgMembersAction.ADD_SEARCH_QUERY,
    payload,
  };
}
