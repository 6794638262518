import React, { FC } from 'react';
import { InstallDialogProps } from '../../scripts/app';
import { InformationOnlyDialog } from '../generic/InformationOnlyDialog';

interface OAuthRequirementsDialogProps extends InstallDialogProps {
  requirements: string[];
}

export const OAuthRequirementsDialog: FC<OAuthRequirementsDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
  requirements,
  children,
}) => {
  return (
    <InformationOnlyDialog
      app={app}
      isOrg={isOrg}
      onClose={onClose}
      open={open}
    >
      <strong>Requirements to connect {app.definition.displayName}</strong>
      <ul>
        {requirements.map((r, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={idx}>{r}</li>
        ))}
      </ul>
      {children}
    </InformationOnlyDialog>
  );
};
