import React from 'react';
import {
  createdModifiedMetaPairs,
  pushResultMeta,
  pushResultMetaUrl,
} from '../../../../../../scripts/page-search/result-utils';
import { UIIcon } from '../../../../../controls/ui/UIIcon/UIIcon';
import { MetaPair, PreviewItem } from '../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../previewProps';

export const GeneralContactPreview: React.FC<PreviewProps> = ({ result }) => {
  let metaPairs: MetaPair[] = [];

  pushResultMetaUrl(result, metaPairs, 'Website', 'domain');
  pushResultMetaUrl(result, metaPairs, 'Email', 'email', true);

  pushResultMeta(result, metaPairs, 'Company', 'company_name');
  pushResultMeta(result, metaPairs, 'Job Title', 'job_title');
  pushResultMeta(result, metaPairs, 'Location', 'address');
  pushResultMeta(result, metaPairs, 'Phone', 'phone');
  pushResultMeta(result, metaPairs, 'Status', 'status');

  metaPairs = [...metaPairs, ...createdModifiedMetaPairs(result)];

  return (
    <PreviewItem
      icon={result.source}
      metaPairs={metaPairs}
      result={result}
      title={result.unhighlighted_name}
      titleIcon={<UIIcon className="prefixIcon" name="company" size={16} />}
    />
  );
};
