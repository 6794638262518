import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  attemptOpenDeeplink,
  trackEvent,
} from '../../../../extra/sharedMethods';
import { setPreview } from '../../../../redux/pageSearch/actions';
import { useDispatch } from '../../../../redux/store';
import { composeRefs } from '../../../../scripts/compose-refs';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { FileType, ObjectType } from '../../../../scripts/constants/filters';
import {
  useAllSearchRelatedParams,
  useBoolState,
  useButtonEmulation,
  useFileTypeParam,
  useFilterParam,
  usePageSearch,
  useTrackImpression,
  useTrackMouseEnter,
} from '../../../../scripts/hooks';
import { useFeedback } from '../../../../scripts/hooks/feedback';
import {
  PageResultAttachment,
  PageSearchResult,
} from '../../../../scripts/models/page-search-result';
import { elementIndex, isSafari, logError } from '../../../../scripts/utils';
import { useCloudIndex } from '../../../../search/CloudIndexContext';

export const enum PositionSelector {
  List = '.resultsListContainer .resultItem',
  Grid = '.resultsGridContainer .gridItem',
}

interface ResultActions {
  visibleImpression: (node: HTMLElement | null) => void;
  elementRefFn: RefObject<HTMLElement>;
  handleNav: (
    ev: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>,
    val?: undefined
  ) => void;
  handleAttachmentClick: (attachment: PageResultAttachment) => void;
  onCopy: () => void;
}

/**
 * Provides common result view actions instrumented with analytics.
 */
export const useInstrumentedResultActions = (
  result: PageSearchResult,
  positionSelector: PositionSelector,
  useLocalNav = false,
  isRemote = false
): ResultActions => {
  const resultRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const version = usePageSearch((pageSearch) => pageSearch.version);
  const cloudIndex = useCloudIndex();
  const [searchParams] = useAllSearchRelatedParams();

  const attachments =
    result.attachments?.map((attachment) => attachment.objectID).join(',') ??
    null;

  const matches = result.metadataMatches;

  const analyticsProps = useCallback(() => {
    return {
      source: result.source,
      object_id: result.objectID,
      query: searchParams.q,
      position: elementIndex(resultRef.current, positionSelector),
      matched_owner_email:
        (matches?.owner?.email && result.owners[0]?.email) ?? null,
      matched_owner_name:
        (matches?.owner?.name && result.owners[0]?.name) ?? null,
      matched_domain: matches?.domain ? result.domain : null,
      attachments,
      pinned: result.pins && result.pins.length > 0,
      version,
      remote: isRemote,
      ...searchParams,
    };
  }, [
    result.source,
    result.objectID,
    result.owners,
    result.domain,
    result.pins,
    searchParams,
    positionSelector,
    matches?.owner?.email,
    matches?.owner?.name,
    matches?.domain,
    attachments,
    version,
    isRemote,
  ]);

  const visibleImpression = useTrackImpression(() => {
    trackEvent(AnalyticsEvent.SearchResultImpression, analyticsProps());
  });

  const mouseEnterImpression = useTrackMouseEnter(
    useCallback(() => {
      trackEvent(AnalyticsEvent.SearchResultMouseEnter, analyticsProps());
    }, [analyticsProps])
  );

  const feedback = useFeedback();

  const handleNav = useButtonEmulation(
    (ev) => {
      const selection = window.getSelection();
      if (
        selection &&
        selection.type !== 'None' &&
        selection.type !== 'Caret'
      ) {
        return;
      }

      ev.preventDefault();
      trackEvent(AnalyticsEvent.SearchResultClick, analyticsProps());

      cloudIndex.logDocVisit(result).catch(logError);

      if (useLocalNav) {
        history.push(new URL(result.webview_link).pathname);
        return;
      }

      attemptOpenDeeplink(result.source, result.webview_link, result.deep_link);
      feedback.show();
    },
    [feedback, result, analyticsProps]
  );

  const handleAttachmentClick = useCallback(
    (attachment: PageResultAttachment) => {
      trackEvent(AnalyticsEvent.SearchResultAttachmentClick, {
        ...analyticsProps(),
        attachment_object_id: attachment.objectID,
      });
    },
    [analyticsProps]
  );

  const onCopy = useCallback(() => {
    trackEvent(AnalyticsEvent.SearchResultCopyURLClick, analyticsProps());
  }, [analyticsProps]);

  const elementRefFn = composeRefs(
    resultRef,
    mouseEnterImpression
  ) as RefObject<HTMLDivElement>;

  return {
    visibleImpression,
    elementRefFn,
    handleNav,
    handleAttachmentClick,
    onCopy,
  };
};

/**
 * Returns a new function that also calls a `marker` function, this is usually usually useful for dirty-checking.
 */
export const useMarker = <T>(
  fn: (val: T) => void,
  markerFn: (val: boolean) => void
): ((val: T) => void) => {
  return useCallback(
    (val: T) => {
      fn(val);
      markerFn(true);
    },
    [fn, markerFn]
  );
};

/**
 * Safely wraps a callback function and discards its arguments.
 * This is useful for passing click event handlers when you don't care about the event parameter but have conditional arguments.
 */
export const useUnbind = (cb: () => void): ((...args: unknown[]) => void) => {
  return useCallback(() => {
    cb();
  }, [cb]);
};

/**
 * Returns a component level stable id, useful for labels for inputs.
 */
export const useId = (): string => {
  return useMemo(() => Math.random().toString(), []);
};

/**
 * Binds a function with a single argument, use for for state setters.
 */
export const useBound = <T>(fn: (val: T) => void, value: T): (() => void) => {
  return useCallback(() => {
    fn(value);
  }, [fn, value]);
};

export const usePromiseState = (): [
  boolean,
  <T>(promise: Promise<T>) => Promise<T>,
  (val: boolean) => void
] => {
  const [working, setWorking, setNotWorking] = useBoolState(false);
  return [
    working,
    useCallback(
      async <T>(promise: Promise<T>) => {
        setWorking();
        return promise.finally(setNotWorking);
      },
      [setNotWorking, setWorking]
    ),
    setWorking,
  ];
};

const gridView = new Set([FileType.Presentation, FileType.Image]);

export const useIsGridView = (): boolean => {
  const [fileType] = useFileTypeParam();
  const [objectType] = useFilterParam();
  return objectType === ObjectType.Files && gridView.has(fileType as FileType);
};

interface PreviewControl {
  handleEnter: (ev: React.SyntheticEvent<HTMLElement>) => void;
  handleFocus: (ev: React.FocusEvent<HTMLElement>) => void;
  handleLeave: (
    ev: React.FocusEvent<HTMLElement> | React.MouseEvent<HTMLElement>
  ) => void;
  isActive: boolean;
}

// eslint-disable-next-line import/no-unused-modules
export const usePreviewControl = (result: PageSearchResult): PreviewControl => {
  const dispatch = useDispatch();
  const isActive = usePageSearch(
    ({ preview }) => !!(preview && result.objectID === preview.result.objectID)
  );

  const handleEnter = useCallback(
    ({ currentTarget }: React.SyntheticEvent<HTMLElement>) => {
      dispatch(
        setPreview({
          result,
          offsetY: currentTarget.offsetTop,
        })
      );
    },
    [dispatch, result]
  );

  const handleFocus = useCallback(
    (ev: React.FocusEvent<HTMLElement>) => {
      handleEnter(ev);
      if (
        ev.target !== ev.currentTarget ||
        // Safari doesn't support the below so skip it entirely
        isSafari ||
        // We should only scroll into view when we use keyboard focus
        !ev.currentTarget.matches(':focus-visible')
      ) {
        return;
      }

      ev.currentTarget.scrollIntoView({
        block: 'center',
      });
    },
    [handleEnter]
  );

  const handleLeave = useCallback(
    ({
      relatedTarget,
      currentTarget,
    }: React.FocusEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
      // Only close preview if mouse left the related preview
      if (
        relatedTarget instanceof Element &&
        !relatedTarget.closest('.previewItem') &&
        !currentTarget.contains(relatedTarget)
      ) {
        dispatch(setPreview());
      }
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      handleEnter,
      handleFocus,
      handleLeave,
      isActive,
    }),
    [handleEnter, handleFocus, handleLeave, isActive]
  );
};

/**
 * Tracks the size of element in `elementRef`, calls `callback` on any change in size.
 */
export const useResize = (
  elementRef: React.RefObject<HTMLElement>,
  callback: () => void
): void => {
  useEffect(() => {
    const node = elementRef.current;
    if (!node) {
      return;
    }

    const resizeObserver = new ResizeObserver(callback);

    resizeObserver.observe(node);
    return () => {
      resizeObserver.disconnect();
    };
  }, [callback, elementRef]);
};
