import React, { FC, useMemo } from 'react';
import { ctorMap } from '../../../apps';
import { BOT_FILTER_APPS, useAppDefinition } from '../../../apps/definition';
import { UserApp } from '../../../models/User';
import { useFlag } from '../../../scripts/hooks';
import { useAppName } from '../../../scripts/hooks/apps';
import { ConnectedApps } from '../../../scripts/hooks/sortedInstantApps';
import { ConnectionInfoPopover } from '../../admin/AppsTableRow/ConnectionInfoPopover';
import { LinkFavicon } from '../../controls/LinkFavicon/LinkFavicon';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../controls/ui/UIIconButton/UIIconButton';

export const AppComponent: FC<{
  source: ConnectedApps;
  allSourcesUserApps?: UserApp[];
  showConfigureAppSettings?: boolean;
  configureAppSettingsCallback?: (app: ConnectedApps) => void;
}> = ({
  source: skill,
  allSourcesUserApps: allSkillsUserApps,
  showConfigureAppSettings = false,
  configureAppSettingsCallback,
}) => {
  const appSpecificFiltersEnabled = useFlag('appSpecificFiltersEnabled');

  const { getAppName } = useAppName();
  const definition = useAppDefinition(skill.appName);
  const AppConstructor = ctorMap.get(skill.appName);

  const displayName = useMemo(() => {
    if (skill.connectionName && skill.connectionName !== '') {
      return skill.connectionName;
    }

    const appName = getAppName(skill.id);
    if (appName) {
      return appName;
    }

    return skill.displayName.split(' ').slice(0, 2).join(' ');
  }, [getAppName, skill.connectionName, skill.displayName, skill.id]);

  const appCtor = useMemo(() => {
    if (definition && AppConstructor) {
      return new AppConstructor(definition);
    }
  }, [AppConstructor, definition]);

  const userApp = useMemo(
    () =>
      allSkillsUserApps?.find((skillUserApp) => skillUserApp.id === skill.id),
    [allSkillsUserApps, skill.id]
  );

  return (
    <>
      {skill.appName === 'website' ? (
        <LinkFavicon link={`http://${skill.workspaceName!}`} size={20} />
      ) : skill.appName === 'custom-api' && skill.icon ? ( // eslint-disable-line unicorn/no-nested-ternary
        <img
          alt="custom-api-icon"
          src={skill.icon}
          style={{ width: 20, height: 20 }}
        />
      ) : (
        <UIIcon name={skill.appName} type="apps" />
      )}
      <span>
        {displayName.length > 18
          ? `${displayName.slice(0, 18)}...`
          : displayName}
      </span>
      {appCtor && userApp && (
        <ConnectionInfoPopover app={appCtor} connection={userApp} />
      )}
      {appSpecificFiltersEnabled &&
        showConfigureAppSettings &&
        BOT_FILTER_APPS.has(skill.appName) && (
          <UIIconButton
            name="cog"
            onClick={() => {
              if (configureAppSettingsCallback) {
                configureAppSettingsCallback(skill);
              }
            }}
            tooltip="Specify sources"
          />
        )}
    </>
  );
};
