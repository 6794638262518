import React, { FC } from 'react';
import { Feedback } from '../../models/Bots';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { TableCell, TableRow } from '../shadcn/lib/components/table';

interface FeedbackListItemProps {
  feedback: Feedback;
  onDelete: (feedback: Feedback) => void;
}

export const FeedbackListItem: FC<FeedbackListItemProps> = ({
  feedback,
  onDelete,
}) => {
  const { processed_feedback } = feedback;

  return (
    <TableRow className="font-medium w-full">
      <TableCell className="w-11/12">{processed_feedback}</TableCell>
      <TableCell className="w-1/12">
        <div
          className="flex h-4 items-center text-mahogany-20 focus:text-mahogany-20 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(feedback);
          }}
        >
          <UIIcon className="text-mahogany-20" name="trash-can" />
        </div>
      </TableCell>
    </TableRow>
  );
};
