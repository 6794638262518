import React, { useCallback } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleOAuthInstall, setInProgress }) => {
    const clickConnect = useCallback(async () => {
      setInProgress(true);
      return handleOAuthInstall({});
    }, [handleOAuthInstall, setInProgress]);

    return (
      <>
        <p className="firstNotice">
          Requirement to connect Bitbucket:
          <ul>
            <li>
              <strong>
                You need to{' '}
                <a
                  href="https://bitbucket.org/search"
                  rel="noreferrer"
                  target="_blank"
                >
                  enable code search
                </a>{' '}
                for all workspaces you want to connect. If you don't see an
                "Enable code search" button in the provided link, then the
                selected workspace already has it enabled.
              </strong>
            </li>
            <li>
              <strong>
                In order to connect the integration successfully, you need to
                have at least one workspace with code search enabled.
              </strong>
            </li>
          </ul>
        </p>

        {connectInProgress(clickConnect)}
      </>
    );
  }
);
