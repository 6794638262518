import './AppFilter.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDefinitions } from '../../../../../apps/definition';
import { filterableIndexStates } from '../../../../../constants';
import { getOrgAppData, getUserAppData } from '../../../../../redux/selectors';
import {
  FilterDefaults,
  ObjectType,
} from '../../../../../scripts/constants/filters';
import {
  useAppParam,
  useFilterParam,
  useUserSafe,
} from '../../../../../scripts/hooks';
import { UIIcon } from '../../../../controls/ui/UIIcon/UIIcon';
import {
  UISelect,
  UISelectItem,
} from '../../../../controls/ui/UISelect/UISelect';

const ALL_APPS_ITEM: UISelectItem = {
  label: 'All apps',
  value: FilterDefaults.Apps,
  icon: <UIIcon className="allAppsIcon" name="app" />,
  isDefault: true,
};

export const AppFilter: React.FC = () => {
  const user = useUserSafe();
  const [filterParam] = useFilterParam();
  const [appParam, setAppParam] = useAppParam();
  const [selectItems, setSelectItems] = useState<UISelectItem[]>([]);
  const [selectedValue, setSelectedValue] = useState(
    FilterDefaults.Apps as string
  );

  const appDefinitions = useAppDefinitions();

  useEffect(() => {
    const supportedApps = Object.values(appDefinitions).filter((app) =>
      app.objectTypes?.includes(filterParam as ObjectType)
    );

    // When filter param or connected apps change, we set the available apps
    const appNodes: UISelectItem[] = supportedApps
      .filter((result) => {
        // Only show indexing/indexed apps
        const personalAppData = getUserAppData(user, result.shortname);
        const orgAppData = getOrgAppData(user, result.shortname);
        return (
          filterableIndexStates.has(personalAppData?.statusCode) ||
          filterableIndexStates.has(orgAppData?.statusCode)
        );
      })
      .map((result) => ({
        label: result.displayName,
        value: result.shortname,
        icon: <UIIcon name={result.shortname} size={16} type="apps" />,
      }));

    setSelectItems([ALL_APPS_ITEM, ...appNodes]);

    // If no app param set or is unknown for selected filter, then default to "all_apps"
    if (
      !supportedApps.some((app) => app.shortname === appParam) &&
      appParam !== ALL_APPS_ITEM.value
    ) {
      setAppParam(ALL_APPS_ITEM.value);
    }
  }, [appDefinitions, appParam, filterParam, setAppParam, user]);

  useEffect(() => {
    setSelectedValue(appParam);
  }, [appParam]);

  const handleOnSelect = useCallback(
    (item: UISelectItem) => {
      setAppParam(item.value);
    },
    [setAppParam]
  );

  return (
    <UISelect
      dropdownClassName="appFilterUISelectDropdown"
      items={selectItems}
      onSelect={handleOnSelect}
      selectedValue={selectedValue}
      selectorClassName="appFilterUISelect"
    />
  );
};
