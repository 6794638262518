import { Popover } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useButtonEmulation } from '../../../../scripts/hooks';
import './UIFloatingMenu.scss';

export interface UIFloatingMenuItem {
  label: string;
  value: string;
}

const MenuItem: React.FC<{
  item: UIFloatingMenuItem;
  onSelect(item: UIFloatingMenuItem): void;
}> = ({ item, onSelect }) => {
  const handleSelect = useButtonEmulation(() => {
    onSelect(item);
  }, [item, onSelect]);

  return (
    <li onClick={handleSelect} onKeyDown={handleSelect} tabIndex={0}>
      {item.label}
    </li>
  );
};

interface UIFloatingMenuProps {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  items: UIFloatingMenuItem[];
  className?: string;
  handleSelect(item: UIFloatingMenuItem): void;
  handleClose(): void;
}

export const UIFloatingMenu: React.FC<UIFloatingMenuProps> = ({
  open,
  anchorEl,
  items,
  handleSelect,
  handleClose,
  className,
}) => {
  const handleSelectItem = useCallback(
    (item: UIFloatingMenuItem) => {
      handleSelect(item);
      handleClose();
    },
    [handleSelect, handleClose]
  );

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      className={classNames('uiFloatingMenu', className)}
      onClose={handleClose}
      open={open}
      tabIndex={-1}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <ul>
        {items.map((item) => (
          <MenuItem item={item} key={item.value} onSelect={handleSelectItem} />
        ))}
      </ul>
    </Popover>
  );
};
