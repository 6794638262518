import { Action } from '../actions';
import { GlobalState } from '../state';
import { MetaAction } from './actions';

export const metaReducer = (
  state: GlobalState,
  action: Action
): GlobalState => {
  const meta = { ...state.meta };

  switch (action.type) {
    case MetaAction.SET_USER_DISPLAY_NAME: {
      meta.userDisplayName = action.payload as string | undefined;
      break;
    }

    case MetaAction.SET_USER_PHOTO_URL: {
      meta.userPhotoUrl = action.payload as string | undefined;
      break;
    }
  }

  return {
    ...state,
    meta,
  };
};
