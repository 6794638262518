import { Toast } from '../../scripts/models/toast';
import { Action } from '../actions';
import { GlobalState } from '../state';
import { ToastAction } from './actions';

export const toastReducer = (
  state: GlobalState,
  action: Action
): GlobalState => {
  const toastState = {
    ...state.toast,
  };

  switch (action.type) {
    case ToastAction.ADD_TOAST: {
      const newToast = {
        ...(action.payload as Toast),
        uid: (action.payload as Toast).uid ?? `${Date.now()}${Math.random()}`,
      };

      toastState.toasts = [newToast, ...toastState.toasts];
      break;
    }

    case ToastAction.REMOVE_TOAST: {
      toastState.toasts = toastState.toasts.filter(
        (toast) => toast.uid !== action.payload
      );

      break;
    }
  }

  return {
    ...state,
    toast: toastState,
  };
};
