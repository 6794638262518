import React, { FC } from 'react';
import readmeApiKeyScreen from '../../assets/images/admin/readme_api_key.gif';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  open,
  onClose,
}) => {
  return (
    <SimpleAPIKeyInstall
      app={app}
      instructionSteps={
        <ConnectStep step={1} title="Generate API key">
          <p>
            Go to your Readme project configuration. Navigate to the API Key tab
            and create a new one.
          </p>
          <div className="screenFrame">
            <img src={readmeApiKeyScreen} />
          </div>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
    />
  );
};
