import React, { useCallback } from 'react';
import { trackEvent } from '../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { AppIcons, UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { DeepLink } from '../../links/links';

interface PersonCardHandleProps {
  app: AppIcons;
  url: string;
  label: string;
  deepLink?: boolean;
}

export const PersonCardHandle: React.FC<PersonCardHandleProps> = ({
  app,
  url,
  label,
  deepLink,
}) => {
  const handleClick = useCallback(() => {
    trackEvent(AnalyticsEvent.PersonLinkClick, {
      app,
      url,
    });
  }, [app, url]);

  return (
    <DeepLink
      className="appHandle"
      deepHref={deepLink ? url : undefined}
      href={url}
      onClick={handleClick}
    >
      <UIIcon name={app} type="apps" />
      <span>{label}</span>
    </DeepLink>
  );
};
