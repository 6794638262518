import { Method } from 'axios';
import { GenericAPI } from './GenericAPI';
import { invokeAWSGatewayAPI, InvokeOptions } from './aws';
import { invokeFastApi } from './fastapi';

interface IDObject {
  id: string;
}

export class GenericCRUD<
  TModel extends IDObject,
  TWritable extends object
> extends GenericAPI {
  public constructor(path: string, private readonly idFragment?: string) {
    super(path);
  }

  public async get(id: string): Promise<TModel> {
    return this.sanitize(
      await this.invoke<TModel>({ path: this.desanitizeId(id) })
    );
  }

  public compareIds(a: string, b: string): boolean {
    return this.sanitizeId(a) === this.sanitizeId(b);
  }

  public async create(data: TWritable): Promise<void> {
    await this.invoke({ method: 'POST', data });
  }

  public async update(id: string, data: TWritable): Promise<void> {
    await this.invoke({ method: 'PATCH', path: this.desanitizeId(id), data });
  }

  public async upsert(data: TWritable, id?: string): Promise<void> {
    await (id ? this.update(id, data) : this.create(data));
  }

  public async delete(id: string): Promise<void> {
    await this.invoke({ method: 'DELETE', path: this.desanitizeId(id) });
  }

  protected sanitizeId(id: string): string {
    if (!this.idFragment) {
      return id;
    }

    return id.replace(`${this.idFragment}://`, '');
  }

  /**
   * Removes potentially bad parts of an objects id from the model that, when used for routing, causes issues.
   */
  protected sanitize(model: TModel): TModel {
    model.id = this.sanitizeId(model.id);
    return model;
  }

  /**
   * Counterpart to `sanitize`. Re-adds potentially problematic fragments to the id.
   */
  protected desanitizeId(id: string): string {
    if (!this.idFragment) {
      return id;
    }

    const prefix = `${this.idFragment}://`;

    if (id.startsWith(prefix)) {
      return id;
    }

    return `${this.idFragment}://${id}`;
  }

  protected override async invoke<T = undefined>(
    opts: InvokeOptions
  ): Promise<T> {
    if (opts.rawPath) {
      opts.path = opts.rawPath;
    } else if (opts.path) {
      opts.path = `/${this.path}/${opts.path}`;
    } else {
      opts.path = `/${this.path}`;
    }

    if (opts.path.includes('answer')) {
      const { data } = opts;
      const { path } = opts;
      let { method } = opts;

      if (typeof method === 'string') {
        method = method.toUpperCase() as Method;
      }

      return invokeFastApi<T>({ path, method, body: data });
    }

    return (await invokeAWSGatewayAPI<T>(opts)).data;
  }
}
