import { gql } from 'graphql-tag';

// Note: Ordering of fields is important and should be same as how it is defined in the graphql schema
const appFields = gql`
  fragment AppFields on UsersApp {
    appName
    appUserId
    botOrgWideAccess
    icon
    sharedBy
    connectionName
    createdAt
    displayName
    email
    userId
    extra
    statusCode
    statusMessage
    backwardCursor
    syncedFrom
    syncedUntil
    org
    id
    workspaceId
    workspaceName
  }
`;

const conversationFields = gql`
  fragment ConversationFields on Conversation {
    conversationId
    summary
    createdAt
    messageUserInteractions(condition: { readAt: null }) {
      nodes {
        readAt
      }
      totalCount
    }
  }
`;

export const userFields = gql`
  fragment UserFields on User {
    email
    sub
    aclKey
    admin
    userId
    nodeId
    onboardingState
    hasSeenWebOnboarding
    homepageLinks
    preferences
    preferredLlm
    reconnectedApps
    orgByOrgId {
      id
      domain
      name
      firstAdminOnboarded
      homepageLinks
      preferences
      exploToken
      isWorkspaceSetup
      appPreferences
      disclaimer
      chatRetentionDays
      preferredLlm
      usersAppsByOrg {
        nodes {
          ...AppFields
        }
      }
      dashSubscriptionsByOrgDomain {
        nodes {
          id
          planType
          status
          trialEndAt
          seatCount
        }
      }
      allowedDomains {
        nodes {
          domain
          status
          verificationCode
          createdAt
          allowAllSignups
        }
      }
    }
    conversationMemberships(
      condition: { role: EDITOR }
      first: 5
      orderBy: CREATED_AT_DESC
    ) {
      nodes {
        role
        conversation {
          ...ConversationFields
        }
      }
    }
    botsAdminsByAdminId {
      nodes {
        botId
        adminId
        bot {
          id
          botName
          isManaged
          botsConversations(orderBy: CREATED_AT_DESC, first: 5) {
            nodes {
              conversationId
              createdAt
              conversation {
                ...ConversationFields
              }
            }
          }
        }
      }
    }
    usersApps {
      nodes {
        ...AppFields
      }
    }
  }
  ${appFields}
  ${conversationFields}
`;
