import React, { FC } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <SimpleAPIKeyInstall
      additionalApiKeyInstructions={
        <strong>
          <br />
          <br />
          You must be a Super Admin in Quip. Dashworks will request read-only
          access to files and folders. By default the admin account will be
          added to the common company folder. Make sure to add the admin to all
          the shared folders you want to index.
        </strong>
      }
      app={app}
      credentialName="personal access token"
      instructionSteps={
        <ConnectStep step={1} title="Generate personal access token">
          <p>
            Sign in with the Quip account you want to connect with Dashworks.
          </p>
          <div className="actionContainer">
            <UIButton href="https://quip.com/api/personal-token">
              Sign in with Quip
            </UIButton>
          </div>
          <p>
            On the opened page, click on{' '}
            <strong>Get Personal Access Token</strong>.
          </p>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
    />
  );
};
