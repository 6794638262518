import React, { FC } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { InstallDialogProps } from '../../scripts/app';
import { useLowerCase } from '../../scripts/hooks';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  open,
  onClose,
}) => {
  const [subdomain, setSubdomain] = useLowerCase('');

  return (
    <SimpleAPIKeyInstall
      app={app}
      credentialName="access token"
      instructionSteps={
        <>
          {/* ALTERNATIVE PERSONAL ACCESS TOKEN */}

          <ConnectStep
            step={1}
            title="Enter your GitLab self-managed Instance URL"
          >
            <p>
              Your instance url is the homepage to your organization&apos;s
              gitlab account.
            </p>
            <p>
              {' '}
              We support only GitLab self-managed instances for Org connection.
              To use GitLab cloud instance, please add an individual connection.
            </p>

            <div className="stepField">
              <UITextbox
                className="stepFieldInput"
                onChange={setSubdomain}
                placeholder="https://gitlab.example.com"
                size="large"
                type="text"
                value={subdomain}
              />
            </div>
          </ConnectStep>

          <ConnectStep step={2} title="Generate access token">
            <p>
              As an org administrator navigate to your personal access tokens.
              You can verify if you are an{' '}
              <a
                href="https://docs.gitlab.com/ee/user/admin_area/"
                rel="noreferrer"
                target="_blank"
              >
                administrator
              </a>{' '}
              by accessing{' '}
              <a href={`${subdomain}/admin`} rel="noreferrer" target="_blank">
                admin settings
              </a>
              .{' '}
              <strong>
                The user providing the token must be an Admin for all GitLab
                teams and repositories in the organization.
              </strong>
            </p>

            <div className="actionContainer">
              <UIButton href={`${subdomain}/-/profile/personal_access_tokens`}>
                Generate Personal Access Token
              </UIButton>
            </div>

            <p>
              In the <strong>Token Name</strong> field enter{' '}
              <code>Dashworks</code> or any other friendly name. For the{' '}
              <strong>Expiration date</strong> we suggest you leave it blank.
            </p>

            <p>
              Generate the token with the following scope: <strong>api</strong>
            </p>
          </ConnectStep>
        </>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={2}
      subdomain_override={subdomain}
    />
  );
};
