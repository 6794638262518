import React, { FC } from 'react';
import { AppName, getAppDisplayName } from '../../../apps/definition';
import { LinkFavicon } from '../../controls/LinkFavicon/LinkFavicon';
import { AppIcons, UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { AnswerIcon } from '../../controls/ui/icons/icons';
import { getIconPropsByFileName } from '../files/utils';

export const REFERENCES_PER_PAGE = calculateReferencesPerPage();

export function calculateReferencesPerPage(): number {
  const REFERENCE_CARD_HEIGHT = 72;
  const REFERENCES_CONTAINER_HEIGHT_OFFSET = 160;

  const totalHeight = window.innerHeight - REFERENCES_CONTAINER_HEIGHT_OFFSET;

  const cardCount = Math.floor(totalHeight / REFERENCE_CARD_HEIGHT);

  return Math.max(cardCount, 10);
}

export const ReferenceIcon: FC<{
  source: string;
  url?: string;
  size?: number;
  title?: string;
  icon_url?: string;
}> = ({ source, url, title, size = 20, icon_url }) => {
  let icon;

  // TODO: we need to fix this
  if ((source === 'web' || source === 'website') && url) {
    icon = <LinkFavicon link={url} size={size} />;
  } else if (source === 'custom-api' && icon_url) {
    icon = <img src={icon_url} style={{ width: size, height: size }} />;
  } else if (source === 'verified_answers') {
    icon = <AnswerIcon size={size} />;
  } else if (source === 'files') {
    const iconProps = getIconPropsByFileName(title ?? '.txt');
    icon = <UIIcon size={size} {...iconProps} />;
  } else if (source && source !== '') {
    icon = <UIIcon name={source as AppIcons} size={size} type="apps" />;
  } else if (url) {
    icon = <LinkFavicon link={url} size={size} />;
  } else {
    return null;
  }

  return icon;
};

export const referenceName = (
  source: AppName | 'files' | 'verified_answers'
): string => {
  let name;

  if (source === 'files') {
    name = 'Files';
  } else if (source === 'verified_answers') {
    name = 'Answers';
  } else {
    name = getAppDisplayName(source);
  }

  return name;
};
