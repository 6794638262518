import React from 'react';
import { UIIcon } from '../../../../../../controls/ui/UIIcon/UIIcon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';
import { intercomPersonBreadcrumbs } from '../common';

export const IntercomUserResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Intercom User"
      breadcrumbs={intercomPersonBreadcrumbs(result)}
      hideModified
      icon={<UIIcon className="resultPerson" name="person" size={20} />}
      result={result}
      showLastItemBreadcrumb
    />
  );
};
