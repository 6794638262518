import React, { useCallback } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleOAuthInstall, app, setInProgress }) => {
    const clickConnect = useCallback(async () => {
      setInProgress(true);
      return handleOAuthInstall({});
    }, [handleOAuthInstall, setInProgress]);

    return (
      <>
        <p className="firstNotice">
          Requirement to connect {app.definition.displayName}:
          <ul>
            <li>
              If you are a {app.definition.displayName} admin, you must grant
              consent on behalf of the organization to allow Dashworks read
              access to the employee directory data. Click the Connect button
              below to grant consent.
            </li>
            <li>
              You can follow{' '}
              <a
                href="https://tryfinch.notion.site/Workday-ab3c6e15d2df470a99de5a94c203293b"
                rel="noreferrer"
                target="_blank"
              >
                these
              </a>{' '}
              instructions for more detail.
            </li>
          </ul>
        </p>

        {connectInProgress(clickConnect)}
      </>
    );
  }
);
