import React, { FC, ReactNode } from 'react';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { ConnectStep } from './Components';

interface SubdomainCaptureStepProps {
  step: number;
  name: string;
  hostname: string;
  loginURL: string;
  subdomain: string;
  additionalDescription?: ReactNode;
  onChange(subdomain: string): void;
}

export const SubdomainCaptureStep: FC<SubdomainCaptureStepProps> = ({
  step,
  name,
  hostname,
  loginURL,
  onChange,
  subdomain,
  additionalDescription,
}) => {
  return (
    <ConnectStep step={step} title={`Enter ${name} subdomain`}>
      <p>
        Your subdomain is the first part of the url to your organization&apos;s{' '}
        {name} account: <strong>subdomain</strong>.{hostname}.
      </p>
      {additionalDescription && <p>{additionalDescription}</p>}

      <div className="stepField">
        <UITextbox
          className="stepFieldInput"
          fixedText={`.${hostname}`}
          onChange={onChange}
          placeholder={`Enter ${name} subdomain`}
          size="large"
          type="text"
          value={subdomain}
        />
      </div>

      <p className="note">
        <a href={loginURL} rel="noreferrer" target="_blank">
          Don&apos;t remember your company&apos;s url?{' '}
        </a>
      </p>
    </ConnectStep>
  );
};
