import React, { FC, useCallback, useState } from 'react';
import { AuthorDetails } from '../../components/controls/AuthorDetails/AuthorDetails';
import { DataPlaceholder } from '../../components/controls/DataPlaceholder/DataPlaceholder';
import {
  Loading,
  LoadingSize,
} from '../../components/controls/Loading/Loading';
import { DateFormat, Timestamp } from '../../components/controls/Timestamp';
import { Modal } from '../../components/controls/ui/Modal/Modal';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { useQATextInputBoxStates } from '../../components/jit-qa/textInputBox/QATextInputBoxStates';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/shadcn/lib/components/table';
import { UploadedFile } from '../../models/File';
import { useAdminGuard } from '../../scripts/hooks';
import { FileUpload } from './FileUpload';

export const FilesPage: FC = () => {
  useAdminGuard();

  const {
    allFiles: files,
    filesLoading: loading,
    fileUploadStatus: uploadStatus,
    uploadFile,
    deleteFile,
  } = useQATextInputBoxStates();

  return (
    <div className="mx-2 lg:mx-auto border-transparent my-24 max-w-5xl">
      <div className="flex gap-2 items-center">
        <UIIcon name="doc" size={32} />
        <h2 className="text-2xl m-0 font-bold">Files</h2>
      </div>
      <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-9 max-sm:p-4">
        <div>
          Add Files for Dash AI to reference when answering questions asked by
          anyone in your org. It will prioritize responses from these Files when
          there is a match. Max file size is 1GB.
        </div>
        <FileUpload uploadFile={uploadFile} uploadStatus={uploadStatus} />
      </div>

      {loading ? (
        <Loading size={LoadingSize.Large} />
      ) : (
        <FilesTable deleteFile={deleteFile} files={files} />
      )}
    </div>
  );
};

const FilesTable = ({
  files,
  deleteFile,
}: {
  files: UploadedFile[];
  deleteFile: (id: string) => Promise<void>;
}) => {
  const [selectedFile, setSelectedFile] = useState<string | null>();

  if (!files.length) {
    return (
      <DataPlaceholder
        content="Upload files and share knowledge with your team."
        icon="doc"
        title="There are no files yet."
      />
    );
  }

  return (
    <Table className="mt-10">
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/3">Name</TableHead>
          <TableHead className="w-1/3">Uploaded By</TableHead>
          <TableHead className="w-1/3">Uploaded On</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {files.map((file) => (
          <TableRow className="group" key={file.id}>
            <TableCell>
              <div className="flex items-center gap-2">{file.title}</div>
            </TableCell>
            <TableCell>
              <AuthorDetails
                displayName={file.creator.name ?? file.creator.email}
                icon={file.creator.icon}
              />
            </TableCell>
            <TableCell>
              <Timestamp
                format={DateFormat.DateOnly}
                unixTime={file.created_at}
              />
            </TableCell>
            <TableCell>
              <div
                className="flex group-hover:visible invisible hover:bg-cloud-10 rounded-full cursor-pointer"
                onClick={() => {
                  setSelectedFile(file.id);
                }}
              >
                <UIIcon name="trash-can" />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {selectedFile && (
        <FileModal
          closeModal={() => {
            setSelectedFile(null);
          }}
          deleteFile={deleteFile}
          fileId={selectedFile}
        />
      )}
    </Table>
  );
};

const FileModal = ({
  fileId,
  deleteFile,
  closeModal,
}: {
  fileId: string;
  deleteFile: (id: string) => Promise<void>;
  closeModal: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await deleteFile(fileId);
    closeModal();
    setLoading(false);
  }, [fileId, deleteFile, closeModal]);

  return (
    <Modal modalClasses="!w-[480px]" onClose={closeModal}>
      <div className="text-lg font-semibold">Delete this File</div>
      <div className="mt-2">Would you like to delete this file?</div>
      <div className="flex mt-4 gap-4 justify-between">
        <UIButton onClick={closeModal} type="secondary">
          Cancel
        </UIButton>
        <UIButton onClick={handleDelete} processing={loading} type="delete">
          Delete
        </UIButton>
      </div>
    </Modal>
  );
};
