import { ReactNode } from 'react';

export const enum ToastType {
  SUCCESS,
  FAILURE,
  LOADING,
}

export interface Toast {
  type: ToastType;
  message: ReactNode;

  // Used to identify key for transitions
  uid?: string;

  // Used to hide while animating
  hidden?: boolean;

  // Hide after milliseconds
  timeout?: number;

  // Show X button to close toast
  dismissible?: boolean;
}
