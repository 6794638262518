import React from 'react';
import { Timestamp } from '../../../../../../controls/Timestamp';
import { MetaPair, PreviewItem } from '../../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../../previewProps';

export const WebsitePreview: React.FC<PreviewProps> = ({ result }) => {
  const metaPairs: MetaPair[] = [
    {
      name: 'Updated',
      value: (
        <span className="heavier">
          <Timestamp unixTime={result.modified_time} />
        </span>
      ),
    },
  ];

  return (
    <PreviewItem
      icon="website"
      metaPairs={metaPairs}
      result={result}
      title={result.unhighlighted_name}
    />
  );
};
