import classNames from 'classnames';
import React, { FC } from 'react';
import { UIIcon } from '../ui/UIIcon/UIIcon';
import './Loading.scss';

export const enum LoadingSize {
  XSmall = 'xsmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Huge = 'huge',
}

export interface LoadingProps {
  size?: LoadingSize;
  text?: string;
  inline?: boolean;
}

export const Loading: FC<LoadingProps> = ({
  size = 'medium',
  text = 'Loading...',
  inline = false,
}) => {
  let iconSize = 0;
  switch (size) {
    case LoadingSize.XSmall:
      iconSize = 16;
      break;
    case LoadingSize.Small:
      iconSize = 32;
      break;
    case LoadingSize.Medium:
      iconSize = 64;
      break;
    case LoadingSize.Large:
      iconSize = 96;
      break;
    case LoadingSize.Huge:
      iconSize = 128;
      break;
  }

  return (
    <div className={classNames('loading', size, { inline })}>
      <UIIcon name="spinner-animated" size={iconSize} />
      <span>{text}</span>
    </div>
  );
};
