import { useEffect } from 'react';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import {
  useOrgPreferences,
  useSidebarOpen,
  useUserSafe,
} from '../../scripts/hooks';

export const Tours: React.FC = () => {
  const userBackgroundEnabled = useOrgPreferences(
    (pref) => pref.background_allow_user
  );

  const userHasBackground = useUserSafe(
    (pref) => !!pref.preferences?.background?.type
  );

  const sidebarOpen = useSidebarOpen();

  useEffect(() => {
    if (userBackgroundEnabled && !userHasBackground && sidebarOpen) {
      analytics.track(AnalyticsEvent.TourUserBackgroundAvailable);
    }
  }, [sidebarOpen, userBackgroundEnabled, userHasBackground]);

  return null;
};
