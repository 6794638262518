import React, { useMemo } from 'react';
import { Timestamp } from '../../../../controls/Timestamp';
import { PersonAvatar } from '../../../results/PersonAvatar/PersonAvatar';
import { PreviewItem } from '../../PreviewItem/PreviewItem';
import { PreviewProps } from '../previewProps';

export const EmailPreview: React.FC<PreviewProps> = ({ result }) => {
  const [owner] = result.owners;

  const metaPairs = useMemo(
    () => [
      {
        name: 'Time',
        value: (
          <span className="heavier">
            <Timestamp unixTime={result.modified_time} />
          </span>
        ),
      },
      {
        name: 'From',
        value: <span className="heavier">{result.from}</span>,
      },
      {
        name: 'To',
        value: <span className="heavier">{result.to}</span>,
      },
    ],
    [result]
  );

  return (
    <PreviewItem
      icon={result.source}
      metaPairs={metaPairs}
      result={result}
      title={result.unhighlighted_name}
      titleIcon={
        owner?.photo ? <PersonAvatar imageUrl={owner.photo} /> : undefined
      }
    />
  );
};
