import React, { FC, useCallback, useMemo, useState } from 'react';
import { Bot } from '../../../models/Bots';
import { useMembers } from '../../../scripts/hooks/members';
import { OrgMember } from '../../../scripts/models/org-member';
import { debounce } from '../../../scripts/utils';
import { ProfileInfoHover } from '../../bots/ProfileInfoHover';
import { UserImage } from '../../controls/UserImage/UserImage';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

interface MentionsSelectProps {
  bots: Bot[];
  setSelectedBot: (bot: Bot) => void;
  setSelectedMember: (member: OrgMember) => void;
}

const MentionsSelectDashAi: FC<MentionsSelectProps> = ({
  bots,
  setSelectedBot,
  setSelectedMember,
}) => {
  const [query, setQuery] = useState('');
  const { members, fetchMembersByQuery } = useMembers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchMembers = useCallback(
    debounce((value: string) => {
      fetchMembersByQuery(value);
    }, 200),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      setQuery(value);
      debouncedFetchMembers(value);
    },
    [debouncedFetchMembers]
  );

  const filteredBots = useMemo(() => {
    return bots.filter((bot) =>
      bot.bot_name.toLowerCase().includes(query.toLowerCase())
    );
  }, [bots, query]);

  return (
    <div className="max-h-[300px] overflow-y-auto cursor-auto">
      <UITextbox
        autoFocus
        onChange={handleSearch}
        placeholder="Search members"
        value={query}
      />
      {members.length > 0 && (
        <div className="flex justify-between p-2 pb-1">
          <div className="text-sm text-gray-50 font-medium">People</div>
        </div>
      )}
      {members.slice(0, 5).map((member) => {
        return (
          <div
            className="flex items-center px-3 py-2 gap-2 hover:bg-cloud-10 cursor-pointer"
            key={member.user_id}
            onClick={() => {
              setSelectedMember(member);
            }}
          >
            <UserImage
              displayName={member.name}
              imageUrl={member.icon}
              size={20}
            />
            {member.name ?? member.email}
          </div>
        );
      })}
      {filteredBots.length > 0 && (
        <div className="flex justify-between p-2 pb-1">
          <div className="text-sm text-gray-50 font-medium">Bots</div>
        </div>
      )}
      {filteredBots.map((bot) => {
        return (
          <div
            className="flex items-center px-3 py-2 gap-2 hover:bg-cloud-10 cursor-pointer"
            key={bot.id}
            onClick={() => {
              setSelectedBot(bot);
            }}
          >
            <span className="text-lg">{bot.icon}</span>
            {bot.bot_name}
            {bot.description && (
              <ProfileInfoHover
                description={bot.description}
                icon={bot.icon}
                name={bot.bot_name}
              />
            )}
          </div>
        );
      })}

      {bots.length === 0 && (
        <div className="flex items-center px-3 py-2">
          This bot has no connected apps.
        </div>
      )}
    </div>
  );
};

interface MentionsSelectButtonProps {
  disabled?: boolean;
  bots: Bot[];
  setSelectedBot: (bot: Bot) => void;
  setSelectedMember: (member: OrgMember) => void;
}

export const MentionsSelectButtonDashAi: FC<MentionsSelectButtonProps> = ({
  disabled,
  bots,
  setSelectedBot,
  setSelectedMember,
}) => {
  const [openSelect, setOpenSelect] = useState(false);

  const handleSelectBot = useCallback(
    (bot: Bot) => {
      setSelectedBot(bot);
      setOpenSelect(false);
    },
    [setSelectedBot, setOpenSelect]
  );

  const handleSelectMember = useCallback(
    (member: OrgMember) => {
      setSelectedMember(member);
      setOpenSelect(false);
    },
    [setSelectedMember, setOpenSelect]
  );

  const handleOpenSelect = useCallback(() => {
    if (disabled) {
      setOpenSelect(false);
      return;
    }

    setOpenSelect((prev) => !prev);
  }, [disabled, setOpenSelect]);

  return (
    <Popover onOpenChange={handleOpenSelect} open={openSelect}>
      <PopoverTrigger asChild disabled={disabled}>
        <div>
          <UITooltip title="Select Bot">
            <div
              className={`flex items-center hover:bg-cloud-10 p-2 rounded-md cursor-pointer border-gray-30 border-solid border ${
                disabled ? 'opacity-50' : ''
              }`}
            >
              <UIIcon name="at-sign" size={16} stroke />
            </div>
          </UITooltip>
        </div>
      </PopoverTrigger>
      <PopoverContent align="center" className="p-0">
        <MentionsSelectDashAi
          bots={bots}
          setSelectedBot={handleSelectBot}
          setSelectedMember={handleSelectMember}
        />
      </PopoverContent>
    </Popover>
  );
};
