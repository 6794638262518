import './PersonCard.scss';
import moment from 'moment';
import React from 'react';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useTrackSearchImpression, useUserSafe } from '../../../scripts/hooks';
import { PersonResult } from '../../../scripts/models/person-result';
import { capitalize } from '../../../scripts/utils';
import { UserImage } from '../../controls/UserImage/UserImage';
import { DeepLink } from '../../links/links';
import { PersonCardDetails } from './PersonCardDetails';
import { PersonCardHandle } from './PersonCardHandle';

interface PersonCardProps {
  personResult: PersonResult;
}

// eslint-disable-next-line complexity
export const PersonCard: React.FC<PersonCardProps> = ({ personResult }) => {
  const user = useUserSafe();
  const {
    name,
    email,
    profileImage,
    role,
    creation_time,
    timezone_offset,
    linkedin_webview_link,
    slack_deeplink,
    gmail_webview_link,
    city,
    state,
    country,
    phoneNumbers,
    recentItems,
  } = personResult;

  const sinceText =
    creation_time && moment(creation_time * 1000).format('MMM YYYY');

  const slack = slack_deeplink && (
    <PersonCardHandle app="slack" deepLink label={name} url={slack_deeplink} />
  );

  // We want to go to the logged in account for user
  const gmailLink = gmail_webview_link
    ? gmail_webview_link.replace('/u/0', `/u/${user.email}`)
    : gmail_webview_link;

  const gmail = gmailLink && (
    <PersonCardHandle app="gmail" label={email ?? ''} url={gmailLink} />
  );

  const linkedIn = linkedin_webview_link && (
    <PersonCardHandle app="linkedin" label={name} url={linkedin_webview_link} />
  );

  const showHandles = !!(slack ?? gmail ?? linkedIn);

  const localTime =
    timezone_offset &&
    `Local time: ${moment
      .utc()
      .utcOffset(timezone_offset / 60)
      .format('h:mma')}`;

  const [phone] = phoneNumbers ?? [];

  const phoneNumber = phone && `Phone number: ${phone}`;

  const applyTrackImpression = useTrackSearchImpression(
    AnalyticsEvent.PersonProfileImpression
  );

  const location = [city, state, country]
    .filter(
      (item): item is string =>
        item !== null && item !== undefined && item !== ''
    )
    .map((item) => capitalize(item))
    .join(', ');

  return (
    <div className="personCard" ref={applyTrackImpression}>
      <div className="personHeader">
        {profileImage && (
          <div className="personImage">
            <UserImage imageUrl={profileImage} size={96} />
          </div>
        )}

        <div className="personRight">
          <div className="personName">{name}</div>
          <div className="titleRow">
            {role && <span className="personTitle">{role}</span>}
            {location && <span className="personLocation">{location}</span>}
            {sinceText && (
              <span className="personSince">Since {sinceText}</span>
            )}
          </div>
          {showHandles && (
            <div className="handleRow">
              {slack}
              {gmail}
              {linkedIn}
            </div>
          )}
          {phoneNumber && <div className="phoneNumber">{phoneNumber}</div>}
          {localTime && <div className="localTime">{localTime}</div>}
        </div>
      </div>
      <PersonCardDetails person={personResult} />

      {recentItems.length > 0 && (
        <>
          <hr />
          <div className="activities">
            <h4>Top public slack channels</h4>
            <ul>
              {recentItems.map((item) => (
                <li key={item.item_id}>
                  <DeepLink deepHref={item.deep_link} href={item.webview_link}>
                    #{item.name}
                  </DeepLink>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
