import classNames from 'classnames';
import React, { FC } from 'react';

export const QADivider: FC<{
  text: string;
  hideMargin?: boolean;
}> = ({ text, hideMargin = false }) => {
  return (
    <div className={classNames('relative bg-white', { 'mb-6': !hideMargin })}>
      <div className="border-[0.5px] border-solid border-cloud-15" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-1 px-4 bg-white whitespace-nowrap">
        <div className="text-amethys-10">{text}</div>
      </div>
    </div>
  );
};
