import { AppDefinition, AppName } from '../apps/definition';
import { AppState, filterableIndexStates } from '../constants';
import { AppPreferences, User, UserApp } from '../models/User';

/**
 * Returns the user's connected app data (either personal or org).
 */
export function getUserAppData(
  user: User,
  appShortname: AppName
): UserApp | undefined {
  return user.usersApps.nodes.find(
    (app) => app.appName === appShortname && app.org === ''
  );
}

export function getOrgAppData(
  user: User,
  appShortname: AppName
): UserApp | undefined {
  return user.orgByOrgId.usersAppsByOrg.nodes.find(
    (app) => app.appName === appShortname
  );
}

export function getStatusCode(
  user: User,
  { shortname }: AppDefinition
): AppState | undefined {
  return (getOrgAppData(user, shortname) ?? getUserAppData(user, shortname))
    ?.statusCode;
}

export function isIndexable(user: User, appDef: AppDefinition): boolean {
  return filterableIndexStates.has(getStatusCode(user, appDef));
}

export function getAppPreferences(user: User): AppPreferences {
  return user.orgByOrgId.appPreferences;
}
