import './QAInlineReference.scss';
import React, { FC, useCallback, useRef, useState } from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { QAMessage } from '../../../models/QAmodels';
import { ReferencePopover } from './QAReferencePopover';

interface IInlineLink {
  props: JSX.IntrinsicElements['a'] & ReactMarkdownProps;
  qaMessage: QAMessage;
}

export const InlineLink: FC<IInlineLink> = ({ props, qaMessage }) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const handlePopoverOpen = useCallback(() => {
    setOpenedPopover(true);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setOpenedPopover(false);
  }, []);

  // type from react markdown is wrong -- we check if value exists so it's safe
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  const value = (props.node as any)?.children?.[0]?.children?.[0]?.value;

  if (value) {
    const reference = qaMessage.references[Number(value) - 1];
    if (reference) {
      return (
        <div className="inline">
          {props.href ? (
            <a
              href={props.href}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              ref={popoverAnchor}
              rel="noreferrer"
              target="_blank"
            >
              {props.children}
            </a>
          ) : (
            props.children
          )}
          <ReferencePopover
            handlePopoverClose={handlePopoverClose}
            handlePopoverOpen={handlePopoverOpen}
            openedPopover={openedPopover}
            popoverAnchor={popoverAnchor}
            reference={reference}
          />
        </div>
      );
    }
  }

  return (
    <a href={props.href} rel="noreferrer" target="_blank">
      {props.children}
    </a>
  );
};
