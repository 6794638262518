import classNames from 'classnames';
import React from 'react';
import './UINotice.scss';
export const UINotice: React.FC<{ centered?: boolean; className?: string }> = ({
  children,
  className,
  centered,
}) => {
  return (
    <div className={classNames('uiNotice', { centered }, className)}>
      {children}
    </div>
  );
};
