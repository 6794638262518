import { useEffect, useState } from 'react';
import { fetchWithTimeout } from '../utils';

/**
 * Returns "false" if user does not have internet connection.
 */
export function useOnlineStatus(): boolean {
  // Default to true
  const [onlineStatus, setOnlineStatus] = useState(true);

  useEffect(() => {
    let intervalId: number | null = null;

    async function checkOnlineStatus() {
      try {
        await fetchWithTimeout(
          'https://www.gstatic.com/generate_204',
          {
            mode: 'no-cors',
          },
          10_000
        );

        setOnlineStatus(true);
        if (intervalId !== null) {
          clearInterval(intervalId);
          intervalId = null;
        }
      } catch {
        setOnlineStatus(false);
      }
    }

    function startChecking() {
      if (intervalId === null) {
        intervalId = window.setInterval(() => {
          void checkOnlineStatus();
        }, 10_000);
      }
    }

    function stopChecking() {
      if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
      }
    }

    // Start checking online status immediately
    checkOnlineStatus();

    function handleOnline() {
      setOnlineStatus(true);
      stopChecking();
    }

    function handleOffline() {
      setOnlineStatus(false);
      startChecking();
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      stopChecking();
    };
  }, []);

  return onlineStatus;
}

/**
 * Creates a hook function that loads the specified script and mounts it into `<head>`.
 * The component that uses this hook MUST be wrapped in `<Suspense>` element.
 * @param src The script URL to load
 */
export function createScriptLoader(src: string): () => void {
  let loader: Promise<void> | undefined;
  let finished = false;
  return () => {
    if (loader === undefined) {
      loader = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        const onLoad = () => {
          script.removeEventListener('error', onError);
          finished = true;
          resolve();
        };

        const onError = (ev: ErrorEvent) => {
          script.removeEventListener('load', onLoad);
          reject(ev.error);
        };

        script.addEventListener('load', onLoad, { once: true });
        script.addEventListener('error', onError, {
          once: true,
        });

        script.src = src;
        script.type = 'text/javascript';
        document.head.append(script);
      });
    }

    if (!finished) {
      // Intentionally throw promise, this is part of React API
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw loader;
    }
  };
}
