import React, { FC } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { App } from '../../scripts/app';
import { ConnectStep } from './Components';
interface IntegrationInstallStepProps {
  step: number;
  app: App;
  url: string;
}

export const IntegrationInstallStep: FC<IntegrationInstallStepProps> = ({
  step,
  app,
  url,
}) => {
  return (
    <ConnectStep
      step={step}
      title={`Enable ${app.definition.displayName} integration support`}
    >
      <p>
        In order for Dashworks to connect you need to install the Dashworks app
        on {app.definition.displayName}.
        <br />
      </p>
      <p>
        <UIButton href={url} type="primary">
          <UIIcon
            className="preserveFill"
            name={app.definition.shortname}
            size={14}
            type="apps"
          />
          <span>&nbsp;Proceed to {app.definition.displayName}</span>
        </UIButton>
      </p>
    </ConnectStep>
  );
};
