import React from 'react';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

export const ZendeskTicketResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Zendesk Ticket"
      result={result}
      status={result.status}
      teamName={result.location}
    />
  );
};
