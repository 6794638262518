import React from 'react';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { useQAFiles } from './QAFilesContext';
import { getIconPropsByFileName } from './utils';

export const QAFileCard = ({
  file,
  canBeDeleted,
}: {
  file: { id: string; title: string };
  canBeDeleted: boolean;
}): JSX.Element => {
  const { deleteFile } = useQAFiles();

  const onDelete = async () => {
    await deleteFile(file.id);
  };

  const iconProps = getIconPropsByFileName(file.title);

  return (
    <div
      // eslint-disable-next-line max-len
      className="relative w-[44%] sm:w-[30%] py-1 px-1 pr-4 sm:px-2 flex items-center gap-1 border-gray-30 border border-solid rounded text-xs neumorphic-background"
      tabIndex={0}
    >
      <div>
        <UIIcon size={16} {...iconProps} />
      </div>
      <div className="truncate">{file.title}</div>
      {canBeDeleted && (
        <div className="cursor-pointer ml-auto" onClick={onDelete}>
          <UIIcon name="cross" />
        </div>
      )}
    </div>
  );
};
