import React, { useCallback, useMemo, useRef, useState } from 'react';
import { v1 as uuidV1 } from 'uuid';
import {
  ImagePicker,
  ImageType,
} from '../../components/admin/ImagePicker/ImagePicker';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { withInstallModal } from '../../hoc/withInstallModal';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleAPIInstall }) => {
    const textboxRef = useRef<HTMLInputElement>(null);

    const [connectionName, setConnectionName] = useState<string>('');
    const [apiError, setApiError] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');

    const connectionID = useMemo(() => uuidV1(), []);

    const handleImageComplete = useCallback((url: string) => {
      setImageUrl(url);
    }, []);

    const handleClickDone = useCallback(async () => {
      return handleAPIInstall({
        connection_name: connectionName,
        connection_id: connectionID,
        icon: imageUrl,
      }).catch(() => {
        setApiError('Please enter a unique connection name.');
        textboxRef.current?.focus();
      });
    }, [handleAPIInstall, connectionName, connectionID, imageUrl]);

    const connectDisabled = connectionName.trim().length === 0;

    return (
      <div className="flex flex-col gap-4">
        <p className="text-gray-600">
          Follow the set-up process in order to connect Custom API to Dashworks.
          If you run into difficulty reach out to{' '}
          <a className="text-blue-600 hover:underline" href="#">
            support
          </a>
          .
        </p>

        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold">Icon</div>
          <ImagePicker
            imageUrl={imageUrl}
            maxFileSize={5 * 1024 * 1024}
            onComplete={handleImageComplete}
            showPreview
            subject="Connection Icon"
            type={ImageType.Logo}
          >
            Change Icon
          </ImagePicker>
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold">Connection Name</div>
          <UITextbox
            className="stepFieldInput"
            defaultWhiteInputField
            error={apiError}
            onChange={setConnectionName}
            placeholder="Please enter a connection name"
            ref={textboxRef}
            size="large"
            value={connectionName}
          />
        </div>

        <div className="flex flex-col gap-1">
          <p>
            This connection enables API access to ingest and search documents
            programmatically in your organization.
          </p>
          <p>
            Once connected, you'll receive a <strong>connection_id</strong>.
            This is an organization-wide connection that can be used to:
            <ul className="list-disc ml-6">
              <li>Ingest & Manage documents via API</li>
              <li>Search across ingested documents</li>
            </ul>
          </p>
          <p>
            Please refer to our{' '}
            <a
              className="text-blue-600 hover:underline"
              href="https://support.dashworks.ai/api/api-reference"
              rel="noopener noreferrer"
              target="_blank"
            >
              API documentation
            </a>{' '}
            for detailed instructions on how to use the connection_id for
            document ingestion and retrieval.
          </p>
        </div>

        {connectInProgress(handleClickDone, connectDisabled)}
      </div>
    );
  }
);
