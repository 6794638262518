import React, { SyntheticEvent, useCallback, useRef } from 'react';
import dashworksLogo from '../../../assets/images/dashworks_logo.svg';
import { useUserSafe } from '../../../scripts/hooks/redux';

export const CompanyLogo: React.FC<{ size?: number }> = ({ size = 24 }) => {
  const logo =
    useUserSafe((u) => u.orgByOrgId.preferences.logo) ?? dashworksLogo;

  const loadAttemptsRemaining = useRef(3);

  const onImageMissing = useCallback(
    (evt: SyntheticEvent<HTMLImageElement>) => {
      if (loadAttemptsRemaining.current === 0) {
        return;
      }

      loadAttemptsRemaining.current--;
      evt.currentTarget.src = dashworksLogo;
    },
    []
  );

  return (
    <img
      className="companyLogo"
      onError={onImageMissing}
      src={logo}
      style={{ width: size }}
    />
  );
};
