import './HomepageLinks.scss';
import { arrayMoveImmutable } from 'array-move';
import classNames from 'classnames';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
  SortableHandle as sortableHandle,
} from 'react-sortable-hoc';
import { AppCuesIds } from '../../../external-references';
import { trackEvent } from '../../../extra/sharedMethods';
import { updateOrgBookmarks, updateUserBookmarks } from '../../../scripts/apis';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useBoolState, useToaster, useUserSafe } from '../../../scripts/hooks';
import { useContrast, useExtremeLight } from '../../../scripts/hooks/colors';
import { HomepageLink } from '../../../scripts/models/homepage-link';
import {
  getStorageItem,
  setStorageItem,
  StorageKey,
} from '../../../scripts/storage';
import { inExtension, logError, safeCreateUrl } from '../../../scripts/utils';
import {
  ConfirmDialog,
  ConfirmMode,
} from '../../controls/Dialog/ConfirmDialog';
import { LinkFavicon } from '../../controls/LinkFavicon/LinkFavicon';
import {
  UIFloatingMenu,
  UIFloatingMenuItem,
} from '../../controls/ui/UIFloatingMenu/UIFloatingMenu';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { HomepageLinkDialog } from '../HomepageLinkDialog/HomepageLinkDialog';

const menuItems: UIFloatingMenuItem[] = [
  {
    label: 'Edit',
    value: 'edit',
  },
  {
    label: 'Remove',
    value: 'remove',
  },
];

const DragHandle = sortableHandle(() => (
  <span className="absolute top-1/4 -left-5 p-1 group-hover:opacity-40 opacity-0 transition-opacity group-hover:delay-500 delay-0 bg-gray-20 rounded border-0 flex cursor-grab">
    <UIIcon name="grapple" size={16} />
  </span>
));

const getLinkMetaData = (link: HomepageLink) => ({
  origin: safeCreateUrl(link.link)?.origin,
});

const LinkButton: React.FC<{
  link: HomepageLink;
  canEdit: boolean;
  isOrg: boolean;
  handleEdit(link: HomepageLink): void;
  handleRemove(link: HomepageLink): void;
}> = ({ link, canEdit, handleEdit, handleRemove, isOrg }) => {
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  const moreButtonRef = useRef<HTMLButtonElement>(null);

  const handleClick = useCallback(() => {
    trackEvent(AnalyticsEvent.BookmarkClick, {
      ...getLinkMetaData(link),
      isOrg,
    });
  }, [isOrg, link]);

  const handleMoreClick = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      trackEvent(AnalyticsEvent.BookmarkOpenMenu, {
        ...getLinkMetaData(link),
        isOrg,
      });

      evt.stopPropagation();
      setMoreMenuOpen(true);
    },
    [isOrg, link]
  );

  const handleMenuClose = useCallback(() => {
    trackEvent(AnalyticsEvent.BookmarkCloseMenu, {
      ...getLinkMetaData(link),
      isOrg,
    });

    setMoreMenuOpen(false);
  }, [isOrg, link]);

  const handleMenuSelect = useCallback(
    (item: UIFloatingMenuItem) => {
      if (item.value === 'edit') {
        handleEdit(link);
      } else {
        handleRemove(link);
      }
    },
    [handleEdit, handleRemove, link]
  );

  return (
    <div className="w-24 flex flex-col items-center text-center relative group gap-1">
      <a
        className="w-[48px] text-center"
        href={link.link}
        onClick={handleClick}
        rel="noreferrer"
        target={inExtension() ? '_top' : '_self'}
      >
        <div className="neumorphic-background p-2 rounded-lg flex justify-center items-center h-[48px]">
          <LinkFavicon link={link.link} size={24} />
        </div>
      </a>

      {canEdit && (
        <>
          <DragHandle />
          <button
            className="absolute top-1/4 -right-5 p-1 group-hover:opacity-40 opacity-0 transition-opacity group-hover:delay-500 delay-0 bg-gray-20 rounded border-0 cursor-pointer"
            onClick={handleMoreClick}
            ref={moreButtonRef}
            type="button"
          >
            <UIIcon className="flex" name="pencil" size={16} />
          </button>
          <UIFloatingMenu
            anchorEl={moreButtonRef.current}
            className="homepageLinkMenu"
            handleClose={handleMenuClose}
            handleSelect={handleMenuSelect}
            items={menuItems}
            open={moreMenuOpen}
          />
        </>
      )}
      <div className="text-sm">{link.title}</div>
    </div>
  );
};

const AddLinkButton: React.FC<{ isOrg: boolean; onClick(): void }> = ({
  isOrg,
  onClick,
}) => {
  return (
    <div className="w-16 flex flex-col items-center">
      <div
        className="text-cloud-20 neumorphic-background p-2 rounded-lg flex justify-center items-center h-[48px] w-[48px] cursor-pointer"
        data-appcues-id={
          isOrg
            ? AppCuesIds.AddOrgBookmarkButton
            : AppCuesIds.AddPersonalBookmarkButton
        }
        onClick={onClick}
      >
        <UIIcon className="text-black" name="plus" size={24} />
      </div>
    </div>
  );
};

interface SortableLinkData {
  element: ReactElement;
  link: HomepageLink;
}

const SortableLink = sortableElement(
  ({ value }: { value: SortableLinkData }) => value.element
);

const SortableLinksContainer = sortableContainer(
  ({
    items,
    sortable,
    addButton,
  }: {
    items: SortableLinkData[];
    sortable: boolean;
    addButton: JSX.Element | false;
  }) => {
    return (
      <div className="flex my-4 gap-8 flex-wrap">
        {items.map((item, i) => (
          <SortableLink
            disabled={!sortable}
            index={i}
            key={`${item.link.title}${item.link.link}`}
            value={item}
          />
        ))}
        {addButton}
      </div>
    );
  }
);

const BOOKMARK_LIMIT = 8;

interface LinkState {
  index: number;
  link: HomepageLink;
}

interface HomepageLinksProps {
  isOrg?: boolean;
  forceOrgColorScheme?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export const HomepageLinks: React.FC<HomepageLinksProps> = ({
  isOrg = false,
  forceOrgColorScheme,
}) => {
  const user = useUserSafe();
  const homepageLinks = isOrg
    ? user.orgByOrgId.homepageLinks
    : user.homepageLinks;

  const [links, setLinks] = useState<HomepageLink[]>([]);
  const lsKey = isOrg
    ? StorageKey.OrgHomepageLinks
    : StorageKey.UserHomepageLinks;

  const [dialogOpen, setDialogOpen] = useState(false);

  const [editedLink, setEditedLink] = useState<LinkState | null>(null);
  const [removedLink, setRemovedLink] = useState<LinkState | null>(null);
  const [sortActive, setSortActive, unsetSortActive] = useBoolState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toaster = useToaster();

  useEffect(() => {
    // On load, use homepage links from LS if available
    const storedLinks = getStorageItem(lsKey) as HomepageLink[] | null;
    if (!storedLinks) {
      return;
    }

    setLinks(storedLinks);
  }, [lsKey]);

  useEffect(() => {
    setStorageItem(lsKey, homepageLinks);
    setLinks(homepageLinks);
  }, [homepageLinks, lsKey]);

  const canEdit = !isOrg || user.admin;

  const handleEdit = useCallback(
    (index: number) => (link: HomepageLink) => {
      trackEvent(AnalyticsEvent.BookmarkBeginEdit, {
        isOrg,
        ...getLinkMetaData(link),
      });

      setEditedLink({ index, link });
      setDialogOpen(true);
    },
    [isOrg]
  );

  const handleRemove = useCallback(
    (index: number) => (link: HomepageLink) => {
      trackEvent(AnalyticsEvent.BookmarkBeginDelete, {
        isOrg,
        ...getLinkMetaData(link),
      });

      setRemovedLink({ index, link });
    },
    [isOrg]
  );

  const linksData = links.map((link, i) => ({
    element: (
      <LinkButton
        canEdit={canEdit}
        handleEdit={handleEdit(i)}
        handleRemove={handleRemove(i)}
        isOrg={isOrg}
        key={link.link}
        link={link}
      />
    ),
    link,
  }));

  const updateRequest = useCallback(
    async (newLinks: HomepageLink[]) => {
      return isOrg
        ? updateOrgBookmarks(newLinks)
        : updateUserBookmarks(user.userId, newLinks);
    },
    [isOrg, user]
  );

  const onSortStart = useCallback(() => {
    setSortActive();
  }, [setSortActive]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      unsetSortActive();

      if (oldIndex === newIndex) {
        return;
      }

      const newLinks = arrayMoveImmutable(links, oldIndex, newIndex);

      // Show change before response comes back
      setLinks(newLinks);

      updateRequest(newLinks).catch(() => {
        toaster.failure(
          'There was an error reordering your bookmark. Please try again'
        );

        // Revert back to old order
        setLinks(links);
      });
    },
    [links, toaster, updateRequest, unsetSortActive]
  );

  const onAddClick = useCallback(() => {
    setEditedLink(null);
    setDialogOpen(true);
    trackEvent(AnalyticsEvent.BookmarkBeginCreate, {
      isOrg,
    });
  }, [isOrg]);

  // Only admins can add bookmarks
  const addButton = links.length < BOOKMARK_LIMIT && canEdit && (
    <AddLinkButton isOrg={isOrg} key="addLink" onClick={onAddClick} />
  );

  const typeText = isOrg ? 'Company' : 'My';
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
    trackEvent(AnalyticsEvent.BookmarkDialogClose, {
      isOrg,
    });
  }, [isOrg]);

  const handleSaveLink = useCallback(
    (link: string, title: string) => {
      let newLinks: HomepageLink[] = [];

      if (editedLink) {
        newLinks = [...homepageLinks];
        newLinks[editedLink.index] = {
          link,
          title,
        };
      } else {
        newLinks = [...homepageLinks, { link, title }];
      }

      toaster
        .withPromise(
          updateRequest(newLinks),
          `${isOrg ? 'Company' : 'Personal'} bookmark created.`,
          'There was an error creating your bookmark. Please try again'
        )
        .then(() => {
          trackEvent(
            editedLink
              ? AnalyticsEvent.BookmarkEdited
              : AnalyticsEvent.BookmarkCreated,
            {
              ...getLinkMetaData({ link, title }),
              isOrg,
              origin: safeCreateUrl(link)?.origin,
            }
          );

          setDialogOpen(false);
        })
        .catch(logError);
    },
    [editedLink, homepageLinks, updateRequest, setDialogOpen, isOrg, toaster]
  );

  const handleConfirmRemove = useCallback(() => {
    const newLinks = [...homepageLinks];
    const [link] = newLinks.splice(removedLink!.index, 1);

    toaster
      .withPromise(
        updateRequest(newLinks),
        `${isOrg ? 'Company' : 'Personal'} bookmark deleted.`,
        'There was an error deleting your bookmark. Please try again'
      )
      .finally(() => {
        if (link) {
          trackEvent(AnalyticsEvent.BookmarkDelete, {
            ...getLinkMetaData(link),
            isOrg,
          });
        }

        setRemovedLink(null);
      });
  }, [homepageLinks, removedLink, isOrg, toaster, updateRequest]);

  const handleCancelRemove = useCallback(() => {
    trackEvent(AnalyticsEvent.BookmarkCancelDelete, {
      isOrg,
      ...getLinkMetaData(removedLink!.link),
    });

    setRemovedLink(null);
  }, [isOrg, removedLink]);

  const classes = classNames('mt-8', {
    contrast: useContrast(forceOrgColorScheme),
    light: useExtremeLight(forceOrgColorScheme),
  });

  // Non-admins cannot see empty bookmarks
  if (isOrg && !user.admin && homepageLinks.length === 0) {
    return null;
  }

  return (
    <div className={classes} ref={containerRef}>
      <div>{typeText} bookmarks</div>
      <div className={classNames({ sortActive })}>
        <SortableLinksContainer
          addButton={addButton}
          axis="x"
          helperContainer={containerRef.current as HTMLElement}
          items={linksData}
          onSortEnd={onSortEnd}
          onSortStart={onSortStart}
          sortable={canEdit}
          useDragHandle
        />
      </div>
      <HomepageLinkDialog
        editedLink={editedLink?.link}
        isOrg={isOrg}
        onClose={handleDialogClose}
        onSave={handleSaveLink}
        open={dialogOpen}
      />
      {removedLink && (
        <ConfirmDialog
          confirmButtonText="Remove"
          confirmButtonType="warning"
          mode={ConfirmMode.Delete}
          onCancel={handleCancelRemove}
          onConfirm={handleConfirmRemove}
          subject="bookmark"
          title={`Removing "${removedLink.link.title}" bookmark?`}
        />
      )}
    </div>
  );
};
