import './ResultsContainer.scss';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useAppDefinitions } from '../../../../apps/definition';
import { SearchLoadState } from '../../../../redux/pageSearch/actions';
import {
  useBoolState,
  usePageSearch,
  useSearchParam,
} from '../../../../scripts/hooks';
import { AccessDiscoveryResult } from '../../../../scripts/models/page-search-result';
import { getAccessDiscoveryRequests } from '../../../../scripts/page-search/access-discovery';
import { EmptyResults } from '../../../controls/EmptyResults/EmptyResults';
import { AccessRequestDialog } from '../../AccessRequest/AccessRequestDialog';
import { DashAiSuggestion } from '../../notices/DashAiSuggestion';
import { ResultFilters } from '../ResultFilters/ResultFilters';
import { QuerySpellCorrectionView } from '../misc/QuerySpellCorrectionView';
import { useIsGridView } from '../misc/hooks';
import { GridView } from '../view/GridView/GridView';
import { ListView } from '../view/ListView/ListView';

const emptySuggestions = [
  'Make sure all words are spelled correctly.',
  'Try different keywords.',
  'Try more general keywords.',
  'Try fewer keywords.',
  'Try more general filters.',
  'Ensure all apps are connected properly.',
];

const NoResults: React.FC<{ query: string }> = ({ query }) => {
  return (
    <EmptyResults
      message={
        <p>
          <strong>No results found for {query}.</strong>
        </p>
      }
      suggestions={emptySuggestions}
    />
  );
};

const enum Layout {
  List,
  Grid,
}

// eslint-disable-next-line complexity
export const ResultsContainer: React.FC = () => {
  const [q] = useSearchParam();

  const [
    isAccessRequestDialogOpen,
    setAccessRequestDialogOpen,
    setAccessRequestDialogClose,
  ] = useBoolState(false);

  const [accessDiscoveryResult, setAccessDiscoveryResult] = useState<
    AccessDiscoveryResult | undefined
  >();

  const appDefs = useAppDefinitions();

  const results = usePageSearch((pageSearch) => pageSearch.results).filter(
    (r) => r.source in appDefs || r.source === 'system'
  );

  const accessDiscoveryResults = usePageSearch(
    (pageSearch) => pageSearch.accessDiscoveryResults
  );

  const accessDiscoveryRequestsHistory = getAccessDiscoveryRequests();

  const pinnedResults = usePageSearch(
    (pageSearch) => pageSearch.pinnedResults
  ).filter((r) => r.source in appDefs || r.source === 'system');

  const hasMoreResults = usePageSearch((pageSearch) => pageSearch.hasMore);

  const messages = usePageSearch((ps) => ps.messages);

  const personResult = usePageSearch((pageSearch) => pageSearch.personResult);
  const loadState = usePageSearch((pageSearch) => pageSearch.load);

  const hasSpecialContent =
    pinnedResults.length > 0 || !!personResult || !!messages.length;

  // Need to have no results for non-empty, finished query
  const isEmpty =
    (loadState === SearchLoadState.Loaded ||
      loadState === SearchLoadState.LoadingMore) &&
    q.length > 0 &&
    !results.length &&
    !hasSpecialContent;

  const layout = useIsGridView() ? Layout.Grid : Layout.List;

  const ResultsView = layout === Layout.List ? ListView : GridView;

  return (
    <div className="resultsContainer">
      <ResultFilters />
      <div
        className={classNames('resultsListsWrapper', {
          isSearching: loadState === SearchLoadState.Loading,
        })}
      >
        {loadState === SearchLoadState.Loaded && !isEmpty && (
          <QuerySpellCorrectionView />
        )}
        <DashAiSuggestion />
        {isEmpty && <NoResults query={q} />}
        {isAccessRequestDialogOpen && accessDiscoveryResult !== undefined && (
          <AccessRequestDialog
            onClose={setAccessRequestDialogClose}
            query={q}
            result={accessDiscoveryResult}
            shown
          />
        )}
        {(loadState === SearchLoadState.Loaded ||
          loadState === SearchLoadState.Loading ||
          loadState === SearchLoadState.LoadingMore) && (
          <ResultsView
            // Send the access discovery props properly
            accessDiscoveryResults={accessDiscoveryResults.filter((result) => {
              return !accessDiscoveryRequestsHistory.includes(result.id_hash);
            })}
            accessRequestDialogProps={{
              setAccessRequestDialogOpen,
              setAccessDiscoveryResult,
            }}
            hasMoreResults={hasMoreResults}
            messages={messages}
            personResult={personResult}
            pinnedResults={pinnedResults}
            results={results}
          />
        )}
      </div>
    </div>
  );
};
