import { useCallback, useEffect, useState } from 'react';
import {
  addOrgMembers,
  addOrgMembersSearchedQuery,
} from '../../redux/orgMembers/actions';
import { useDispatch } from '../../redux/store';
import { logError, uniqBy } from '../../scripts/utils';
import { getMembers } from '../apis';
import { OrgMember } from '../models/org-member';
import { useGlobalState } from './redux';

interface MembersResponse {
  allMembers: OrgMember[];
  members: OrgMember[];
  fetchMembersByQuery: (q: string) => Promise<OrgMember[]>;
}

const filterMembersByQuery = (
  members: OrgMember[],
  query: string
): OrgMember[] => {
  return members.filter((member) =>
    (member.name ?? member.email).toLowerCase().includes(query.toLowerCase())
  );
};

export const useMembers = (): MembersResponse => {
  const dispatch = useDispatch();
  const { members: allMembers, searchedQueries } = useGlobalState(
    (state) => state.orgMembers
  );

  const [members, setMembers] = useState<OrgMember[]>([]);

  const fetchMembersByQuery = useCallback(
    async (q: string): Promise<OrgMember[]> => {
      if (searchedQueries.some((query) => query.q === q)) {
        const filteredMembers = filterMembersByQuery(allMembers, q);
        setMembers(filteredMembers);
        return filteredMembers;
      }

      try {
        const { members: searchedMembers } = await getMembers(q);
        const allMergedMembers = uniqBy(
          [...allMembers, ...searchedMembers],
          'user_id'
        );

        dispatch(addOrgMembers(searchedMembers));
        dispatch(addOrgMembersSearchedQuery({ q, page: 1 }));

        const filteredMembers = filterMembersByQuery(allMergedMembers, q);
        setMembers(filteredMembers);
        return filteredMembers;
      } catch (error) {
        logError(error);
      }

      const filteredMembers = filterMembersByQuery(allMembers, q);
      setMembers(filteredMembers);
      return filteredMembers;
    },
    [allMembers, dispatch, searchedQueries]
  );

  useEffect(() => {
    fetchMembersByQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetchMembersByQuery,
    members,
    allMembers,
  };
};
