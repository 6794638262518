import React from 'react';
import { PageSearchResultItem } from '../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../resultProps';

export const ServiceNowCaseResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="ServiceNow Case"
      result={result}
      showLastItemBreadcrumb
      status={result.status}
    />
  );
};
