import React, { FC } from 'react';
import { Answer } from '../../../scripts/models/answers';
import { AnswerInfoPopover } from '../../admin/AnswersTable/AnswerInfoPopover';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';

export const AnswerComponent: FC<{
  source: Answer;
}> = ({ source: answer }) => {
  return (
    <>
      <UIIcon name="answer" size={20} />
      <span>
        {answer.title.length > 18
          ? `${answer.title.slice(0, 18)}...`
          : answer.title}
      </span>
      <AnswerInfoPopover answer={answer} />
    </>
  );
};
