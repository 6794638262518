import React from 'react';
import { WebsiteFavicon } from '../../../../../../controls/WebsiteFavicon/WebsiteFavicon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

export const WebSitePageResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Website Page"
      icon={<WebsiteFavicon imageUrl={result.image_url!} />}
      modifiedLabel="Last indexed"
      result={result}
    />
  );
};
