import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { logError } from '../../../../../scripts/utils';
import {
  UISelect,
  UISelectItem,
} from '../../../../controls/ui/UISelect/UISelect';
import './SearchFilter.scss';

interface SearchFilterProps<T> {
  defaultItem: UISelectItem;
  searchPrompt: string;

  // TODO: This feels hacky but is cleaner than having to pass more stuff around
  searchParamHook(): [string, (val: string) => void];
  dataSource(search?: string): Promise<T[]>;
  itemToResultItem(item: T): UISelectItem;
  fuzzyMatch(item: T, query: string): boolean;
  exactMatch(item: T, paramValue: string): boolean;
}

export const SearchFilter = <T extends object>({
  searchParamHook,
  defaultItem,
  dataSource,
  fuzzyMatch,
  itemToResultItem,
  exactMatch,
  searchPrompt,
}: SearchFilterProps<T>): JSX.Element => {
  const [filterParam, setFilterParam] = searchParamHook();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<UISelectItem>(defaultItem);

  const [items, setItems] = useState<T[]>([]);
  const [selectItems, setSelectItems] = useState<UISelectItem[]>([defaultItem]);
  const [resultItems, setResultItems] = useState<UISelectItem[]>([]);

  const handleOnSelect = useCallback(
    (item: UISelectItem) => {
      setSelectedItem(item);

      // If user selects "everyone", we remove any additional dropdown items
      if (item.value === defaultItem.value) {
        setSelectItems([defaultItem]);
      }

      setFilterParam(item.value);
    },
    [defaultItem, setFilterParam]
  );

  const handleResultSelect = useCallback(
    (item: UISelectItem) => {
      // Add newly selected item to non-results list
      setSelectItems([defaultItem, item]);
      handleOnSelect(item);
    },
    [handleOnSelect, defaultItem]
  );

  useEffect(() => {
    if (filterParam === defaultItem.value) {
      return;
    }

    const existingItem = items.find((item) => exactMatch(item, filterParam));

    if (existingItem) {
      handleResultSelect(itemToResultItem(existingItem));
    } else {
      // If param isn't valid on load, set to default
      handleOnSelect(defaultItem);
    }
  }, [
    handleOnSelect,
    handleResultSelect,
    items,
    filterParam,
    defaultItem,
    itemToResultItem,
    exactMatch,
  ]);

  const onOpen = useCallback(() => {
    dataSource(searchQuery).then(setItems, logError);
  }, [dataSource, searchQuery]);

  useEffect(() => {
    if (filterParam === defaultItem.value) {
      handleOnSelect(defaultItem);
      return;
    }

    const item = items.find((it) => exactMatch(it, filterParam));
    if (!item) {
      return;
    }

    setSelectedItem(itemToResultItem(item));
  }, [
    handleOnSelect,
    items,
    filterParam,
    defaultItem,
    itemToResultItem,
    exactMatch,
  ]);

  const updateResults = useCallback(() => {
    const trimmedQuery = searchQuery.trim().toLowerCase();
    setResultItems(
      items
        .filter((item) => fuzzyMatch(item, trimmedQuery))
        .slice(0, 5)
        .map((item) => itemToResultItem(item))
    );
  }, [fuzzyMatch, itemToResultItem, items, searchQuery]);

  const onQueryChange = useCallback(
    (value: string) => {
      setSearchQuery(value);
      updateResults();
    },
    [updateResults]
  );

  // When async items are updated, we need to update the results
  useEffect(() => {
    updateResults();
  }, [updateResults]);

  const searchMode = useMemo(
    () => ({
      results: resultItems,
      onResultSelect: handleResultSelect,
      onQueryChange,
      placeholder: 'Search',
      emptyQueryMessage: searchPrompt,
      noResultsMessage: `No results found for ${searchQuery}.`,
    }),
    [handleResultSelect, onQueryChange, resultItems, searchQuery, searchPrompt]
  );

  return (
    <UISelect
      dropdownClassName="searchFilterDropdown"
      items={selectItems}
      onOpen={onOpen}
      onSelect={handleOnSelect}
      searchMode={searchMode}
      selectedValue={selectedItem.value}
      selectorClassName="searchFilterSelector"
    />
  );
};
