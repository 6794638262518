import React, { FC } from 'react';
import { pluralize } from '../../../../../scripts/utils';
import { UIIcon, UIIcons } from '../../../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../../../controls/ui/UIToolTip/UIToolTip';

interface CountIndicatorProps {
  count: number;
  name: string;
  icon: UIIcons;
}

export const CountIndicator: FC<CountIndicatorProps> = ({
  count,
  name,
  icon,
}) => {
  if (count === 0) {
    return null;
  }

  return (
    <UITooltip title={`${count} ${pluralize(name, count)}`}>
      <span className="countIndicator">
        <UIIcon name={icon} size={12} />
        &nbsp;{count}
      </span>
    </UITooltip>
  );
};
