import React from 'react';
import { PageSearchResultItem } from '../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../resultProps';

export const ServiceNowKnowledgeArticle: React.FC<ResultProps> = ({
  result,
}) => {
  return (
    <PageSearchResultItem
      appName="ServiceNow Article"
      result={result}
      showLastItemBreadcrumb
    />
  );
};
