import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Toasts.scss';
import { useGlobalState } from '../../../scripts/hooks';
import { ToastItem } from './ToastItem';

export const Toasts: React.FC = () => {
  const toastsState = useGlobalState((state) => state.toast);

  const toasts = toastsState.toasts.map((toast) => {
    return (
      <CSSTransition classNames="toastState" key={toast.uid} timeout={100}>
        <ToastItem toast={toast} />
      </CSSTransition>
    );
  });

  return (
    <TransitionGroup className="toastsContainer">{toasts}</TransitionGroup>
  );
};
