import './MembersTable.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppState } from '../../../constants';
import { DomainVerificationStatus } from '../../../models/User';
import { batch } from '../../../redux/store';
import { getMembers } from '../../../scripts/apis';
import {
  useBoolState,
  useSticky,
  useToaster,
  useUserSafe,
} from '../../../scripts/hooks';
import { MemberStatus, OrgMember } from '../../../scripts/models/org-member';
import { debounce, logError } from '../../../scripts/utils';
import { Loading, LoadingSize } from '../../controls/Loading/Loading';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import {
  handleMemberAction,
  MemberActionButtonsRow,
  MemberActions,
} from '../MemberActionButtonsRow/MemberActionButtonsRow';
import { MembersTableRow } from '../MembersTableRow/MembersTableRow';

// eslint-disable-next-line max-lines-per-function
export const MembersTable: React.FC<{ filterStatus?: MemberStatus }> = ({
  filterStatus,
}) => {
  const [page, setPage] = useState(1);
  const authenticatedUser = useUserSafe();

  const [searchQuery, setSearchQuery] = useState('');
  const applySticky = useSticky();
  const [members, setMembers] = useState<OrgMember[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userCheckedStatus, setUserCheckedStatus] = useState<
    Record<string, boolean>
  >({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((q: string, searchPage: number) => {
      setIsLoading(true);
      getMembers(q, searchPage, filterStatus).then(
        ({ members: rMembers, total: rTotal }) => {
          batch(() => {
            if (searchPage > 1) {
              setMembers((oldMembers) => oldMembers.concat(rMembers));
            } else {
              setMembers(rMembers);
            }

            setTotal(rTotal);
            setIsLoading(false);
          });
        },
        logError
      );
    }, 100),
    []
  );

  const onSearchQueryChange = useCallback((newQuery: string) => {
    setPage(1);
    setSearchQuery(newQuery);
  }, []);

  const [isProcessing, setProcessing, setNotProcessing] = useBoolState(false);
  const toaster = useToaster();
  const handleEmailInvite = useCallback(() => {
    setProcessing();
    handleMemberAction(MemberActions.SEND_INVITE, {
      emails: searchQuery.split(/[\s,]+/).map((email) => email.trim()),
    })
      .then(() => {
        setNotProcessing();
        setSearchQuery('');
        toaster.success('Invited Successfully');
      })
      .catch((error) => {
        logError(error);
        setNotProcessing();
        toaster.failure();
      });
  }, [searchQuery, setNotProcessing, setProcessing, toaster]);

  useEffect(() => {
    debouncedSearch(searchQuery, page);
  }, [debouncedSearch, page, searchQuery]);

  const onMore = useCallback(() => {
    setPage((p) => p + 1);
  }, []);

  const allowedDomains = useUserSafe((u) => u.orgByOrgId.allowedDomains.nodes);
  const verifiedAllowedDomains = useMemo(() => {
    return allowedDomains
      .filter(({ status }) => status === DomainVerificationStatus.VERIFIED)
      .map(({ domain }) => domain);
  }, [allowedDomains]);

  const areValidEmails = useMemo(() => {
    const emails = searchQuery.split(/[\s,]+/).map((email) => email.trim());
    return emails.every((email) =>
      verifiedAllowedDomains.some((domain) => email.endsWith(`@${domain}`))
    );
  }, [searchQuery, verifiedAllowedDomains]);

  const validDomainsString = useMemo(() => {
    if (verifiedAllowedDomains.length === 0) {
      return '';
    }

    const lastDomain =
      verifiedAllowedDomains[verifiedAllowedDomains.length - 1] ?? '';

    const allDomainsExceptLast = verifiedAllowedDomains.slice(0, -1);

    const validDomains = allDomainsExceptLast.length
      ? `${allDomainsExceptLast
          .map((domain) => `@${domain}`)
          .join(', ')} or @${lastDomain}`
      : `@${lastDomain}`;

    return validDomains;
  }, [verifiedAllowedDomains]);

  const selectedUsers = Object.keys(userCheckedStatus).filter(
    (email) => userCheckedStatus[email]
  );

  const emptyMessage = areValidEmails ? (
    ''
  ) : (
    <p className="emptyResultsMessage">
      {searchQuery
        ? `No members matched for '${searchQuery}'`
        : `No results to show`}
    </p>
  );

  const handleMemberChange = useCallback(
    (member: OrgMember, newMember: Partial<OrgMember>) => {
      setMembers(
        members.map((m) => {
          if (member.email !== m.email) {
            return m;
          }

          return {
            ...m,
            ...newMember,
          };
        })
      );
    },
    [members]
  );

  const handleMemberCheckboxChange = useCallback(
    (email: string, checked: boolean) => {
      setUserCheckedStatus({ ...userCheckedStatus, [email]: checked });
    },
    [userCheckedStatus, setUserCheckedStatus]
  );

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const newUserCheckedStatus: Record<string, boolean> = {};
        for (const member of members) {
          if (member.email !== authenticatedUser.email) {
            newUserCheckedStatus[member.email] = true;
          }
        }

        batch(() => {
          setSelectAllChecked(true);
          setUserCheckedStatus(newUserCheckedStatus);
        });
      } else {
        setSelectAllChecked(false);
        setUserCheckedStatus({});
      }
    },
    [authenticatedUser.email, members]
  );

  const googleDirectorySyncStatusCode =
    useUserSafe(
      (user) =>
        user.orgByOrgId.usersAppsByOrg.nodes.find(
          (a) => a.appName === 'gworkspace-directory'
        )?.statusCode
    ) ?? AppState.NotConnected;

  return (
    <div className="membersTable">
      <div className="searchMembers border border-solid border-gray-30 rounded-lg">
        <UITextbox
          autoFocus
          className="textSearchMembers"
          icon={<UIIcon name="search" />}
          onChange={onSearchQueryChange}
          placeholder={
            googleDirectorySyncStatusCode === AppState.NotConnected
              ? 'Type an email address to invite'
              : 'Search for an email address to invite'
          }
          plain
          size="large"
          useClear
          value={searchQuery}
        />
      </div>
      {areValidEmails && members.length === 0 ? (
        <UIButton
          className="inviteButton"
          onClick={handleEmailInvite}
          processing={isProcessing}
          size="small"
          type="primary"
        >
          <UIIcon name="mail" />
          Invite &nbsp; <span className="emailText">{searchQuery}</span>
        </UIButton>
      ) : (
        searchQuery !== '' &&
        members.length === 0 && (
          <div className="inviteEmailMessage">
            Please type valid {validDomainsString} email addresses to invite.
          </div>
        )
      )}
      <table className="basic">
        {members.length > 0 && (
          <thead ref={applySticky}>
            <tr>
              <th>
                {authenticatedUser.admin && (
                  <input
                    checked={selectAllChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                  />
                )}
              </th>
              <th className="colName">Name</th>
              <th className="colEmail">Email</th>
              <th className="colRole">Role</th>
              <th className="colAction">Actions</th>
            </tr>
          </thead>
        )}
        <tbody>
          {(!isLoading || page > 1) &&
            members.map((member) => (
              <MembersTableRow
                checked={userCheckedStatus[member.email] ?? false}
                key={member.email}
                member={member}
                onCheckedChange={handleMemberCheckboxChange}
                onMemberChange={handleMemberChange}
              />
            ))}
        </tbody>
      </table>
      {isLoading && <Loading size={LoadingSize.Medium} />}
      {!isLoading && members.length === 0 && emptyMessage}
      {total > members.length && (
        <UIButton onClick={onMore} type="secondary">
          More
        </UIButton>
      )}
      {selectedUsers.length > 0 &&
        authenticatedUser.admin &&
        !!filterStatus && (
          <div className="selectedActionBar">
            <strong>{selectedUsers.length} users selected</strong>
            <MemberActionButtonsRow
              emails={selectedUsers}
              status={filterStatus}
            />
          </div>
        )}
    </div>
  );
};
