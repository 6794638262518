import React, { FC } from 'react';
import { InstallDialogProps } from '../../scripts/app';
import { OAuthRequirementsDialog } from '../generic/OAuthRequirementsDialog';

const requirements = [
  'You will grant Dashworks read-only access to board and teams',
];

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <OAuthRequirementsDialog
      app={app}
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      requirements={requirements}
    />
  );
};
