import React, { FC, useState } from 'react';
import { sanitizeInline } from '../../../scripts/html';
import { Answer } from '../../../scripts/models/answers';
import { AuthorDetails } from '../../controls/AuthorDetails/AuthorDetails';
import { DateFormat, Timestamp } from '../../controls/Timestamp';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../shadcn/lib/components/popover';

export const AnswerInfoPopover: FC<{
  answer: Answer;
}> = ({ answer }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover onOpenChange={setPopoverOpen} open={popoverOpen}>
      <PopoverTrigger asChild className="hidden sm:block">
        <div
          className="flex"
          onMouseEnter={() => {
            setPopoverOpen(true);
          }}
          onMouseLeave={() => {
            setPopoverOpen(false);
          }}
        >
          <UIIcon name="info" />
        </div>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col w-80 z-[99999]" side="right">
        <div className="flex justify-between">
          <div className="font-medium">Verified Answer</div>
          <div>
            <UIIcon name="answer" size={20} />
          </div>
        </div>
        <div className="flex justify-between mt-2 gap-2">
          <div className="text-cloud-40">Question</div>
          <div>
            {answer.title.length > 20
              ? `${answer.title.slice(0, 20)}...`
              : answer.title}
          </div>
        </div>
        <div className="flex justify-between mt-2 gap-2">
          <div className="text-cloud-40">Answer</div>
          <div className="flex">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitizeInline(answer.content.slice(0, 20)),
              }}
            />
            <div>...</div>
          </div>
        </div>
        <div className="flex justify-between mt-2 gap-2">
          <div className="text-cloud-40">Created by</div>
          <AuthorDetails
            displayName={answer.creator.name}
            icon={answer.creator.icon}
          />
        </div>
        {answer.created_time && (
          <div className="flex justify-between mt-2 gap-2">
            <div className="text-cloud-40">Created on</div>
            <Timestamp
              format={DateFormat.DateOnly}
              unixTime={answer.created_time}
            />
          </div>
        )}
        <div className="flex justify-between mt-2 gap-2">
          <div className="text-cloud-40">Views</div>
          <div>{answer.views}</div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
