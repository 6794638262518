import './ContentSnippet.scss';
import React, { FC } from 'react';
import { HighlightedText } from '../../../controls/HighlightedText/HighlightedText';

export const ContentSnippet: FC<{ content: string }> = ({ content }) => {
  return (
    <div className="contentSnippet">
      <HighlightedText text={content} />
    </div>
  );
};
