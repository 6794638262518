import './GridView.scss';
import React, { useCallback, useRef } from 'react';
import { ArrowNavMode, useArrowNav } from '../../../../../scripts/hooks';
import { PageSearchResult } from '../../../../../scripts/models/page-search-result';
import { PinnedResultsContainer } from '../../PinnedResultsContainer/PinnedResultsContainer';
import { ShowMoreButton } from '../../misc/ShowMoreButton';
import { ResultViewProps } from '../View';
import { GridItem } from './GridItem';

export const GridView: React.FC<ResultViewProps> = ({
  results,
  pinnedResults,
  hasMoreResults,
}) => {
  const showMoreRef = useRef<() => void>();
  const showMoreAction = useCallback(() => {
    showMoreRef.current?.();
  }, []);

  const arrowNav = useArrowNav<HTMLDivElement>(
    '.gridItem',
    showMoreAction,
    ArrowNavMode.Horizontal
  );

  return (
    <div className="resultsGridContainer" ref={arrowNav}>
      {pinnedResults.length > 0 && (
        <PinnedResultsContainer>
          <div className="pinnedGrid">
            {pinnedResults.map((result: PageSearchResult) => (
              <GridItem key={result.objectID} result={result} />
            ))}
          </div>
        </PinnedResultsContainer>
      )}
      <div className="resultGrid">
        {results.map((result) => (
          <GridItem key={result.objectID} result={result} />
        ))}
      </div>
      {hasMoreResults && <ShowMoreButton ref={showMoreAction} />}
    </div>
  );
};
