import { GenericAPI } from './GenericAPI';
import { getGoogleOAuthURL } from './auth';
import { baseRequest, RequestError } from './request';

export interface CreateSSOptions {
  clientId: string;
  clientSecret: string;
  issuerUrl: string;
}

export interface SSOInfo {
  name: string;
  config?: CreateSSOptions;
}

export class SSOUnsupportedError extends Error {
  public override readonly name = 'SSOUnsupportedError';

  public constructor() {
    super('SSO not supported for this organization');
  }
}

interface ProviderResponseValue {
  url: string;
  on_prem_url: string | null;
}

export class SSOApi extends GenericAPI {
  public constructor() {
    super('sso-oidc');
  }

  public async getProviderResponse(
    email: string
  ): Promise<ProviderResponseValue> {
    const url = getGoogleOAuthURL();
    url.searchParams.set('sso', '1');
    url.searchParams.set('email', email);
    try {
      return await baseRequest<ProviderResponseValue>({
        url: url.href,
        mode: 'cors',
      });
    } catch (error) {
      if (error instanceof RequestError && error.statusCode === 501) {
        throw new SSOUnsupportedError();
      }

      throw error;
    }
  }

  public async get(): Promise<SSOInfo> {
    return this.invoke<SSOInfo>();
  }

  public async update(options: CreateSSOptions): Promise<void> {
    await this.invoke({
      method: 'PATCH',
      data: options,
    });
  }

  public async create(options: CreateSSOptions): Promise<void> {
    await this.invoke({
      method: 'POST',
      data: options,
    });
  }

  public async delete(): Promise<void> {
    // Should be `delete` but isn't...
    await this.invoke({
      method: 'DELETE',
    });
  }
}
