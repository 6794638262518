import './PageSearchResultItem.scss';
import classNames from 'classnames';
import React, { FC, ReactNode, RefObject, useContext } from 'react';
import {
  useAllSearchRelatedParams,
  useGlobalState,
  useSearchParam,
} from '../../../../scripts/hooks';
import {
  PageResultAttachment,
  PageSearchResult,
} from '../../../../scripts/models/page-search-result';
import { stripHtml } from '../../../../scripts/page-search/result-utils';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import {
  CollatedContext,
  CollatedResults,
} from '../CollatedResults/CollatedResults';
import { ResultMetadataMatches } from '../ResultMetadataMatches/MetadataMatches';
import { ContentSnippet } from '../misc/ContentSnippet';
import {
  PositionSelector,
  useInstrumentedResultActions,
  usePreviewControl,
} from '../misc/hooks';
import { ResultProps } from '../objectTypes/resultProps';
import { Attachments } from './Attachments/Attachments';
import { CollatedResult } from './CollatedResult/CollatedResult';
import { Footer } from './Footer/Footer';
import { Title } from './TItle/TItle';
import { Actions } from './actions/Actions';

export interface PageSearchResultItemProps extends ResultProps {
  icon?: ReactNode;
  appName: string;

  // Override result name
  name?: string;

  // Whether to include the last item of "filePath" in the breadcrumb
  showLastItemBreadcrumb?: boolean;

  // Optionally provide custom breadcrumbs
  breadcrumbs?: string[] | null;

  // Status for tasks
  status?: string;
  teamName?: string;

  // Messages sent time
  sentTime?: number;

  // Other metadata
  miscMeta?: ReactNode;

  // Customize "Last modified" label
  modifiedLabel?: string;
  hideModified?: boolean;

  /**
   * If specified we will always use the raw content when there is no snippet available.
   */
  rawContentAsSnippetFallback?: boolean;

  /**
   * If specified will open the path of the link on the current website,
   * This will generally only work for system results.
   */
  useLocalNav?: boolean;
}

function getLogoDecoratedTitle({ name, logo, source }: PageSearchResult) {
  if (name && logo && source === 'notion' && !/(http(s?)):\/\//i.test(logo)) {
    return `${logo} ${name}`;
  }

  return name;
}

interface InnerResultContentProps extends ResultProps {
  icon: ReactNode;
  appName: string;
  name: string;
  breadCrumbsUI: ReactNode;
  status?: string;
  teamName?: string;
  sentTime?: number;
  miscMeta: ReactNode;
  modifiedLabel?: string;
  hideModified: boolean;
  contentSnippet: string;
  onAttachmentClick: (attachment: PageResultAttachment) => void;
  visibleRef: (el: HTMLElement | null) => void;
  onCopy: () => void;
}

const InnerResultContent: FC<InnerResultContentProps> = ({
  icon,
  name,
  visibleRef: visibleImpression,
  appName,
  teamName,
  breadCrumbsUI,
  miscMeta,
  result,
  modifiedLabel,
  hideModified,
  sentTime,
  status,
  contentSnippet,
  onAttachmentClick,
  onCopy,
}) => {
  const [query] = useSearchParam();

  return (
    <div className="activeContainer">
      <div className="resultLeft">
        <div className="appIcon">{icon}</div>
      </div>
      <div className="resultRight">
        <div className="titleContainer" ref={visibleImpression}>
          <Title content={name} />
          {status && <div className="status">{status}</div>}
        </div>
        <div className="meta">
          <div className="appName">{appName}</div>
          {teamName && (
            <>
              <div className="divider" />
              <div className="team">{teamName}</div>
            </>
          )}
          {breadCrumbsUI}
          {miscMeta && (
            <>
              <div className="divider" />
              {miscMeta}
            </>
          )}
        </div>
        <ContentSnippet content={contentSnippet} />
        {result.attachments && (
          <Attachments
            attachments={result.attachments}
            maxAttachments={3}
            onClick={onAttachmentClick}
          />
        )}
        {result.metadataMatches && (
          <ResultMetadataMatches metadataMatches={result.metadataMatches} />
        )}
        <Footer
          backLinkCount={result.backLinks.length}
          commentCount={result.num_comments ?? 0}
          hideModified={hideModified}
          missingTerms={result.missingTerms}
          modifiedLabel={modifiedLabel}
          modifiedTime={result.modified_time}
          pin={result.pins?.[0]}
          sentTime={sentTime}
          viewCount={result.views ?? 0}
        />
      </div>
      <Actions onCopy={onCopy} result={result} searchQuery={query} />
    </div>
  );
};

export const BreadcrumbsUI: React.FC<{
  file_path: string[];
  breadCrumbs?: string[] | null;
  showLastItemBreadcrumb?: boolean;
}> = ({ file_path, breadCrumbs, showLastItemBreadcrumb }) => {
  const breadcrumbComponents: ReactNode[] = [];
  const filePath = breadCrumbs ?? [...file_path];

  if (!showLastItemBreadcrumb) {
    filePath.pop();
  }

  // eslint-disable-next-line unicorn/no-array-for-each
  filePath.forEach((breadcrumb: string, i: number) => {
    /*
     * Breadcrumbs are generally static and will never re-order out of order, there is also nothing really unique about them
     * so this is safe to disable.
     */
    // eslint-disable-next-line react/no-array-index-key
    breadcrumbComponents.push(<span key={i * 2}>{breadcrumb}</span>);

    if (i < filePath.length - 1) {
      breadcrumbComponents.push(
        // eslint-disable-next-line react/no-array-index-key
        <UIIcon key={i * 2 + 1} name="arrow-right" />
      );
    }
  });

  return breadcrumbComponents.length > 0 ? (
    <>
      <div className="divider" />
      <div className="breadcrumbs">{breadcrumbComponents}</div>
    </>
  ) : null;
};

export const PageSearchResultItem: React.FC<PageSearchResultItemProps> = ({
  result,
  breadcrumbs,
  teamName,
  miscMeta,
  showLastItemBreadcrumb,
  name = getLogoDecoratedTitle(result),
  modifiedLabel,
  sentTime,
  icon,
  appName,
  hideModified,
  status = result.status,
  rawContentAsSnippetFallback,
  useLocalNav,
}) => {
  const [searchState] = useAllSearchRelatedParams();
  const debugDisplay = useGlobalState((state) => state.debug.resultDebug);

  // Whether to treat this as a collated result
  const collated = useContext(CollatedContext);

  const { handleEnter, handleFocus, handleLeave, isActive } =
    usePreviewControl(result);

  const {
    handleNav,
    elementRefFn,
    onCopy,
    visibleImpression,
    handleAttachmentClick,
  } = useInstrumentedResultActions(result, PositionSelector.List, useLocalNav);

  let contentSnippet = result.content_snippet;
  if (
    !contentSnippet.trim() &&
    rawContentAsSnippetFallback &&
    result.raw_content
  ) {
    contentSnippet = stripHtml(result.raw_content);
  }

  const breadcrumbsUI = (
    <BreadcrumbsUI
      breadCrumbs={breadcrumbs}
      file_path={result.file_path}
      showLastItemBreadcrumb={showLastItemBreadcrumb}
    />
  );

  icon ??= <UIIcon name={result.source} type="apps" />;

  return (
    <>
      <div
        className={classNames('pageSearchResultItem resultItem', {
          previewActive: isActive,
          collated,
        })}
        data-result-id={result.objectID}
        onBlur={handleLeave}
        onClick={handleNav}
        onFocus={handleFocus}
        onKeyDown={handleNav}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        ref={elementRefFn as RefObject<HTMLDivElement>}
        role="button"
        tabIndex={0}
      >
        {collated ? (
          <CollatedResult
            appName={appName}
            applyTrackImpression={visibleImpression}
            breadCrumbsUI={breadcrumbsUI}
            name={name}
            onCopy={onCopy}
            result={result}
            resultIcon={icon}
            searchQuery={searchState.q}
          />
        ) : (
          <InnerResultContent
            appName={appName}
            breadCrumbsUI={breadcrumbsUI}
            contentSnippet={contentSnippet}
            hideModified={!!hideModified}
            icon={icon}
            miscMeta={miscMeta}
            modifiedLabel={modifiedLabel}
            name={name ?? ''}
            onAttachmentClick={handleAttachmentClick}
            onCopy={onCopy}
            result={result}
            sentTime={sentTime}
            status={status}
            teamName={teamName}
            visibleRef={visibleImpression}
          />
        )}
      </div>
      {debugDisplay && (
        <>
          {result.index}
          <pre className="debugInfo">
            {JSON.stringify(result.log, null, '  ')}
          </pre>
        </>
      )}

      <CollatedResults result={result} />
    </>
  );
};
