import React from 'react';
import { Timestamp } from '../../../../../../controls/Timestamp';
import { PersonAvatar } from '../../../../../results/PersonAvatar/PersonAvatar';
import { MetaPair, PreviewItem } from '../../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../../previewProps';

export const SlackMessagePreview: React.FC<PreviewProps> = ({ result }) => {
  const [owner] = result.owners;
  const metaPairs: MetaPair[] = [
    {
      name: 'Sent',
      value: (
        <span className="heavier">
          <Timestamp unixTime={result.modified_time} />
        </span>
      ),
    },
    {
      name: 'Location',
      value: <span className="heavier">{result.channel_name}</span>,
    },
  ];

  return (
    <PreviewItem
      icon="slack"
      metaPairs={metaPairs}
      result={result}
      title={owner?.name ?? 'Unknown'}
      titleIcon={
        owner?.photo && (
          <PersonAvatar imageUrl={owner.photo} source={result.source} />
        )
      }
    />
  );
};
