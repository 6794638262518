import React from 'react';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { PersonAvatar } from '../../../../PersonAvatar/PersonAvatar';
import { ResultProps } from '../../../resultProps';

const PERSONS_LIMIT = 3;

export const SlackMessageResult: React.FC<ResultProps> = ({ result }) => {
  const breadcrumbItems = result.file_path[0]
    ? result.file_path[0].split(', ').map((item) => {
        // Could be a person or a channel
        return item.startsWith('#') ? item : `@${item}`;
      })
    : [];

  let personTrail = breadcrumbItems.join(', ');

  // Show overflow state
  if (breadcrumbItems.length > PERSONS_LIMIT) {
    personTrail = `${breadcrumbItems.slice(0, PERSONS_LIMIT).join(', ')}, +${
      breadcrumbItems.length - PERSONS_LIMIT
    }`;
  }

  const breadcrumbs = [result.team_name!, personTrail];

  return (
    <PageSearchResultItem
      appName="Slack Message"
      breadcrumbs={breadcrumbs}
      hideModified
      icon={<PersonAvatar imageUrl={result.image_url} source={result.source} />}
      name={result.owners[0]?.name}
      result={result}
      sentTime={result.created_time}
      showLastItemBreadcrumb
    />
  );
};
