import React, { useCallback, useEffect, useState } from 'react';
import { trackEvent } from '../../../../../../extra/sharedMethods';
import { pinResult, PinResultArgs } from '../../../../../../scripts/apis';
import { AnalyticsEvent } from '../../../../../../scripts/constants/analytics-event';
import { useToaster, useUserId } from '../../../../../../scripts/hooks';
import { PageSearchResult } from '../../../../../../scripts/models/page-search-result';
import { ResultPin } from '../../../../../../scripts/models/result-pin';
import { UIIconButton } from '../../../../../controls/ui/UIIconButton/UIIconButton';
import { UITooltip } from '../../../../../controls/ui/UIToolTip/UIToolTip';

interface PinResultProps {
  result: PageSearchResult;
  searchQuery: string;
  square?: boolean;
}

export const PinResult: React.FC<PinResultProps> = ({
  result,
  searchQuery,
  square,
}) => {
  const userId = useUserId();

  const isResultPinned = !!result.pins && result.pins.length > 0;
  const [userPinned, setUserPinned] = useState<boolean | undefined>();
  const [interacted, setInteracted] = useState<boolean>(false);
  const toaster = useToaster();

  useEffect(() => {
    // Only set on first render
    if (userPinned !== undefined) {
      return;
    }

    setUserPinned(
      isResultPinned &&
        !!result.pins?.some((pin: ResultPin) => pin.pinner.id === userId)
    );
  }, [isResultPinned, result, userId, userPinned]);

  const onClick = useCallback(() => {
    const args: PinResultArgs = {
      resultObjectId: result.objectID,
      searchQuery,

      // Unpin only if result is pinned already
      unpin: !!userPinned,
    };

    // Show feedback right away so user doesn't have to wait for response
    setUserPinned(!userPinned);

    pinResult(args).catch((error) => {
      // Revert back to original state on failure
      setUserPinned(userPinned);

      toaster.failure();
      throw error;
    });

    // Always show the UI once user has interacted
    setInteracted(true);

    // Analytics event
    const eventAction = isResultPinned
      ? AnalyticsEvent.PinnedResultsUnpinClick
      : AnalyticsEvent.PinnedResultsPinClick;

    trackEvent(eventAction, {
      search_query: searchQuery,
      result_source: result.source,
      result_object_id: result.objectID,
    });
  }, [isResultPinned, result, searchQuery, toaster, userPinned]);

  const tooltip = userPinned
    ? 'Remove pin for your entire team'
    : 'Pin for your entire team';

  // We only show the control if result is not pinned already or if the user has pinned the result
  const showUI =
    !result.pins || result.pins.length === 0 || !!userPinned || interacted;

  return showUI ? (
    <UITooltip title={tooltip}>
      <UIIconButton
        className="pinResult"
        name={userPinned ? 'pin-filled' : 'pin'}
        onClick={onClick}
        size={24}
        square={square}
      />
    </UITooltip>
  ) : null;
};
