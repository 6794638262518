import dompurify from 'dompurify';

/**
 * Converts any anchor tags to use "_blank" target.
 */
dompurify.addHook('afterSanitizeAttributes', (node) => {
  // Makes all links open in new window and adds anonymisation
  if (node.tagName === 'A') {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

const inlineSanitizeConfig: dompurify.Config = {
  FORBID_TAGS: ['p', 'h1', 'h2', 'h3', 'h4', 'h5'],
};

// eslint really doesn't like the next line
// eslint-disable-next-line import/no-unused-modules, prefer-destructuring, @typescript-eslint/unbound-method
export const sanitize = dompurify.sanitize;

export const sanitizeInline = (source: string): string => {
  return dompurify.sanitize(source, inlineSanitizeConfig) as string;
};
