import React, { FC, useCallback, useRef, useState } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { withInstallModal } from '../../hoc/withInstallModal';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';

const HybridInstallDialog = withInstallModal(
  ({ connectInProgress, handleOAuthInstall, app }) => {
    const credentialName = 'API key';
    const textboxRef = useRef<HTMLInputElement>(null);

    const [apiKey, setApiKey] = useState('');

    const handleClickDone = useCallback(async () => {
      await handleOAuthInstall({ apiKey });
    }, [handleOAuthInstall, apiKey]);

    return (
      <>
        <ConnectStep step={1} title={`Generate ${credentialName}`}>
          <p>
            Sign in with the {app.definition.displayName} account you want to
            connect with Dashworks and copy your {credentialName}.
          </p>
          <p>
            You can find the key under{' '}
            <strong>Your Profile &gt; Authentication &gt; API Keys</strong>.
            Follow{' '}
            <a
              href="https://developer.helpscout.com/docs-api/#your-api-key"
              rel="noreferrer"
              target="_blank"
            >
              this
            </a>{' '}
            for more details.
          </p>
          <div className="actionContainer">
            <UIButton href="https://secure.helpscout.net">
              Sign in with {app.definition.displayName}
            </UIButton>
          </div>
        </ConnectStep>
        <ConnectStep step={2} title={`Enter ${credentialName}`}>
          <p>Paste the {credentialName} and click connect.</p>
          <div className="stepField">
            <UITextbox
              className="stepFieldInput"
              onChange={setApiKey}
              placeholder={`Enter ${credentialName}`}
              ref={textboxRef}
              size="large"
              type="password"
              value={apiKey}
            />
          </div>
        </ConnectStep>
        {connectInProgress(handleClickDone)}
      </>
    );
  }
);

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <HybridInstallDialog
      app={app}
      isOrg={isOrg}
      onClose={onClose}
      open={open}
    />
  );
};
