import React from 'react';
import { Timestamp } from '../../../../../controls/Timestamp';
import { MetaPair, PreviewItem } from '../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../previewProps';

export const MSTeamsMessagePreview: React.FC<PreviewProps> = ({ result }) => {
  const [owner] = result.owners;
  const metaPairs: MetaPair[] = [
    {
      name: 'Sent',
      value: (
        <span className="heavier">
          <Timestamp unixTime={result.modified_time} />
        </span>
      ),
    },
    {
      name: 'Location',
      value: <span className="heavier">{result.channel_name}</span>,
    },
  ];

  return (
    <PreviewItem
      icon="msteams"
      metaPairs={metaPairs}
      result={result}
      title={owner?.name ?? 'Unknown'}
    />
  );
};
