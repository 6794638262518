import { makeLazyComponent } from '../../../scripts/utils';

export const LazyUserSettingsPage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "user-settings-page" */ './UserSettingsPage'
      )
    ).UserSettingsPage
);
