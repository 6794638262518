import classNames from 'classnames';
import React, { ReactNode } from 'react';
import './TopHeader.scss';

interface TopHeaderProps {
  hideBorder?: boolean;
  onHomepage?: boolean;

  // For search UI
  search?: ReactNode;
  filterBar?: ReactNode;
  rightControls?: ReactNode;
}

export const TopHeader: React.FC<TopHeaderProps> = ({
  search,
  filterBar,
  hideBorder,
  onHomepage,
  rightControls,
}) => {
  const headerClass = classNames('topHeader', {
    hideBorder,
    transparent: onHomepage,
  });

  return (
    <div className={headerClass}>
      <header>
        <div className="left">{search}</div>
        <div className="right">{rightControls}</div>
      </header>
      {filterBar}
    </div>
  );
};
