import React, { useCallback, useRef, useState } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';
import {
  PostMessage,
  PostMessageEvent,
} from '../../scripts/constants/post-message-event';
import { useUserSafe } from '../../scripts/hooks';
import { openCenterPopup } from '../../scripts/utils';
import { GeneralError } from '../generic/Components';
import { EmailCaptureStep } from './EmailCaptureStep';

function getAppClientId(AppName: string) {
  switch (AppName) {
    case 'onedrive':
      return ONEDRIVE_CLIENT_ID;
    case 'msteams':
      return MSTEAMS_CLIENT_ID;
    case 'outlook':
      return OUTLOOK_CLIENT_ID;
    default:
      return SHAREPOINT_CLIENT_ID;
  }
}

const createAuthUrl = (org: string, app_name: string): string => {
  const client_id = getAppClientId(app_name);

  const url = new URL('https://login.microsoftonline.com');
  url.pathname = `/${org}/adminConsent`;
  url.searchParams.set('client_id', client_id);

  return url.href;
};

const useAdminConsentPopup = (
  app_name: string
): [string, () => Promise<boolean>] => {
  const user = useUserSafe();
  const pollRef = useRef(0);
  const [errorMessage, setErrorMessage] = useState('');

  return [
    errorMessage,
    useCallback(async () => {
      const auth = openCenterPopup(
        createAuthUrl(user.orgByOrgId.domain, app_name),
        'microsoft'
      );

      if (!auth) {
        setErrorMessage('Unable to connect, please check your popup blocker.');
        return false;
      }

      window.addEventListener(
        'message',
        (message: MessageEvent<PostMessage<void>>) => {
          if (message.data.action === PostMessageEvent.AppAuthenticated) {
            auth.close();
          }
        }
      );

      return new Promise<boolean>((resolve) => {
        pollRef.current = setInterval(() => {
          if (auth.closed) {
            // TODO - Fix this to not resolve on user closing the popup
            resolve(true);
            clearInterval(pollRef.current);
          }
        }, 250) as unknown as number;
      });
    }, [user.orgByOrgId.domain, app_name]),
  ];
};

export const AzureInstallModal = withInstallModal(
  ({ connectInProgress, handleAPIInstall, setInProgress, app }) => {
    const [popupError, showPopup] = useAdminConsentPopup(
      app.definition.shortname
    );

    const user = useUserSafe();
    const currentUsername = user.email.split('@').slice(0, -1).join('@');
    const orgDomain = user.orgByOrgId.domain;

    const [email, setEmail] = useState(`${currentUsername}@${orgDomain}`);

    const clickConnect = useCallback(async () => {
      setInProgress(true);
      if (!(await showPopup())) {
        setInProgress(false);
      }

      return handleAPIInstall({
        email,
        marketplace: true,
      });
    }, [email, handleAPIInstall, setInProgress, showPopup]);

    return (
      <>
        <EmailCaptureStep
          additionalDescription={
            <p>
              <ul>
                <li>
                  It must be on <strong>@{orgDomain}</strong> domain.
                </li>
                <li>
                  It must have Admin privileges in Azure Active Directory.
                  Dashworks will request read-only access.
                </li>
              </ul>
            </p>
          }
          onChange={setEmail}
          step={1}
          value={email}
        />
        <p className="firstNotice">
          <strong>
            Unable to connect {app.definition.displayName} to Dashworks? Make
            sure your Global Administrator has allowed user consent for
            third-party apps. You may follow these steps:
          </strong>
          <ol>
            <li>
              Log into{' '}
              <a
                href="https://portal.azure.com/"
                rel="noreferrer"
                target="_blank"
              >
                Azure Portal
              </a>{' '}
              as a Global Administrator.
            </li>
            <li>
              Go to Enterprise applications &gt; User settings &gt; Consent and
              permissions.
            </li>
            <li>Enable “Allow user consent for apps” and click Save.</li>
          </ol>
        </p>
        {popupError && <GeneralError text={popupError} />}

        {connectInProgress(clickConnect)}
      </>
    );
  }
);
