import React, { FC } from 'react';
import { PageHeader } from '../../admin/PageHeader/PageHeader';
import { UIButton, UIButtonType } from '../ui/UIButton/UIButton';
import { Dialog } from './Dialog';

export const enum ConfirmMode {
  None,
  Delete,
  Discard,
  Confirm,
}

const titlePrefixFromMode = (mode: ConfirmMode) => {
  switch (mode) {
    case ConfirmMode.None:
      return '';
    case ConfirmMode.Delete:
      return 'Delete';
    case ConfirmMode.Discard:
      return 'Discard';
    case ConfirmMode.Confirm:
      return 'Confirm';
  }
};

interface ConfirmDialogProps {
  title?: string;
  subTitle?: string;
  mode: ConfirmMode;
  verb?: string;
  confirmButtonType?: UIButtonType;
  subject: string;
  confirmButtonText?: string;
  onConfirm(): void;
  onCancel(): void;
}
export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  onCancel,
  onConfirm,
  confirmButtonType = 'primary',
  mode,
  subject,
  verb = titlePrefixFromMode(mode),
  title = `${verb} ${subject}`,
  confirmButtonText = titlePrefixFromMode(mode),
  subTitle = `Are you sure you want to ${verb.toLowerCase()} this ${subject.toLowerCase()}?`,
}) => {
  return (
    <Dialog
      className="confirmDialog"
      content={<PageHeader subTitle={subTitle} title={title} />}
      footer={
        <UIButton onClick={onConfirm} type={confirmButtonType}>
          {confirmButtonText}
        </UIButton>
      }
      footerFirst={mode === ConfirmMode.Delete || mode === ConfirmMode.Discard}
      onClose={onCancel}
      open={mode !== ConfirmMode.None}
      showCancel
      showClose={false}
    />
  );
};
