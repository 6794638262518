import './PageHeader.scss';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

export const PageHeader: React.FC<{
  title: string;
  subTitle?: ReactNode;
  secondaryControl?: ReactNode;
  withBorder?: boolean;
  topMargin?: boolean;
}> = ({ title, subTitle, secondaryControl, withBorder, topMargin }) => {
  return (
    <div className={classNames('pageHeader', { withBorder, topMargin })}>
      <div className="headerLeft">
        <h4 className="title">{title}</h4>
        <span className="subTitle">{subTitle}</span>
      </div>
      {secondaryControl}
    </div>
  );
};
