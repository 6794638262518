/* eslint-disable max-params */
import { UploadedFile } from '../../models/File';
import { Sources } from '../../models/User';
import { ConnectedApps } from '../hooks/sortedInstantApps';
import { Answer } from '../models/answers';
import { areArraysEqual } from '../utils';

export const DefaultSources: Sources = {
  skills: {
    search_all: true,
    filters: null,
  },
  files: {
    search_all: true,
    filters: null,
  },
  answers: {
    search_all: true,
    filters: null,
  },
  contextual_files: [],
};

export const checkAiOnlyModeFromSources = (sources: Sources): boolean => {
  return (
    !sources.skills.search_all &&
    sources.skills.filters?.length === 0 &&
    !sources.files.search_all &&
    sources.files.filters?.length === 0 &&
    !sources.answers.search_all &&
    sources.answers.filters?.length === 0
  );
};

export const checkWebModeFromSources = (sources: Sources): boolean => {
  return (
    !sources.skills.search_all &&
    sources.skills.filters?.length === 1 &&
    sources.skills.filters[0]?.app_name === 'web' &&
    !sources.files.search_all &&
    sources.files.filters?.length === 0 &&
    !sources.answers.search_all &&
    sources.answers.filters?.length === 0
  );
};

export const getSourcesFromFilters = (
  isBaseLLM: boolean,
  skillFilters: ConnectedApps[],
  fileFilters: UploadedFile[],
  answerFilters: Answer[],
  allSkills: ConnectedApps[],
  allFiles: UploadedFile[],
  allAnswers: Answer[],
  contextualFiles: string[]
): Sources => {
  let sources: Sources;

  if (contextualFiles.length > 0) {
    sources = {
      skills: {
        search_all: false,
        filters: [],
      },
      files: {
        search_all: false,
        filters: [],
      },
      answers: {
        search_all: false,
        filters: [],
      },
      contextual_files: contextualFiles,
    };
  } else if (isBaseLLM) {
    // AI only mode
    sources = {
      skills: {
        search_all: false,
        filters: [],
      },
      files: {
        search_all: false,
        filters: [],
      },
      answers: {
        search_all: false,
        filters: [],
      },
      contextual_files: [],
    };
  } else if (skillFilters.length === 1 && skillFilters[0]?.appName === 'web') {
    // Web mode
    sources = {
      skills: {
        search_all: false,
        filters: [{ app_id: 0, app_name: 'web' }],
      },
      files: {
        search_all: false,
        filters: [],
      },
      answers: {
        search_all: false,
        filters: [],
      },
      contextual_files: [],
    };
  } else {
    // Knowledge mode
    const allSkillsSelected =
      areArraysEqual(skillFilters, allSkills, (a, b) => a.id! - b.id!) &&
      !skillFilters.some(
        (skillFilter) => skillFilter.appSpecificFilters !== undefined
      );

    const allFilesSelected = areArraysEqual(
      fileFilters,
      allFiles,
      (a, b) => a.created_at - b.created_at
    );

    const allAnswersSelected = areArraysEqual(
      answerFilters,
      allAnswers,
      (a, b) => a.created_time - b.created_time
    );

    sources = {
      skills: {
        search_all: allSkillsSelected,
        filters: allSkillsSelected
          ? null
          : skillFilters.map((skill) => ({
              app_id: skill.id!,
              app_name: skill.appName,
              app_details: skill.appSpecificFilters,
            })),
      },
      files: {
        search_all: allFilesSelected,
        filters: allFilesSelected ? null : fileFilters.map((file) => file.id),
      },
      answers: {
        search_all: allAnswersSelected,
        filters: allAnswersSelected
          ? null
          : answerFilters.map((answer) => answer.id),
      },
      contextual_files: [],
    };
  }

  return sources;
};
