import React, { useCallback } from 'react';
import './Attachment.scss';
import { PageResultAttachment } from '../../../../../scripts/models/page-search-result';
import { FileTypeIcon } from '../../../../controls/FileTypeIcon/FileTypeIcon';
import { HighlightedText } from '../../../../controls/HighlightedText/HighlightedText';
import { UIButton } from '../../../../controls/ui/UIButton/UIButton';

export const ResultAttachment: React.FC<{
  attachment: PageResultAttachment;
  onClick: (attachment: PageResultAttachment) => void;
}> = ({ attachment, onClick }) => {
  const openUrl = useCallback(() => {
    // Do not trigger result click
    window.open(attachment.url);
    onClick(attachment);
  }, [attachment, onClick]);

  return (
    <UIButton className="resultAttachment" onClick={openUrl} type="secondary">
      <FileTypeIcon fileName={attachment.name ?? attachment.filename} />
      <span className="attachmentName">
        <HighlightedText
          text={attachment.name ?? attachment.filename ?? 'Unnamed'}
        />
      </span>
    </UIButton>
  );
};
