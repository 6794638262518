/* eslint-disable no-restricted-imports */
import { configureStore } from '@reduxjs/toolkit';
import React, { createContext, FC } from 'react';
import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook,
  ReactReduxContextValue,
  Provider as ReduxProvider,
} from 'react-redux';
import { Dispatch, Store } from 'redux';
import { isWebStaging } from '../scripts/utils';
import { Action } from './actions';
import { rootReducer } from './reducers';
import { GlobalState } from './state';

export const store = configureStore({
  reducer: rootReducer,
  devTools: isWebStaging(),
});

export type Dispatcher = Dispatch<Action>;

const storeContext = createContext(
  {} as ReactReduxContextValue<GlobalState, Action>
);

interface ProviderProps {
  storeOverride?: Store<GlobalState, Action>;
}

export const Provider: FC<ProviderProps> = ({ children, storeOverride }) => {
  return (
    <ReduxProvider context={storeContext} store={storeOverride ?? store}>
      {children}
    </ReduxProvider>
  );
};

export const useDispatch = createDispatchHook(storeContext);
export const useSelector = createSelectorHook(storeContext);
export const useStore = createStoreHook(storeContext);

export { batch } from 'react-redux';
