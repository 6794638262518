import { FC } from 'react';
import { AppDefinition, FINCH_APPS } from '../apps/definition';
import { disconnectApp } from './apis';
import { invokeFastApi } from './apis/fastapi';
import { PostMessage, PostMessageEvent } from './constants/post-message-event';

export interface InstallCallbackArgs {
  reload?: boolean;
  isOrg?: boolean;
  subdomain?: string;
  apiKey?: string;
  clientId?: string;
  clientSecret?: string;
  hr_provider?: string;
  username?: string;
  password?: string;
  appId?: number;
  v2?: boolean;
}

export interface InstallDialogProps {
  app: App;
  appId?: number;
  isOrg: boolean;
  open: boolean;
  onClose: () => void;
}

export class AppAuthenticationError extends Error {
  public constructor(public override message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'AppAuthenticationError';
  }
}

export type InstallDialog = FC<InstallDialogProps>;

export class App {
  public constructor(public readonly definition: AppDefinition) {}
  public async uninstall(isOrg = false, id?: number): Promise<void> {
    await disconnectApp(this.definition.shortname, isOrg, id);
  }

  public async install(
    isOrg: boolean,
    args: InstallCallbackArgs = {}
  ): Promise<void> {
    const path = `/oauth/${this.definition.shortname}`;
    const params = {} as Record<string, string>;
    if (isOrg) {
      params.admin = 'true';
    }

    for (const [key, value] of Object.entries(args)) {
      if (!value) {
        continue;
      }

      params[key] = String(value);
    }

    if (FINCH_APPS.has(this.definition.shortname)) {
      params.hr_provider = this.definition.shortname;
    }

    const { url } = await invokeFastApi<{ url: URL }>({
      path,
      method: 'PUT',
      body: params,
    });

    return new Promise((resolve, reject) => {
      const oauthWindow = window.open(url, this.definition.shortname);

      const installListener = (message: MessageEvent<PostMessage<void>>) => {
        if (message.origin !== 'https://www.dashworks.ai') {
          return;
        }

        if (message.data.action === PostMessageEvent.AppAuthenticationFailure) {
          oauthWindow?.close();
          reject(
            new AppAuthenticationError(
              message.data.error ?? 'App authentication failed'
            )
          );

          window.removeEventListener('message', installListener);
          return;
        }

        if (message.data.appName !== this.definition.shortname) {
          return;
        }

        if (message.data.action === PostMessageEvent.AppAuthenticated) {
          oauthWindow?.close();
          window.removeEventListener('message', installListener);
          resolve();
        }
      };

      window.addEventListener('message', installListener);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getInstallDialog(_isOrg: boolean): InstallDialog | null {
    return null;
  }
}
