import React, { FC, useCallback } from 'react';
import { useSearchParam } from '../../../../../scripts/hooks/query';
import { escapeRegExp, intersperse } from '../../../../../scripts/utils';
import './MissingWords.scss';

interface MissingWordProps {
  word: string;
}

const MakeWordRequired: FC<MissingWordProps> = ({ word }) => {
  const [search, setSearch] = useSearchParam();

  const handleClick = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      // Replace word only once using word boundary, also match quotes to avoid double quoting
      setSearch(
        search.replace(
          new RegExp(`"?\\b${escapeRegExp(word)}\\b"?`),
          `"${word}"`
        )
      );
    },
    [search, setSearch, word]
  );

  return (
    <a href="#" onClick={handleClick}>
      {word}
    </a>
  );
};

interface MissingWordsProps {
  missing: string[];
  showRequire?: boolean;
}

export const MissingWords: FC<MissingWordsProps> = ({
  missing,
  showRequire = true,
}) => {
  return (
    <div className="missingWords">
      <span className="missingWords">
        Missing:{' '}
        {intersperse(
          missing.map((m) => <del key={m}>{m}</del>),
          ', '
        )}
      </span>
      {showRequire && (
        <>
          {' '}
          Require:{' '}
          {intersperse(
            missing.map((m) => <MakeWordRequired key={m} word={m} />),
            ', '
          )}
        </>
      )}
    </div>
  );
};
