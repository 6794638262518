import React, { useState } from 'react';
import { useFlag, useToaster } from '../../../scripts/hooks';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { useQAFiles } from './QAFilesContext';
import { isQAFileTypeSupported } from './utils';

interface QAFileDragNDDropProps {
  children: React.ReactNode;
}

const preventDefault = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

export const QAFileDragNDrop = ({
  children,
}: QAFileDragNDDropProps): JSX.Element => {
  const [isOver, setIsOver] = useState(false);
  const toaster = useToaster();

  const { uploadFiles } = useQAFiles();
  const conversationFilesEnabled = useFlag('conversationFilesEnabled');

  const onFilesDrop = (files: FileList) => {
    const supportedFiles: File[] = [];
    const notSupportedFiles: File[] = [];

    for (const file of files) {
      if (isQAFileTypeSupported(file)) {
        supportedFiles.push(file);
      } else {
        notSupportedFiles.push(file);
      }
    }

    if (supportedFiles[0]) {
      uploadFiles(supportedFiles);
    }

    if (notSupportedFiles.length) {
      const notSupportedFileTypes = Array.from(
        new Set(notSupportedFiles.map((file) => file.name.split('.').pop()))
      );

      toaster.failure(
        `${notSupportedFileTypes.join(', ')} file type${
          notSupportedFileTypes.length === 1 ? ' is ' : 's are'
        } not supported`
      );
    }
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefault(e);
    onFilesDrop(e.dataTransfer.files);
    setIsOver(false);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    preventDefault(e);
    if (!conversationFilesEnabled) return;
    setIsOver(true);
  };

  return (
    <div
      onDragLeave={(e) => {
        preventDefault(e);
        if (!conversationFilesEnabled) return;
        setIsOver((prev) => prev || false);
      }}
      onDragOver={onDragOver}
    >
      {isOver && (
        <div
          className="absolute top-0 left-0 bottom-0 right-0 text-white bg-gray-60 opacity-90 z-[150] flex flex-col justify-center items-center gap-2"
          onDragLeave={(e) => {
            preventDefault(e);
            setIsOver(false);
          }}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <UIIcon
            className="text-white w-20 h-20 mb-3"
            name="files"
            size={32}
            type="ui"
          />
          <div className="font-medium text-2xl text-center px-4">
            Drop your file to ask questions about it
          </div>

          <UIIcon
            className="text-white mb-3 absolute top-5 right-5 cursor-pointer"
            name="cross"
            onClick={() => {
              setIsOver(false);
            }}
            size={32}
            type="ui"
          />
        </div>
      )}

      {children}
    </div>
  );
};
