import { Toast } from '../../scripts/models/toast';
import { Action } from '../actions';

export enum ToastAction {
  ADD_TOAST = 'TOAST_ADD_TOAST',
  REMOVE_TOAST = 'TOAST_REMOVE_TOAST',
}

export type ToastInit = Omit<Toast, 'uid'> & { uid?: string };

export function addToast(payload: ToastInit): Action {
  return {
    type: ToastAction.ADD_TOAST,
    payload,
  };
}

export function removeToast(toastUid: string): Action {
  return {
    type: ToastAction.REMOVE_TOAST,
    payload: toastUid,
  };
}
