import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { trackEvent } from '../../../extra/sharedMethods';
import { StaticAnswerType } from '../../../models/QAmodels';
import { useQAController } from '../../../scripts/QAController';
import { updateUser } from '../../../scripts/apis';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useUserId, useUserSafe } from '../../../scripts/hooks';
import { logError } from '../../../scripts/utils';
import { QADivider } from './QADivider';
import { QARecommendedApps } from './QARecommendedApps';
import { QAUseCases } from './QAUseCases';

interface QAOnboardingMessageProps {
  sharable: boolean;
  type?: StaticAnswerType;
}

export const QAOnboardingMessage: FC<QAOnboardingMessageProps> = ({
  sharable,
  type,
}) => {
  const userId = useUserId();
  const hasUserSeenOnboarding = useUserSafe(
    (user) => user.hasSeenWebOnboarding
  );

  const qaController = useQAController();
  const { isNewTopic } = qaController.getIsNewTopic();
  const messages = qaController.useMessages();

  const atLeastOneQuestionAsked = useMemo(
    () => messages.some((qaMessage) => qaMessage.sender === 'USER'),
    [messages]
  );

  const handleOnboardingCompleted = useCallback(() => {
    if (!hasUserSeenOnboarding) {
      trackEvent(AnalyticsEvent.QAOnboardingMessageShown);
      updateUser(userId, { has_seen_web_onboarding: true }).catch((error) => {
        logError(error);
      });
    }
  }, [hasUserSeenOnboarding, userId]);

  useEffect(() => {
    handleOnboardingCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (type !== StaticAnswerType.ONBOARDING_INITAL) {
    return null;
  }

  return (
    <div className="mt-6 flex flex-col gap-6">
      {!sharable && <QADivider hideMargin text="Welcome to Dashworks" />}
      <QAUseCases />
      <QARecommendedApps />
      {!sharable && !isNewTopic && !atLeastOneQuestionAsked ? (
        <QADivider text="Ask Questions" />
      ) : // eslint-disable-next-line unicorn/no-nested-ternary
      atLeastOneQuestionAsked ? (
        <div />
      ) : null}
    </div>
  );
};
