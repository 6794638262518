import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { UploadedFile } from '../../../models/File';
import { UserApp } from '../../../models/User';
import { ConnectedApps } from '../../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../../scripts/models/answers';
import { AnswerComponent } from '../KnowledgeComponents/AnswerComponent';
import { AppComponent } from '../KnowledgeComponents/AppComponent';
import { FileComponent } from '../KnowledgeComponents/FileComponent';
import { KnowledgeSourceTemplate } from './KnowledgeSourceTemplate';

type TKnowledgeSelectMenuTypes = 'answers' | 'apps' | 'files';

interface IKnowledgeSelectProps {
  skillFilters: ConnectedApps[];
  fileFilters: UploadedFile[];
  answerFilters: Answer[];
  setSkillFilters: React.Dispatch<React.SetStateAction<ConnectedApps[]>>;
  setFileFilters: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  setAnswerFilters: React.Dispatch<React.SetStateAction<Answer[]>>;
  allSkills: ConnectedApps[];
  allSkillsUserApps: UserApp[];
  allFiles: UploadedFile[];
  allAnswers: Answer[];
  showConfigureAppSettings?: boolean;
  configureAppSettingsCallback?: (app: ConnectedApps) => void;
}

export const KnowledgeSelect: FC<IKnowledgeSelectProps> = ({
  skillFilters,
  fileFilters,
  answerFilters,
  setSkillFilters,
  setFileFilters,
  setAnswerFilters,
  allSkills,
  allSkillsUserApps,
  allFiles,
  allAnswers,
  showConfigureAppSettings = false,
  configureAppSettingsCallback,
}) => {
  const [menu, setMenu] = useState<TKnowledgeSelectMenuTypes>(
    // eslint-disable-next-line unicorn/no-nested-ternary
    allSkills.length ? 'apps' : allFiles.length ? 'files' : 'answers'
  );

  return (
    <>
      <div className="flex gap-6 pt-1 px-4 text-sm border-b">
        {allSkills.length > 0 && (
          <div
            className={classNames(
              {
                'border-0 border-b-2 border-solid': menu === 'apps',
                'text-gray-50': menu !== 'apps',
              },
              'py-1'
            )}
            onClick={() => {
              setMenu('apps');
            }}
          >
            Apps
          </div>
        )}
        {allFiles.length > 0 && (
          <div
            className={classNames(
              {
                'border-0 border-b-2 border-solid': menu === 'files',
                'text-gray-50': menu !== 'files',
              },
              'py-1'
            )}
            onClick={() => {
              setMenu('files');
            }}
          >
            Files
          </div>
        )}
        {allAnswers.length > 0 && (
          <div
            className={classNames(
              {
                'border-0 border-b-2 border-solid': menu === 'answers',
                'text-gray-50': menu !== 'answers',
              },
              'py-1'
            )}
            onClick={() => {
              setMenu('answers');
            }}
          >
            Answers
          </div>
        )}
      </div>

      {menu === 'apps' && (
        <KnowledgeSourceTemplate<ConnectedApps>
          SourceComponent={AppComponent}
          allSources={allSkills}
          allSourcesUserApps={allSkillsUserApps}
          configureAppSettingsCallback={configureAppSettingsCallback}
          handleSourceCheckChange={(app, prevChecked) => {
            if (prevChecked) {
              setSkillFilters((prev) =>
                prev.filter((item) =>
                  item.id ? item.id !== app.id : item.appName !== app.appName
                )
              );
            } else {
              setSkillFilters((prev) => [...prev, app]);
            }
          }}
          isSourceChecked={(app) =>
            skillFilters.some((item) =>
              item.id ? item.id === app.id : item.appName === app.appName
            )
          }
          setSourceFilters={setSkillFilters}
          showConfigureAppSettings={showConfigureAppSettings}
          sourceFilters={skillFilters}
        />
      )}
      {menu === 'files' && (
        <KnowledgeSourceTemplate<UploadedFile>
          SourceComponent={FileComponent}
          allSources={allFiles}
          handleSourceCheckChange={(file, prevChecked) => {
            if (prevChecked) {
              setFileFilters((prev) =>
                prev.filter((item) => item.id !== file.id)
              );
            } else {
              setFileFilters((prev) => [...prev, file]);
            }
          }}
          isSourceChecked={(file) =>
            fileFilters.some((item) => item.id === file.id)
          }
          setSourceFilters={setFileFilters}
          sourceFilters={fileFilters}
        />
      )}
      {menu === 'answers' && (
        <KnowledgeSourceTemplate<Answer>
          SourceComponent={AnswerComponent}
          allSources={allAnswers}
          handleSourceCheckChange={(answer, prevChecked) => {
            if (prevChecked) {
              setAnswerFilters((prev) =>
                prev.filter((item) => item.id !== answer.id)
              );
            } else {
              setAnswerFilters((prev) => [...prev, answer]);
            }
          }}
          isSourceChecked={(answer) =>
            answerFilters.some((item) => item.id === answer.id)
          }
          setSourceFilters={setAnswerFilters}
          sourceFilters={answerFilters}
        />
      )}
    </>
  );
};
