import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { Authenticator } from './Authenticator';
import { Toasts } from './components/controls/Toasts/Toasts';
import { EnvIndicator } from './components/dev/EnvIndicator';
import {
  ConfirmFn,
  NavigationPromptHandler,
} from './components/navigation/NavigationPromptHandler';
import { LoginPage } from './pages/auth/LoginPage/LoginPage';
import { SignupPage } from './pages/auth/SignupPage/SignupPage';
import { setFeatureFlagsFullyLoaded } from './redux/actions';
import { useDispatch } from './redux/store';
import { NotifyAuth } from './scripts/NotifyAuth';
import { getLoginTokenFromLocalStorage } from './scripts/authentication';
import { useLoginTokens, useUser } from './scripts/hooks';
import { RedirectWithCurrentUrl } from './scripts/routing/RedirectWithCurrentUrl';
import { logError, noop } from './scripts/utils';

const LDUpdater: FC = () => {
  const ldClient = useLDClient();
  const user = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFeatureFlagsFullyLoaded(false));
    if (!ldClient || !user?.email || !user.userId) {
      return;
    }

    ldClient
      .identify({
        key: user.userId,
        email: user.email,
      })
      .then(() => {
        dispatch(setFeatureFlagsFullyLoaded(true));
      }, logError);
  }, [ldClient, user?.userId, user?.email, dispatch]);

  return null;
};

export const LoginRouter: FC = () => {
  const confirmFnRef = useRef<ConfirmFn>(noop);

  // This wrapper is needed as `BrowserRouter` does not re-render when `getUserConfirmation` is changed.
  const getUserConfirmation = useCallback<ConfirmFn>((ok, cb) => {
    confirmFnRef.current(ok, cb);
  }, []);

  const tokens = useLoginTokens() ?? getLoginTokenFromLocalStorage();

  return (
    <>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <NotifyAuth target={window}>
            <LDProvider
              clientSideID={LAUNCHDARKLY_CLIENT_ID}
              user={{
                anonymous: true,
              }}
            >
              <>
                <EnvIndicator interactive />
                <LDUpdater />
                <Switch>
                  {tokens ? (
                    <Switch>
                      <Route component={Authenticator} path="/*" />
                    </Switch>
                  ) : (
                    <Switch>
                      <Route component={SignupPage} exact path="/signup" />
                      <Route component={LoginPage} exact path="/login" />
                      <RedirectWithCurrentUrl to="/login" />
                    </Switch>
                  )}
                </Switch>
              </>
            </LDProvider>
          </NotifyAuth>

          <Toasts />
        </QueryParamProvider>
      </BrowserRouter>
      <NavigationPromptHandler ref={confirmFnRef} />
    </>
  );
};
