import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { SkillRetrieved } from '../../../../models/QAmodels';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { useChatReferences } from '../../../../scripts/hooks/chatReferences';
import { Loading } from '../../../controls/Loading/Loading';
import { Pagination } from '../../../controls/ui/Pagination/Pagination';
import { UITextbox } from '../../../controls/ui/UITextbox/UITextbox';
import { referenceName, REFERENCES_PER_PAGE } from '../QAReferenceUtil';
import { ChatReferencesItem } from './ChatReferencesItem';

export const AppTabContent: FC<{
  question_id: string;
  app_count: SkillRetrieved;
}> = ({ question_id, app_count }) => {
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);

  const { loading, chatReferences, fetchChatReferences, totalPages } =
    useChatReferences();

  const appDisplayName = referenceName(app_count.appName);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchChatReferences = useCallback(
    debounce((value?: string) => {
      setPage(1);

      const body = {
        question_id,
        app_name: app_count.appName,
        page: 1,
        references_per_page: REFERENCES_PER_PAGE,
        query: value,
      };

      trackEvent(AnalyticsEvent.QAReferencesSidecarQuerySearch, body);
      fetchChatReferences(body);
    }, 200),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      setQuery(value);
      debouncedFetchChatReferences(value);
    },
    [debouncedFetchChatReferences]
  );

  useEffect(() => {
    fetchChatReferences({
      question_id,
      app_name: app_count.appName,
      page,
      references_per_page: REFERENCES_PER_PAGE,
      query,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="px-6 flex flex-col gap-4">
      <UITextbox
        autoFocus
        defaultWhiteInputField
        onChange={handleSearch}
        placeholder={`Search results in ${appDisplayName}`}
        value={query}
      />

      {loading && <Loading />}

      {!loading && chatReferences.length === 0 && query && (
        <div className="text-gray-50">
          No matching sources found in {appDisplayName}
        </div>
      )}
      {!loading && chatReferences.length > 0 && (
        <div className="flex flex-col gap-2">
          {chatReferences.map((reference) => (
            <ChatReferencesItem
              key={`reference_${reference.reference_id}`}
              question_id={question_id}
              reference={reference}
            />
          ))}
        </div>
      )}

      {!loading && !!totalPages && (
        <div className="absolute w-full bottom-2 left-1/2 transform -translate-x-1/2 px-6 bg-white">
          <Pagination
            eventName={AnalyticsEvent.QAReferencesSidecarNavigatePage}
            eventParams={{
              question_id,
              app_name: app_count.appName,
              references_per_page: REFERENCES_PER_PAGE,
            }}
            eventParamsSensitive={{
              query,
            }}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </div>
  );
};
