import React, { FC, useCallback, useMemo } from 'react';
import { futureApps } from '../../../apps/future';
import { trackEvent } from '../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { FilterDefaults } from '../../../scripts/constants/filters';
import { useAppParam, useSearchParam } from '../../../scripts/hooks';
import { capitalize } from '../../../scripts/utils';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { appSuggestionUrl } from '../../links/links';
import { SearchSuggestionContainer } from './SearchSuggestionContainer';

export const AppRequest: FC = () => {
  const [search] = useSearchParam();
  const [appFilter] = useAppParam();

  const matchingApps = useMemo(() => {
    const matching: string[] = [];
    for (const [regExp, name] of futureApps) {
      const match = regExp.exec(search);
      if (match && match[0]!.length < search.length) {
        matching.push(name);
      }
    }

    return matching;
  }, [search]);

  const handleClick = useCallback(() => {
    trackEvent(AnalyticsEvent.SearchNoticeAppRequestClick, {
      appName: matchingApps[0],
    });
  }, [matchingApps]);

  if (matchingApps.length !== 1 || appFilter !== FilterDefaults.Apps) {
    return null;
  }

  return (
    <SearchSuggestionContainer
      visibleImpression={AnalyticsEvent.SearchNoticeAppRequestView}
    >
      <p>
        Dashworks doesn't support <strong>{capitalize(matchingApps[0])}</strong>{' '}
        connect yet. Request this app and get notified when it becomes
        available.
      </p>
      <UIButton href={appSuggestionUrl} onClick={handleClick} size="small">
        Request app
      </UIButton>
    </SearchSuggestionContainer>
  );
};
