import React, { FC } from 'react';
import {
  IconButtonProps,
  UIIconButton,
} from '../../ui/UIIconButton/UIIconButton';
import './DeleteButton.scss';

export const DeleteButton: FC<Omit<IconButtonProps, 'name' | 'type'>> = (
  props
) => (
  <UIIconButton
    className="deleteButton"
    name="trash-can"
    square
    tooltip="Delete"
    {...props}
  />
);
