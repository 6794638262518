import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import './ExpandableList.scss';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { UIIconButton } from '../UIIconButton/UIIconButton';

interface ExpandableListProps<T> {
  className?: string;
  title: string;
  items: T[];
  ItemComponent: React.ComponentType<{ item: T; index: number }>;
  defaultCollapsed?: boolean;
  maxItems?: number;
  alwaysExpanded?: boolean;
}

export const ExpandableList = <T,>({
  title,
  items,
  className,
  ItemComponent,
  defaultCollapsed = false,
  maxItems = 5,
  alwaysExpanded = false,
}: ExpandableListProps<T>): JSX.Element | null => {
  const [isExpanded, setIsExpanded] = useState(
    alwaysExpanded ? true : !defaultCollapsed
  );

  const handleClick = useCallback(() => {
    trackEvent(AnalyticsEvent.ToggleExpandableList, {
      item: title,
      expanded: !isExpanded,
    });

    setIsExpanded(!isExpanded);
  }, [title, isExpanded]);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return items?.length > 0 ? (
    <div className={classNames('expandableListContainer', className)}>
      <div className="expandableListTitle" onClick={handleClick}>
        {title}
        {!alwaysExpanded && (
          <UIIconButton
            name={isExpanded ? 'arrow-down' : 'arrow-right'}
            onClick={handleClick}
          />
        )}
      </div>
      {isExpanded && (
        <ul className="expandableListItems">
          {items.slice(0, maxItems).map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ItemComponent index={i} item={item} key={`item_${i}`} />
          ))}
        </ul>
      )}
    </div>
  ) : null;
};
