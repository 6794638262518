import React, { ReactNode } from 'react';
import './Notice.scss';

interface NoticeProps {
  title?: ReactNode;
  body: ReactNode;
}

export const Notice: React.FC<NoticeProps> = ({ title, body }) => {
  return (
    <div className="notice">
      {title && <h3 className="title">{title}</h3>}

      <span className="suggestion">{body}</span>
    </div>
  );
};
