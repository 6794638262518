import React, { forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useButtonEmulation } from '../../../../scripts/hooks';
import { IconProps, UIIcon } from '../UIIcon/UIIcon';

export type IconButtonProps = IconProps & {
  link?: string;
};

export const UIIconButton = forwardRef<SVGElement, IconButtonProps>(
  ({ onClick, link, ...props }, ref) => {
    const history = useHistory();
    const handleClick = useCallback(
      (ev: React.SyntheticEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        onClick?.(ev);
        if (link) {
          history.push(link);
        }
      },
      [onClick, link, history]
    );

    const onAction = useButtonEmulation(handleClick);
    return (
      <UIIcon
        {...props}
        onClick={onAction}
        onKeyDown={onAction}
        ref={ref}
        role="button"
        style={{ cursor: 'pointer' }}
        tabIndex={0}
      />
    );
  }
);

UIIconButton.displayName = 'UIIconButton';
