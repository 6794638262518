import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { split } from '@apollo/client/link/core/split';
import { onError } from '@apollo/client/link/error';
import { HttpLink, HttpOptions } from '@apollo/client/link/http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { sha256 } from 'crypto-hash';
import { logError } from './utils';

/** Return a connected graphQL subscription **/
export function setupGraphQLClient(
  loginIdToken: string
): ApolloClient<NormalizedCacheObject> {
  const webSocketLink = new WebSocketLink({
    uri: `wss://${GRAPHQL_ORIGIN}`,
    options: {
      reconnect: true,
      // must be lower than graphql setting
      timeout: 20_000,
      connectionParams: {
        authorization: `Bearer ${loginIdToken}`,
      },
    },
  });

  const persistedQueryHttpLink = createPersistedQueryLink({
    sha256,
  }).concat(
    new HttpLink({
      uri: `https://${GRAPHQL_ORIGIN}`,
      includeExtensions: true,
    })
  );

  const authLink = setContext(
    (_, { headers }: HttpOptions): Partial<HttpOptions> => {
      /*
       * Get the authentication token from wherever you store it
       * return the headers to the context so httpLink can read them
       */
      return {
        headers: {
          ...(headers as object),
          authorization: loginIdToken ? `Bearer ${loginIdToken}` : '',
          dash: 'using-auth-link',
        },
      };
    }
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    webSocketLink,
    authLink.concat(persistedQueryHttpLink)
  );

  const link = onError((error) => {
    if (error.graphQLErrors) {
      logError(error.graphQLErrors);
    }

    if (error.networkError) {
      logError(error.networkError);
    }
  }).concat(splitLink);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
}
