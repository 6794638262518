import React, { useCallback, useRef, useState } from 'react';
import { PageSearchResult } from '../../../../scripts/models/page-search-result';
import './CollatedResults.scss';
import { ResultComponent } from '../../../../scripts/page-search/result-lookup';
import { UIButton } from '../../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';

export const CollatedContext = React.createContext(false);

// Show 4 results before "See all" button
const overflowLimit = 4;

// Show up to 50 maximum
const hardLimit = 50;

export const CollatedResults: React.FC<{ result: PageSearchResult }> = ({
  result,
}) => {
  const [allVisible, setAllVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const onSeeAllClick = useCallback(() => {
    setAllVisible(true);
  }, []);

  const onSeeLessClick = useCallback(() => {
    setAllVisible(false);

    if (containerRef.current) {
      window.scrollTo(0, containerRef.current.scrollTop);
    }
  }, []);

  if (!result.collated) {
    return null;
  }

  const hardLimitResults = result.collated.slice(0, hardLimit);

  const limitedResults = allVisible
    ? hardLimitResults
    : hardLimitResults.slice(0, overflowLimit);

  return (
    <CollatedContext.Provider value>
      <div className="collatedResults" ref={containerRef}>
        {limitedResults.map((collatedResult, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ResultComponent key={i} result={collatedResult} />
        ))}
        {!allVisible && hardLimitResults.length > overflowLimit && (
          <div className="seeAllContainer">
            <UIButton onClick={onSeeAllClick} type="ghost">
              <span>See all with the same title</span>
              <UIIcon name="arrow-down" />
            </UIButton>
          </div>
        )}

        {allVisible && (
          <div className="seeAllContainer">
            <UIButton onClick={onSeeLessClick} type="ghost">
              <span>See less</span>
              <UIIcon name="arrow-up" />
            </UIButton>
          </div>
        )}
      </div>
    </CollatedContext.Provider>
  );
};
