import { AllowedDomain, OrgByOrg, User, UserApp } from '../../models/User';
import {
  setAllowedDomains,
  setAppsData,
  setPartialOrg,
  setPartialUser,
} from '../../redux/actions';
import { Dispatcher } from '../../redux/store';
import { fetchData } from '../apis/fetchData';
import { logDebug } from '../utils';

export enum ManualFetchDataType {
  USER = 'user',
  ORG = 'org',
  APPS = 'apps',
  ALLOWED_DOMAINS = 'allowed_domains',
}

export function useManualDataFetch(
  dispatch: Dispatcher,
  datatype: ManualFetchDataType
): () => Promise<void> {
  return async () => {
    return fetchData(datatype).then((response) => {
      switch (datatype) {
        case ManualFetchDataType.USER: {
          const { user } = response as { user: Partial<User> };
          dispatch(setPartialUser(user));
          break;
        }

        case ManualFetchDataType.ORG: {
          const { org } = response as { org: Partial<OrgByOrg> };
          dispatch(setPartialOrg(org));
          break;
        }

        case ManualFetchDataType.APPS: {
          const payload = response as {
            userApps: UserApp[];
            orgApps: UserApp[];
          };

          dispatch(setAppsData(payload));
          break;
        }

        case ManualFetchDataType.ALLOWED_DOMAINS: {
          const { allowedDomains } = response as {
            allowedDomains: AllowedDomain[];
          };

          dispatch(setAllowedDomains(allowedDomains));
          break;
        }
      }

      logDebug('data set manually for ', datatype, response);
    });
  };
}
