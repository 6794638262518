import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { FINCH_APPS } from '../apps/definition';
import { FirstNotice } from '../apps/generic/Components';
import { Dialog } from '../components/controls/Dialog/Dialog';
import { UIButton } from '../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../components/controls/ui/UIIcon/UIIcon';
import { Notice } from '../components/general/Notice/Notice';
import { integrationInstallMessages } from '../messages';
import { useDispatch } from '../redux/store';
import { AuthCodeArgs, updateAuthCode } from '../scripts/apis';
import { BadRequestError, ErrorCode } from '../scripts/apis/request';
import {
  App,
  AppAuthenticationError,
  InstallCallbackArgs,
  InstallDialogProps,
} from '../scripts/app';
import { useBoolState, useTimeoutCreator, useToaster } from '../scripts/hooks';
import {
  ManualFetchDataType,
  useManualDataFetch,
} from '../scripts/hooks/manual-trigger-hooks';
import { logError } from '../scripts/utils';
import './withInstallModal.scss';

interface WithInstallModalProps {
  handleOAuthInstall: (args?: InstallCallbackArgs) => Promise<void>;
  handleAPIInstall: (args: AuthCodeArgs, autoClose?: boolean) => Promise<void>;
  setInProgress: (value: boolean) => void;
  connectInProgress: (
    handleClickDone: () => Promise<void>,
    disabled?: boolean
  ) => React.ReactNode;
  app: App;
  isOrg: boolean;
}

const NON_ACL_APPS = new Set(['simpplr', 'servicenow', 'gsites', 'custom-api']);

const CommonElements: FC<{ displayName: string; shortname: string }> = ({
  children,
  displayName,
  shortname,
}) => {
  if (shortname === 'website') {
    return <div>{children}</div>;
  }

  // skip the ACL message from install modal
  if (NON_ACL_APPS.has(shortname)) {
    return (
      <>
        <FirstNotice name={displayName} />
        {children}
      </>
    );
  }

  if (FINCH_APPS.has(shortname)) {
    return (
      <>
        <Notice>
          Dashworks syncs specific fields for active employees from{' '}
          <strong>{displayName}</strong>: employee name, email, title,
          department, location, and manager name. No other content or fields are
          synced. The sync can take up to 24 hours and refreshes every 24 hours
          after connection. Learn more{' '}
          <a
            href="https://support.dashworks.ai/getting-started/connect-apps/real-time-api-vs-minimal-indexing"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </Notice>
        <FirstNotice name={displayName} />
        {children}
      </>
    );
  }

  return (
    <>
      <Notice>
        Dashworks will only show each user content they already have access to
        in <strong>{displayName}</strong>. Users will not see anyone else&apos;s
        private content.
      </Notice>
      <FirstNotice name={displayName} />
      {children}
    </>
  );
};

const ConnectButton: FC<{
  handleClickDone: () => Promise<void>;
  disabled?: boolean;
  inProgress: boolean;
  footerRef: RefObject<HTMLElement>;
}> = ({ handleClickDone, inProgress, disabled, footerRef }) => {
  const handleClickDoneInner = useCallback(() => {
    handleClickDone().catch(logError);
  }, [handleClickDone]);

  // HACK: The footer that contains this button renders into isn't ready on the first render
  const [, toggle] = useBoolState(false);
  const timeout = useTimeoutCreator();

  useEffect(() => {
    timeout(toggle, 10);
  }, [footerRef, timeout, toggle]);

  if (!footerRef.current) {
    return null;
  }

  return createPortal(
    <UIButton
      disabled={disabled}
      onClick={handleClickDoneInner}
      processing={inProgress}
      size="large"
    >
      <span>Connect</span>
      <UIIcon name="arrow-right" size={20} />
    </UIButton>,
    footerRef.current
  );
};

/**
 * Wraps component content in a modal dialog with standard close/connect controls.
 */
export const withInstallModal = <T extends object>( // eslint-disable-line @typescript-eslint/ban-types
  Component: FC<T & WithInstallModalProps>
): FC<InstallDialogProps & T> => {
  const Wrapper: FC<InstallDialogProps & T> = ({
    app,
    appId,
    isOrg,
    onClose,
    open,
    ...props
  }) => {
    const { definition } = app;
    const [inProgress, setInProgress] = useState(false);
    const toaster = useToaster();
    const footerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const manualTriggerAppRefresh = useManualDataFetch(
      dispatch,
      ManualFetchDataType.APPS
    );

    // TODO: Refactor this to not use an HoC, this is kind of a hack
    const connectInProgress = useCallback(
      (handleClickDone: () => Promise<void>, disabled?: boolean) => {
        return (
          <ConnectButton
            disabled={disabled}
            footerRef={footerRef}
            handleClickDone={handleClickDone}
            inProgress={inProgress}
          />
        );
      },
      [inProgress]
    );

    const handleOAuthInstall = useCallback(
      async (args: InstallCallbackArgs = {}) => {
        setInProgress(true);
        args.v2 = true;

        if (appId) {
          args.appId = appId;
        }

        try {
          await app.install(isOrg, args);
          await manualTriggerAppRefresh();
          onClose();
        } catch (error) {
          if (error instanceof AppAuthenticationError) {
            toaster.failure(error.message);
          } else {
            toaster.failure(
              `Failed to install ${definition.displayName}, please verify the provided credentials.`
            );
          }

          throw error;
        } finally {
          setInProgress(false);
        }
      },
      [app, appId, definition, isOrg, manualTriggerAppRefresh, onClose, toaster]
    );

    const handleAPIInstall = useCallback(
      async (args: AuthCodeArgs, authClose = true) => {
        setInProgress(true);
        args.v2 = true;

        if (appId) {
          args.appId = appId;
        }

        return updateAuthCode(definition.parent ?? definition.shortname, {
          ...args,
          admin: isOrg,
        })
          .then(manualTriggerAppRefresh)
          .then(
            () => {
              toaster.success(integrationInstallMessages(definition)[0], 6000);

              if (authClose) {
                onClose();
              }
            },
            (error: unknown) => {
              if (
                error instanceof BadRequestError &&
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                error.code === ErrorCode.AppPermissionMismatch
              ) {
                toaster.failure(
                  `Failed to install ${definition.displayName}: ${error.message}`
                );
              } else {
                const field =
                  definition.shortname === 'website'
                    ? 'URL'
                    : definition.shortname === 'custom-api' // eslint-disable-line unicorn/no-nested-ternary
                    ? 'connection name'
                    : 'credentials';

                toaster.failure(
                  `Failed to install ${definition.displayName}, please verify the provided ${field}.`
                );
              }

              throw error;
            }
          )
          .finally(() => {
            setInProgress(false);
          });
      },
      [definition, isOrg, appId, manualTriggerAppRefresh, onClose, toaster]
    );

    return (
      <Dialog
        className="appInstallDialog"
        content={
          <CommonElements
            displayName={definition.displayName}
            shortname={definition.shortname}
          >
            <Component
              app={app}
              connectInProgress={connectInProgress}
              handleAPIInstall={handleAPIInstall}
              handleOAuthInstall={handleOAuthInstall}
              isOrg={isOrg}
              setInProgress={setInProgress}
              {...(props as T)}
            />
          </CommonElements>
        }
        footer={<div ref={footerRef}> </div>}
        onClose={onClose}
        open={open}
        showCancel
        title={
          <>
            <UIIcon name={definition.shortname} type="apps" />
            <h1>Connect {definition.displayName}</h1>
          </>
        }
      />
    );
  };

  return Wrapper;
};
