import classNames from 'classnames';
import React, { FC } from 'react';
import './OnboardingStepContainer.scss';

interface OnboardingStepContainerProps {
  classes?: string;
  title?: string;
  subTitle?: string;
}

export const OnboardingStepContainer: FC<OnboardingStepContainerProps> = ({
  classes,
  children,
  title,
  subTitle,
}) => {
  return (
    <div className={classNames('onboardingStepContainer', classes)}>
      {title && <h1>{title}</h1>}
      {subTitle && <h2>{subTitle}</h2>}
      <div className="container">
        <div className="containerContent">{children}</div>
      </div>
    </div>
  );
};
