import './HomepageLinkDialog.scss';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { HomepageLink } from '../../../scripts/models/homepage-link';
import { isValidUrl } from '../../../scripts/utils';
import { Dialog } from '../../controls/Dialog/Dialog';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import { invalidUrl } from '../../messages';

interface HomepageLinkDialogProps {
  isOrg: boolean;
  open: boolean;
  // Pass this in for edit mode
  editedLink: HomepageLink | undefined;
  onSave(link: string, title: string): void;
  onClose(): void;
}

export const HomepageLinkDialog: FC<HomepageLinkDialogProps> = ({
  open,
  isOrg,
  onSave,
  onClose,
  editedLink,
}) => {
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');

  const dialogTitle = `${editedLink ? 'Edit' : 'New'} ${
    isOrg ? 'company' : 'personal'
  } bookmark`;

  const isValidLink = useMemo(() => isValidUrl(link), [link]);

  const handleSave = useCallback(() => {
    if (!isValidLink) {
      return;
    }

    onSave(link, title);
    onClose();
  }, [link, isValidLink, onSave, title, onClose]);

  // Reset inputs on open
  useEffect(() => {
    if (open) {
      setLink(editedLink?.link ?? '');
      setTitle(editedLink?.title ?? '');
    }
  }, [editedLink, open]);

  const urlHasContent = link.trim().length > 0;

  return (
    <Dialog
      className="homepageLinkDialog"
      content={
        <>
          <UITextbox
            autoFocus
            inputClassName="title"
            label="Title"
            maxLength={256}
            onChange={setTitle}
            placeholder="Give your bookmark a title"
            size="large"
            value={title}
          />
          <UITextbox
            error={!link && urlHasContent ? invalidUrl : undefined}
            inputClassName="link"
            label="Link"
            maxLength={3000}
            onChange={setLink}
            onEnter={handleSave}
            placeholder="https://example.com"
            size="large"
            type="url"
            value={link}
          />
        </>
      }
      footer={
        <UIButton disabled={!isValidLink} onClick={handleSave}>
          Save
        </UIButton>
      }
      onClose={onClose}
      open={open}
      showCancel
      showClose
      title={dialogTitle}
    />
  );
};
