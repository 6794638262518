/* eslint-disable @cspell/spellchecker */
import { IconProps } from '../../controls/ui/UIIcon/UIIcon';

export const acceptedMimeTypes = new Set([
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/vnd.google-apps.document',
  'application/vnd.google-apps.presentation',
  'application/vnd.google-apps.spreadsheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroenabled.12',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/x-vnd.oasis.opendocument.spreadsheet',
  'application/vnd.apple.numbers',
  'text/csv',
  'text/html',
  'text/plain',
  'text/tab-separated-values',
]);

export const isQAFileTypeSupported = (file: File): boolean => {
  return acceptedMimeTypes.has(file.type);
};

export function getIconPropsByFileName(fileName: string): IconProps {
  const extension = fileName.split('.').pop()?.toLowerCase() ?? '';

  switch (extension) {
    case 'doc':
    case 'docx':
    case 'rtf':
      return { name: 'word', type: 'files' };

    case 'xls':
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
    case 'xltm':
    case 'ods':
    case 'numbers':
    case 'tsv':
    case 'csv':
      return { name: 'spreadsheet', type: 'files' };

    case 'ppt':
    case 'pptx':
    case 'odp':
      return { name: 'presentation', type: 'files' };

    case 'pdf':
      return { name: 'adobe-pdf', type: 'files' };

    case 'txt':
      return { name: 'doc', type: 'ui' };

    default:
      return { name: 'file-other', type: 'ui' };
  }
}
