import React, { FC, ReactNode } from 'react';
import installExtensionScreen from '../../../../assets/images/install_extension_chrome.png';
import { useExtensionObserver } from '../../../../scripts/hooks/extension';
import { installExtension } from '../../../../scripts/utils';
import { Dialog } from '../../Dialog/Dialog';
import { UIButton } from '../../ui/UIButton/UIButton';
import { UIIconButton } from '../../ui/UIIconButton/UIIconButton';

interface InstallChromeExtensionDialogProps {
  open: boolean;
  onClose(): void;
}

export const InstallChromeExtensionDialog: FC<
  InstallChromeExtensionDialogProps
> = ({ open, onClose }) => {
  const extensionInstalled = useExtensionObserver();

  return (
    <Dialog
      content={
        <div className="pt-3 pb-2 px-1 flex flex-col items-center gap-8">
          <div className="w-full flex justify-end">
            <UIIconButton name="cross" onClick={onClose} size={24} square />
          </div>
          <div className="flex flex-col gap-4">
            <InstructionItem
              description="This will allow Dashworks to activate and complete your installation"
              number={1}
              title={
                <div>
                  Install extension and Click{' '}
                  <span className="text-amethys-10">‘Keep it’</span> when Chrome
                  shows you the pop-up below
                </div>
              }
            />
            <InstructionItem
              description="Do this from Settings > Personal > Browser Extension"
              number={2}
              title={<div>You can always revert back to your previous tab</div>}
            />
          </div>
          <img src={installExtensionScreen} width={500} />
          <UIButton
            disabled={extensionInstalled}
            onClick={() => {
              installExtension();
              onClose();
            }}
            size="long"
          >
            Click here to install
          </UIButton>
        </div>
      }
      onClose={onClose}
      open={open}
    />
  );
};

interface InstructionItemProps {
  number: number;
  title: ReactNode;
  description: string;
}

const InstructionItem: FC<InstructionItemProps> = ({
  number,
  title,
  description,
}) => {
  return (
    <div className="flex">
      <div className="flex-shrink-0 bg-black w-6 h-6 flex items-center justify-center rounded-full border-2 border-gray-300 text-sm font-semibold mr-3">
        <div className="text-white"> {number}</div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="font-semibold">{title}</div>
        <div className="text-gray-50">{description}</div>
      </div>
    </div>
  );
};
