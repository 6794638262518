import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { useAppDefinitions } from '../../../../../../apps/definition';
import { filterableIndexStates } from '../../../../../../constants';
import { trackEvent } from '../../../../../../extra/sharedMethods';
import { SearchLoadState } from '../../../../../../redux/pageSearch/actions';
import { getStatusCode } from '../../../../../../redux/selectors';
import { AnalyticsEvent } from '../../../../../../scripts/constants/analytics-event';
import {
  FilterDefaults,
  ObjectType,
} from '../../../../../../scripts/constants/filters';
import {
  useAppParam,
  useFilterParam,
  usePageSearch,
  useUserSafe,
} from '../../../../../../scripts/hooks';
import { count as countItems } from '../../../../../../scripts/utils';
import { Loading, LoadingSize } from '../../../../../controls/Loading/Loading';
import { UIButton } from '../../../../../controls/ui/UIButton/UIButton';
import { AppFilterRow } from './AppFilterRow';
import './InPageAppFilter.scss';

const ResetFilters: FC = () => {
  const [appParam, setAppParam] = useAppParam();

  const handleClickReset = useCallback(() => {
    trackEvent(AnalyticsEvent.InPageAppFilterResetClick);
    setAppParam(FilterDefaults.Apps);
  }, [setAppParam]);

  return appParam === FilterDefaults.Apps ? null : (
    <UIButton
      className="reset"
      onClick={handleClickReset}
      size="small"
      type="ghost"
    >
      Reset
    </UIButton>
  );
};

const handleMoreClick = () => {
  trackEvent(AnalyticsEvent.InPageAppFilterMoreAppsClick);
};

interface InPageAppFilterProps {
  reduceRelevantCount?: number;
}

// eslint-disable-next-line import/no-unused-modules
export const InPageAppFilter: FC<InPageAppFilterProps> = ({
  reduceRelevantCount,
}) => {
  const [filterParam] = useFilterParam();
  const [appParam] = useAppParam();
  const counts = usePageSearch((s) => s.hitCounts?.apps ?? []);
  const loading = usePageSearch((s) => s.load !== SearchLoadState.Loaded);
  const user = useUserSafe();

  const appDefinitions = useAppDefinitions();

  const selectable = useMemo(() => {
    const countMap = new Map(counts.map((c) => [c.name, c.count]));

    return Object.values(appDefinitions)
      .filter((app) => {
        if (!app.objectTypes?.includes(filterParam as ObjectType)) {
          return false;
        }

        return filterableIndexStates.has(getStatusCode(user, app));
      })
      .sort(
        (appA, appB) =>
          (countMap.get(appB.shortname) ?? 0) -
          (countMap.get(appA.shortname) ?? 0)
      )
      .sort(
        (appA, appB) =>
          (appB.shortname === appParam ? 1 : 0) -
          (appA.shortname === appParam ? 1 : 0)
      );
  }, [appDefinitions, appParam, counts, filterParam, user]);

  if (selectable.length === 0) {
    return null;
  }

  const relevantCounts = countItems(counts, (c) => !!c.count);

  return (
    <div className="inPageAppFilter">
      <div className="header">
        Apps filter <ResetFilters />
      </div>
      {loading ? (
        <Loading size={LoadingSize.Small} />
      ) : (
        <div className="itemListWrapper">
          <div
            className={classNames('itemList', {
              abridged: relevantCounts < selectable.length,
            })}
            style={
              {
                '--relevant-count': Math.max(
                  relevantCounts - (reduceRelevantCount ?? 0),
                  0
                ),
              } as React.CSSProperties
            }
          >
            {selectable.map((appDef) => (
              <AppFilterRow
                appDef={appDef}
                count={
                  counts.find((d) => d.name === appDef.shortname)?.count ?? 0
                }
                key={appDef.shortname}
                selected={appParam === appDef.shortname}
              />
            ))}
          </div>
          <UIButton
            className="connectMore"
            link={user.admin ? '/apps/org' : '/apps/personal'}
            onClick={handleMoreClick}
            type="secondary"
          >
            Connect more apps
          </UIButton>
        </div>
      )}
    </div>
  );
};
