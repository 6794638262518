import React from 'react';
import {
  createdModifiedMetaPairs,
  pushResultMeta,
} from '../../../../../../../scripts/page-search/result-utils';
import { MetaPair } from '../../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../../previewProps';
import { GeneralTaskPreview } from '../../general/GeneralTaskPreview/GeneralTaskPreview';

export const ZendeskTicketPreview: React.FC<PreviewProps> = ({ result }) => {
  let metaPairs: MetaPair[] = [];

  pushResultMeta(result, metaPairs, 'Org', 'company_name');
  pushResultMeta(result, metaPairs, 'Requester', 'from');
  pushResultMeta(result, metaPairs, 'Group', 'location');

  metaPairs = [...metaPairs, ...createdModifiedMetaPairs(result)];

  return <GeneralTaskPreview metaPairs={metaPairs} result={result} />;
};
