import { useEffect } from 'react';

// https://docs.appcues.com/dev-api-data/appcues-events-reference
export const enum AppcuesEventNames {
  ChecklistItemStarted = 'checklist_item_started',
  ChecklistItemCompleted = 'checklist_item_completed',
}

// https://studio.appcues.com/checklists
export const enum AppcuesChecklistItemLabels {
  InviteYourTeam = 'Invite your team',
  TryASearch = 'Try a search',
}

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var appcuesReady: (callback: () => void) => void;
}

export function useAppcuesEvent(
  eventName: string,
  itemLabel: string,
  callback: () => void
): void {
  useEffect(() => {
    const appcuesEventCallback = (event: AppcuesEvent) => {
      if (event.itemLabel === itemLabel) {
        callback();
      }
    };

    /*
     * https://docs.appcues.com/dev-api-data/javascript-api-developer
     * https://docs.appcues.com/dev-api-data/working-with-client-side-appcues-events-developer
     */
    window.appcuesReady(() => {
      Appcues.on(eventName, appcuesEventCallback);
    });

    return () => {
      window.appcuesReady(() => {
        Appcues.off(eventName, appcuesEventCallback);
      });
    };
  }, [callback, eventName, itemLabel]);
}
