import React from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';

export const InformationOnlyDialog = withInstallModal(
  ({ connectInProgress, handleOAuthInstall: handleClickInstall, children }) => {
    return (
      <>
        {children}

        {connectInProgress(handleClickInstall)}
      </>
    );
  }
);
