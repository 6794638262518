import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  CloudIndexWrapper,
  PageSearchContainer,
} from '../../../containers/PageSearchContainer';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { trackEvent } from '../../../extra/sharedMethods';
import {
  useHasValidSubscriptionPlan,
  useIsWorkspaceSetupNeeded,
  useSubscriptionSummary,
} from '../../../hooks/subscriptionHooks';
import { SubscriptionStatus } from '../../../models/User';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { OnboardingStep } from '../../../scripts/constants/onboarding-step';
import { useRedirectParam, useUserSafe } from '../../../scripts/hooks';
import { useOnboardingStepUpdater } from '../../../scripts/hooks/onboarding';

const shouldUserOnboard = (onboardingStep: OnboardingStep | null): boolean => {
  // If step is unknown, do not onboard

  if (!onboardingStep) {
    return true;
  }

  if (!Object.values(OnboardingStep).includes(onboardingStep)) {
    // Unknown value
    return false;
  }

  return onboardingStep !== OnboardingStep.Completed;
};

export const MenuPage: FC = () => {
  const isWorkspaceSetupNeeded = useIsWorkspaceSetupNeeded();
  const [redirect] = useRedirectParam();

  const onboardingStep = useUserSafe((user) => user.onboardingState.step);
  const [next] = useOnboardingStepUpdater(OnboardingStep.Completed);

  if (isWorkspaceSetupNeeded) {
    return <Redirect to="/workspace-setup" />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (shouldUserOnboard(onboardingStep)) {
    setTimeout(next, 0);
    return <Redirect to={SidebarRoutes.JitQA} />;
  }

  return (
    <EnsureValidSubscriptionPlan>
      <CloudIndexWrapper>
        <PageSearchContainer />
      </CloudIndexWrapper>
    </EnsureValidSubscriptionPlan>
  );
};

const EnsureValidSubscriptionPlan: FC = ({ children }) => {
  const subscription = useSubscriptionSummary();
  const hasValidSubscription = useHasValidSubscriptionPlan();

  useEffect(() => {
    if (subscription?.status === SubscriptionStatus.TRIAL) {
      const trialDaysLeft = subscription.trialEndAt
        ? Math.max(0, moment(subscription.trialEndAt).diff(moment(), 'days'))
        : undefined;

      trackEvent(AnalyticsEvent.SubscriptionTrialWillEnd, {
        trialDaysLeft,
      });
    }
  }, [subscription]);

  if (!hasValidSubscription) {
    return <Redirect to={SidebarRoutes.Billing} />;
  }

  return <div>{children}</div>;
};
