import React, { FC, useCallback } from 'react';
import { setSearchResultDebug } from '../../redux/debug/actions';
import { useDispatch } from '../../redux/store';

export const SearchDebug: FC = () => {
  const dispatch = useDispatch();

  const handleToggle = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setSearchResultDebug(ev.currentTarget.checked));
    },
    [dispatch]
  );

  return (
    <div>
      Show search debug info
      <input onChange={handleToggle} type="checkbox" />
    </div>
  );
};
