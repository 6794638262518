import React from 'react';
import { Timestamp } from '../../../../../../controls/Timestamp';
import { UIIcon } from '../../../../../../controls/ui/UIIcon/UIIcon';
import { MetaPair, PreviewItem } from '../../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../../previewProps';

export const IntercomConversationPreview: React.FC<PreviewProps> = ({
  result,
}) => {
  const [owner] = result.owners;
  const metaPairs: MetaPair[] = [
    {
      name: 'Date',
      value: (
        <span className="heavier">
          <Timestamp unixTime={result.modified_time} />
        </span>
      ),
    },
  ];

  if (result.email && result.email.length > 0) {
    metaPairs.push({
      name: 'Email',
      value: <span className="heavier">{result.email[0]}</span>,
    });
  }

  return (
    <PreviewItem
      icon="intercom"
      metaPairs={metaPairs}
      result={result}
      title={owner?.name.length ? owner.name : result.unhighlighted_name}
      titleIcon={<UIIcon className="prefixIcon" name="person" size={16} />}
    />
  );
};
