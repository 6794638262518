import { Location } from 'history';
import React, { FC, useCallback } from 'react';
import { Prompt, useLocation } from 'react-router-dom';

interface NavigationPrompt {
  ignoreQuery?: boolean;
  block: boolean;
}

/**
 * This wrapped for `react-router`s` `Prompt` that has some sensible defaults and extra options.
 */
export const NavigatePrompt: FC<NavigationPrompt> = ({
  ignoreQuery,
  block,
}) => {
  const location = useLocation();
  const handleNavigateAway = useCallback(
    (newLocation: Location): boolean | string => {
      if (location.pathname === newLocation.pathname && ignoreQuery) {
        return true;
      }

      return 'There are unsaved changed. Are you sure you want to leave?';
    },
    [ignoreQuery, location.pathname]
  );

  return <Prompt message={handleNavigateAway} when={block} />;
};
