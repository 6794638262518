export class TimeoutWatcher {
  private readonly delay_secs: number;
  private _onTimeout?: (error: Error) => void;
  private timerHandle?: NodeJS.Timeout;

  public constructor(_delay_secs: number) {
    this.delay_secs = _delay_secs;
  }

  public onTimeout(cb: (error: Error) => void): void {
    this._onTimeout = cb;
  }

  public start(): void {
    this.timerHandle = setTimeout(() => {
      this._onTimeout?.(new TimeoutError(this.delay_secs));
    }, this.delay_secs * 1000);
  }

  public markAlive(): void {
    if (!this.timerHandle) {
      throw new Error(
        'markAlive() called without an active timer, maybe you forgot to call .start()?'
      );
    }

    clearTimeout(this.timerHandle);
    this.start();
  }

  public end(): void {
    if (!this.timerHandle) {
      throw new Error(
        'end() called without an active timer, maybe you forgot to call .start()?'
      );
    }

    clearTimeout(this.timerHandle);
  }
}

export class TimeoutError extends Error {
  public timeoutSecs: number;
  public constructor(_timeout_secs: number) {
    super(`Timeout occurred after waiting for ${_timeout_secs} secs`);
    this.name = 'TimeoutError';
    this.timeoutSecs = _timeout_secs;
  }
}
