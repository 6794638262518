import React, { MouseEvent, useCallback } from 'react';
import { FileType, ObjectType } from '../../../scripts/constants/filters';
import { useFileTypeParam, useFilterParam } from '../../../scripts/hooks';
import { Notice } from './Notice';

interface FileTypeNoticeProps {
  fileType: FileType;
}

export const FileTypeNotice: React.FC<FileTypeNoticeProps> = ({ fileType }) => {
  const [, setFilter] = useFilterParam();
  const [, setFileType] = useFileTypeParam();
  const onClick = useCallback(
    (ev: MouseEvent<HTMLAnchorElement>) => {
      setFilter(ObjectType.Files);
      setFileType(fileType);
      ev.preventDefault();
    },
    [setFilter, setFileType, fileType]
  );

  return (
    <Notice
      body={
        <>
          Are you looking for presentations? Try{' '}
          <a href="#" onClick={onClick}>
            filtering by {fileType}s
          </a>
          .
        </>
      }
    />
  );
};
