import classNames from 'classnames';
import React, { FC } from 'react';
import { UIIcon, UIIcons } from '../ui/UIIcon/UIIcon';
import './FileTypeIcon.scss';

export const FileTypeIcon: FC<{
  type?: string;
  mimeType?: string;
  fileName?: string;
  size?: number;
  // eslint-disable-next-line complexity
}> = ({ type, mimeType, fileName, size }) => {
  let name: UIIcons = 'file';
  let className = '';
  // Special file icons for specific mime types
  switch (mimeType) {
    // Images
    case 'svg':
    case 'webp':
    case 'png':
    case 'jpg':
      name = 'image';
      break;
    // Archives
    case 'tar':
    case 'gz':
      name = 'archive';
      break;
    case 'xlsx':
      name = 'sheet';
      className = 'sheet';
      break;
    case 'pptx':
      name = 'slide';
      className = 'slide';
      break;
    case 'docx':
      name = 'doc';
      className = 'doc';
      break;
    case 'pdf':
      name = 'pdf';
      className = 'pdf';
      break;
    default:
      break;
  }

  if (fileName) {
    const reExt = /^.+\.([^.]+)$/.exec(fileName);
    const extension = reExt === null ? '' : reExt[1];
    switch (extension) {
      case 'svg':
      case 'webp':
      case 'png':
      case 'jpg':
        name = 'image';
        break;
      case 'zip':
      case 'tar':
      case 'gz':
        name = 'archive';
        break;
      case 'xls':
      case 'xlsx':
        name = 'sheet';
        className = 'sheet';
        break;
      case 'ppt':
      case 'pptx':
        name = 'slide';
        className = 'slide';
        break;
      case 'doc':
      case 'docx':
        name = 'doc';
        className = 'doc';
        break;
      case 'pdf':
        name = 'pdf';
        className = 'pdf';
        break;
      default:
        break;
    }
  }

  switch (type) {
    case 'doc':
      name = 'doc';
      className = 'doc';
      break;
    case 'slide':
      name = 'slide';
      className = 'slide';
      break;
    case 'sheet':
      name = 'sheet';
      className = 'sheet';
      break;
    case 'pdf':
      name = 'pdf';
      className = 'pdf';
      break;
    case 'image':
      name = 'image';
      break;
    case 'folder':
      name = 'folder';
      break;
    case 'other':
      name = 'file-other';
      break;
    default:
      break;
  }

  return (
    <UIIcon
      className={classNames(className, 'fileTypeIcon')}
      name={name}
      size={size}
    />
  );
};
