/* eslint-disable max-params */
import { UploadedFile } from '../../models/File';
import { Workflow } from '../../models/Workflows';
import { ConnectedApps } from '../hooks/sortedInstantApps';
import { Answer } from '../models/answers';
import { checkAiOnlyModeFromSources, checkWebModeFromSources } from './common';

export const getFiltersFromWorkflowSources = (
  workflow: Workflow,
  userEmail: string,
  allSkills: ConnectedApps[],
  allFiles: UploadedFile[],
  allAnswers: Answer[]
): [ConnectedApps[], UploadedFile[], Answer[]] => {
  let skillFilters: ConnectedApps[] = [];

  if (workflow.sources.skills.search_all) {
    skillFilters = allSkills;
  } else {
    const matchedSkills = allSkills.filter((skill) =>
      workflow.sources.skills.filters?.some(
        (skillFilter) => skill.id === skillFilter.app_id
      )
    );

    let unmatchedSkills: ConnectedApps[] = [];

    if (
      workflow.visibility === 'PUBLIC' &&
      workflow.author.email !== userEmail
    ) {
      const remainingPersonalSkills = allSkills.filter(
        (skill) =>
          !skill.isOrg &&
          !matchedSkills.some((matchedSkill) => matchedSkill.id === skill.id)
      );

      const remainingSkillFilterNames = workflow.sources.skills.filters
        ?.filter(
          (skillFilter) =>
            !matchedSkills.some(
              (matchedSkill) => matchedSkill.id === skillFilter.app_id
            )
        )
        .map((skillFilter) => skillFilter.app_name);

      unmatchedSkills = remainingPersonalSkills.filter((skill) =>
        remainingSkillFilterNames?.some(
          (skillFilterName) => skill.appName === skillFilterName
        )
      );
    }

    skillFilters = [...matchedSkills, ...unmatchedSkills];
  }

  const fileFilters = workflow.sources.files.search_all
    ? allFiles
    : allFiles.filter((file) =>
        workflow.sources.files.filters?.some((fileId) => file.id === fileId)
      );

  const answerFilters = workflow.sources.answers.search_all
    ? allAnswers
    : allAnswers.filter((answer) =>
        workflow.sources.answers.filters?.some(
          (answerId) => answer.id === answerId
        )
      );

  return [skillFilters, fileFilters, answerFilters];
};

export const setFiltersFromWorkflowSources = (
  setIsBaseLLM: (value: boolean) => void,
  setSkillFilters: (value: ConnectedApps[]) => void,
  setFileFilters: (value: UploadedFile[]) => void,
  setAnswerFilters: (value: Answer[]) => void,
  workflow: Workflow,
  userEmail: string,
  allSkills: ConnectedApps[],
  allFiles: UploadedFile[],
  allAnswers: Answer[]
): void => {
  if (checkAiOnlyModeFromSources(workflow.sources)) {
    // AI only mode
    setIsBaseLLM(true);
    setSkillFilters([]);
    setFileFilters([]);
    setAnswerFilters([]);
  } else if (checkWebModeFromSources(workflow.sources)) {
    // Web mode
    setIsBaseLLM(false);
    setSkillFilters([{ appName: 'web', displayName: 'Web' }]);
    setFileFilters([]);
    setAnswerFilters([]);
  } else {
    // Knowledge mode
    const [skillSources, fileSources, answerSources] =
      getFiltersFromWorkflowSources(
        workflow,
        userEmail,
        allSkills,
        allFiles,
        allAnswers
      );

    setIsBaseLLM(false);
    setSkillFilters(skillSources);
    setFileFilters(fileSources);
    setAnswerFilters(answerSources);
  }
};
