import * as Sentry from '@sentry/browser';
import { Request as AWS4Request, sign } from 'aws4';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { store } from '../../redux/store';
import { refreshAllTokensOrLogout } from '../authentication';
import { deriveErrorFromFailedRequest } from './request';

export interface InvokeOptions {
  rawPath?: string;
  method?: Method;
  path?: string;
  query?: Record<string, number | string>;
  data?: object;
  retryCount?: number;
}
const MAX_REQUEST_RETRIES = 2;
/**
 * Used to invoke endpoints against AWS API Gateway.
 */
export async function invokeAWSGatewayAPI<T>({
  path = '/',
  method = 'GET',
  data,
  query,
  retryCount = 0,
}: InvokeOptions): Promise<AxiosResponse<T>> {
  const { tokens } = store.getState();
  if (!tokens?.awsTokens) {
    if (retryCount > MAX_REQUEST_RETRIES) {
      throw new Error('tokens not available for api call, retries exceeded');
    }

    await refreshAllTokensOrLogout();
    return invokeAWSGatewayAPI({
      path,
      method,
      data,
      query,
      retryCount: retryCount + 1,
    });
  }

  const credentials = tokens.awsTokens;
  const serialized = data ? JSON.stringify(data) : undefined;
  let versionedPath = `/v1${path}`;
  if (query) {
    versionedPath += `?${new URLSearchParams(
      // Assertion: This handles number values too.
      query as Record<string, string>
    ).toString()}`;
  }

  const request: AWS4Request & AxiosRequestConfig = {
    host: API_ORIGIN,
    method,
    url: `https://${API_ORIGIN}${versionedPath}`,
    // Aws4 looks for body; axios for data
    data: serialized,
    body: serialized,
    service: 'execute-api',
    path: versionedPath,
    region: AWS_REGION,
    headers: data
      ? {
          'Content-Type': 'application/json',
        }
      : undefined,
  };

  sign(request, credentials);
  if (request.headers) {
    // Make linter happy
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete, @typescript-eslint/dot-notation
    delete request.headers['Host'];
    delete request.headers['Content-Length'];
  }

  try {
    return await axios.request<T>(request as AxiosRequestConfig);
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }

    const err = deriveErrorFromFailedRequest(method, path, error.response);

    if (err && err.statusCode !== 404) {
      Sentry.captureException(err);
    }

    if (error.response?.status === 403 && retryCount === 0) {
      await refreshAllTokensOrLogout();
      return invokeAWSGatewayAPI({
        path,
        method,
        data,
        query,
        retryCount: retryCount + 1,
      });
    }

    throw err ?? error;
  }
}
