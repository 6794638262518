import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Bot } from '../../../models/Bots';
import { UploadedFile } from '../../../models/File';
import { UserApp } from '../../../models/User';
import { ConnectedApps } from '../../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../../scripts/models/answers';
import { getFiltersFromBotSources } from '../../../scripts/sources/bot';
import { AnswerComponent } from '../KnowledgeComponents/AnswerComponent';
import { AppComponent } from '../KnowledgeComponents/AppComponent';
import { FileComponent } from '../KnowledgeComponents/FileComponent';
import { BotKnowledgeSourceTemplate } from './BotKnowledgeSourceTemplate';

type TBotKnowledgeDisplayMenuTypes = 'answers' | 'apps' | 'files';

interface IBotKnowledgeDisplayProps {
  bot: Bot;
  botsEnabledApps: ConnectedApps[];
  botsEnabledUserApps: UserApp[];
  allFiles: UploadedFile[];
  allAnswers: Answer[];
}

export const BotKnowledgeDisplay: FC<IBotKnowledgeDisplayProps> = ({
  bot,
  botsEnabledApps,
  botsEnabledUserApps,
  allFiles,
  allAnswers,
}) => {
  const [menu, setMenu] = useState<TBotKnowledgeDisplayMenuTypes>('apps');

  const [botSkills, setBotSkills] = useState<ConnectedApps[]>([]);
  const [botSkillsUserApps, setBotSkillsUserApps] = useState<UserApp[]>([]);
  const [botFiles, setBotFiles] = useState<UploadedFile[]>([]);
  const [botAnswers, setBotAnswers] = useState<Answer[]>([]);

  useEffect(() => {
    const [skillSources, fileSources, answerSources] = getFiltersFromBotSources(
      bot.sources,
      botsEnabledApps,
      allFiles,
      allAnswers
    );

    const skillSourcesUserApps = botsEnabledUserApps.filter((userApp) =>
      skillSources.some((skill) => skill.id === userApp.id)
    );

    setBotSkills(skillSources);
    setBotSkillsUserApps(skillSourcesUserApps);
    setBotFiles(fileSources);
    setBotAnswers(answerSources);

    if (skillSources.length === 0) {
      setMenu(fileSources.length ? 'files' : 'answers');
    }
  }, [allAnswers, allFiles, bot.sources, botsEnabledApps, botsEnabledUserApps]);

  return (
    <>
      <div className="flex gap-6 pt-1 px-4 text-sm border-b">
        {botSkills.length > 0 && (
          <div
            className={classNames(
              {
                'border-0 border-b-2 border-solid': menu === 'apps',
                'text-gray-50': menu !== 'apps',
              },
              'py-1'
            )}
            onClick={() => {
              setMenu('apps');
            }}
          >
            Apps
          </div>
        )}
        {botFiles.length > 0 && (
          <div
            className={classNames(
              {
                'border-0 border-b-2 border-solid': menu === 'files',
                'text-gray-50': menu !== 'files',
              },
              'py-1'
            )}
            onClick={() => {
              setMenu('files');
            }}
          >
            Files
          </div>
        )}
        {botAnswers.length > 0 && (
          <div
            className={classNames(
              {
                'border-0 border-b-2 border-solid': menu === 'answers',
                'text-gray-50': menu !== 'answers',
              },
              'py-1'
            )}
            onClick={() => {
              setMenu('answers');
            }}
          >
            Answers
          </div>
        )}
      </div>

      {menu === 'apps' && (
        <BotKnowledgeSourceTemplate<ConnectedApps>
          SourceComponent={AppComponent}
          allSources={botSkills}
          allSourcesUserApps={botSkillsUserApps}
        />
      )}
      {menu === 'files' && (
        <BotKnowledgeSourceTemplate<UploadedFile>
          SourceComponent={FileComponent}
          allSources={botFiles}
        />
      )}
      {menu === 'answers' && (
        <BotKnowledgeSourceTemplate<Answer>
          SourceComponent={AnswerComponent}
          allSources={botAnswers}
        />
      )}
    </>
  );
};
