import React, { FC, ReactNode } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { trackEvent } from '../../extra/sharedMethods';
import { Bot } from '../../models/Bots';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { OrgMember } from '../../scripts/models/org-member';
import { logDebug } from '../../scripts/utils';
import { RenderSuggestion } from './QAMentionsSuggestions';

interface QAMentionsInputProps {
  autofocus: boolean;
  showPopupFromBottom?: boolean;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  onKeyDown: (event: React.KeyboardEvent) => void;
  handleTextInputChange: (newValue: string) => void;
  dynamicPlaceholder: string;
  searchQuery: string;
  mentions?: string;
  setMentions: (mentions?: string) => void;
  setUserMentions: React.Dispatch<React.SetStateAction<string[]>>;
  bots?: Bot[];
  members: OrgMember[];
  fetchMembersByQuery: (q: string) => Promise<OrgMember[]>;
  botsLoading: boolean;
  shouldShowDashworksBot: boolean;
  selectedBotMention?: string;
  selectedUserMentions: string[];
}

interface MentionData {
  id: string;
  display: string;
}

export const DASHWORKS_BOT_ID = 'internal-dashworks-bot';
const DASHWORKS_BOT = {
  id: DASHWORKS_BOT_ID,
  bot_name: 'Dashworks',
};

const getAllMentions = (
  query: string,
  orgMembers: OrgMember[],
  shouldShowDashworksBot: boolean,
  selectedUserMentions: string[],
  mentionBots?: Bot[],
  selectedBotMention?: string
): { users: MentionData[]; bots: MentionData[] } => {
  const dashworksBot = shouldShowDashworksBot ? [DASHWORKS_BOT] : [];
  const botsMentionsData: MentionData[] = (
    mentionBots ? [...dashworksBot, ...mentionBots] : []
  )
    .map((bot) => ({
      id: bot.id,
      display: bot.bot_name,
    }))
    .filter((mention) =>
      mention.display.toLowerCase().includes(query.toLowerCase())
    );

  logDebug(query);

  const membersMentionsData: MentionData[] = orgMembers
    .map((member) => ({
      id: member.user_id,
      display: member.name ?? member.email,
    }))
    .filter((mention) =>
      mention.display.toLowerCase().includes(query.toLowerCase())
    )
    .filter((mention) => !selectedUserMentions.includes(mention.id))
    .slice(0, 5);

  const isBotSelectionAllowed = selectedUserMentions.length === 0;

  const isMemberSelectionAllowed = !selectedBotMention;

  return {
    users: isMemberSelectionAllowed ? membersMentionsData : [],
    bots: isBotSelectionAllowed ? botsMentionsData : [],
  };
};

export const QAMentionsInput: FC<QAMentionsInputProps> = ({
  autofocus,
  showPopupFromBottom = false,
  inputRef,
  onKeyDown,
  handleTextInputChange,
  dynamicPlaceholder,
  searchQuery,
  mentions,
  setMentions,
  bots,
  members,
  botsLoading,
  fetchMembersByQuery,
  shouldShowDashworksBot,
  setUserMentions,
  selectedBotMention,
  selectedUserMentions,
}) => {
  const [firstBotId, setFirstBotId] = React.useState<string | undefined>(
    undefined
  );

  const getMembersByQuery = async (q: string): Promise<OrgMember[]> => {
    const rMembers = await fetchMembersByQuery(q);
    return rMembers;
  };

  return (
    <MentionsInput
      allowSpaceInQuery
      allowSuggestionsAboveCursor
      autoFocus={autofocus}
      className="bg-transparent block text-base flex-1 border-0 rounded-lg resize-none outline-none w-full break-words"
      // eslint-disable-next-line react/no-unstable-nested-components
      customSuggestionsContainer={(children: ReactNode): ReactNode => (
        <div
          className={`bg-white border border-solid border-gray-30 rounded-md w-72 max-h-[300px] overflow-y-auto !cursor absolute left-2 ${
            showPopupFromBottom ? 'top-0' : 'bottom-0'
          }`}
        >
          {children}
        </div>
      )}
      forceSuggestionsAboveCursor
      inputRef={inputRef}
      onChange={(_, newValue) => {
        handleTextInputChange(newValue);
        if (mentions && !newValue.includes(mentions)) {
          setMentions(undefined);
        }

        setUserMentions((prev) =>
          prev.filter((mention) => newValue.includes(mention))
        );
      }}
      onKeyDown={onKeyDown}
      placeholder={dynamicPlaceholder}
      style={{
        control: {
          border: 'none',
          outline: 'none',
        },
        highlighter: {
          overflow: 'hidden',
          maxHeight: '250px',
        },
        input: {
          margin: 0,
          border: 'none',
          outline: 'none',
          overflow: 'auto',
          maxHeight: '250px',
          scrollbarWidth: 'none',
        },
        suggestions: {
          zIndex: 1000,
        },
      }}
      tabIndex={1}
      value={searchQuery}
    >
      <Mention
        appendSpaceOnAdd
        className="mentions__suggestions"
        data={async (q, cb) => {
          if (botsLoading) {
            cb([]);
            return [];
          }

          let allMentions = getAllMentions(
            q,
            members,
            shouldShowDashworksBot,
            selectedUserMentions,
            bots,
            selectedBotMention
          );

          setFirstBotId(allMentions.bots[0]?.id);

          cb([...allMentions.users, ...allMentions.bots]);

          const rMembers = await getMembersByQuery(q);
          allMentions = getAllMentions(
            q,
            rMembers,
            shouldShowDashworksBot,
            selectedUserMentions,
            bots,
            selectedBotMention
          );

          setFirstBotId(allMentions.bots[0]?.id);

          cb([...allMentions.users, ...allMentions.bots]);

          return [...allMentions.users, ...allMentions.bots];
        }}
        displayTransform={(_, display) => ` @${display} `}
        markup="@{{__display__}}{{__id__}}"
        onAdd={(id) => {
          if (id === DASHWORKS_BOT_ID) {
            setMentions(String(id));
            trackEvent(AnalyticsEvent.MentionedDashworksBot, { bot_id: id });
          }

          const bot = bots?.find((b) => b.id === id);
          if (bot) {
            setMentions(String(id));
            trackEvent(AnalyticsEvent.MentionedBot, { bot_id: id });
          } else {
            setUserMentions((prev) => [...prev, String(id)]);
            trackEvent(AnalyticsEvent.MentionedUser, { user_id: id });
          }
        }}
        renderSuggestion={(
          entry,
          _search,
          highlightedDisplay,
          _index,
          focused
        ) => {
          return (
            <RenderSuggestion
              bots={bots}
              entry={entry}
              firstBotId={firstBotId}
              focused={focused}
              highlightedDisplay={highlightedDisplay}
              index={_index}
              members={members}
            />
          );
        }}
        style={{
          backgroundColor: '#C9FFFF',
          borderRadius: '6px',
          marginLeft: '2px',
          paddingBottom: '4px',
        }}
        trigger="@"
      />
    </MentionsInput>
  );
};
