/* eslint-disable @cspell/spellchecker */
import React from 'react';
import './UserImage.scss';
import { UIIcon } from '../ui/UIIcon/UIIcon';

interface UserImageProps {
  displayName?: string;
  imageUrl?: string | null;
  fontSize?: number;
  size?: number;
}

export const UserImage: React.FC<UserImageProps> = ({
  imageUrl,
  displayName,
  fontSize = 12,
  size = 40,
}) => {
  const style = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: `${size / 2}px`,
    fontSize: `${fontSize}px`,
  };

  if (imageUrl && imageUrl.length > 0) {
    return (
      <img
        alt="User image"
        className="userImage"
        src={imageUrl}
        style={style}
      />
    );
  }

  if (displayName && displayName.length > 0) {
    const userName = displayName.trim();
    const nameTokens = userName.split(/\s+/);
    const nameInitials = nameTokens
      .map((word) => word[0]?.toUpperCase())
      .join('');

    return (
      <div
        className="bg-amethys-20 flex justify-center items-center text-center text-white text-sm"
        style={style}
      >
        {nameInitials}
      </div>
    );
  }

  return (
    <div className="userImage empty" style={style}>
      <UIIcon name="person" size={size * 0.5} />
    </div>
  );
};
