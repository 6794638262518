/* eslint-disable import/no-unused-modules */
import { invokeAWSGatewayAPI } from './aws';

export interface AccessDiscoveryArgs {
  searchQuery: string;
  idHash: string;
  message?: string;
}

export const requestAccess = async (
  args: AccessDiscoveryArgs
): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: '/access-discovery',
    method: 'POST',
    data: {
      q: args.searchQuery,
      message: args.message,
      idHash: args.idHash,
      timestamp: Math.floor(Date.now() / 1000),
    },
  });
};
