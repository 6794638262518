import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { withInstallModal } from '../../hoc/withInstallModal';
import { App } from '../../scripts/app';
import { useLowerCase, useUserSafe } from '../../scripts/hooks';
import { ConnectStep } from './Components';
import { EmailCaptureStep } from './EmailCaptureStep';
import { SubdomainCaptureStep } from './SubdomainCaptureStep';

interface SubdomainCaptureOptions {
  loginURL: string;
  hostname: string;
}

interface SimpleAPIKeyInstallProps {
  instructionSteps: ReactNode;
  stepCount: number;
  app: App;
  credentialName?: string;
  subdomainCapture?: SubdomainCaptureOptions;
  emailCapture?: boolean;
  subdomain_override?: string;
  initialApiKeyInstructions?: ReactNode;
  additionalApiKeyInstructions?: ReactNode;
  teamSlug?: string;
  suppressApiError?: boolean;
  authFlag?: string;
  authSession?: string;
}

export const SimpleAPIKeyInstall = withInstallModal<SimpleAPIKeyInstallProps>(
  ({
    connectInProgress,
    authFlag,
    authSession,
    app: { definition },
    instructionSteps,
    stepCount,
    credentialName = 'API key',
    subdomainCapture,
    emailCapture,
    subdomain_override,
    initialApiKeyInstructions,
    additionalApiKeyInstructions,
    teamSlug,
    suppressApiError,
    handleAPIInstall,
  }) => {
    const textboxRef = useRef<HTMLInputElement>(null);

    const [credential, setCredential] = useState('');
    const [apiError, setApiError] = useState('');
    const initialEmail = useUserSafe((u) => u.email);
    const [email, setEmail] = useState(emailCapture ? '' : initialEmail);

    const [subdomain, setSubdomain] = useLowerCase('');

    const setApiErrorMessage = useCallback(
      (message: string) => {
        if (suppressApiError) {
          return;
        }

        setApiError(message);
        if (message !== '') {
          textboxRef.current?.focus();
        }
      },
      [suppressApiError]
    );

    const handleClickDone = useCallback(async () => {
      return handleAPIInstall({
        username: email,
        api_key: credential,
        subdomain: subdomain_override ?? subdomain,
        team_slug: teamSlug,
        auth_session: authSession,
        auth_flag: authFlag,
      }).catch(() => {
        setApiErrorMessage(`Incorrect ${credentialName}. Please try again.`);
      });
    }, [
      authFlag,
      authSession,
      credential,
      credentialName,
      email,
      handleAPIInstall,
      setApiErrorMessage,
      subdomain,
      subdomain_override,
      teamSlug,
    ]);

    const connectDisabled =
      credential.trim() === '' ||
      (subdomainCapture && subdomain.trim() === '') ||
      email === '';

    const { displayName: name } = definition;

    return (
      <>
        {instructionSteps}

        {subdomainCapture && (
          <SubdomainCaptureStep
            hostname={subdomainCapture.hostname}
            loginURL={subdomainCapture.loginURL}
            name={name}
            onChange={setSubdomain}
            step={++stepCount}
            subdomain={subdomain}
          />
        )}

        {emailCapture && (
          <EmailCaptureStep
            onChange={setEmail}
            step={++stepCount}
            value={email}
          />
        )}

        <ConnectStep step={++stepCount} title={`Enter ${credentialName}`}>
          <p>
            {initialApiKeyInstructions}
            Paste the {credentialName} and click connect.
            {additionalApiKeyInstructions}
          </p>
          <div className="stepField">
            <UITextbox
              className="stepFieldInput"
              error={apiError}
              onChange={setCredential}
              placeholder={`Enter ${credentialName}`}
              ref={textboxRef}
              size="large"
              type="password"
              value={credential}
            />
          </div>
        </ConnectStep>
        {connectInProgress(handleClickDone, connectDisabled)}
      </>
    );
  }
);
