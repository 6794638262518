import { Action } from '../actions';

export enum DebugAction {
  SET_SEARCH_RESULT = 'DEBUG_SET_SEARCH_RESULT',
}

export function setSearchResultDebug(payload: boolean): Action {
  return {
    type: DebugAction.SET_SEARCH_RESULT,
    payload,
  };
}
