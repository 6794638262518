import React, { useCallback } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';
import { useLowerCase } from '../../scripts/hooks';
import { SubdomainCaptureStep } from '../generic/SubdomainCaptureStep';

export const AppInstallModal = withInstallModal(
  ({ handleOAuthInstall, connectInProgress }) => {
    const [subdomain, setSubdomain] = useLowerCase('');

    const handleClickDone = useCallback(async () => {
      await handleOAuthInstall({ subdomain });
    }, [handleOAuthInstall, subdomain]);

    const connectDisabled = subdomain.trim().length === 0;

    return (
      <>
        <SubdomainCaptureStep
          hostname="atlassian.net"
          loginURL="https://id.atlassian.com/login"
          name="Confluence"
          onChange={setSubdomain}
          step={1}
          subdomain={subdomain}
        />

        {connectInProgress(handleClickDone, connectDisabled)}
      </>
    );
  }
);
