import { Answer } from '../models/answers';
import { GenericTitledViewCounted } from './GenericTitledViewCounted';
import { SSOApi } from './sso';
import { WorkflowApi } from './workflows';

export * from './apps';
export * from './auth';
export * from './members';
export * from './user';
export * from './previews';
export * from './orgs';

export { SSOUnsupportedError } from './sso';
export type { SSOInfo, CreateSSOptions } from './sso';

export { apiBase } from './request';

export const ssoAPI = new SSOApi();

type AnswerWritable = Pick<Answer, 'content' | 'title'>;
export const answersAPI = new GenericTitledViewCounted<Answer, AnswerWritable>(
  'answers',
  'answer'
);

export const workflowAPI = new WorkflowApi();
