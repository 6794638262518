/* eslint-disable @cspell/spellchecker */

export const futureApps: [RegExp, string][] = [
  [/\bamplitude\b/i, 'Amplitude'],
  [/\bcanva\b/i, 'Canva'],
  [/\bchurn ?zero\b/i, 'ChurnZero'],
  [/\bcirrus\b/i, 'Cirrus'],
  [/\bfield ?wire\b/i, 'Fieldwire'],
  [/\bfigma\b/i, 'Figma'],
  [/\bfresh ?desk\b/i, 'Freshdesk'],
  [/\bgong\b/i, 'Gong'],
  [/\bhalp\b/i, 'Halp'],
  [/\bhappy ?fox\b/i, 'HappyFox'],
  [/\blattice\b/i, 'Lattice'],
  [/\blimechat\b/i, 'LimeChat'],
  [/\blooker\b/i, 'Looker'],
  [/\bloom\b/i, 'Loom'],
  [/\bpro ?core\b/i, 'Procore'],
  [/\bretool\b/i, 'Retool'],
  [/\bsnow ?flake\b/i, 'Snowflake'],
  [/\bwhats ?app\b/i, 'WhatsApp'],
  [/\bworkable\b/i, 'Workable'],
  [/\bzing?(hr)?\b/i, 'ZingHR'],
  [/\bzoom\b/i, 'Zoom'],
];
