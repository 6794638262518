import React, { useCallback, useState } from 'react';
import { trackEvent } from '../../../extra/sharedMethods';
import { requestAccess } from '../../../scripts/apis/AccessDiscoveryAPI';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import {
  useBoolState,
  useHistoryPush,
  useToaster,
} from '../../../scripts/hooks';
import { AccessDiscoveryResult } from '../../../scripts/models/page-search-result';
import { pushAccessDiscoveryRequest } from '../../../scripts/page-search/access-discovery';
import { logError } from '../../../scripts/utils';
import { Dialog } from '../../controls/Dialog/Dialog';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UISwitchRow } from '../../controls/ui/UISwitchRow/UISwitchRow';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
export interface AccessRequestDialogProps {
  setAccessRequestDialogOpen: () => void;
  setAccessDiscoveryResult: (result: AccessDiscoveryResult) => void;
}

export const AccessRequestDialog: React.FC<{
  shown: boolean;
  query: string;
  result: AccessDiscoveryResult;
  onClose: () => void;
}> = ({ shown, query, result, onClose }) => {
  const placeholder = `I'm looking for information related to ${query}`;
  const toaster = useToaster();
  const [isProcessing, setIsProcessing, setNotProcessing] = useBoolState(false);
  const [shareQuery, setShareQuery] = useState(true);
  const [message, setMessage] = useState(placeholder);

  trackEvent(AnalyticsEvent.AccessDiscoveryResultClick, { query, result });

  const handleRequestAccess = useHistoryPush(`?q=${query}`);

  const idHash = result.id_hash;
  const onRequest = useCallback(() => {
    setIsProcessing();
    requestAccess({ searchQuery: query, idHash, message })
      .then(() => {
        onClose();
        setNotProcessing();
        pushAccessDiscoveryRequest(result);
        toaster.success(
          'You will get an email when the document is shared with you.'
        );

        trackEvent(AnalyticsEvent.AccessDiscoveryRequest, {
          result,
          message,
        });

        handleRequestAccess();
      })
      .catch((error) => {
        logError(error);
        setNotProcessing();
        toaster.failure();
      });
  }, [
    setIsProcessing,
    query,
    idHash,
    message,
    onClose,
    setNotProcessing,
    result,
    toaster,
    handleRequestAccess,
  ]);

  const appNameSplit = result.app_name.split(' ');

  return (
    <Dialog
      className="accessRequestDialog"
      content={
        <div>
          <p>
            Request access from your colleagues to view this{' '}
            {appNameSplit[appNameSplit.length - 1]?.toLowerCase()}. You will be
            able to search it once you have the necessary permissions in{' '}
            {result.app_name.split(' ')[0]}
          </p>
          <UISwitchRow
            checked={shareQuery}
            description="Speed up the access request from your colleagues"
            label="Share Search Query"
            onChange={setShareQuery}
          />
          <br />
          <div className="stepField">
            <UITextbox
              className="stepFieldInput"
              onChange={setMessage}
              placeholder={placeholder}
              size="large"
              type="text"
              value={message}
            />
          </div>
        </div>
      }
      footer={
        <UIButton onClick={onRequest} processing={isProcessing}>
          Request access
        </UIButton>
      }
      onClose={onClose}
      open={shown}
      showCancel
      showClose={false}
    />
  );
};
