import React from 'react';
import { sanitize } from '../../../scripts/html';
import './HighlightedText.scss';

export const highlightStartCodeRe = /\$\/#\$/g;
const highlightEndCodeRe = /\$#\\\$/g;

export const highlightToHTML = (
  value?: string,
  pre = '<span class="highlight">',
  post = '</span>'
): string => {
  if (!value) {
    return '';
  }

  return value
    .replace(highlightStartCodeRe, pre)
    .replace(highlightEndCodeRe, post);
};

export const HighlightedText: React.FC<{ text: string }> = ({ text }) => {
  const highlighted = highlightToHTML(text);

  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitize(highlighted),
      }}
    />
  );
};
