import React, { useEffect, useRef } from 'react';
import { Loading, LoadingSize } from '../components/controls/Loading/Loading';
import { trackEvent } from '../extra/sharedMethods';
import { AnalyticsEvent } from '../scripts/constants/analytics-event';
import { useUserSafe } from '../scripts/hooks';

export const UninstallExtensionPage: React.FC = () => {
  const userData = useUserSafe();
  const sentEvent = useRef(false);
  useEffect(() => {
    if (!sentEvent.current) {
      sentEvent.current = true;
      trackEvent(AnalyticsEvent.UninstallExtension, {
        user_id: userData.userId,
        org: userData.orgByOrgId.domain,
      });

      window.location.href = 'https://dashworks.ai/extension/uninstall';
    }
  }, [userData.orgByOrgId.domain, userData.userId]);

  return <Loading size={LoadingSize.Huge} text="Redirecting.." />;
};
