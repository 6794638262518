/* eslint-disable complexity */
import React, { FC } from 'react';
import { AnswerIcon } from '../../components/controls/ui/icons/icons';
import { GoogleCalendarEventPreview } from '../../components/pageSearch/previews/objectTypes/calendar/gcal/GoogleCalendarEventPreview/GoogleCalendarEventPreview';
import { GeneralContactPreview } from '../../components/pageSearch/previews/objectTypes/contacts/GeneralContactPreview/GeneralContactPreview';
import { GithubRepoPreview } from '../../components/pageSearch/previews/objectTypes/files/github/GithubRepoPreview/GithubRepoPreview';
import { SharepointPreview } from '../../components/pageSearch/previews/objectTypes/files/sharepoint/SharepointPreview';
import { WebsitePreview } from '../../components/pageSearch/previews/objectTypes/files/website/WebsitePreview/WebsitePreview';
import { GeneralCreatedPreview } from '../../components/pageSearch/previews/objectTypes/general/GeneralCreatedPreview/GeneralCreatedPreview';
import { EmailPreview } from '../../components/pageSearch/previews/objectTypes/messages/EmailPreview';
import { IntercomConversationPreview } from '../../components/pageSearch/previews/objectTypes/messages/intercom/IntercomConversationPreview/IntercomConversationPreview';
import { MSTeamsMessagePreview } from '../../components/pageSearch/previews/objectTypes/messages/msteams/MSTeamsMessagePreview';
import { NotionPagePreview } from '../../components/pageSearch/previews/objectTypes/messages/notion/NotionPagePreview';
import { SlackChannelPreview } from '../../components/pageSearch/previews/objectTypes/messages/slack/SlackChannelPreview/SlackChannelPreview';
import { SlackMessagePreview } from '../../components/pageSearch/previews/objectTypes/messages/slack/SlackMessagePreview/SlackMessagePreview';
import { FreshDeskTicketPreview } from '../../components/pageSearch/previews/objectTypes/tasks/freshdesk/FreshdeskTicketPreview/FreshdeskTicketPreview';
import { GeneralTaskPreview } from '../../components/pageSearch/previews/objectTypes/tasks/general/GeneralTaskPreview/GeneralTaskPreview';
import { ServiceNowCasePreview } from '../../components/pageSearch/previews/objectTypes/tasks/servicenow/ServiceNowCasePreview';
import { ZendeskTicketPreview } from '../../components/pageSearch/previews/objectTypes/tasks/zendesk/ZendeskTicketPreview/ZendeskTicketPreview';
import { PageSearchResult } from '../models/page-search-result';

export const ResultPreview: FC<{ result: PageSearchResult }> = ({ result }) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (result.source) {
    case 'coda':
      return <GeneralCreatedPreview result={result} />;
    case 'notion':
      return <NotionPagePreview result={result} />;
    case 'website':
      return <WebsitePreview result={result} />;
    case 'airtable':
      return <GeneralCreatedPreview result={result} />;
    case 'brandfolder':
      return <GeneralCreatedPreview asImage result={result} />;
    case 'confluence':
      return (
        <GeneralCreatedPreview
          fetchPreview={result.type === 'page'}
          result={result}
        />
      );
    case 'gdrive':
      return (
        <GeneralCreatedPreview
          fetchPreview={!['drive', 'folder'].includes(result.type)}
          result={result}
        />
      );

    case 'onedrive':
      switch (result.type) {
        case 'file':
          return <GeneralCreatedPreview fetchPreview result={result} />;
      }

      break;

    case 'miro':
      return <GeneralCreatedPreview fetchPreview result={result} />;

    case 'readme':
      return <GeneralCreatedPreview fetchPreview result={result} />;

    case 'freshdesk':
      return <FreshDeskTicketPreview result={result} />;

    case 'dropbox':
      return <GeneralCreatedPreview fetchPreview result={result} />;
    case 'quip':
      return (
        <GeneralCreatedPreview
          fetchPreview={result.type === 'thread'}
          result={result}
        />
      );
    case 'slack':
      switch (result.type) {
        case 'file':
          return <GeneralCreatedPreview fetchPreview result={result} />;
        case 'channel':
          return <SlackChannelPreview result={result} />;
        case 'message':
          return <SlackMessagePreview result={result} />;
      }

      break;
    case 'msteams':
      return <MSTeamsMessagePreview result={result} />;
    case 'sharepoint':
      return <SharepointPreview result={result} />;
    case 'github':
      switch (result.type) {
        case 'issue':
          return <GeneralTaskPreview result={result} />;
        case 'pr':
          return <GeneralTaskPreview result={result} />;
        case 'repo':
          return <GithubRepoPreview result={result} />;
        case 'markdown_doc':
          return <GeneralCreatedPreview fetchPreview result={result} />;
      }

      break;
    case 'gitlab':
      switch (result.type) {
        case 'issue':
          return <GeneralTaskPreview result={result} />;
        case 'merge_request':
          return <GeneralTaskPreview result={result} />;
        case 'project':
          return <GeneralCreatedPreview result={result} />;
        case 'markdown_doc':
          return <GeneralCreatedPreview result={result} />;
        case 'milestone':
          return <GeneralCreatedPreview result={result} />;
      }

      break;
    case 'asana':
      switch (result.type) {
        case 'project':
          return <GeneralCreatedPreview result={result} />;
        case 'task':
          return <GeneralTaskPreview result={result} />;
      }

      break;
    case 'jira':
      switch (result.type) {
        case 'project':
          return <GeneralCreatedPreview result={result} />;
        case 'issue':
          return <GeneralTaskPreview result={result} />;
      }

      break;
    case 'monday':
      switch (result.type) {
        case 'item':
          return <GeneralTaskPreview result={result} />;
        case 'board':
          return <GeneralTaskPreview result={result} />;
      }

      break;
    case 'clickup':
      switch (result.type) {
        case 'task':
          return <GeneralTaskPreview result={result} />;
        case 'list':
          return <GeneralTaskPreview result={result} />;
      }

      break;

    case 'trello':
      switch (result.type) {
        case 'board':
          return <GeneralCreatedPreview result={result} />;
        case 'card':
          return <GeneralTaskPreview result={result} />;
      }

      break;
    case 'outlook':
    case 'gmail':
      return <EmailPreview result={result} />;
    case 'intercom':
      switch (result.type) {
        case 'conversation':
          return <IntercomConversationPreview result={result} />;
        case 'user':
          return <GeneralContactPreview result={result} />;
        case 'lead':
          return <GeneralContactPreview result={result} />;
        case 'company':
          return <GeneralContactPreview result={result} />;
      }

      break;
    case 'hubspot':
      return <GeneralContactPreview result={result} />;
    case 'salesforce':
      switch (result.type) {
        case 'account':
        case 'contact':
        case 'lead':
        case 'opportunity':
          return <GeneralContactPreview result={result} />;
        case 'case':
          return <GeneralTaskPreview result={result} />;
        case 'knowledgeArticle':
          return <GeneralCreatedPreview result={result} />;
        default:
          return <GeneralContactPreview result={result} />;
      }

    case 'gcal':
      return <GoogleCalendarEventPreview result={result} />;
    case 'zendesk':
      switch (result.type) {
        case 'article':
          return <GeneralCreatedPreview fetchPreview result={result} />;
        case 'ticket':
          return <ZendeskTicketPreview result={result} />;
      }

      break;
    case 'servicenow':
      switch (result.type) {
        case 'case':
          return <ServiceNowCasePreview result={result} />;
        case 'knowledgearticle':
          return <GeneralCreatedPreview result={result} />;
      }

      break;
    case 'system':
      switch (result.type) {
        case 'answer':
          return (
            <GeneralCreatedPreview icon={<AnswerIcon />} result={result} />
          );
      }
  }

  // Does not match
  return null;
};
