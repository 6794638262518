import {
  AppConnectionType,
  AppDefinition,
  InstantAvailableStatus,
} from '../../../apps/definition';

export const showAppInPersonalApps = ({
  connectionType,
}: AppDefinition): boolean => {
  return (
    connectionType !== AppConnectionType.OrgOnly &&
    connectionType !== AppConnectionType.OrgRestricted &&
    connectionType !== AppConnectionType.Other
  );
};

export const isInstantAvailable = (
  { instantAvailable }: AppDefinition,
  checkForOnly?: InstantAvailableStatus
): boolean => {
  if (instantAvailable === undefined) {
    return false;
  }

  if (checkForOnly === undefined) {
    return instantAvailable !== InstantAvailableStatus.NotAvailable;
  }

  // eslint-disable-next-line no-bitwise
  return (checkForOnly & instantAvailable) === checkForOnly;
};
