import React, { FC } from 'react';
import botsImage from '../../../assets/images/bots2.svg';
import { AuthorDetails } from '../../../components/controls/AuthorDetails/AuthorDetails';
import { UIIcon } from '../../../components/controls/ui/UIIcon/UIIcon';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/shadcn/lib/components/table';
import { SlackbotChannel } from '../../../models/Bots';

interface SlackbotChannelsTabProps {
  slackbotChannels: SlackbotChannel[];
  setCurrentChannel: (channel: SlackbotChannel) => void;
}

export const SlackbotChannelsTab: FC<SlackbotChannelsTabProps> = ({
  slackbotChannels,
  setCurrentChannel,
}) => {
  if (slackbotChannels.length === 0) {
    return (
      <div className="max-w-3xl">
        <img className="mt-10 w-full" src={botsImage} />
        <div className="flex flex-col gap-6 mt-6">
          <div className="flex gap-1">
            <div className="font-semibold">
              Add bots to your Slack Channels by following these steps.
            </div>
            <div>
              <a
                className="inline-block"
                href="https://support.dashworks.ai/bots/slackbot"
                rel="noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="flex">
            <InstructionItem number={1} />
            <div>Connect Slack integration from the App Store</div>
          </div>
          <div className="flex">
            <InstructionItem number={2} />
            <div>
              Mark the app connection as Bot connection and set it as Primary
              Slackbot Connection
            </div>
          </div>
          <div className="flex">
            <InstructionItem number={3} />
            <div>
              Invite the Dashworks Slackbot by typing "/invite @Dashworks" in a
              Slack channel
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-3/5">Name</TableHead>
          <TableHead className="w-2/5">Invited by</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {slackbotChannels.map((channel) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks

          return (
            <TableRow
              className="cursor-pointer"
              key={channel.id}
              onClick={() => {
                setCurrentChannel(channel);
              }}
            >
              <TableCell>
                <div className="flex items-center gap-2">
                  <UIIcon name="slack" size={24} type="apps" />
                  {channel.channel_name}
                </div>
              </TableCell>
              <TableCell>
                <AuthorDetails
                  displayName={
                    channel.author.display_name ?? channel.author.email
                  }
                  icon={channel.author.icon}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

interface InstructionItemProps {
  number: number;
  completed?: boolean;
}

const InstructionItem: React.FC<InstructionItemProps> = ({
  number,
  completed,
}) => {
  return completed ? (
    <div className="flex-shrink-0 bg-moss-30 text-white w-6 h-6 flex items-center justify-center rounded-full border-2 border-gray-300 text-sm font-semibold mr-3">
      <UIIcon name="check" size={16} />
    </div>
  ) : (
    <div className="flex-shrink-0 bg-black text-black w-6 h-6 flex items-center justify-center rounded-full border-2 border-gray-300 text-sm font-semibold mr-3">
      <div className="text-white"> {number}</div>
    </div>
  );
};
