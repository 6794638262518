export interface OrgMember {
  user_id: string;
  admin: boolean;
  name?: string;
  email: string;
  icon: string;
  invited_at: string;
  onboarded?: boolean;
  status: MemberStatus;
}

export enum MemberStatus {
  SIGNED_UP = 'SIGNED_UP',
  BLOCKED = 'BLOCKED',
  DELETED = 'DELETED',
  INVITED = 'INVITED',
  CREATED = 'CREATED',
}
