import React, { useCallback, useRef, useState } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';
import { useUserSafe } from '../../scripts/hooks';
import { openCenterPopup } from '../../scripts/utils';
import { GeneralError } from '../generic/Components';

const createAuthUrl = (email: string, org: string): string => {
  const url = new URL('https://accounts.google.com');
  url.pathname = `/a/${org}/ServiceLogin`;
  url.searchParams.set('service', 'mail');
  url.searchParams.set('passive', 'true');
  url.searchParams.set('Email', email);
  url.searchParams.set(
    'continue',
    `https://workspace.google.com/marketplace/dwi/${GOOGLE_MARKETPLACE_ID}?redirect_url=./marketplace/adminoauthpromptredirect`
  );

  return url.href;
};

export const useGoogleMarketPopup = (): [string, () => Promise<boolean>] => {
  const user = useUserSafe();
  const pollRef = useRef(0);
  const [errorMessage, setErrorMessage] = useState('');

  return [
    errorMessage,
    useCallback(async () => {
      const auth = openCenterPopup(
        createAuthUrl(user.email, user.orgByOrgId.domain),
        'gmarketplace'
      );

      if (!auth) {
        setErrorMessage('Unable to connect, please check your popup blocker.');
        return false;
      }

      return new Promise<boolean>((resolve) => {
        pollRef.current = setInterval(() => {
          if (auth.closed) {
            // This dialog does not post any messages, so we assume the window closing means the user is done.
            resolve(true);
            clearInterval(pollRef.current);
          }
          // CAST: NodeJS type issue
        }, 250) as unknown as number;
      });
    }, [user.email, user.orgByOrgId.domain]),
  ];
};

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleAPIInstall, setInProgress }) => {
    const [popupError, showPopup] = useGoogleMarketPopup();

    const clickConnect = useCallback(async () => {
      setInProgress(true);
      if (!(await showPopup())) {
        setInProgress(false);
        return;
      }

      return handleAPIInstall({
        marketplace: true,
      });
    }, [handleAPIInstall, setInProgress, showPopup]);

    return (
      <>
        <p className="firstNotice">
          This step allows Dashworks to connect and fetch your company
          directory.
          <br />
          <br />
          Note: You need be a Google Workspace admin to proceed.
        </p>
        {popupError && <GeneralError text={popupError} />}

        {connectInProgress(clickConnect)}
      </>
    );
  }
);
