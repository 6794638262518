/* eslint-disable @cspell/spellchecker */

import React, { FC, useCallback } from 'react';
import { useQAController } from '../../../scripts/QAController';
import { UIIcon, UIIcons } from '../../controls/ui/UIIcon/UIIcon';
import { Card, CardContent } from '../../shadcn/lib/components/card';

export const QAUseCases: FC = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <div className="flex items-center">
          <UIIcon name="light-bulb" size={20} />
        </div>
        <div className="font-medium">How teams use Dash AI</div>
      </div>
      <div className="flex flex-col sm:flex-row gap-2 justify-center items-center sm:items-stretch">
        <UseCaseCard
          examples={[
            'How to request time off?',
            'How does feature work?',
            'Q4 Company Goals',
          ]}
          icon="glossary"
          title="Understand"
        />
        <UseCaseCard
          examples={[
            'Write follow up email',
            'Generate social post',
            'Plan a presentation',
          ]}
          icon="pencil-2"
          title="Create"
        />
        <UseCaseCard
          examples={[
            'Link to Q1 Sales Report',
            'Link to Onboarding Guide',
            'Link to Daily Updates doc',
          ]}
          icon="search-2"
          title="Find"
        />
      </div>
    </div>
  );
};

interface UseCaseCardProps {
  icon: UIIcons;
  title: string;
  examples: string[];
}

const UseCaseCard: FC<UseCaseCardProps> = ({ icon, title, examples }) => {
  const qaController = useQAController();

  const handleClick = useCallback(
    (query: string) => {
      qaController.triggerEvent('setQuery', {
        query,
      });
    },
    [qaController]
  );

  return (
    <Card className="w-[80%] sm:w-[32%] shadow-md p-2 pt-3">
      <CardContent className="flex flex-col gap-4">
        <div className="pl-1 flex items-center gap-1 text-lg font-semibold">
          <UIIcon name={icon} size={24} />
          {title}
        </div>
        <div className="flex flex-col gap-2">
          {examples.map((example) => (
            <div
              className="p-2 bg-cloud-10 rounded hover:bg-purple-10 cursor-pointer"
              key={example}
              onClick={() => {
                handleClick(example);
              }}
            >
              {example}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
