import React, { FC, useCallback, useRef, useState } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { Reference } from '../../../../models/QAmodels';
import { useQAController } from '../../../../scripts/QAController';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { ReferencePopover } from '../QAReferencePopover';
import { ReferenceIcon } from '../QAReferenceUtil';

export const ReferencesItem: FC<{ item: Reference; index: number }> = ({
  item: reference,
  index,
}) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const qaController = useQAController();

  const handlePopoverOpen = useCallback(() => {
    setOpenedPopover(true);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setOpenedPopover(false);
  }, []);

  const url = reference.url ?? '';
  const title = reference.title || url;
  const { source } = reference;

  const onReferenceClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      trackEvent(
        AnalyticsEvent.QAMessageReferenceClicked,
        {
          position: index + 1,
        },
        {
          url,
          title,
          source,
        }
      );

      if (source === 'verified_answers') {
        // answer id is expected to be last fragment in url
        const answerId = url.split('/').pop();
        qaController.triggerEvent('showAnswerDialog', { answerId });
        e.preventDefault();
        return;
      }

      if (url) {
        window.open(url);
      }
    },
    [url, title, source, index, qaController]
  );

  return (
    <>
      <div
        // eslint-disable-next-line max-len
        className={`w-[44%] sm:w-[30%] py-1 px-1 sm:px-2 flex items-center gap-1 border-gray-30 border border-solid rounded text-xs neumorphic-background hover:bg-none hover:bg-purple-10 hover:border-purple-30  ${
          url ? 'cursor-pointer' : ''
        }`}
        onClick={onReferenceClick}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        ref={popoverAnchor}
        tabIndex={0}
      >
        <div className="text-sm">{index + 1}.</div>
        <div>
          <ReferenceIcon
            icon_url={reference.extra_fields?.icon_url} // eslint-disable-line unicorn/consistent-destructuring
            size={16}
            source={source}
            title={title}
            url={url}
          />
        </div>
        <div className="truncate">
          {title.length > 20 ? `${title.slice(0, 20)}...` : title}
        </div>
      </div>
      <ReferencePopover
        handlePopoverClose={handlePopoverClose}
        handlePopoverOpen={handlePopoverOpen}
        openedPopover={openedPopover}
        popoverAnchor={popoverAnchor}
        reference={reference}
      />
    </>
  );
};
