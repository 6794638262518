import React, { FC } from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';
import { QueryParams } from '../hooks';
import { QueryPreservingRedirect } from './QueryPreservingRedirect';

interface RedirectWithCurrentUrlProps extends RedirectProps {
  to: string;
}

export const RedirectWithCurrentUrl: FC<RedirectWithCurrentUrlProps> = ({
  to,
  ...props
}) => {
  const { search } = useLocation();
  const { pathname } = window.location;
  let newTo = to;

  const redirectString = `${QueryParams.Redirect}=${encodeURIComponent(
    `${pathname}${search}`
  )}`;

  newTo += to.includes('?') ? `&${redirectString}` : `?${redirectString}`;

  if (pathname === '/') {
    return <QueryPreservingRedirect {...props} to={to} />;
  }

  return <Redirect {...props} push to={newTo} />;
};
