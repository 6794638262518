import './ShowMoreButton.scss';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import {
  useAllSearchRelatedParams,
  usePageSearch,
} from '../../../../scripts/hooks';
import { useCloudIndex } from '../../../../search/CloudIndexContext';
import { UIButton } from '../../../controls/ui/UIButton/UIButton';

export const ShowMoreButton = forwardRef<() => void>((_props, ref) => {
  const cloudIndex = useCloudIndex();
  const version = usePageSearch((pageSearch) => pageSearch.version);
  const queryId = usePageSearch((pageSearch) => pageSearch.queryId);
  const [searchState] = useAllSearchRelatedParams();

  const showMoreAction = useCallback(() => {
    cloudIndex.showMore();

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
      });
    }, 250);

    trackEvent(AnalyticsEvent.ShowMoreClick, {
      query: searchState.q,
      ...searchState,
      version,
      queryId,
    });
  }, [cloudIndex, queryId, searchState, version]);

  useImperativeHandle(ref, () => showMoreAction);

  return (
    <div className="showMore">
      <UIButton onClick={showMoreAction} type="secondary">
        More results
      </UIButton>
    </div>
  );
});

ShowMoreButton.displayName = 'ShowMoreButton';
