import React, { FC, useCallback, useMemo } from 'react';
import {
  GOOGLE_APPS,
  InstantAvailableStatus,
} from '../../../../apps/definition';
import { AppState } from '../../../../constants';
import { trackEvent } from '../../../../extra/sharedMethods';
import { UserApp } from '../../../../models/User';
import { App } from '../../../../scripts/app';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { calculateIndexingPercentage } from '../../../../scripts/utils';
import { UIButton } from '../../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../../controls/ui/UIToolTip/UIToolTip';
import { useAppInstallController } from '../../AppsTable/AppInstallController';
import { isInstantAvailable } from '../../AppsTable/AppUtils';

export const OrgAdminButton: FC<{
  app: App;
  connection: UserApp;
  installEventName?: AnalyticsEvent;
  installCallback?(): void;
}> = ({ app, connection, installEventName, installCallback }) => {
  const appInstallController = useAppInstallController();

  const { shortname } = app.definition;

  const orgInstallCallback = useCallback(() => {
    const eventBody = {
      app: shortname,
      isOrg: true,
    };

    trackEvent(AnalyticsEvent.AppConnect, eventBody);

    if (installEventName) {
      trackEvent(installEventName, eventBody);
    }

    if (installCallback) {
      installCallback();
    }

    appInstallController.triggerAppInstallRequest(app, true, connection.id);
  }, [
    shortname,
    installEventName,
    installCallback,
    appInstallController,
    app,
    connection.id,
  ]);

  const orgUninstallCallback = useCallback(() => {
    trackEvent(AnalyticsEvent.AppDisconnect, {
      app: shortname,
      isOrg: true,
    });

    appInstallController.triggerAppUninstallRequest(app, true, connection.id);
  }, [app, appInstallController, connection.id, shortname]);

  const { definition } = app;

  const isInstant = isInstantAvailable(definition, InstantAvailableStatus.Org);

  const isDisabled = useMemo(() => GOOGLE_APPS.has(shortname), [shortname]);

  switch (connection.statusCode) {
    case AppState.PartiallyConnected: {
      return <PartiallyConnectedContent />;
    }

    case AppState.Connecting:
    case AppState.Indexing:
    case AppState.IndexingFailed:
    case AppState.IndexingComplete: {
      if (isInstant || connection.statusCode === AppState.IndexingComplete) {
        // hack to enable frontend to show connected status for instant apps

        if (isDisabled) {
          return <ConnectedContent disableDisconnect />;
        }

        return (
          <div className="flipHover">
            <span className="flipNormal">
              <ConnectedContent />
            </span>
            <DisableButton onClick={orgUninstallCallback} />
          </div>
        );
      }

      return <IndexingButton connection={connection} />;
    }

    case AppState.NotConnected:
    case AppState.ErrorDoNotReconnect:
      return notIndexingState;
    case AppState.ErrorReconnect: {
      return (
        <UIButton
          className="connectButton"
          onClick={orgInstallCallback}
          size="small"
          type="caution"
        >
          <UIIcon name="error" />
          <span>Reconnect</span>
        </UIButton>
      );
    }

    case AppState.Disconnecting: {
      return disablingState;
    }
  }
};

export const OrgUserButton: FC<{
  app: App;
  connection: UserApp;
}> = ({ app, connection }) => {
  const { definition } = app;

  const isInstant = isInstantAvailable(definition, InstantAvailableStatus.Org);

  switch (connection.statusCode) {
    case AppState.PartiallyConnected: {
      return <PartiallyConnectedContent />;
    }

    case AppState.Connecting:
    case AppState.Indexing:
    case AppState.IndexingFailed:
    case AppState.IndexingComplete: {
      if (isInstant || connection.statusCode === AppState.IndexingComplete) {
        return <ConnectedContent disableDisconnect />;
      }

      return <IndexingButton connection={connection} />;
    }

    case AppState.NotConnected:
    case AppState.ErrorDoNotReconnect:
    case AppState.ErrorReconnect: {
      return contactAdmin;
    }

    case AppState.Disconnecting: {
      return disablingState;
    }
  }
};

export const PersonalButton: FC<{
  app: App;
  connection: UserApp;
  installEventName?: AnalyticsEvent;
  installCallback?(): void;
}> = ({ app, connection, installEventName, installCallback }) => {
  const appInstallController = useAppInstallController();
  const { shortname } = app.definition;

  const personalInstallCallback = useCallback(() => {
    const eventBody = {
      app: shortname,
      isOrg: false,
      connection: connection.id,
    };

    trackEvent(AnalyticsEvent.AppConnect, eventBody);

    if (installEventName) {
      trackEvent(installEventName, eventBody);
    }

    if (installCallback) {
      installCallback();
    }

    appInstallController.triggerAppInstallRequest(app, false, connection.id);
  }, [
    shortname,
    connection.id,
    installEventName,
    installCallback,
    appInstallController,
    app,
  ]);

  const personalUninstallCallback = useCallback(() => {
    appInstallController.triggerAppUninstallRequest(app, false, connection.id);
    trackEvent(AnalyticsEvent.AppDisconnect, {
      app: shortname,
      connection: connection.id,
      isOrg: false,
    });
  }, [app, appInstallController, connection.id, shortname]);

  const { definition } = app;

  const isInstant = isInstantAvailable(
    definition,
    InstantAvailableStatus.Personal
  );

  switch (connection.statusCode) {
    case AppState.PartiallyConnected: {
      return <PartiallyConnectedContent />;
    }

    case AppState.Connecting:
    case AppState.Indexing:
    case AppState.IndexingFailed:
    case AppState.IndexingComplete: {
      if (isInstant || connection.statusCode === AppState.IndexingComplete) {
        // hack to enable frontend to show connected status for instant apps

        return (
          <div className="flipHover">
            <span className="flipNormal">
              <ConnectedContent />
            </span>
            <DisableButton onClick={personalUninstallCallback} />
          </div>
        );
      }

      return <IndexingButton connection={connection} />;
    }

    case AppState.NotConnected:
    case AppState.ErrorDoNotReconnect:
      return notIndexingState;
    case AppState.ErrorReconnect: {
      return (
        <UIButton
          className="connectButton"
          onClick={personalInstallCallback}
          size="small"
          type="caution"
        >
          <UIIcon name="error" />
          <span className="ml-2">Reconnect</span>
        </UIButton>
      );
    }

    case AppState.Disconnecting: {
      return disablingState;
    }
  }
};

const DisableButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <UIButton
      className="connectButton flipActive"
      onClick={onClick}
      size="small"
      type="warning"
    >
      Disconnect
    </UIButton>
  );
};

const IndexingButton: FC<{ connection: UserApp }> = ({ connection }) => {
  const percentage = calculateIndexingPercentage(connection);

  return (
    <div className="indexingApp">
      <UIIcon name="spinner-animated" />
      <span>{percentage === 0 ? 'Syncing' : `syncing (${percentage}%)`}</span>
    </div>
  );
};

export const notAvailable = <div className="w-4 h-px bg-cloud-20 " />;

const disablingState = (
  <div className="disablingApp">
    <UIIcon name="spinner-animated" />
    <span>Disabling</span>
  </div>
);

const contactAdmin = (
  <UITooltip title="Please contact your admin to reconnect the app.">
    <div className="connectionIssue gap-1">
      <UIIcon name="error" />
      <span>Error</span>
    </div>
  </UITooltip>
);

const PartiallyConnectedContent: FC = () => (
  <div className="connectedApp">
    <UIIcon className="loader" name="spinner-animated" />
    <span>Connecting</span>
  </div>
);

const ConnectedContent: FC<{ disableDisconnect?: boolean }> = ({
  disableDisconnect = false,
}) =>
  disableDisconnect ? (
    <UITooltip title="Your admin has connected the app.">
      <div className="connectedApp">
        <UIIcon name="check" />
        <span>Connected</span>
      </div>
    </UITooltip>
  ) : (
    <div className="connectedApp">
      <UIIcon name="check" />
      <span>Connected</span>
    </div>
  );

const notIndexingState = (
  <div className="connectionIssue">
    <UIIcon name="error" />
    <span>Not indexing</span>
  </div>
);
