import './GoogleCalendarEventPreview.scss';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import {
  calendarTimeRange,
  newWindowLink,
} from '../../../../../../../scripts/page-search/result-utils';
import { MetaPair, PreviewItem } from '../../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../../previewProps';

export const GoogleCalendarEventPreview: React.FC<PreviewProps> = ({
  result,
}) => {
  const metaPairs: MetaPair[] = [];

  const { attendees, start_time, end_time } = result;
  if (attendees && attendees.length > 0) {
    const attendeesUI: ReactNode[] = [];

    // eslint-disable-next-line unicorn/no-array-for-each
    attendees.forEach((attendee, i) => {
      const nameEmail =
        attendee.name && attendee.name.length > 0
          ? attendee.name
          : attendee.email;

      attendeesUI.push(
        <span className="attendee" key={attendee.email}>
          <span className={classNames('attendeeStatus', attendee.status)} />
          <span>{nameEmail}</span>
        </span>
      );

      if (attendee.is_organizer) {
        metaPairs.unshift({
          name: 'Organizer',
          value: <span className="heavier">{nameEmail}</span>,
        });
      }

      if (i < attendees.length - 1) {
        attendeesUI.push(
          <span className="heavier" key={`div-${attendee.email!}`}>
            ,{' '}
          </span>
        );
      }
    });

    metaPairs.push({
      name: 'Who',
      value: <div className="previewAttendeesContainer">{attendeesUI}</div>,
    });
  }

  if (start_time && end_time) {
    const timeRange = calendarTimeRange(result);
    metaPairs.push({
      name: 'When',
      value: <span className="heavier">{timeRange}</span>,
    });
  }

  const { location, unhighlighted_name } = result;
  if (location && location.length > 0) {
    if (location.startsWith('http')) {
      metaPairs.push({
        name: 'Where',
        value: newWindowLink(location),
      });
    } else {
      metaPairs.push({
        name: 'Where',
        value: location,
      });
    }
  }

  return (
    <PreviewItem
      icon="gcal"
      metaPairs={metaPairs}
      result={result}
      title={unhighlighted_name}
    />
  );
};
