/**
 * Central place to keep track of analytics events we're sending.
 * See: https://www.notion.so/dashworks/ec3cf952a5a348738fee1e1c7297f76c?v=42369433c25141f5996bbe1d3829751a
 */
export const enum AnalyticsEvent {
  // On first load
  Load = 'dash-load',

  // Blur/unfocus from Dashworks window
  Blur = 'dash-blur',

  // Focus on Dashworks window
  Focus = 'dash-focus',

  // Click on "Login" button
  LoginClick = 'dash-login-click',

  // Click on "Logout" from user dropdown menu
  LogoutClick = 'dash-logout-click',

  // User clicks on link in preview
  PreviewLinkClick = 'dash-preview-link-click',

  // Mouse enters preview
  PreviewMouseEnter = 'dash-preview-mouse-enter',

  // User copies content from preview
  CopyClipboard = 'dash-copy-clipboard',

  // Link was copied to clipboard
  CopyLink = 'dash-copy-link',

  // Triggered a search
  SearchQuery = 'dash-search-query',
  SearchQueryLoaded = 'dash-search-query-load',

  // Clicked on search result
  SearchResultClick = 'dash-search-result-click',

  // Clicked on copy URL button
  SearchResultCopyURLClick = 'dash-search-result-copy-url-click',

  // Mouse enters search result
  SearchResultMouseEnter = 'dash-search-result-mouse-enter',

  // Search result shown within viewport
  SearchResultImpression = 'dash-search-result-impression',

  // Clicked on search result attachment
  SearchResultAttachmentClick = 'dash-search-result-attachment-click',

  // Person profile shown within viewport
  PersonProfileImpression = 'dash-search-person-profile-impression',

  // Extension related
  InstallExtensionClicked = 'dash-install-extension-clicked',
  ExtensionInstalled = 'dash-extension-installed',
  ExtensionNotInstalled = 'dash-extension-not-installed',
  ExtensionSettings = 'dash-extension-settings',

  // Connected Directory
  DirectoryConnected = 'dash-directory-connected',

  // Connect/disconnect app from apps page
  AppConnect = 'dash-app-connect',
  AppDisconnect = 'dash-app-disconnect',

  // Reconnect apps modal
  ReconnectAppsModalShown = 'dash-reconnect-apps-modal-shown',
  ReconnectAppsModalReconnectClicked = 'dash-reconnect-apps-modal-reconnect-clicked',

  // Click on a filter in Autosuggest
  AutosuggestFilterClick = 'dash-autosuggest-filter-click',

  // Click on 'Show more' button
  ShowMoreClick = 'dash-search-show-more-click',

  // Toggle expandable list
  ToggleExpandableList = 'dash-toggle-expandable-list',

  // Click on 'query spell correct' in search results
  QuerySpellCorrectClick = 'dash-search-query-spell-correct-click',

  // Query spell correction impression
  QuerySpellCorrectImpression = 'dash-search-query-spell-correct-impression',

  // Searched using a recent history item in Autosuggest
  AutosuggestHistorySearch = 'dash-autosuggest-history-search',

  // Searched using an autosuggest suggestion
  AutosuggestSuggestionSearch = 'dash-autosuggest-suggestion-search',

  // clicked recently visited dropdown suggestion
  RecentVisitsSuggestionSearch = 'dash-autosuggest-recent-visits-suggestion-search',

  // browser history search suggestion click
  BrowserHistorySuggestionSearch = 'dash-autosuggest-browser-history-search-suggestion-search',

  // Used arrow-down shortcut to select next item in Autosuggest
  AutosuggestKeyArrowDown = 'dash-autosuggest-key-arrow-down',

  // Used arrow-up shortcut to select previous item in Autosuggest
  AutosuggestKeyArrowUp = 'dash-autosuggest-key-arrow-up',

  // Used destination linking
  AutosuggestDestinationClick = 'dash-autosuggest-destination-click',

  // Used for Dash AI search in autosuggest
  AutosuggestDashAiSearch = 'dash-autosuggest-dash-ai-search',

  // Pinned results
  PinnedResultsPinClick = 'dash-pinned-results-pin-click',
  PinnedResultsUnpinClick = 'dash-pinned-results-unpin-click',

  // People card results
  PersonLinkClick = 'dash-person-link-click',

  // Sidebar
  SidebarItemClick = 'dash-sidebar-item-click',

  // Onboarding
  OnboardingAdminSidebarButtonClick = 'dash-onboarding-admin-sidebar-button-click',

  // Bookmarks
  BookmarkClick = 'dash-bookmark-click',
  BookmarkOpenMenu = 'dash-bookmark-open-menu',
  BookmarkCloseMenu = 'dash-bookmark-close-bookmark-menu',
  BookmarkBeginCreate = 'dash-bookmark-begin-create',
  BookmarkCreated = 'dash-bookmark-created',
  BookmarkBeginEdit = 'dash-bookmark-begin-edit',
  BookmarkEdited = 'dash-bookmark-edited',
  BookmarkDelete = 'dash-bookmark-delete',
  BookmarkCancelDelete = 'dash-bookmark-cancel-delete',
  BookmarkDialogClose = 'dash-bookmark-dialog-close',
  BookmarkBeginDelete = 'dash-bookmark-begin-delete',
  // Tour related
  TourUserBackgroundAvailable = 'dash-tour-user-background-available',
  NewTabPageToggle = 'dash-new-tab-page-toggle',
  FloatingWidgetHiddenSitesUpdate = 'dash-floating-widget-hidden-sites-update',
  FloatingWidgetHiddenEverywhereToggle = 'dash-floating-widget-hidden-everywhere-toggle',

  // In page app filter
  InPageAppFilterClick = 'dash-in-page-app-filter-click',
  InPageAppFilterHover = 'dash-in-page-app-filter-hover',
  InPageAppFilterMoreAppsClick = 'dash-in-page-app-filter-more-apps-click',
  InPageAppFilterResetClick = 'dash-in-page-app-filter-reset-click',

  MessageSectionClickMore = 'dash-message-section-more-click',

  // Search notices
  SearchNoticeDashAiSuggestionView = 'dash-search-notice-dash-ai-suggestion-view',
  SearchNoticeDashAiSuggestionClick = 'dash-search-notice-dash-ai-suggestion-click',

  SearchNoticeAppFilterSuggestionView = 'dash-search-notice-app-filter-suggestion-view',
  SearchNoticeAppFilterSuggestionClick = 'dash-search-notice-app-filter-suggestion-click',

  SearchNoticeAppInstallSuggestionView = 'dash-search-notice-app-install-suggestion-view',
  SearchNoticeAppInstallSuggestionClick = 'dash-search-notice-app-install-suggestion-click',

  SearchNoticeAppRequestView = 'dash-search-notice-app-request-view',
  SearchNoticeAppRequestClick = 'dash-search-notice-app-request-click',

  BacklinkClick = 'dash-backlink-click',

  // Workspace Setup
  WorkspaceSetupConnectAppsPageOpened = 'dash-workspace-setup-connect-apps-page-opened',
  WorkspaceSetupStepCompleted = 'dash-workspace-setup-step-completed',
  WorkspaceSetupStripeCheckoutComplete = 'dash-workspace-setup-stripe-checkout-complete',
  WorkspaceSetupSidebarStepNavigate = 'dash-workspace-setup-sidebar-step-navigate',
  WorkspaceSetupComplete = 'dash-workspace-setup-complete',
  WorkspaceSetupScheduleDemoClick = 'dash-workspace-setup-schedule-demo-click',

  // Access Discovery
  AccessDiscoveryResultClick = 'dash-access-discovery-result-click',
  AccessDiscoveryRequest = 'dash-access-discovery-request',

  // Subscription
  SubscriptionTrialWillEnd = 'dash-subs-trial-will-end',
  SubscriptionStripeCheckoutComplete = 'dash-subs-stripe-checkout-complete',

  // Member Actions
  MemberBlock = 'dash-member-block',
  MemberUnblock = 'dash-member-unblock',
  MemberResendInvite = 'dash-member-resend-invite',
  MemberSendInvite = 'dash-member-send-invite',
  MemberChangeRole = 'dash-member-change-role',

  // JIT-QA (Dash AI)
  QADashAiPageOpened = 'dash-qa-dash-ai-page-opened',
  QADashAiPageClosed = 'dash-qa-dash-ai-page-closed',
  QaConnectMoreAppsButtonClick = 'dash-qa-connect-more-apps-button-click',
  QAHomepageRecommendedQueryClick = 'dash-qa-homepage-recommended-query-click',
  QAHomepageNavigateRecommendedQueries = 'dash-qa-homepage-navigate-recommended-queries',
  QAAppsFilterButtonClicked = 'dash-ai-apps-filter-toggled',
  QAWebFilterButtonClicked = 'dash-ai-web-filter-clicked',
  QAContextualFilesButtonClicked = 'dash-ai-contextual-files-button-clicked',
  QAAiOnlyFilterButtonClicked = 'dash-ai-ai-only-filter-clicked',
  QANewTopicToggled = 'dash-ai-new-topic-toggled',
  QAMessageFeedback = 'dash-qa-message-feedback',
  QAMessageRate = 'dash-qa-message-rate',
  QAMessageReferenceRate = 'dash-qa-message-reference-rate',
  QAMessageResponseUserCopiedText = 'dash-qa-message-response-user-copied-text',
  QAMessageResponseUserCopiedTextReferences = 'dash-qa-message-response-user-copied-text-references',
  QAMessageQueryUserCopiedText = 'dash-qa-message-query-user-copied-text',
  QAOnboardingMessageShown = 'dash-qa-onboarding-message-shown',
  QAOnboardingConnectAppClicked = 'dash-qa-onboarding-connect-app-clicked',
  QAOnboardingQuerySuggestionsShown = 'dash-qa-onboarding-query-suggestions-shown',
  QAOnboardingQuerySuggestionClicked = 'dash-qa-onboarding-query-suggestion-clicked',
  QAUserAskedQuestion = 'dash-qa-user-asked-question',
  QAMessageReferenceClicked = 'dash-qa-message-reference-clicked',
  QASeeAllSourcesClicked = 'dash-qa-see-all-sources-clicked',
  QAReferencesSidecarClosed = 'dash-qa-references-sidecar-closed',
  QAReferencesSidecarReferenceClicked = 'dash-qa-references-sidecar-reference-clicked',
  QAReferencesSidecarQuerySearch = 'dash-qa-references-sidecar-query-search',
  QAReferencesSidecarNavigatePage = 'dash-qa-references-sidecar-navigate-page',
  QAReferencesSidecarNavigateTab = 'dash-qa-references-sidecar-navigate-tab',
  QAMessageRelatedSearchClicked = 'dash-qa-message-related-search-clicked',
  QAUserInputWhilePreviousResponseLoading = 'dash-qa-user-input-while-previous-response-loading',
  QAPageBlur = 'dash-qa-page-blur',

  // Personal settings page opened
  PersonalSettingsPageOpened = 'dash-personal-settings-page-opened',
  // Homepage opened
  HomepageOpenedWithoutExtension = 'dash-homepage-opened-without-extension',
  HomepageOpenedWithExtension = 'dash-homepage-opened-with-extension',

  // Uninstall extension event
  UninstallExtension = 'dash-extension-uninstall',
  // Customize Workspace
  WorkspaceNameUpdated = 'dash-workspace-name-updated',
  WorkspaceLogoUpdated = 'dash-workspace-logo-updated',

  // Workflow events
  WorkflowCreated = 'dash-workflow-created',
  WorkflowUpdated = 'dash-workflow-updated',
  WorkflowDeleted = 'dash-workflow-deleted',
  WorkflowUsage = 'dash-workflow-usage',
  WorkflowViewed = 'dash-workflow-viewed',
  OpenWorkflowsModalFromDashAi = 'dash-open-workflows-modal-from-dash-ai',
  ClickedOnSaveWorkflowButton = 'dash-clicked-on-save-workflow-button',

  // Sharable link events
  SharableLinkCreated = 'dash-sharable-link-created',
  SharableLinkViewed = 'dash-sharable-link-viewed',

  // App events
  NotionTokenNotFoundAfterInstall = 'dash-notion-token-not-found-after-install',

  // AI Controls
  AiControlsDisclaimerUpdated = 'dash-ai-controls-disclaimer-updated',
  AiControlsCustomInstructionsUpdated = 'dash-ai-controls-custom-instructions-updated',

  // Product Feedback
  ProductFeedback = 'dash-product-feedback',

  // Topics V2
  ContinueTopic = 'dash-continue-topic',
  DeleteTopic = 'dash-delete-topic',

  // Side Panel
  SidePanelOpened = 'dash-side-panel-opened',

  // Auth
  SelfServeAttempted = 'dash-self-serve-attempted',

  // Bots
  RecommendedSlackbotChannelCTAClicked = 'dash-recommended-slackbot-channel-cta-clicked',
  CreatedBot = 'dash-created-bot',
  UpdatedBot = 'dash-updated-bot',
  DeletedBot = 'dash-deleted-bot',
  MentionedBot = 'dash-mentioned-bot',
  MentionedDashworksBot = 'dash-mentioned-dashworks-bot',
  MentionedUser = 'dash-mentioned-user',
}

export const enum EventSurface {
  WEB = 'web',
  EXTENSION = 'extension',
}

export const enum EventSubSurface {
  WEB = 'web',
  SIDE_PANEL = 'side_panel',
  ADDRESS_BAR = 'address_bar',
  NEW_TAB = 'new_tab',
}
