import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { useBoolState, useToggle } from '../../scripts/hooks';
import { capitalize, isWebDev, isWebStaging, noop } from '../../scripts/utils';
import { useBound } from '../pageSearch/results/misc/hooks';
import { FeatureFlagTable } from './FeatureFlagTable';
import { Overrides } from './Overrides';
import { SearchDebug } from './SearchDebug';
import './EnvIndicator.scss';

const enum Menu {
  FeatureFlags,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Overrides,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SearchDebug,
}

const menus = new Map<Menu, FC>([
  [Menu.FeatureFlags, FeatureFlagTable],
  [Menu.Overrides, Overrides],
  [Menu.SearchDebug, SearchDebug],
]);

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var __dashworksEnableDevTool: () => void;
}

const LOCAL_STORAGE_DEVTOOL_SETTING = 'devtool';

window.__dashworksEnableDevTool = (on = true) => {
  localStorage.setItem(LOCAL_STORAGE_DEVTOOL_SETTING, on.toString());
  location.reload();
};

export const EnvIndicator: FC<{ interactive?: boolean }> = ({
  interactive = false,
}) => {
  const [inspecting, toggleInspecting] = useToggle(false);
  const [hidden, hide] = useBoolState(false);
  const [menu, setMenu] = useState<Menu>(Menu.FeatureFlags);
  const openFeatureFlags = useBound(setMenu, Menu.FeatureFlags);
  const openOverrides = useBound(setMenu, Menu.Overrides);
  const openSearchDebug = useBound(setMenu, Menu.SearchDebug);

  let text = 'PROD';
  // pulses red
  let backgroundColor = 'red';
  if (isWebDev()) {
    text = 'LOCAL DEV';
    backgroundColor = 'lime';
  } else if (isWebStaging()) {
    text = 'STAGING';
    backgroundColor = 'orange';
  } else if (
    (text === 'PROD' &&
      localStorage.getItem(LOCAL_STORAGE_DEVTOOL_SETTING) !== 'true') ||
    hidden
  ) {
    return null;
  }

  const MenuComponent = menus.get(menu);

  if (hidden) {
    return null;
  }

  return (
    <div
      className={classNames(
        { open: inspecting, [`env${capitalize(text)}`]: true },
        'envIndicator'
      )}
      onClick={inspecting ? noop : toggleInspecting}
      style={{
        backgroundColor,
      }}
    >
      {interactive && inspecting && (
        <>
          <div
            style={{
              alignSelf: 'end',
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
            }}
          >
            <button onClick={hide} type="button">
              Hide until refresh
            </button>
            <button onClick={toggleInspecting} type="button">
              X
            </button>
          </div>
          <div className="menu">
            <button onClick={openFeatureFlags} type="button">
              Feature Flags
            </button>
            <button onClick={openOverrides} type="button">
              Overrides
            </button>
            <button onClick={openSearchDebug} type="button">
              Search debug
            </button>
          </div>
          <div className="content">
            {MenuComponent ? <MenuComponent /> : null}
          </div>
        </>
      )}

      {!inspecting && <span style={{ fontSize: '20px' }}>{text}</span>}
    </div>
  );
};
