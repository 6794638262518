import React from 'react';
import { createdModifiedMetaPairs } from '../../../../../../../scripts/page-search/result-utils';
import { Timestamp } from '../../../../../../controls/Timestamp';
import { MetaPair, PreviewItem } from '../../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../../previewProps';

interface GeneralTaskPreviewProps extends PreviewProps {
  metaPairs?: MetaPair[];
}

export const GeneralTaskPreview: React.FC<GeneralTaskPreviewProps> = ({
  result,
  metaPairs,
}) => {
  // Use meta override if available
  metaPairs =
    metaPairs && metaPairs.length > 0
      ? metaPairs
      : [...createdModifiedMetaPairs(result)];

  const {
    assignee,
    status,
    due_time,
    attachments,
    source,
    unhighlighted_name,
  } = result;

  if (status) {
    if (typeof status === 'string' && status.length > 0) {
      metaPairs.unshift({
        name: 'Status',
        value: <span className="status">{status}</span>,
      });
    } else if (status) {
      metaPairs.unshift({
        name: 'Status',
        value: <span className="status">{status}</span>,
      });
    }
  }

  if (due_time && due_time > 100) {
    metaPairs.push({
      name: 'Due date',
      value: (
        <span className="lighter">
          <Timestamp unixTime={due_time} />
        </span>
      ),
    });
  }

  if (assignee?.name) {
    metaPairs.push({
      name: 'Assigned to',
      value: <span className="heavier">{assignee.name}</span>,
    });
  }

  if (attachments && attachments.length > 0) {
    const files = attachments.map((attachment) => {
      return (
        <li key={attachment.url}>
          <a href={attachment.url} rel="noopener noreferrer" target="_blank">
            {attachment.name}
          </a>
        </li>
      );
    });

    metaPairs.push({
      name: 'Files',
      value: <ul>{files}</ul>,
    });
  }

  return (
    <PreviewItem
      icon={source}
      metaPairs={metaPairs}
      result={result}
      title={unhighlighted_name}
    />
  );
};
