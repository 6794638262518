import { isExtremeLight, isLight } from '../utils';
import { useBackground } from './redux';

/**
 * Returns `true` if background color is dark enough to use lighter text.
 */
export function useContrast(forceOrg = false): boolean {
  const [isImage, color] = useBackground(forceOrg);
  if (isImage) {
    return true;
  }

  return !isLight(color);
}

/**
 * Returns `true` if background color is extremely bright.
 */
export function useExtremeLight(forceOrg = false): boolean {
  const [isImage, color] = useBackground(forceOrg);
  if (isImage) {
    return true;
  }

  return isExtremeLight(color);
}
