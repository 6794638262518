import React from 'react';
import { AnswerIcon } from '../../../../controls/ui/icons/icons';
import { PageSearchResultItem } from '../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../resultProps';

export const SystemAnswerResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Answer"
      icon={<AnswerIcon />}
      rawContentAsSnippetFallback
      result={result}
      useLocalNav
    />
  );
};
