import React, { FC, useMemo, useState } from 'react';
import {
  AllReferencesSummary,
  QAMessage,
  Reference,
} from '../../../models/QAmodels';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';
import { ReferencesItem } from './items/ReferencesItem';
import { ShowAllReferencesItem } from './items/ShowAllReferencesItem';

export const QAReferencesList: FC<{
  answerId: string;
  citedReferences: Reference[];
  allReferencesSummary: AllReferencesSummary;
  showAllSources?: boolean;
  topicMessages?: QAMessage[];
}> = ({
  answerId,
  citedReferences,
  allReferencesSummary,
  showAllSources = false,
  topicMessages,
}) => {
  const [showEntireList, setShowEntireList] = useState(false);
  const { app_references_count } = allReferencesSummary;

  const maxItems = useMemo(() => {
    if (showEntireList) {
      return citedReferences.length;
    }

    return 8;
  }, [showEntireList, citedReferences]);

  if (
    citedReferences.length === 0 &&
    (app_references_count === undefined || app_references_count.length === 0)
  ) {
    return null;
  }

  return (
    <div>
      <ul className="mt-4 mb-0 mx-0 p-0 flex flex-row flex-wrap gap-2">
        {citedReferences.slice(0, maxItems).map((reference, i) => (
          <ReferencesItem
            index={i}
            item={reference}
            key={`reference_${reference.id}`}
          />
        ))}

        {showAllSources && (citedReferences.length < 8 || showEntireList) && (
          <ShowAllReferencesItem
            allReferencesSummary={allReferencesSummary}
            answerId={answerId}
            topicMessages={topicMessages}
          />
        )}

        {citedReferences.length >= (showAllSources ? 8 : 9) && (
          <div
            className="px-0.5 sm:px-1.5 flex items-center border-gray-30 border border-solid rounded neumorphic-background hover:bg-none hover:bg-purple-10 hover:border-purple-30 cursor-pointer"
            onClick={() => {
              setShowEntireList(!showEntireList);
            }}
            tabIndex={0}
          >
            <UITooltip
              title={
                showEntireList ? 'Collapse references' : 'Expand references'
              }
            >
              <UIIcon name={showEntireList ? 'arrow-up' : 'arrow-down'} />
            </UITooltip>
          </div>
        )}
      </ul>
    </div>
  );
};
