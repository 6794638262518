import { OrgMember } from '../../scripts/models/org-member';
import { uniqBy } from '../../scripts/utils';
import { Action } from '../actions';
import { GlobalState } from '../state';
import { OrgMembersAction } from './actions';
import { OrgMembersSearchQuery } from './state';

export const orgMembersReducer = (
  state: GlobalState,
  action: Action
): GlobalState => {
  const orgMembersState = { ...state.orgMembers };

  switch (action.type) {
    case OrgMembersAction.ADD_ORG_MEMBERS: {
      const newlyAddedMembers = action.payload as OrgMember[];

      orgMembersState.members = uniqBy(
        [...orgMembersState.members, ...newlyAddedMembers],
        'user_id'
      );

      break;
    }

    case OrgMembersAction.ADD_SEARCH_QUERY: {
      const addedQuery = action.payload as OrgMembersSearchQuery;

      const queryExists = orgMembersState.searchedQueries.some(
        (query) => query.q === addedQuery.q && query.page === addedQuery.page
      );

      if (!queryExists) {
        orgMembersState.searchedQueries = [
          ...orgMembersState.searchedQueries,
          addedQuery,
        ];
      }

      break;
    }
  }

  return {
    ...state,
    orgMembers: orgMembersState,
  };
};
