import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { AppDefinition } from '../../../../../../apps/definition';
import { AppState, filterableIndexStates } from '../../../../../../constants';
import { trackEvent } from '../../../../../../extra/sharedMethods';
import {
  getOrgAppData,
  getStatusCode,
  getUserAppData,
} from '../../../../../../redux/selectors';
import { AnalyticsEvent } from '../../../../../../scripts/constants/analytics-event';
import { useAppParam, useUserSafe } from '../../../../../../scripts/hooks';
import {
  getIndexingAppData,
  noop,
  quantityShortHand,
} from '../../../../../../scripts/utils';
import { UIIcon } from '../../../../../controls/ui/UIIcon/UIIcon';
import './AppFilterRow.scss';

interface AppFilterRowProps {
  appDef: AppDefinition;
  count: number;
  selected?: boolean;
}

export const AppFilterRow: FC<AppFilterRowProps> = ({
  appDef,
  count,
  selected,
}) => {
  const user = useUserSafe();
  const [, setAppParam] = useAppParam();

  // Only show indexing/indexed apps
  const statusCode = getStatusCode(user, appDef);

  const handleClick = useCallback(() => {
    trackEvent(AnalyticsEvent.InPageAppFilterClick, {
      source: appDef.shortname,
      statusCode,
      count,
    });

    setAppParam(appDef.shortname);
  }, [appDef.shortname, count, setAppParam, statusCode]);

  const handleHover = useCallback(() => {
    trackEvent(AnalyticsEvent.InPageAppFilterHover, {
      source: appDef.shortname,
      statusCode,
      count,
    });
  }, [appDef.shortname, count, statusCode]);

  if (statusCode === AppState.NotConnected) {
    return null;
  }

  const isAvailable = !!count && filterableIndexStates.has(statusCode);
  const appData = getIndexingAppData(
    getOrgAppData(user, appDef.shortname),
    getUserAppData(user, appDef.shortname)
  );

  const showIndexingStatus =
    appData?.statusCode === AppState.Indexing ||
    appData?.statusCode === AppState.Connecting ||
    appData?.statusCode === AppState.IndexingFailed;

  return (
    <div className="appFilterRow">
      <button
        className={classNames({
          selected,
          disabled: !isAvailable,
        })}
        onClick={isAvailable ? handleClick : noop}
        onMouseEnter={handleHover}
        type="button"
      >
        <UIIcon name={appDef.shortname} size={16} type="apps" />
        {appDef.displayName}
        {showIndexingStatus && <UIIcon name="spinner-animated" />}
        <span className="count">{quantityShortHand(count)}</span>
      </button>
    </div>
  );
};
