import React from 'react';
import {
  createdModifiedMetaPairs,
  pushResultMeta,
} from '../../../../../../../scripts/page-search/result-utils';
import { MetaPair } from '../../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../../previewProps';
import { GeneralTaskPreview } from '../../general/GeneralTaskPreview/GeneralTaskPreview';

export const FreshDeskTicketPreview: React.FC<PreviewProps> = ({ result }) => {
  const metaPairs: MetaPair[] = [];

  pushResultMeta(result, metaPairs, 'Source', 'ticket_source');
  return (
    <GeneralTaskPreview
      metaPairs={[...metaPairs, ...createdModifiedMetaPairs(result)]}
      result={result}
    />
  );
};
