import { MemberStatus, OrgMember } from '../models/org-member';
import { invokeAWSGatewayAPI } from './aws';

interface MemberData {
  members: OrgMember[];
  total: number;
  total_uninvited: number;
}

/**
 * Get members from the Google domain.
 */
export const getMembers = async (
  q: string,
  page = 1,
  status?: MemberStatus
): Promise<MemberData> => {
  const query: Record<string, number | string> = {
    q,
    page,
  };

  if (status) {
    query.status = status.toString();
  }

  const { data } = await invokeAWSGatewayAPI<MemberData>({
    path: '/members',
    method: 'GET',
    query,
  });

  return {
    total: data.total,
    total_uninvited: data.total_uninvited,
    members: data.members.map((memberData) => ({
      ...memberData,
      name: (memberData.name as string | null) ?? 'Invited User',
    })),
  };
};

/**
 * Invite members from Google domain to Dashworks.
 */
interface InviteMembersArgs {
  invited_members: {
    user_id: string;
    role: 'admin' | 'member';
  }[];
}

export const inviteMembers = async (args: InviteMembersArgs): Promise<void> => {
  await invokeAWSGatewayAPI({ path: '/invite', method: 'POST', data: args });
};

interface EmailInviteArgs {
  email: string;
  is_admin: boolean;
}

export const sendEmailInvite = async (
  params: EmailInviteArgs
): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: '/members/send_email_invite',
    method: 'PATCH',
    data: params,
  });
};

export interface MemberActionArgs {
  emails: string[];
  is_admin?: boolean;
  run_all_matching_status?: MemberStatus;
}

export const takeMemberAction = async (
  action: string,
  params: MemberActionArgs
): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: `/members/action/${action}`,
    method: 'POST',
    data: params,
  });
};
