import './ListView.scss';
import React, { Fragment, useCallback, useRef } from 'react';
import { SearchLoadState } from '../../../../../redux/pageSearch/actions';
import { FileType } from '../../../../../scripts/constants/filters';
import {
  useArrowNav,
  useFileTypeParam,
  useFlag,
  usePageSearch,
} from '../../../../../scripts/hooks';
import { PageSearchResult } from '../../../../../scripts/models/page-search-result';
import { ResultComponent } from '../../../../../scripts/page-search/result-lookup';
import { count, groupBy, splitToBack } from '../../../../../scripts/utils';
import { Loading, LoadingSize } from '../../../../controls/Loading/Loading';
import { PersonCard } from '../../../PersonCard/PersonCard';
import {
  AppFilterSuggestion,
  AppInstallSuggestion,
} from '../../../notices/AppFilterSuggestion';
import { AppRequest } from '../../../notices/AppRequest';
import { FileTypeNotice } from '../../../notices/FileTypeNotice';
import { PreviewContainer } from '../../../previews/PreviewContainer/PreviewContainer';
import { MessageResults } from '../../../sections/MessageResults/MessageResults';
import { AccessDiscovery } from '../../AccessDiscovery/AccessDiscovery';
import { PinnedResultsContainer } from '../../PinnedResultsContainer/PinnedResultsContainer';
import { InPageAppFilter } from '../../filters/AppFilter/InPageAppFilter/InPageAppFilter';
import { ShowMoreButton } from '../../misc/ShowMoreButton';
import { ResultViewProps } from '../View';

const presentationTypes = new Set(['pptx', 'ppt', 'odp']);

// eslint-disable-next-line complexity
export const ListView: React.FC<ResultViewProps> = ({
  personResult,
  pinnedResults,
  results,
  hasMoreResults,
  messages,
  accessDiscoveryResults,
  accessRequestDialogProps,
}) => {
  const showMoreRef = useRef<() => void>(null);
  const showMoreAction = useCallback(() => {
    showMoreRef.current?.();
  }, []);

  const accessDiscoveryEnabled = useFlag('searchAccessDiscovery');

  const arrowNav = useArrowNav<HTMLDivElement>('.resultItem', showMoreAction);

  const isLoadingMore = usePageSearch(
    (pageSearch) => pageSearch.load === SearchLoadState.LoadingMore
  );

  const fileTypeThreshold = useFlag('fileTypeFilterNoticeThreshold');
  const appFilterSuggestion = useFlag('appFilterSuggestions');
  const [fileType] = useFileTypeParam();

  const showPresentationSuggestion =
    fileType === FileType.Any &&
    count(results.slice(0, 15), (result) =>
      presentationTypes.has(result.mimeType)
    ) >= fileTypeThreshold;

  const mergedResults = (
    accessDiscoveryEnabled ? accessDiscoveryResults : []
  ).reduce(
    (element, accessDiscoveryResult) => {
      element.splice(
        accessDiscoveryResult.position,
        0,
        <AccessDiscovery
          accessRequestDialogProps={accessRequestDialogProps}
          hiddenResult={accessDiscoveryResult}
          key={accessDiscoveryResult.id_hash}
        />
      );

      return element;
    },

    results.map((result) => (
      <ResultComponent key={result.objectID} result={result} />
    ))
  );

  const [fullMatches1, fullMatches2] = splitToBack(mergedResults, 5);

  const hasAnyContent =
    !!personResult ||
    pinnedResults.length > 0 ||
    messages.length ||
    results.length > 0;

  const messageResultGroups = groupBy(messages, (m) => m.source);

  return (
    <div className="listView">
      <div className="resultsListContainer" ref={arrowNav}>
        <div className="notices">
          {appFilterSuggestion && hasAnyContent && (
            <>
              <AppRequest />
              <AppFilterSuggestion />
              <AppInstallSuggestion />
            </>
          )}
          {showPresentationSuggestion && (
            <FileTypeNotice fileType={FileType.Presentation} />
          )}
        </div>
        <div className="resultsList">
          {personResult && <PersonCard personResult={personResult} />}
          {pinnedResults.length > 0 && (
            <PinnedResultsContainer>
              {pinnedResults.map((result: PageSearchResult) => (
                <ResultComponent key={result.objectID} result={result} />
              ))}
            </PinnedResultsContainer>
          )}
          {!!fullMatches1.length && <hr />}
          {fullMatches1.slice(0, 3).map((result) => result)}
          {messageResultGroups.size > 0 && (
            <>
              {Array.from(messageResultGroups.entries()).map(([k, v]) => (
                <Fragment key={k}>
                  <hr />
                  <MessageResults messages={v} source={k} />
                </Fragment>
              ))}
              <hr />
            </>
          )}
          {fullMatches1.slice(3).map((result) => result)}

          {fullMatches2.map((result) => result)}
        </div>
        {isLoadingMore && (
          <Loading
            size={LoadingSize.Medium}
            text="Fetching additional results..."
          />
        )}
        {hasMoreResults && !isLoadingMore && (
          <ShowMoreButton ref={showMoreRef} />
        )}
      </div>
      <div>
        <PreviewContainer />
        {results.length + messages.length > 0 && <InPageAppFilter />}
      </div>
    </div>
  );
};
