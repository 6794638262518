import React, { FC } from 'react';
import './links.scss';

export const SupportLink: React.FC = ({ children }): JSX.Element => {
  return (
    <span
      className="supportLink"
      onClick={() => {
        // eslint-disable-next-line new-cap
        Pylon('show');
      }}
    >
      {children ?? 'support'}
    </span>
  );
};

export const bookCallUrl = 'https://dashworks.ai';
export const appSuggestionUrl = 'https://dashworks.ai/feature-roadmap';

export const launchDarklyOverview =
  'https://app.launchdarkly.com/default/test/features';
export const launchDarklyFlag = (flagName: string): string =>
  `${launchDarklyOverview}/${flagName}`;

export const privacyPolicy = 'https://dashworks.ai/privacy-policy';
export const termsOfService = 'https://dashworks.ai/terms-of-service';

interface DeepLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  deepHref?: string;
}

export const DeepLink: FC<DeepLinkProps> = ({ deepHref, href, ...props }) => {
  return (
    <a
      href={deepHref ?? href}
      rel="noopener noreferrer"
      target={deepHref ? '_top' : '_blank'}
      {...props}
    />
  );
};
