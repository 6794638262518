import { Sources, SupportedLlm } from './User';

interface WorkflowAuthor {
  display_name: string;
  email: string;
  icon: string;
}

export interface Workflow {
  id: string;
  org_id: string;
  default: boolean;
  title: string;
  description?: string;
  visibility: 'PRIVATE' | 'PUBLIC';
  sources: Sources;
  preferred_llm: SupportedLlm | null;
  template: string;
  uses: number;
  author: WorkflowAuthor;
  created_at: number;
  modified_at: number;
}

export enum WorkflowModalTypes {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
  VIEW_TEMPLATE = 'VIEW_TEMPLATE',
  VIEW_ALL_TEMPLATES = 'VIEW_ALL_TEMPLATES',
}
