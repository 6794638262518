import React, { FC } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AllReferencesSummary, QAMessage } from '../../../../models/QAmodels';
import { useQAController } from '../../../../scripts/QAController';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { ReferenceIcon } from '../QAReferenceUtil';

export const ShowAllReferencesItem: FC<{
  answerId: string;
  allReferencesSummary: AllReferencesSummary;
  topicMessages?: QAMessage[];
}> = ({ answerId, allReferencesSummary, topicMessages }) => {
  const qaController = useQAController();
  const parentMessage = qaController.getUserMessageForAssistantAnswer(
    answerId,
    topicMessages
  );

  const { app_references_count } = allReferencesSummary;

  if (app_references_count === undefined || app_references_count.length === 0) {
    return null;
  }

  const appCount = window.innerWidth < 768 ? 1 : 3;

  return (
    <div
      // eslint-disable-next-line max-len
      className="w-[44%] sm:w-[30%] py-1 px-1 sm:px-2 flex items-center gap-1.5 border-gray-30 border border-solid rounded neumorphic-background hover:bg-none hover:bg-purple-10 hover:border-purple-30 cursor-pointer"
      onClick={() => {
        const body = {
          allReferencesSummary,
          query_id: parentMessage?.row_id,
        };

        trackEvent(AnalyticsEvent.QASeeAllSourcesClicked, body);

        qaController.triggerEvent('hideAppsNotifications', {});
        qaController.triggerEvent('showReferencesSidecar', body);
      }}
    >
      <div className="flex">
        {app_references_count.slice(0, appCount).map((app_count) => (
          <div
            className="flex-shrink-0 flex items-center justify-center rounded-full"
            key={app_count.appName}
          >
            <ReferenceIcon size={16} source={app_count.appName} />
          </div>
        ))}
        {app_references_count.length > appCount && (
          <div className="flex-shrink-0 bg-white w-5 h-5 flex items-center justify-center rounded-full text-2xs font-medium">
            {Math.min(app_references_count.length - appCount, 9)}+
          </div>
        )}
      </div>
      <div className="text-xs truncate">Explore more</div>
    </div>
  );
};
