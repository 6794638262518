import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Returns a tuple of `[value, setTruthy, setFalsy]`.
 * This function is useful as it does not require the consumer to explicitly `memo`/`useCallback` the setters.
 */
export const useBoolState = (
  defaultValue: boolean
): [boolean, () => void, () => void, (val: boolean) => void] => {
  const [val, setVal] = useState(defaultValue);

  return [
    val,
    useCallback(() => {
      setVal(true);
    }, []),
    useCallback(() => {
      setVal(false);
    }, []),
    setVal,
  ];
};

export const useToggle = (defaultValue: boolean): [boolean, () => void] => {
  const [val, setVal] = useState(defaultValue);

  const toggle = useCallback(() => {
    setVal(!val);
  }, [val]);

  return [val, toggle];
};

/**
 * Always lower cases the passed string.
 */
export function useLowerCase(init: string): [string, (val: string) => void] {
  const [raw, setRaw] = useState(init.toLowerCase());
  const setStr = useCallback(
    (str: string) => {
      setRaw(str.toLowerCase());
    },
    [setRaw]
  );

  return [raw, setStr];
}

/**
 * Used to detect changes.
 * See: https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

/**
 * Hook that limits the maximum length of a string, useful for restricting form input lengths.
 */
export const useMaxLength = (
  initial: string,
  maxLength: number
): [string, (val: string) => void, boolean] => {
  const [innerValue, setInnerValue] = useState(initial);
  const setter = useCallback(
    (val: string) => {
      setInnerValue(val.slice(0, maxLength));
    },
    [maxLength]
  );

  return [innerValue, setter, innerValue.length === maxLength];
};
