import { makeLazyComponent } from '../../../scripts/utils';

export const LazyContentAwareImage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "content-aware-image" */ './ContentAwareImage'
      )
    ).ContextAwareImage
);
