import { ReactNode, useMemo } from 'react';
import { useDispatch } from '../../redux/store';
import { addToast, removeToast, ToastInit } from '../../redux/toast/actions';
import { ToastType } from '../models/toast';

export interface Toaster {
  create(toast: ToastInit): void;
  delete(uid: ReactNode): void;
  success(message: ReactNode, timeout?: number): void;
  failure(message?: ReactNode, timeout?: number): void;
  sticky(toast: ToastInit): void;
  withPromise<T>(
    promise: Promise<T>,
    success: ReactNode,
    failure?: string,
    successTime?: number
  ): Promise<void>;
}

export const useToaster = (): Toaster => {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      create(toast: ToastInit) {
        dispatch(addToast(toast));
      },
      delete(uid: string) {
        dispatch(removeToast(uid));
      },
      success(message: string, timeout = 3000) {
        this.create({
          message,
          type: ToastType.SUCCESS,
          timeout,
        });
      },
      failure(
        message = 'Last action failed, please try again.',
        timeout = 4000
      ) {
        this.create({
          message,
          timeout,
          type: ToastType.FAILURE,
        });
      },
      sticky(toast: ToastInit) {
        this.create({
          ...toast,
          timeout: 0,
        });
      },
      async withPromise<T>(
        promise: Promise<T>,
        success: string,
        failure: string,
        successTime?: number
      ): Promise<void> {
        return promise
          .then(() => {
            this.success(success, successTime);
          })
          .catch((error) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            this.failure(error.message ?? failure);
            throw error;
          });
      },
    }),
    [dispatch]
  );
};
