import React, { useCallback, useState } from 'react';
import { RadioList } from '../../components/controls/RadioList/RadioList';
import { UITextbox } from '../../components/controls/ui/UITextbox/UITextbox';
import { withInstallModal } from '../../hoc/withInstallModal';
import { useUserSafe } from '../../scripts/hooks';
import { ConnectStep } from '../generic/Components';

export const AppInstallModal = withInstallModal(
  ({ app, connectInProgress, handleAPIInstall, isOrg }) => {
    const user = useUserSafe();

    const [isCustomDomain, setIsCustomDomain] = useState(false);
    const [customDomain, setCustomDomain] = useState('');
    const [teamSlug, setTeamSlug] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [email, setEmail] = useState(user.email);

    const handleClickDone = useCallback(async () => {
      return handleAPIInstall({
        api_key: apiKey,
        email,
        ...(isCustomDomain
          ? { custom_domain: customDomain.replace(/\/$/, '') }
          : { team_slug: teamSlug }),
      });
    }, [
      handleAPIInstall,
      apiKey,
      email,
      isCustomDomain,
      customDomain,
      teamSlug,
    ]);

    const connectDisabled =
      (teamSlug.trim().length === 0 && customDomain.trim().length === 0) ||
      apiKey.trim().length === 0;

    return (
      <>
        {!isOrg && (
          <ConnectStep step={1} title="Does your instance use a custom domain?">
            <p>
              Note: Only available on {app.definition.displayName} Enterprise
              plan.
            </p>
            <RadioList
              onChange={setIsCustomDomain}
              options={[
                { value: true, displayName: 'Yes' },
                { value: false, displayName: 'No' },
              ]}
              selected={isCustomDomain}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              setPaywallModalOpen={() => {}}
            />
          </ConnectStep>
        )}
        {isCustomDomain ? (
          <>
            <ConnectStep step={2} title="Enter Custom Instance URL">
              <p>
                Your custom instance URL looks like:{' '}
                <strong>https://stackoverflow.company.com</strong>
              </p>
              <div className="stepField">
                <UITextbox
                  className="stepFieldInput"
                  onChange={setCustomDomain}
                  placeholder="https://stackoverflow.company.com"
                  size="large"
                  type="text"
                  value={customDomain}
                />
              </div>
            </ConnectStep>
            <ConnectStep step={3} title="Enter API Access Key">
              <p>
                <p>
                  Generate an API Access Key. Follow the instructions below to
                  manage your API Access Key:
                  <ul>
                    <li>Navigate to your user's API applications page.</li>
                    <li>
                      Type a name in the "API application name" field, and click
                      Create.
                    </li>
                    <li>
                      Click "Show details" and copy the newly created
                      application's key.
                    </li>
                  </ul>
                </p>
                <p>Paste the API Access Key and click connect.</p>
              </p>
              <div className="stepField">
                <UITextbox
                  className="stepFieldInput"
                  onChange={setApiKey}
                  placeholder="Enter API Access Key"
                  size="large"
                  type="password"
                  value={apiKey}
                />
              </div>
            </ConnectStep>
            <ConnectStep
              step={4}
              title={`Enter ${app.definition.displayName} Email`}
            >
              <p>
                Please enter your {app.definition.displayName} Account email.
              </p>
              <div className="stepField">
                <UITextbox
                  className="stepFieldInput"
                  onChange={setEmail}
                  placeholder={`Enter ${app.definition.displayName} email`}
                  size="large"
                  value={email}
                />
              </div>
            </ConnectStep>
          </>
        ) : (
          <>
            <ConnectStep step={isOrg ? 1 : 2} title="Enter team slug">
              <p>
                Your Team slug is part of the URL of your Teams instance:
                <div>
                  https://stackoverflow.com/c/<strong>my-team-slug</strong>/
                </div>
              </p>
              <div className="stepField">
                <UITextbox
                  className="stepFieldInput"
                  onChange={setTeamSlug}
                  placeholder="my-team-slug"
                  size="large"
                  type="text"
                  value={teamSlug}
                />
              </div>
              <p className="note">
                <a
                  href="https://stackoverflowteams.com/users/login"
                  rel="noreferrer"
                  target="_blank"
                >
                  Open {app.definition.displayName}{' '}
                </a>
              </p>
            </ConnectStep>
            <ConnectStep
              step={isOrg ? 2 : 3}
              title="Enter Personal Access Token (PAT)"
            >
              <p>
                <p>
                  Generate a Personal Access Token (PAT). Follow{' '}
                  <a
                    href="https://stackoverflowteams.help/en/articles/4385859-stack-overflow-for-teams-api#:~:text=API%20call%20header.-,The%20PAT%20interface,-To%20create%20and"
                    rel="noreferrer"
                    target="_blank"
                  >
                    these
                  </a>{' '}
                  instructions to manage your PAT tokens.
                </p>
                <strong>
                  Dashworks requires read-only access to your team. We advise
                  you to keep the expiration date to "No expiration" for a
                  smooth experience.
                </strong>
                <p>Paste the Personal Access Token (PAT) and click connect.</p>
              </p>
              <div className="stepField">
                <UITextbox
                  className="stepFieldInput"
                  onChange={setApiKey}
                  placeholder="Enter Personal Access Token (PAT)"
                  size="large"
                  type="password"
                  value={apiKey}
                />
              </div>
            </ConnectStep>
            <ConnectStep
              step={isOrg ? 3 : 4}
              title={`Enter ${app.definition.displayName} Email`}
            >
              <p>
                Please enter your {app.definition.displayName} Account email.
              </p>
              <div className="stepField">
                <UITextbox
                  className="stepFieldInput"
                  onChange={setEmail}
                  placeholder={`Enter ${app.definition.displayName} email`}
                  size="large"
                  value={email}
                />
              </div>
            </ConnectStep>
          </>
        )}

        {connectInProgress(handleClickDone, connectDisabled)}
      </>
    );
  }
);
