import { User } from '../models/User';
import { AllSearchRelatedParams } from '../scripts/hooks';
import { AwsTokens } from '../scripts/models/aws-tokens';
import { CommonTokens } from '../scripts/models/login-tokens';
import { getSearchHistory } from '../scripts/page-search/search-history';
import { DebugState, DEFAULT_DEBUG_STATE } from './debug/state';
import { DEFAULT_META_STATE, MetaState } from './meta/state';
import { DEFAULT_ORG_MEMBERS_STATE, OrgMembersState } from './orgMembers/state';
import { DEFAULT_PAGE_SEARCH_STATE, PageSearchState } from './pageSearch/state';
import { DEFAULT_SIDEBAR_STATE, SidebarState } from './sidebar/state';
import { DEFAULT_TOAST_STATE, ToastState } from './toast/state';
import {
  DEFAULT_WORKFLOW_STORE,
  WorkflowStore,
} from './workflow/workflowState';

export interface Sorting {
  sort: SortProperty;
  order: SortOrder;
}

export enum SortProperty {
  Created = 'created',
  Modified = 'modified',
  Relevance = 'relevance',
}

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface Tokens {
  awsTokens?: AwsTokens;
  loginTokens?: CommonTokens;
}

export interface GlobalState {
  user?: User;
  searchHistory: AllSearchRelatedParams[];
  tokens?: Tokens;
  pageSearch: PageSearchState;
  sidebar: SidebarState;
  meta: MetaState;
  toast: ToastState;
  workflows: WorkflowStore;
  debug: DebugState;
  featureFlagsFullyLoaded: boolean;
  recommendedQueries?: string[];
  orgMembers: OrgMembersState;
}

export const DEFAULT_STATE: GlobalState = {
  searchHistory: getSearchHistory(),
  // New page search state
  pageSearch: DEFAULT_PAGE_SEARCH_STATE,
  sidebar: DEFAULT_SIDEBAR_STATE,
  meta: DEFAULT_META_STATE,
  toast: DEFAULT_TOAST_STATE,
  workflows: DEFAULT_WORKFLOW_STORE,
  debug: DEFAULT_DEBUG_STATE,
  /**
   * LaunchDarkly has no good indicator as to when feature flags are fully loaded for the current user.
   * Thus we store this in redux for certain use cases where we need to ensure feature flags are fully up to date.
   */
  featureFlagsFullyLoaded: false,
  orgMembers: DEFAULT_ORG_MEMBERS_STATE,
};
