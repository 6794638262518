import './ResultFilters.scss';
import React, { useEffect } from 'react';
import { AppName } from '../../../../apps/definition';
import { SearchLoadState } from '../../../../redux/pageSearch/actions';
import { ObjectType } from '../../../../scripts/constants/filters';
import { useAllFilters, usePageSearch } from '../../../../scripts/hooks';
import { AppFilter } from '../filters/AppFilter/AppFilter';
import { ResetFilters } from '../filters/AppFilter/ResetFilters';
import { FileTypeFilter } from '../filters/FileTypeFilter/FileTypeFilter';
import { ModifiedFilter } from '../filters/ModifiedFilter/ModifiedFilter';
import { PersonFilter } from '../filters/PersonFilter/PersonFilter';
import { SortFilter } from '../filters/SortFilter/SortFilter';
import { useIsGridView } from '../misc/hooks';

const sortDisabledApps = new Set<AppName>(['gmail', 'outlook']);

export const ResultFilters: React.FC = () => {
  const [query, setQuery] = useAllFilters();
  const isGridView = useIsGridView();
  const searchEmpty = usePageSearch(
    (ps) =>
      ps.results.length + ps.messages.length === 0 &&
      ps.load === SearchLoadState.Loaded
  );

  useEffect(() => {
    if (query.filter !== ObjectType.Files) {
      setQuery({
        filetype: undefined,
      });
    }
  }, [query.filter, setQuery]);

  return (
    <div className="resultFilters">
      {(isGridView || searchEmpty) && <AppFilter />}
      {query.filter === ObjectType.Files && <FileTypeFilter />}
      <PersonFilter />
      <ModifiedFilter />
      {!sortDisabledApps.has(query.app as AppName) && <SortFilter />}
      <ResetFilters />
    </div>
  );
};
