import { useLocation } from 'react-router-dom';
import { useSearchParam } from './query';

/**
 * Returns `true` if user is on homepage.
 */
export const useIsHomepage = (): boolean => {
  const [searchParam] = useSearchParam();
  const location = useLocation();
  return location.pathname === '/' && searchParam.trim().length === 0;
};
