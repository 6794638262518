import { setSidebarOpen } from '../redux/sidebar/actions';
import { store } from '../redux/store';
import { getStorageItem, setStorageItem, StorageKey } from './storage';

// Must be due to user action
export const toggleSidebar = (open: boolean): void => {
  store.dispatch(setSidebarOpen(open));
  setStorageItem(StorageKey.SidebarOpen, { open });
};

export const getSidebarOpenFromStorage = (): boolean => {
  return !!(getStorageItem(StorageKey.SidebarOpen) as { open: boolean } | null)
    ?.open;
};
