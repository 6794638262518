import React, { FC, useState } from 'react';
import { AuthorDetails } from '../../components/controls/AuthorDetails/AuthorDetails';
import { DateFormat, Timestamp } from '../../components/controls/Timestamp';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/shadcn/lib/components/popover';
import { UploadedFile } from '../../models/File';

export const FileInfoPopover: FC<{
  file: UploadedFile;
}> = ({ file }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover onOpenChange={setPopoverOpen} open={popoverOpen}>
      <PopoverTrigger asChild className="hidden sm:block">
        <div
          className="flex"
          onMouseEnter={() => {
            setPopoverOpen(true);
          }}
          onMouseLeave={() => {
            setPopoverOpen(false);
          }}
        >
          <UIIcon name="info" />
        </div>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col w-80 z-[99999]" side="right">
        <div className="flex justify-between">
          <div className="font-medium">File</div>
          <div>
            <UIIcon name="doc" size={20} />
          </div>
        </div>
        <div className="flex justify-between mt-2 gap-2">
          <div className="text-cloud-40">Name</div>
          <div className="">
            {file.title.length > 20
              ? `${file.title.slice(0, 20)}...`
              : file.title}
          </div>
        </div>
        {file.creator.email && (
          <div className="flex justify-between mt-2 gap-2">
            <div className="text-cloud-40">Uploaded by</div>
            <AuthorDetails
              displayName={file.creator.name ?? file.creator.email}
              icon={file.creator.icon}
            />
          </div>
        )}
        {file.created_at && (
          <div className="flex justify-between mt-2 gap-2">
            <div className="text-cloud-40">Uploaded on</div>
            <Timestamp
              format={DateFormat.DateOnly}
              unixTime={file.created_at}
            />
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
