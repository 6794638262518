import {
  DEFAULT_WORKSPACE_WORKFLOW_STATE,
  WorkflowType,
  WorkspaceOnboardingStep,
  WorkspaceWorkflowState,
} from '../../models/workflow';

export interface WorkflowStoreState<S extends object, U extends string> {
  state: S;
  currentStep: U;
}

export interface WorkflowStoreInner {
  [WorkflowType.WORKSPACE_ONBOARDING]: WorkflowStoreState<
    WorkspaceWorkflowState,
    WorkspaceOnboardingStep
  >;
}

export interface WorkflowStore {
  data: WorkflowStoreInner;
}

export const DEFAULT_WORKFLOW_STORE: WorkflowStore = {
  data: {
    [WorkflowType.WORKSPACE_ONBOARDING]: {
      state: DEFAULT_WORKSPACE_WORKFLOW_STATE,
      currentStep: WorkspaceOnboardingStep.CONNECT_APPS,
    },
  },
};
