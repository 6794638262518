import React, { FC, useMemo } from 'react';
import { StaticAnswerType } from '../../../models/QAmodels';
import { useQAController } from '../../../scripts/QAController';
import { useUserSafe } from '../../../scripts/hooks';
import { useRecommendedWorkflows } from '../../../scripts/hooks/workflows';
import { RecommendedWorkflows } from '../../recommendedWorkflows/RecommendedWorkflows';
import { QATextInputBox, QATextInputBoxProps } from './QATextInputBox';

interface QATextInputViewProps extends QATextInputBoxProps {
  hideRecommendedWorkflows?: boolean;
}

export const QATextInputView: FC<QATextInputViewProps> = ({
  className,
  autofocus,
  hideNewTopicButton,
  hideStopAnswerGenerationButton,
  showPopupFromBottom,
  disableNewTopicButton,
  disableNewTopicButtonReason,
  continueConversationId,
  setOpenPaywallModal,
  sendQueryCallback,
  newTopicCallback,
  hideRecommendedWorkflows = false,
}: QATextInputViewProps) => {
  const user = useUserSafe();
  const { workflows: recommendedWorkflows } = useRecommendedWorkflows();

  const qaController = useQAController();
  const messages = qaController.useMessages();
  const { isNewTopic } = qaController.getIsNewTopic();

  const isOnboardingMessage = useMemo(
    () =>
      messages[messages.length - 1]?.extraData?.staticAnswerType ===
        // eslint-disable-next-line @cspell/spellchecker
        StaticAnswerType.ONBOARDING_INITAL ||
      messages[messages.length - 1]?.extraData?.staticAnswerType ===
        StaticAnswerType.ONBOARDING_ASK_QUESTION,
    [messages]
  );

  const showRecommendedWorkflows = useMemo(() => {
    return (
      !hideRecommendedWorkflows &&
      !isOnboardingMessage &&
      isNewTopic &&
      !continueConversationId
    );
  }, [
    hideRecommendedWorkflows,
    continueConversationId,
    isNewTopic,
    isOnboardingMessage,
  ]);

  return (
    <div className="flex flex-col items-center my-2 w-screen sm:w-full px-1 sm:px-0 mt-4">
      {showRecommendedWorkflows && (
        <div className="w-full max-w-[750px] mb-2">
          <RecommendedWorkflows recommendedWorkflows={recommendedWorkflows} />
        </div>
      )}
      <QATextInputBox
        autofocus={autofocus}
        className={className}
        continueConversationId={continueConversationId}
        disableNewTopicButton={disableNewTopicButton}
        disableNewTopicButtonReason={disableNewTopicButtonReason}
        hideNewTopicButton={hideNewTopicButton}
        hideStopAnswerGenerationButton={hideStopAnswerGenerationButton}
        newTopicCallback={newTopicCallback}
        sendQueryCallback={sendQueryCallback}
        setOpenPaywallModal={setOpenPaywallModal}
        showPopupFromBottom={showPopupFromBottom}
      />
      <div className="mt-2 max-w-[750px] text-center text-xs text-cloud-40">
        {user.orgByOrgId.disclaimer}
      </div>
    </div>
  );
};
