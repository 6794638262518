import './QAPageView.scss';
import React, { FC, useEffect, useMemo } from 'react';
import { useIsWorkspaceSetupNeeded } from '../../hooks/subscriptionHooks';
import { TopicType } from '../../models/QAmodels';
import { processGQLConversation } from '../../models/User';
import { useAppConnectionSummary } from '../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';
import { setUserPhotoUrl } from '../../redux/meta/actions';
import { useDispatch } from '../../redux/store';
import { QAController, QAControllerProvider } from '../../scripts/QAController';
import { getUserPhoto } from '../../scripts/apis';
import { useToaster, useUserSafe } from '../../scripts/hooks';
import { logError } from '../../scripts/utils';
import { NotificationsProvider } from '../notifications/NotificationsContext';
import { RealtimeChatProvider } from '../realtimeChat/RealtimeChatContext';
import { QAFilesProvider } from './files/QAFilesContext';
import { QATextInputBoxStatesProvider } from './textInputBox/QATextInputBoxStates';

export const QAWrapper: FC = ({ children }) => {
  const qaController = useMemo(() => new QAController(), []);
  const toaster = useToaster();
  const { instantAppConnectedCount } = useAppConnectionSummary();
  const dispatch = useDispatch();
  const isWorkspaceSetupNeeded = useIsWorkspaceSetupNeeded();
  const conversationMemberships = useUserSafe(
    (user) => user.conversationMemberships
  );

  const botsAdminsByAdminId = useUserSafe((user) => user.botsAdminsByAdminId);

  const hasConnectedAnyInstantApp = useMemo(
    () => instantAppConnectedCount > 0,
    [instantAppConnectedCount]
  );

  useEffect(() => {
    getUserPhoto()
      .then((response) => {
        dispatch(setUserPhotoUrl(response.photoUrl));
      })
      .catch(logError);
  }, [dispatch]);

  useEffect(() => {
    if (isWorkspaceSetupNeeded) return;

    qaController.fetchHistoryMessages(true).catch((error) => {
      toaster.failure(
        'Failed to fetch Dash AI messages, please try again later'
      );

      logError(error);
    });

    return () => {
      qaController.destruct();
    };
  }, [
    isWorkspaceSetupNeeded,
    qaController,
    toaster,
    hasConnectedAnyInstantApp,
  ]);

  useEffect(() => {
    if (conversationMemberships) {
      qaController.insertTopics(
        conversationMemberships.nodes.map((item) =>
          processGQLConversation(item.conversation)
        ),
        TopicType.SHARED_WITH_ME
      );
    }
  }, [conversationMemberships, qaController]);

  useEffect(() => {
    if (botsAdminsByAdminId) {
      for (const botAdmin of botsAdminsByAdminId.nodes) {
        const { bot } = botAdmin;
        const botConversations = bot.botsConversations;
        const processedConversations = botConversations.nodes.map((item) =>
          processGQLConversation(item.conversation)
        );

        qaController.insertTopics(
          processedConversations,
          TopicType.MANAGED_BOTS,
          bot.id
        );
      }
    }
  }, [botsAdminsByAdminId, qaController]);

  return (
    <QAControllerProvider value={qaController}>
      <RealtimeChatProvider>
        <NotificationsProvider>
          <QAFilesProvider>
            <QATextInputBoxStatesProvider>
              {children}
            </QATextInputBoxStatesProvider>
          </QAFilesProvider>
        </NotificationsProvider>
      </RealtimeChatProvider>
    </QAControllerProvider>
  );
};
