import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { useQAController } from '../../../../scripts/QAController';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { ChatReferenceResponse } from '../../../../scripts/hooks/chatReferences';
import { Timestamp } from '../../../controls/Timestamp';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../../controls/ui/UIToolTip/UIToolTip';
import { ReferenceIcon } from '../QAReferenceUtil';

const Divider = () => <div className="text-cloud-20">|</div>;

export const ChatReferencesItem: FC<{
  question_id: string;
  reference: ChatReferenceResponse;
}> = ({ question_id, reference }) => {
  const url = reference.url ?? '';
  const title = reference.title || url;
  const { reference_id, cited, modified_time, author, object_type, source } =
    reference;

  const icon_url = reference.extra_fields?.icon_url; // eslint-disable-line unicorn/consistent-destructuring

  const qaController = useQAController();

  const onReferenceClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      trackEvent(AnalyticsEvent.QAReferencesSidecarReferenceClicked, {
        reference_id,
        question_id,
        cited,
      });

      if (source === 'verified_answers') {
        // answer id is expected to be last fragment in url
        const answerId = url.split('/').pop();
        qaController.triggerEvent('showAnswerDialog', { answerId });
        e.preventDefault();
        return;
      }

      if (url) {
        window.open(url);
      }
    },
    [reference_id, question_id, cited, source, url, qaController]
  );

  return (
    <div
      className={classNames(
        'py-3 pl-6 pr-2 flex gap-2 text-sm rounded hover:bg-none hover:bg-purple-10 hover:border-purple-30',
        {
          'cursor-pointer': !!url,
          'border border-solid border-gray-30 neumorphic-background': cited,
          'border border-solid border-white': !cited,
        }
      )}
      onClick={onReferenceClick}
    >
      <div>
        <ReferenceIcon icon_url={icon_url} source={source} url={url} />
      </div>
      <div className="w-[10px] flex-1 flex flex-col">
        <div className="truncate">{title}</div>
        <div className="flex gap-1 text-gray-50">
          {object_type && <div className="capitalize">{object_type}</div>}
          {modified_time !== undefined && modified_time > 0 && (
            <>
              <Divider />
              <Timestamp className="fieldValue" unixTime={modified_time} />
            </>
          )}
          {author && (author.name || author.email) && (
            <>
              <Divider />
              {author.name ?? author.email}
            </>
          )}
        </div>
      </div>

      {cited && (
        <div>
          <UITooltip title="Referenced in answer">
            <div className="px-1 bg-gray-20 rounded">
              <UIIcon name="editor-quote" size={20} />
            </div>
          </UITooltip>
        </div>
      )}
    </div>
  );
};
