import React from 'react';
import { AnalyticsIdentifier } from '../controls/AnalyticsIdentifier/AnalyticsIdentifier';
import { Tours } from './Tours';

export const Analytics: React.FC = () => {
  return (
    <>
      <AnalyticsIdentifier />
      <Tours />
    </>
  );
};
