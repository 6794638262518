import * as Sentry from '@sentry/browser';
import difference from 'lodash/difference';
import React, { ComponentType, FC, lazy, Suspense } from 'react';
import { AppState } from '../constants';
import { trackEvent } from '../extra/sharedMethods';
import { UserApp } from '../models/User';
import { AnalyticsEvent } from './constants/analytics-event';

/**
 * Whether in web app, on staging env.
 */
export const isWebStaging = (): boolean => {
  return WEBAPP_STAGING;
};

/**
 * Whether in web app, on dev env.
 */
export const isWebDev = (): boolean => DEV_SERVER;

export const isChrome = navigator.userAgent.includes('Chrome');
export const isFirefox = navigator.userAgent.includes('Firefox');
export const isSafari = navigator.userAgent.includes('Safari');

export const deploymentOrigin = location.origin;

export const extensionEnabledBrowser = isChrome || isFirefox;

export const installExtension = (): void => {
  if (!extensionEnabledBrowser) {
    return;
  }

  if (isFirefox) {
    trackEvent(AnalyticsEvent.InstallExtensionClicked, {
      browser: 'firefox',
    });

    window.open(FIREFOX_EXTENSION_URL);
  } else if (isChrome) {
    trackEvent(AnalyticsEvent.InstallExtensionClicked, {
      browser: 'chrome',
    });

    window.open(CHROME_EXTENSION_URL);
  }
};

/**
 * Webapp always in iframe for extension.
 */
export const inExtension = (): boolean => window.self !== window.top;

export const inSidePanel = (): boolean =>
  window.location.pathname === '/side-panel';

/**
 * Creates a lazy loaded component with loading from the provided resolver.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function makeLazyComponent<P extends {}>(
  provider: () => Promise<ComponentType<P>>
): React.FC<P> {
  const LazyComponent = lazy(async () => ({
    default: await provider(),
  }));

  const LazyWrapper: FC<P> = (props) => {
    return (
      <Suspense fallback={null}>
        {/* CAST: Uncertain why this doesn't compile*/}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <LazyComponent {...(props as any)} />
      </Suspense>
    );
  };

  return LazyWrapper;
}

/**
 * Returns the position of the element within the full set of selectors.
 * Returns -1 if not found.
 */
export function elementIndex(el: Element | null, selector: string): number {
  if (!el) {
    return -1;
  }

  return Array.from(document.querySelectorAll(selector)).indexOf(el);
}

class NonErrorTypeError extends Error {
  public override readonly name = 'NonErrorTypeError';

  public constructor(public readonly value: unknown) {
    super(`Received non error object ${typeof value} ${String(value)}`);
  }
}

export const logDebug = (...args: unknown[]): void => {
  if (DEV_SERVER || self.location.search.includes('__DEBUG__')) {
    // LOG ONLY WHEN IN DEV ENV or when __DEBUG__ flag is set in query string
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export function logError(err: unknown, context?: unknown): undefined {
  const err2 = err instanceof Error ? err : new NonErrorTypeError(err);
  try {
    // Check is sentry was initialized
    if (!DEV_SERVER && Sentry.getCurrentHub().getClient()) {
      if (context) {
        Sentry.captureException(err2, {
          contexts: {
            my_content: { context: JSON.stringify(context) },
          },
        });
      } else {
        Sentry.captureException(err2);
      }

      return;
    }

    // eslint-disable-next-line no-console
    console.error(err2);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to log', err, err2, error);
  }
}

export function logErrorWithData(err: Error, extraData?: unknown): undefined {
  const err2 = err instanceof Error ? err : new NonErrorTypeError(err);
  try {
    // Check is sentry was initialized
    if (!DEV_SERVER && Sentry.getCurrentHub().getClient()) {
      Sentry.withScope((scope) => {
        scope.setExtra('data', extraData);
        Sentry.captureException(err2);
      });

      return;
    }

    // eslint-disable-next-line no-console
    console.error(err2);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to log', err, err2, error);
  }
}

export const logWarning = (...args: unknown[]): void => {
  // eslint-disable-next-line no-console
  console.warn(args);
};

export const noop = (): void => {
  /* Noop */
};

/**
 * Returns the counts of elements the predicate returned `true` for.
 */
export function count<T>(arr: T[], predicate: (val: T) => boolean): number {
  let cnt = 0;
  for (const v of arr) {
    if (predicate(v)) {
      cnt++;
    }
  }

  return cnt;
}

export const openCenterPopup = (url: string, name: string): Window | null => {
  // Used to position OAuth popup in center of screen
  const popupWidth = 600;
  const popupHeight = 700;
  const popupTop = screen.height / 2 - popupHeight / 2;
  const popupLeft = screen.width / 2 - popupWidth / 2;

  return window.open(
    url,
    name,
    `toolbar=no, menubar=no, width=${popupWidth}, height=${popupHeight}, top=${popupTop}, left=${popupLeft}`
  );
};

/**
 * Returns `true` if company name is valid.
 */
export const validateCompanyName = (companyName: string): boolean => {
  return /^\w([\w !#&.@-])*$/.test(companyName);
};

export const pluralize = (
  word: string,
  entityCount: number,
  suffix = 's'
): string => {
  if (entityCount === 1) {
    return word;
  }

  return word + suffix;
};

const colorBrightness = (backgroundColorHex?: string): number => {
  if (!backgroundColorHex) {
    return 0;
  }

  const hex = backgroundColorHex.replace('#', '');
  const c = Number.parseInt(hex.slice(0, 2), 16);
  const g = Number.parseInt(hex.slice(2, 4), 16);
  const b = Number.parseInt(hex.slice(4, 6), 16);
  return c * 0.299 + g * 0.587 + b * 0.114;
};

/**
 * Returns `true` if should use light color on background color.
 */
export const isLight = (backgroundColorHex?: string): boolean => {
  return colorBrightness(backgroundColorHex) > 155;
};

export const isExtremeLight = (backgroundColorHex?: string): boolean => {
  return colorBrightness(backgroundColorHex) > 240;
};

/*
 * Wraps a function and only calls it after the wrapped function has not been called for `wait`, this is useful
 * for throttling expensive operations while ensuring that the last call always executes.
 *
 * @param immediate If specified the function will always execute on the first call of a series.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<TArgs extends any[]>(
  func: (...args: TArgs) => unknown,
  wait: number,
  immediate = false
): (...args: TArgs) => unknown {
  let timeout: number | undefined;
  return (...args: TArgs) => {
    const later = () => {
      timeout = undefined;
      if (!immediate) {
        func(...args);
      }
    };

    const callNow = immediate && !timeout;
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait) as unknown as number;
    if (callNow) {
      func(...args);
    }
  };
}

/**
 * Takes in a var-arg and removes all falsy values, returns array.
 */
export const unSparse = <T,>(
  ...args: T[]
): Exclude<T, false | null | undefined>[] => {
  return args.filter((v) => !!v) as Exclude<T, false | null | undefined>[];
};

const validateInternal = (raw: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new URL(raw);
    return true;
  } catch {
    return false;
  }
};

export function isValidEmail(email: string): boolean {
  const emailPattern = /^.+@.+\.\w+$/;

  return emailPattern.test(email);
}

export function isValidDomain(domain: string): boolean {
  // https://stackoverflow.com/a/14646633
  const domainPattern =
    /^((?:(?:\w[+.-]?)*\w)+)((?:(?:\w[+.-]?){0,62}\w)+)\.(\w{2,6})$/;

  return domainPattern.test(domain);
}

export function isValidUrl(url: string): boolean {
  const urlPattern =
    /^(https?:\/\/)?(www\.)?([\dA-Za-z-]+\.)+[A-Za-z]{2,4}(\.[A-Za-z]{2,4})?(\/\S*)?$/;

  return urlPattern.test(url);
}

export const validateUrl = (raw: string): [boolean, string | undefined] => {
  const prefixed = `https://${raw}`;
  const canPrefixWithHttps = validateInternal(prefixed);
  if (raw.includes('http') && !raw.includes('//')) {
    return [false, canPrefixWithHttps ? prefixed : undefined];
  }

  return validateInternal(raw)
    ? [true, raw]
    : [false, canPrefixWithHttps ? prefixed : undefined];
};

/**
 * Strips all html tags, useful for showing plain text content.
 */
// eslint-disable-next-line import/no-unused-modules
export const stripHtml = (input: string): string =>
  input.replace(/<\/?[^>]+(>|$)/g, ' ');

/**
 * Acts like `array.map` but in addition allows specifying a limit for the maximum items to map.
 * Additionally allows passing a function that is called with the count of `overflow` (total - mapped), which allows appending an additional item.
 * This function is mostly useful for limiting item counts in react.
 */
// eslint-disable-next-line import/no-unused-modules
export function mapMax<T, R>(
  items: T[],
  max: number,
  fn: (val: T) => R,
  addOnOverflow?: (diff: number) => R
): R[] {
  const out: R[] = [];
  for (const item of items) {
    out.push(fn(item));
    if (out.length === max) {
      break;
    }
  }

  if (items.length > max && addOnOverflow) {
    out.push(addOnOverflow(items.length - max));
  }

  return out;
}

/**
 * Returns a tuple that includes `[addedItems, removedItems]`
 */
function removeAddItems<T>(a: T[], b: T[]): [added: T[], removed: T[]] {
  return [difference(b, a), difference(a, b)];
}

/**
 * Returns a tuple that includes `[addedItems.length, removedItems.length]`
 */
// eslint-disable-next-line import/no-unused-modules
export function removeAddItemsCount<T>(
  a: T[],
  b: T[]
): [added: number, removed: number] {
  const [added, removed] = removeAddItems(a, b);
  return [added.length, removed.length];
}

/**
 * Utility for use with `<Link to={search=...}` />
 */
// eslint-disable-next-line import/no-unused-modules
export function searchParams(
  obj: Record<string, number | string | undefined>
): string {
  // ASSERTION: This is safe in all browsers.
  return `?${new URLSearchParams(obj as Record<string, string>).toString()}`;
}

export function intersperse<T, T2>(arr: T[], sep: T2): (T | T2)[] {
  const out = new Array<T | T2>(arr.length * 2 - 1);
  for (let i = 0; i < arr.length; ++i) {
    // Allowing this since we have a range check.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    out[i * 2] = arr[i]!;
    if (i !== arr.length - 1) {
      out[i * 2 + 1] = sep;
    }
  }

  return out;
}

export function escapeRegExp(str: string): string {
  return str.replace(/[$()*+.?[\\\]^{|}]/g, '\\$&');
}

/**
 * Passes the escaped version of `value` to `fn` which allows further additions of regex syntax.
 * Wraps final string into RegExp
 */
export function wrapStringToRegex(
  value: string,
  fn: (escaped: string) => string,
  flags?: string
): RegExp {
  return new RegExp(fn(escapeRegExp(value)), flags);
}

/**
 * Ensures that only one promise returned by `fn` is active at a time.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dedupePromise = <TArgs extends any[], TReturn>(
  fn: (...args: TArgs) => Promise<TReturn>
): ((...args: TArgs) => Promise<TReturn>) => {
  let current: Promise<TReturn> | undefined;

  return async (...args: TArgs) => {
    if (current) {
      return current;
    }

    return (current = fn(...args).finally(() => {
      current = undefined;
    }));
  };
};

export const quantityShortHand = (val: number): string => {
  val = Math.floor(val);
  if (val < 1000) {
    return Math.floor(val).toString();
  }

  let unit = '';
  if (val >= 1000) {
    val /= 1000;
    unit = 'k';
  }

  if (val > 1000) {
    val /= 1000;
    unit = 'm';
  }

  return `${val.toFixed(1)}${unit}`;
};

export { default as uniqBy } from 'lodash/uniqBy';
export { default as capitalize } from 'lodash/capitalize';
export { default as sortBy } from 'lodash/sortBy';
export { default as isEqual } from 'lodash/isEqual';
export { default as last } from 'lodash/last';
export { default as kebabCase } from 'lodash/kebabCase';

export const groupBy = <T, R>(
  iter: Iterable<T>,
  discriminator: (item: T) => R
): Map<R, T[]> => {
  const out = new Map<R, T[]>();
  for (const item of iter) {
    const key = discriminator(item);
    let list = out.get(key);
    if (!list) {
      list = [];
      out.set(key, list);
    }

    list.push(item);
  }

  return out;
};

/**
 * Splits an array into 2 parts attempts to put at most `maxCount` elements into the second part before filling the first part.
 *
 * @example
 * ```
 * > splitToBack([1, 2, 3, 4, 5, 6], 6)
 * [ [], [ 1, 2, 3, 4, 5, 6 ] ]
 *
 * > splitToBack([1, 2, 3, 4, 5, 6], 4)
 * [ [ 1, 2 ], [ 3, 4, 5, 6 ] ]
 * ```
 */
export const splitToBack = <T,>(arr: T[], maxCount: number): [T[], T[]] => {
  return [arr.slice(0, -maxCount), arr.slice(-maxCount)];
};

const httpsPattern = /^https?:\/\//i;

export const relativeLinkToAbsoluteLocal = (href: string): string => {
  if (!httpsPattern.test(href)) {
    return new URL(href, window.location.origin).href;
  }

  return href;
};

/**
 * Create a new array from `arr` and `arr2` whose values are unique'd by `key`.
 */
export const updateArrayByKey = <T extends object, K extends keyof T>(
  arr: T[],
  arr2: T[],
  key: K,
  onConflict?: (a: T, b: T) => void
): T[] => {
  const temp = new Map(arr.map((i) => [i[key], i]));
  for (const i of arr2) {
    const existing = temp.get(i[key]);
    if (existing) {
      onConflict?.(existing, i);
    }

    temp.set(i[key], i);
  }

  return [...temp.values()];
};

export type PromiseOr<T> = Promise<T> | T;

export const createGenericContext = <T,>(): readonly [
  () => NonNullable<T>,
  React.Provider<T | undefined>
] => {
  const genericContext = React.createContext<T | undefined>(undefined);

  const useGenericContext = () => {
    const contextIsDefined = React.useContext(genericContext);
    if (!contextIsDefined) {
      throw new Error('useGenericContext must be used within a Provider');
    }

    return contextIsDefined;
  };

  return [useGenericContext, genericContext.Provider] as const;
};

/**
 * Calculates the percentage of indexing completed.
 */
export const calculateIndexingPercentage = (
  appData: UserApp | undefined
): number => {
  let percentage = 0;

  // 3 years in MS
  const total_time = 86_400 * 1000 * 365 * 3;

  if (appData?.syncedFrom && appData.syncedUntil) {
    percentage = Math.max(
      0,
      Math.min(
        Math.ceil(
          ((new Date(appData.syncedUntil).getTime() -
            new Date(appData.syncedFrom).getTime()) /
            total_time) *
            100
        ),
        100
      )
    );
  }

  return percentage;
};

/**
 * Returns the app data that is currently being indexed.
 */
export const getIndexingAppData = (
  orgAppData: UserApp | undefined,
  userAppData: UserApp | undefined
): UserApp | undefined => {
  if (orgAppData && !userAppData) {
    return orgAppData;
  }

  if (userAppData && !orgAppData) {
    return userAppData;
  }

  if (userAppData && orgAppData) {
    return orgAppData.statusCode === AppState.Connecting ||
      orgAppData.statusCode === AppState.Indexing ||
      orgAppData.statusCode === AppState.IndexingFailed
      ? orgAppData
      : userAppData;
  }

  return undefined;
};

/**
 * Makes a fetch request to the resource with specified timeout
 */
export async function fetchWithTimeout(
  resource: string,
  options: RequestInit,
  timeout = 8000
): Promise<Response> {
  const optionsWithTimeout = { ...options, timeout };
  const controller = new AbortController();
  const id = setTimeout(() => {
    controller.abort();
  }, timeout);

  const response = await fetch(resource, {
    ...optionsWithTimeout,
    signal: controller.signal,
  });

  clearTimeout(id);
  return response;
}

/**
 * returns if screen size is corresponds to mobile
 */

export function isMobile(): boolean {
  return window.matchMedia('(max-width: 768px)').matches;
}

export function safeCreateUrl(link: string): URL | undefined {
  try {
    return new URL(link);
  } catch (error) {
    logDebug('Error while creating URL object for link', link, error);
    return undefined;
  }
}

export function stripReferences(
  text: string,
  removeSurroundingWhitespace = false
): string {
  const referencePattern = removeSurroundingWhitespace
    ? /\s*\[<span class='inlineRef'>\d+<\/span>]\(.*?\)\s*/g
    : /\[<span class='inlineRef'>\d+<\/span>]\(.*?\)/g;

  return text.replace(referencePattern, '');
}

export function formatReferences(
  text: string,
  removeSurroundingWhitespace = false
): string {
  const referencePattern = removeSurroundingWhitespace
    ? /\s*\[<span class='inlineRef'>(\d+)<\/span>]\((.*?)\)\s*/g
    : /\[<span class='inlineRef'>(\d+)<\/span>]\((.*?)\)/g;

  const references: Record<string, string> = {};

  const formattedText = text.replace(
    referencePattern,
    (_, refNumber: string, url: string) => {
      references[refNumber] = url;
      return `[${refNumber}]`;
    }
  );

  const sortedReferences = Object.entries(references).sort(
    ([a], [b]) => Number.parseInt(a, 10) - Number.parseInt(b, 10)
  );

  let citations = '\n\nCitations:\n';
  for (const [key, value] of sortedReferences) {
    citations += `[${key}] ${value}\n`;
  }

  return formattedText + citations;
}

export function setCookie(
  name: string,
  value: string,
  daysToExpire: number
): void {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);

  const cookieString = `${name}=${value}; expires=${date.toUTCString()}; path=/; domain=.dashworks.ai`;
  document.cookie = cookieString;
}

export function deleteCookie(name: string): void {
  const cookieString = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.dashworks.ai`;
  document.cookie = cookieString;
}

export function getCookie(name: string): string | null {
  const cookiesArray = decodeURIComponent(document.cookie).split('; ');

  for (const cookie of cookiesArray) {
    const [cookieName, cookieValue = null] = cookie.split('=');

    if (cookieName === name) {
      return cookieValue;
    }
  }

  return null;
}

export const isSquareImg = async (url: string): Promise<boolean> => {
  if (!url) {
    return false;
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.addEventListener('load', () => {
      resolve(img.width === img.height);
    });

    img.addEventListener('error', (error) => {
      logDebug(error);
      resolve(false);
    });
  });
};

export const truncateString = (str: string, maxLength: number): string => {
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength)}...`;
  }

  return str;
};

export const areArraysEqual = <T,>(
  array1: T[],
  array2: T[],
  compare: (a: T, b: T) => number
): boolean => {
  const sortedArray1 = [...array1].sort(compare);
  const sortedArray2 = [...array2].sort(compare);

  return (
    sortedArray1.length === sortedArray2.length &&
    sortedArray1.every((value, index) => {
      const value2 = sortedArray2[index];
      return (
        value !== undefined &&
        value2 !== undefined &&
        compare(value, value2) === 0
      );
    })
  );
};
