import './AnswerDialog.scss';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { batch } from '../../../redux/store';
import { answersAPI } from '../../../scripts/apis';
import {
  useBoolState,
  useIsAdmin,
  useMaxLength,
  useToaster,
  useUserSafe,
} from '../../../scripts/hooks';
import { Answer } from '../../../scripts/models/answers';
import { debounce, logError } from '../../../scripts/utils';
import {
  ConfirmDialog,
  ConfirmMode,
} from '../../controls/Dialog/ConfirmDialog';
import { Dialog } from '../../controls/Dialog/Dialog';
import { CopyEditDelete } from '../../controls/commonActions/CopyEditDelete';
import { LazyTextEditor } from '../../controls/ui/TextEditor';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import { duplicateTitle } from '../../messages';
import { NavigatePrompt } from '../../navigation/NavigationPrompt';
import {
  useMarker,
  usePromiseState,
} from '../../pageSearch/results/misc/hooks';
import { MarkdownParser } from '../../text/MarkdownParser';

interface AnswerDialogProps {
  id?: string;
  open: boolean;
  edit?: boolean;
  editModalTitle?: string;
  newAnswerTitle?: string;
  onClose(): void;
}

const confirmFn = answersAPI.delete.bind(answersAPI);

export const AnswerDialog: FC<AnswerDialogProps> = ({
  id,
  open,
  edit,
  onClose,
  editModalTitle,
  newAnswerTitle,
}) => {
  const [title, setTitle] = useState(newAnswerTitle ?? '');
  const [originalTitle, setOriginalTitle] = useState('');
  const [content, setContent] = useMaxLength('', 65_565);
  const [titleTaken, setTitleTaken] = useState(false);
  const [confirmMode, setConfirmMode] = useState(ConfirmMode.None);
  const toaster = useToaster();
  const [contentEdited, setContentEdited] = useState(false);
  const [loading, setLoading] = useState(!!id);
  const [closeConfirmed, setCloseConfirmed, unsetCloseConfirmed] =
    useBoolState(false);

  const [isEditMode, setEditMode, unsetEditMode] = useBoolState(
    (!id || edit) ?? false
  );

  const [answerObj, setAnswerObj] = useState<Answer | undefined>(undefined);
  const isAdmin = useIsAdmin();
  const currentUserEmail = useUserSafe((user) => user.email);

  const history = useHistory();

  const [working, workWrap] = usePromiseState();

  useEffect(() => {
    if (closeConfirmed) {
      onClose();
    }
  }, [closeConfirmed, onClose]);

  useEffect(() => {
    if (!open) {
      return;
    }

    unsetCloseConfirmed();
    setOriginalTitle('');
    setTitle(newAnswerTitle ?? '');
    setContent('');
    setContentEdited(false);
    setTitleTaken(false);
  }, [open, unsetCloseConfirmed, setContent, newAnswerTitle]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (searchTitle: string) => {
      return answersAPI.getAll({ title: searchTitle }).then((answers) => {
        setTitleTaken(
          answers.some((a) => a.title === searchTitle && a.id !== id)
        );
      });
    }, 100),
    [id]
  );

  const onTitleChange = useMarker(setTitle, setContentEdited);
  const onContentChange = useMarker(setContent, setContentEdited);

  useEffect(() => {
    if (!contentEdited || title === originalTitle) {
      return;
    }

    if (!title) {
      setTitleTaken(false);
      return;
    }

    debouncedSearch(title);
  }, [contentEdited, debouncedSearch, originalTitle, title]);

  useEffect(() => {
    setTitle(newAnswerTitle ?? '');
    setContent('');
    if (!id) {
      return;
    }

    setLoading(true);
    answersAPI
      .get(id)
      .then((_answer) => {
        const { title: newTitle, content: newContent } = _answer;
        batch(() => {
          setLoading(false);
          setTitle(newTitle);
          setOriginalTitle(newTitle);
          setContent(newContent);
          setAnswerObj(_answer);
        });
      })
      .then(
        async () => {
          return answersAPI.view(id);
        },
        (error: Error) => {
          if (edit === undefined) {
            onClose();
          } else {
            // we are like using history to open / close dialog
            history.push('/answers');
          }

          toaster.failure('This answer does not exist or was deleted.');
          logError(error);
        }
      )
      .catch(logError);
  }, [history, id, edit, onClose, setContent, newAnswerTitle, toaster]);

  const handleConfirmDeleteDiscard = useCallback(() => {
    setConfirmMode(ConfirmMode.None);
    setCloseConfirmed();
  }, [setCloseConfirmed]);

  const handleCancel = useCallback(() => {
    setConfirmMode(ConfirmMode.None);
  }, []);

  const handleUpsert = useCallback(() => {
    toaster
      .withPromise(
        workWrap(
          id
            ? answersAPI.update(id, { title, content })
            : answersAPI.create({ title, content })
        ),
        `Answer ${id ? 'updated' : 'created'}`
      )
      .then(setCloseConfirmed, logError);
  }, [workWrap, id, title, content, toaster, setCloseConfirmed]);

  const handleDiscard = useCallback(() => {
    if (!isEditMode || !contentEdited) {
      setCloseConfirmed();
      return;
    }

    setConfirmMode(ConfirmMode.Discard);
  }, [contentEdited, isEditMode, setCloseConfirmed]);

  let modalTitle = 'Answer';
  if (id && isEditMode) {
    modalTitle = 'Edit Answer';
  } else if (isEditMode) {
    modalTitle = editModalTitle ?? 'New Answer';
  }

  return (
    <Dialog
      cancelButtonTitle={isEditMode ? 'Cancel' : 'Close'}
      className="answerDialog"
      content={
        <>
          <div>
            <label className="qaLabel" htmlFor="qa-question">
              Question
            </label>
          </div>

          <UITextbox
            autoFocus
            error={titleTaken && duplicateTitle}
            inputClassName="title"
            inputId="qa-question"
            maxLength={256}
            onChange={onTitleChange}
            placeholder="Commonly asked question"
            plain
            readonly={!isEditMode}
            size="large"
            value={title}
          />
          <div className="answerLabelContainer">
            <label className="qaLabel" htmlFor="answer">
              Answer
            </label>
          </div>
          {isEditMode ? (
            <>
              <LazyTextEditor
                onChange={onContentChange}
                placeholder="Give your team the answer"
                value={content}
              />
              <ConfirmDialog
                confirmButtonType="delete"
                mode={confirmMode}
                onCancel={handleCancel}
                onConfirm={handleConfirmDeleteDiscard}
                subject="Edit"
              />
              <NavigatePrompt block={!closeConfirmed} ignoreQuery />
            </>
          ) : (
            <MarkdownParser text={content} />
          )}
        </>
      }
      footer={
        isEditMode && (
          <UIButton
            disabled={titleTaken || !title || !content}
            onClick={handleUpsert}
            processing={working}
          >
            {id ? 'Save' : 'Create'}
          </UIButton>
        )
      }
      loading={loading}
      onClose={handleDiscard}
      open={open}
      showCancel
      title={modalTitle}
      titleActions={
        id && (
          <CopyEditDelete
            deleteFn={confirmFn}
            id={id}
            isEditing={isEditMode}
            onDeleteConfirmed={handleConfirmDeleteDiscard}
            onEdit={setEditMode}
            onEditUnset={unsetEditMode}
            readonly={
              !(isAdmin || currentUserEmail === answerObj?.creator.email)
            }
            size={20}
            subject="answer"
            toggle
          />
        )
      }
    />
  );
};
