import { invokeAWSGatewayAPI, InvokeOptions } from './aws';

export class GenericAPI {
  public constructor(protected readonly path: string) {}

  protected async invoke<T = undefined>(opts: InvokeOptions = {}): Promise<T> {
    if (opts.rawPath) {
      opts.path = opts.rawPath;
    } else if (opts.path) {
      opts.path = `/${this.path}/${opts.path}`;
    } else {
      opts.path = `/${this.path}`;
    }

    return (await invokeAWSGatewayAPI<T>(opts)).data;
  }
}
