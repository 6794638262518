import classNames from 'classnames';
import React, { useCallback } from 'react';
import './FilterBarItem.scss';
import { useFilterParam } from '../../../../scripts/hooks';
import { quantityShortHand } from '../../../../scripts/utils';

interface FilterBarItemProps {
  title: string;
  icon: React.ReactNode;
  filterKey: string;

  count?: number;
}

export const FilterBarItem: React.FC<FilterBarItemProps> = ({
  title,
  icon,
  filterKey,
  count,
}) => {
  const [filterParam, setFilterParam] = useFilterParam();

  // Keep track of state in URL query param
  const setQueryParam = useCallback(() => {
    setFilterParam(filterKey);
  }, [filterKey, setFilterParam]);

  const itemClass = classNames({
    filterBarItem: true,
    active: filterParam === filterKey,
  });

  return (
    <button className={itemClass} onClick={setQueryParam} type="button">
      {icon}
      <div className="title">{title}</div>
      {count !== undefined && (
        <div className="count">{quantityShortHand(count)}</div>
      )}
    </button>
  );
};
