import './Homepage.scss';
import classNames from 'classnames';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { SidebarRoutes } from '../../../../containers/SidebarContainer';
import { trackEvent } from '../../../../extra/sharedMethods';
import { useQAController } from '../../../../scripts/QAController';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import {
  useBackground,
  useBoolState,
  useFlag,
  useUserSafe,
} from '../../../../scripts/hooks';
import { useContrast } from '../../../../scripts/hooks/colors';
import { useExtensionObserver } from '../../../../scripts/hooks/extension';
import { useRecommendedWorkflows } from '../../../../scripts/hooks/workflows';
import {
  extensionEnabledBrowser,
  installExtension,
  isChrome,
} from '../../../../scripts/utils';
import { InstallChromeExtensionDialog } from '../../../controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../../controls/ui/UIIconButton/UIIconButton';
import { HomepageLinks } from '../../../homepage/HomepageLinks/HomepageLinks';
import { QATextInputBox } from '../../../jit-qa/textInputBox/QATextInputBox';
import { RecommendedWorkflows } from '../../../recommendedWorkflows/RecommendedWorkflows';

interface HomepageProps {
  demo?: boolean;
  forceOrgBackground?: boolean;
  removeAutofocus?: boolean;
  setOpenPaywallModal(): void;
}

export const Homepage: React.FC<HomepageProps> = ({
  demo,
  forceOrgBackground,
  removeAutofocus,
  setOpenPaywallModal,
}) => {
  const enableHomepageConfigIcons = useFlag('enableHomepageConfigIcons');

  const user = useUserSafe();
  const [isImage, bgColor, backgroundImage] = useBackground(forceOrgBackground);
  const { workflows: recommendedWorkflows } = useRecommendedWorkflows();
  const extensionInstalled = useExtensionObserver();
  const qaController = useQAController();

  const [redirectToDashAiPage, setRedirectToDashAiPage] = useState(false);
  const [
    openInstallChromeExtensionDialog,
    setOpenInstallChromeExtensionDialog,
    setNotOpenInstallChromeExtensionDialog,
  ] = useBoolState(false);

  const withBackground = demo && isImage && backgroundImage;
  const contrast = useContrast(forceOrgBackground);

  const classes = classNames('homepage', {
    contrast,
    withBackground,
  });

  const styles: CSSProperties = {
    backgroundColor: isImage && backgroundImage ? '' : `#${bgColor}`,
  };

  if (withBackground) {
    styles.backgroundImage = `url("${backgroundImage}")`;
  }

  useEffect(() => {
    trackEvent(
      extensionInstalled
        ? AnalyticsEvent.HomepageOpenedWithExtension
        : AnalyticsEvent.HomepageOpenedWithoutExtension
    );
  }, [extensionInstalled]);

  useEffect(() => {
    qaController.setIsNewTopic(true);
  }, [qaController]);

  if (redirectToDashAiPage) {
    return <Redirect to={SidebarRoutes.JitQA} />;
  }

  return (
    <div className={classes} style={styles}>
      <div className="relative w-full sm:w-10/12 flex flex-col m-auto md:p-16 p-4 max-w-4xl	">
        {/* Background with blur */}
        <div className="absolute inset-0 bg-transparent sm:bg-[#EAE6E626] backdrop-blur-2xl rounded-lg" />
        <div className="relative">
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <UIIcon
                name={contrast ? 'dashworks-white' : 'dashworks'}
                size={32}
                type="apps"
              />
              <div className={`text-4xl ${contrast ? 'text-white' : ''}`}>
                How may I help you today?
              </div>
            </div>
            <div className="mt-8 text-black flex flex-col gap-2">
              <QATextInputBox
                autofocus={!removeAutofocus}
                className="bg-white"
                hideNewTopicButton
                hideStopAnswerGenerationButton
                sendQueryCallback={() => {
                  setRedirectToDashAiPage(true);
                }}
                setOpenPaywallModal={setOpenPaywallModal}
                showPopupFromBottom
              />
              <RecommendedWorkflows
                recommendedWorkflows={recommendedWorkflows}
              />
            </div>
          </div>
          <div className="homepageLinksContainer">
            <HomepageLinks />
            <HomepageLinks forceOrgColorScheme={forceOrgBackground} isOrg />
          </div>
          {enableHomepageConfigIcons && (
            <div className="configButtons">
              {user.admin ? (
                <UIIconButton
                  className="configBtn"
                  link={SidebarRoutes.Appearance}
                  name="pencil"
                  size={34}
                  tooltip="Manage workspace settings"
                />
              ) : (
                <UIIconButton
                  className="configBtn"
                  link={SidebarRoutes.MySettings}
                  name="pencil"
                  size={34}
                  tooltip="Edit homepage"
                />
              )}
              {extensionEnabledBrowser &&
                (extensionInstalled ? (
                  <UIIconButton
                    className="configBtn"
                    link={SidebarRoutes.BrowserExtension}
                    name="extension"
                    size={34}
                    tooltip="Edit browser extension settings"
                  />
                ) : (
                  <UIIconButton
                    className="configBtn"
                    name="download"
                    onClick={
                      isChrome
                        ? setOpenInstallChromeExtensionDialog
                        : installExtension
                    }
                    size={34}
                    tooltip="Install Dashworks extension"
                  />
                ))}
            </div>
          )}
        </div>
      </div>
      <InstallChromeExtensionDialog
        onClose={setNotOpenInstallChromeExtensionDialog}
        open={openInstallChromeExtensionDialog}
      />
    </div>
  );
};
