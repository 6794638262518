import React from 'react';
import { UIIcon } from '../../../../../../controls/ui/UIIcon/UIIcon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';
import './QuipFileResult.scss';

export const QuipFileResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Quip File"
      icon={<UIIcon className="resultQuipFile" name="doc" size={20} />}
      result={result}
    />
  );
};
