import { productionExtensionId, stagingExtensionId } from '../constants';
import { isFirefox } from './utils';

export async function isExtensionInstalled(): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if (isFirefox) {
      resolve('dashworksExtension' in document.body.dataset);
      return;
    }

    const extensionId = DEV_SERVER ? stagingExtensionId : productionExtensionId;
    const e = new Image();
    e.src = `chrome-extension://${extensionId}/assets/logo.png`;

    e.addEventListener('load', () => {
      resolve(true);
    });

    e.addEventListener('error', () => {
      resolve(false);
    });
  });
}
