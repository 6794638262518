import { invokeFastApi } from '../../scripts/apis/fastapi';
import { logError } from '../../scripts/utils';

export const getAblyRealtimeChatToken = async (): Promise<string | null> => {
  try {
    const res = await invokeFastApi<{ token: string }>({
      path: '/realtime-chat/token',
    });

    return res.token;
  } catch (error) {
    logError(error);
    return null;
  }
};

export const getStringSizeInKB = (string: string): number => {
  // Calculate the size of the string in bytes using UTF-8 encoding
  const sizeInBytes = new TextEncoder().encode(string).length;
  // Convert bytes to kilobytes
  return sizeInBytes / 1024;
};

export const chunkString = (string: string, maxChunkSize = 55): string[] => {
  // Convert maxKB to bytes
  const maxBytes = maxChunkSize * 1024;
  const encoder = new TextEncoder();

  // Encode the string to bytes
  const stringBytes = encoder.encode(string);
  const chunks: string[] = [];

  // Loop through the byte array and create chunks
  for (let i = 0; i < stringBytes.length; i += maxBytes) {
    // Slice the byte array
    const chunkBytes = stringBytes.slice(i, i + maxBytes);
    // Decode the chunk back to a string
    const chunk = new TextDecoder().decode(chunkBytes);
    chunks.push(chunk);
  }

  return chunks;
};
