import './UITextArea.scss';
import classNames from 'classnames';
import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { UIIcon } from '../UIIcon/UIIcon';
import { UITextInputProps } from '../common/TextInput';

interface UITextAreaProps extends UITextInputProps {
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  // If true, enable dynamic sizing of textarea based on number of lines
  multilineExpandable?: boolean;

  // Called on key down
  onKeyDown?: (evt: React.KeyboardEvent) => void;

  // Show spinner while working
  isWorking?: boolean;

  // Optional controls which appear in the end of input text box
  rightControls?: React.ReactNode | null;

  inputClassName?: string;

  defaultWhiteInputField?: boolean;
}

export const UITextArea = React.forwardRef<
  HTMLTextAreaElement,
  UITextAreaProps
>(
  (
    {
      className,
      disabled,
      placeholder,
      label,
      readonly,
      value,
      maxLength,
      maxRows,
      minRows,
      error,
      onChange,
      onKeyDown,
      autoFocus,
      inputClassName,
      tabIndex,
      subLabel,
      multilineExpandable = false,
      isWorking,
      rightControls,
      defaultWhiteInputField,
    },
    ref
  ) => {
    const [rows, setRows] = useState<number>(minRows ?? 1);

    const handleChange = useCallback(
      (val: React.ChangeEvent<HTMLTextAreaElement> | string) => {
        if (!onChange) {
          return;
        }

        if (typeof val !== 'string') {
          val = val.currentTarget.value;
        }

        onChange(val);
      },
      [onChange]
    );

    const inputClass = classNames(
      'noResize border border-solid border-gray-30 focus:border-gray-50 placeholder:text-gray-50 disabled:bg-cloud-15 disabled:opacity-40',
      { showWhiteField: defaultWhiteInputField },
      inputClassName
    );

    const classes = classNames(
      {
        withError: !!error,
        readonly,
      },
      'uiTextInput',
      className
    );

    const handleKeyDown = useCallback(
      (ev: KeyboardEvent<HTMLTextAreaElement>) => {
        onKeyDown?.(ev);
      },
      [onKeyDown]
    );

    useEffect(() => {
      if (!value) {
        setRows(minRows ?? 1);
        return;
      }

      if (multilineExpandable) {
        const lines = value.split('\n');
        let currentRows = lines.length;

        for (const line of lines) {
          const approxCharsPerRow = 70;
          currentRows += Math.floor(line.length / approxCharsPerRow);
        }

        if (maxRows && currentRows > maxRows) {
          currentRows = maxRows;
        }

        if (minRows && currentRows < minRows) {
          currentRows = minRows;
        }

        setRows(currentRows);
      }
    }, [value, maxRows, minRows, multilineExpandable]);

    return (
      <div className="uiTextInputContainer">
        {label && (
          <label>
            {label}
            {subLabel && <span className="subLabel">{subLabel}</span>}
          </label>
        )}
        <div className={classes}>
          <textarea
            autoFocus={autoFocus}
            className={inputClass}
            disabled={disabled}
            maxLength={maxLength}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            readOnly={readonly}
            ref={ref}
            rows={multilineExpandable ? rows : undefined}
            tabIndex={tabIndex}
            value={value}
          />
          <div className="controls">
            {isWorking && <UIIcon name="spinner-animated" />}
            {!!rightControls && rightControls}
          </div>
        </div>
        {/* Only show this if the error is an actual node */}
        {error && error !== true && <div className="inputError">{error}</div>}
      </div>
    );
  }
);

UITextArea.displayName = 'UITextArea';
