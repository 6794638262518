import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ConfirmDialog, ConfirmMode } from '../controls/Dialog/ConfirmDialog';

export type ConfirmFn = (
  message: string,
  callback: (ok: boolean) => void
) => void;

export const NavigationPromptHandler = forwardRef<ConfirmFn>((_props, ref) => {
  const [navPrompt, setNavPrompt] = useState('');
  // Store functions in refs as doesn't work for state
  const cancelNav = useRef<() => void>();
  const confirmNav = useRef<() => void>();

  useImperativeHandle(
    ref,
    () => {
      return (message: string, callback: (ok: boolean) => void) => {
        cancelNav.current = () => {
          setNavPrompt('');
          callback(false);
        };

        confirmNav.current = () => {
          setNavPrompt('');
          callback(true);
        };

        setNavPrompt(message);
      };
    },
    []
  );

  return (
    <ConfirmDialog
      mode={navPrompt ? ConfirmMode.Confirm : ConfirmMode.None}
      onCancel={cancelNav.current!}
      onConfirm={confirmNav.current!}
      subTitle={navPrompt}
      subject="Navigation"
      title="Confirm navigation"
    />
  );
});

NavigationPromptHandler.displayName = 'NavigationPromptHandler';
