import React, { useCallback } from 'react';
import { trackEvent } from '../../extra/sharedMethods';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useToaster } from '../../scripts/hooks';
import { logError } from '../../scripts/utils';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { IInnerComponent } from './QAWorkflowTemplateModal';

export const DeleteWorkflowTemplate: React.FC<IInnerComponent> = ({
  onClose,
  workflowId,
}: IInnerComponent) => {
  const toaster = useToaster();
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = useCallback(async () => {
    if (workflowId) {
      setLoading(true);
      try {
        await invokeFastApi({
          path: `/prompts/${workflowId}`,
          method: 'DELETE',
        });

        trackEvent(AnalyticsEvent.WorkflowDeleted, { promptId: workflowId });

        toaster.success('Workflow deleted');
      } catch (error) {
        logError(error);
        toaster.failure('Failed to delete workflow');
      }

      setLoading(false);
    }

    onClose();
  }, [onClose, workflowId, toaster]);

  return (
    <div className="place-self-center text-center mt-[242px] ">
      <div className="font-bold">Are you sure?</div>
      <div>You and the team will lose access to this template.</div>
      <div className="fixed bottom-0 left-0 right-0 flex justify-between p-6">
        <UIButton onClick={onClose} type="secondary">
          Cancel
        </UIButton>
        <UIButton onClick={handleSubmit} processing={loading} type="warning">
          Delete
        </UIButton>
      </div>
    </div>
  );
};
