import './QARecommendedApps.scss';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ctorMap } from '../../../apps';
import { useApps } from '../../../apps/context';
import {
  AppCategory,
  AppName,
  useAppDefinitions,
} from '../../../apps/definition';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import {
  integrationInstallMessages,
  integrationUninstallMessages,
} from '../../../messages';
import { AppConnectionStatus } from '../../../models/User';
import { useAppConnectionStatus } from '../../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';
import { useDispatch } from '../../../redux/store';
import { App } from '../../../scripts/app';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import {
  useBoolState,
  useHistoryPush,
  useToaster,
  useUserSafe,
} from '../../../scripts/hooks';
import { useExtensionObserver } from '../../../scripts/hooks/extension';
import {
  ManualFetchDataType,
  useManualDataFetch,
} from '../../../scripts/hooks/manual-trigger-hooks';
import {
  extensionEnabledBrowser,
  installExtension,
  isChrome,
  logError,
} from '../../../scripts/utils';
import {
  AppInstallController,
  AppInstallControllerProvider,
  AppInstallEventArgs,
  AppInstallRequest,
} from '../../admin/AppsTable/AppInstallController';
import {
  ConnectButton,
  PersonalButton,
} from '../../admin/AppsTableRowOld/AppsTableRowOld';
import {
  ConfirmDialog,
  ConfirmMode,
} from '../../controls/Dialog/ConfirmDialog';
import { InstallChromeExtensionDialog } from '../../controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { UserImage } from '../../controls/UserImage/UserImage';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';
import { useBound } from '../../pageSearch/results/misc/hooks';

// eslint-disable-next-line max-lines-per-function
export const QARecommendedApps: FC = () => {
  const apps = useApps();
  const definitions = useAppDefinitions();
  const appInstallController = useMemo(() => new AppInstallController(), []);
  const toaster = useToaster();
  const userApps = useAppConnectionStatus();
  const { appPreferences } = useUserSafe((user) => user.orgByOrgId);

  const goToAppStore = useHistoryPush(SidebarRoutes.AppsV2);

  const extensionInstalled = useExtensionObserver();

  const [
    openInstallChromeExtensionDialog,
    setOpenInstallChromeExtensionDialog,
    setNotOpenInstallChromeExtensionDialog,
  ] = useBoolState(false);

  const filteredApps = useMemo(() => {
    if (
      appPreferences.recommended_apps_list &&
      appPreferences.recommended_apps_list.length >= 2
    ) {
      const recommendedApps: App[] =
        appPreferences.recommended_apps_list.reduce((acc: App[], curr) => {
          if (checkIfOrgAppConnected(curr.name, userApps)) {
            return acc;
          }

          const definition = definitions[curr.name];
          const AppConstructor = ctorMap.get(curr.name);

          if (definition && AppConstructor && definition.showInAppStore) {
            acc.push(new AppConstructor(definition));
          }

          return acc;
        }, []);

      return recommendedApps;
    }

    const popularApps = apps.filter(
      (app) =>
        app.definition.appCategory?.includes(AppCategory.POPULAR) &&
        !checkIfOrgAppConnected(app.definition.shortname, userApps)
    );

    popularApps.sort((appA, appB) => {
      const appASortOrder = appA.definition.popularAppSortOrder;
      const appBSortOrder = appB.definition.popularAppSortOrder;

      if (appASortOrder && appBSortOrder) {
        return appASortOrder - appBSortOrder;
      }

      if (appASortOrder) return -1;
      if (appBSortOrder) return 1;
      return 0;
    });

    return popularApps;
  }, [apps, appPreferences, definitions, userApps]);

  const [currentAppInstallRequest, setCurrentAppInstallRequest] = useState<
    AppInstallRequest | undefined
  >();

  const [currentAppUninstallRequest, setCurrentAppUninstallRequest] = useState<
    AppInstallRequest | undefined
  >();

  const unsetInstallApp = useBound(setCurrentAppInstallRequest, undefined);
  const unsetUninstallApp = useBound(setCurrentAppUninstallRequest, undefined);
  const dispatch = useDispatch();
  const manualTriggerAppRefresh = useManualDataFetch(
    dispatch,
    ManualFetchDataType.APPS
  );

  const InstallDialog = currentAppInstallRequest?.app.getInstallDialog(
    currentAppInstallRequest.isOrg
  );

  const showUninstallModal = useCallback(
    ({ appUninstallRequest }: Partial<AppInstallEventArgs>) => {
      setCurrentAppUninstallRequest(appUninstallRequest);
    },
    [setCurrentAppUninstallRequest]
  );

  const handleInstall = useCallback(
    ({ appInstallRequest }: Partial<AppInstallEventArgs>) => {
      if (!appInstallRequest) {
        return;
      }

      setCurrentAppInstallRequest(appInstallRequest);

      if (!appInstallRequest.app.getInstallDialog(appInstallRequest.isOrg)) {
        // there is no dialog for this app, install it now
        toaster
          .withPromise(
            appInstallRequest.app
              .install(appInstallRequest.isOrg)
              .then(manualTriggerAppRefresh),
            ...integrationInstallMessages(appInstallRequest.app.definition),
            6000
          )
          .catch(logError);
      }
    },
    [setCurrentAppInstallRequest, toaster, manualTriggerAppRefresh]
  );

  const handleUninstall = useCallback(() => {
    if (currentAppUninstallRequest?.app) {
      toaster
        .withPromise(
          currentAppUninstallRequest.app
            .uninstall(currentAppUninstallRequest.isOrg)
            .then(manualTriggerAppRefresh),
          ...integrationUninstallMessages(
            currentAppUninstallRequest.app.definition.displayName
          ),
          6000
        )
        .catch(logError);

      setCurrentAppUninstallRequest(undefined);
    }
  }, [currentAppUninstallRequest, manualTriggerAppRefresh, toaster]);

  useEffect(() => {
    const event_listener_handle_install = (
      eventArgs: Partial<AppInstallEventArgs>
    ) => {
      handleInstall(eventArgs);
    };

    const event_listener_handle_uninstall = (
      eventArgs: Partial<AppInstallEventArgs>
    ) => {
      showUninstallModal(eventArgs);
    };

    appInstallController.on(
      'handle_app_install',
      event_listener_handle_install
    );

    appInstallController.on(
      'handle_app_uninstall',
      event_listener_handle_uninstall
    );

    return () => {
      appInstallController.off(
        'handle_app_install',
        event_listener_handle_install
      );

      appInstallController.off(
        'handle_app_uninstall',
        event_listener_handle_uninstall
      );
    };
  }, [appInstallController, handleInstall, showUninstallModal]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <div className="flex items-center">
          <UIIcon name="cog" size={20} />
        </div>
        <div className="font-medium">
          Connect your apps to ask personalized questions about work
        </div>
      </div>
      <div>
        <AppInstallControllerProvider value={appInstallController}>
          <div className="recommendedAppsContainer">
            {filteredApps
              .slice(0, extensionEnabledBrowser && !extensionInstalled ? 4 : 5)
              .map((app: App) => (
                <RecommendedApp app={app} key={app.definition.shortname} />
              ))}
            {extensionEnabledBrowser && !extensionInstalled && (
              <div className="recommendedAppsRow rounded bg-cloud-10">
                <div className="flex items-center gap-2">
                  {isChrome ? (
                    <>
                      <UIIcon name="chrome" size={24} type="apps" />
                      <span className="text-sm font-medium">
                        Chrome Extension
                      </span>
                    </>
                  ) : (
                    <>
                      <UIIcon name="firefox" size={24} type="apps" />
                      <span className="text-sm font-medium">
                        Firefox Extension
                      </span>
                    </>
                  )}
                </div>
                <ConnectButton
                  onClick={
                    isChrome
                      ? setOpenInstallChromeExtensionDialog
                      : installExtension
                  }
                  text="Install"
                />
              </div>
            )}
            <div className="recommendedAppsRow rounded bg-cloud-10">
              <div className="flex items-center gap-2">
                <UIIcon name="app" size={24} stroke />
                <span className="text-sm font-medium">App Store</span>
              </div>
              <ConnectButton onClick={goToAppStore} text="View all" />
            </div>
          </div>
        </AppInstallControllerProvider>

        {InstallDialog && (
          <InstallDialog
            app={currentAppInstallRequest!.app}
            isOrg={currentAppInstallRequest!.isOrg}
            onClose={unsetInstallApp}
            open={!!currentAppInstallRequest}
          />
        )}
        {currentAppUninstallRequest && (
          <ConfirmDialog
            confirmButtonText="Disconnect"
            confirmButtonType="delete"
            mode={ConfirmMode.Delete}
            onCancel={unsetUninstallApp}
            onConfirm={handleUninstall}
            subject="app"
            title={`Disconnect ${currentAppUninstallRequest.app.definition.displayName}`}
            verb="disconnect"
          />
        )}
        <InstallChromeExtensionDialog
          onClose={setNotOpenInstallChromeExtensionDialog}
          open={openInstallChromeExtensionDialog}
        />
      </div>
    </div>
  );
};

const RecommendedApp: FC<{ app: App }> = ({ app }) => {
  const { connectionType, icon, shortname, displayName } = app.definition;
  const { appPreferences } = useUserSafe((user) => user.orgByOrgId);

  const socialProof = useCallback(
    (app_name: string) => {
      const socials = appPreferences.recommended_apps_list?.filter(
        (element) => element.name === app_name
      )[0];

      if (
        Array.isArray(socials?.social_info_list) &&
        socials?.social_info_list[0]
      ) {
        return {
          count: socials.count,
          social_list_info: socials.social_info_list,
        };
      }

      return { count: 0, social_list_info: [] };
    },
    [appPreferences.recommended_apps_list]
  );

  return (
    <div className="recommendedAppsRow rounded bg-cloud-10" key={shortname}>
      <div className="w-[33%] flex items-center gap-2">
        <div>{icon ?? <UIIcon name={shortname} size={24} type="apps" />}</div>
        <span className="text-sm font-medium truncate">{displayName}</span>
      </div>
      <div className="colSocialProof">
        {socialProof(shortname)
          .social_list_info.slice(0, 3)
          .map((socialUser) => (
            <div className="userIcons" key={socialUser.email}>
              <UITooltip title={`Connected by ${socialUser.email}`}>
                <div>
                  <UserImage
                    displayName={socialUser.name}
                    fontSize={12}
                    imageUrl={socialUser.icon}
                    size={24}
                  />
                </div>
              </UITooltip>
            </div>
          ))}
        {socialProof(shortname).count > 3 && (
          <div className="socialProofCount">
            +{socialProof(shortname).count - 3}
          </div>
        )}
      </div>
      <PersonalButton
        app={app}
        appConnectionType={connectionType}
        installEventName={AnalyticsEvent.QAOnboardingConnectAppClicked}
      />
    </div>
  );
};

const checkIfOrgAppConnected = (
  app: AppName,
  userApps: AppConnectionStatus[]
) => {
  return userApps.some((userApp) => userApp.appName === app && userApp.isOrg);
};
