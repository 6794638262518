import React from 'react';
import { capitalize, last } from '../../../../../../../scripts/utils';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

export const GmailEmailResult: React.FC<ResultProps> = ({ result }) => {
  // Pick last label
  const folder =
    result.labels && result.labels.length > 0
      ? capitalize(last(result.labels)!.name)
      : null;

  const breadcrumbs = folder ? [folder] : null;
  if (breadcrumbs) {
    if (folder === 'Sent' && result.to) {
      breadcrumbs.push(`To: ${result.to}`);
    } else if (result.from) {
      breadcrumbs.push(`From: ${result.from}`);
    }
  }

  return (
    <PageSearchResultItem
      appName="Gmail Email"
      breadcrumbs={breadcrumbs}
      hideModified
      result={result}
      sentTime={result.created_time}
      showLastItemBreadcrumb
    />
  );
};
