import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import React, { useCallback, useEffect, useState } from 'react';
import {
  AppConnectionType,
  AppName,
  InstantAvailableStatus,
} from '../../../apps/definition';
import { AppState } from '../../../constants';
import { trackEvent } from '../../../extra/sharedMethods';
import { getOrgAppData, getUserAppData } from '../../../redux/selectors';
import { App } from '../../../scripts/app';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useBoolState, useUserSafe } from '../../../scripts/hooks';
import { calculateIndexingPercentage } from '../../../scripts/utils';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../../controls/ui/UIToolTip/UIToolTip';
import { useAppInstallController } from '../AppsTable/AppInstallController';
import {
  isInstantAvailable,
  showAppInPersonalApps,
} from '../AppsTable/AppUtils';

const DisableButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <UIButton
      className="connectButton flipActive"
      onClick={onClick}
      size="small"
      type="warning"
    >
      Disconnect
    </UIButton>
  );
};

const ConnectedContent: React.FC<{ disableDisconnect?: boolean }> = ({
  disableDisconnect = false,
}) =>
  disableDisconnect ? (
    <UITooltip title="Your admin has connected the app.">
      <div className="connectedApp">
        <UIIcon name="check" />
        <span>Connected</span>
      </div>
    </UITooltip>
  ) : (
    <div className="connectedApp">
      <UIIcon name="check" />
      <span>Connected</span>
    </div>
  );

const PartiallyConnectedContent: React.FC = () => (
  <div className="connectedApp">
    <UIIcon className="loader" name="spinner-animated" />
    <span>Connecting</span>
  </div>
);

export const ConnectButton: React.FC<{
  onClick: () => void;
  text?: string;
}> = ({ onClick, text }) => {
  const [hovered, setHovered, unsetHovered] = useBoolState(false);
  return (
    <div onMouseEnter={setHovered} onMouseLeave={unsetHovered}>
      <UIButton
        className="connectButton"
        onClick={onClick}
        size="small"
        type={hovered ? 'primary' : 'secondary'}
      >
        {text ?? 'Connect'}
      </UIButton>
    </div>
  );
};

const notAvailable = <div className="w-4 h-px bg-cloud-20 " />;

const notIndexingState = (
  <div className="connectionIssue">
    <UIIcon name="error" />
    <span>Not indexing</span>
  </div>
);

const IndexingButton: React.FC<{ shortname: AppName; isOrg: boolean }> = ({
  shortname,
  isOrg,
}) => {
  const user = useUserSafe();

  const appData = isOrg
    ? getOrgAppData(user, shortname)
    : getUserAppData(user, shortname);

  const percentage = calculateIndexingPercentage(appData);

  return (
    <div className="indexingApp">
      <UIIcon name="spinner-animated" />
      <span>{percentage === 0 ? 'Syncing' : `syncing (${percentage}%)`}</span>
    </div>
  );
};

const disablingState = (
  <div className="disablingApp">
    <UIIcon name="spinner-animated" />
    <span>Disabling</span>
  </div>
);

export const PersonalButton: React.FC<{
  app: App;
  appConnectionType: AppConnectionType;
  installEventName?: AnalyticsEvent;
  // eslint-disable-next-line complexity
}> = ({ app, appConnectionType, installEventName }) => {
  const [disabling, setDisabling] = useState(false);

  const appInstallController = useAppInstallController();
  const { shortname } = app.definition;
  const user = useUserSafe();

  const installCallback = useCallback(() => {
    const eventBody = {
      app: shortname,
      isOrg: false,
    };

    trackEvent(AnalyticsEvent.AppConnect, eventBody);

    if (installEventName) {
      trackEvent(installEventName, eventBody);
    }

    appInstallController.triggerAppInstallRequest(app, false);
  }, [appInstallController, app, shortname, installEventName]);

  const uninstallCallback = useCallback(() => {
    appInstallController.triggerAppUninstallRequest(app, false);
    trackEvent(AnalyticsEvent.AppDisconnect, {
      app: shortname,
      isOrg: false,
    });
  }, [app, appInstallController, shortname]);

  const personalAppData = getUserAppData(user, shortname);
  const orgAppData = getOrgAppData(user, shortname);

  useEffect(() => {
    setDisabling(false);
  }, [personalAppData?.statusCode]);

  /*
   * Org-level Google Workspace connections provide all data,
   * personal connections are not allowed.
   */

  if (
    appConnectionType === AppConnectionType.OrgOnly ||
    !showAppInPersonalApps(app.definition)
  ) {
    return notAvailable;
  }

  // if org app is connected, personal app is not required
  if (
    orgAppData &&
    (orgAppData.statusCode === AppState.Connecting ||
      orgAppData.statusCode === AppState.IndexingComplete) &&
    appConnectionType === AppConnectionType.AccessControl
  ) {
    return notAvailable;
  }

  const {
    definition: { shortname: appShortname },
    definition,
  } = app;

  const isInstant = isInstantAvailable(
    definition,
    InstantAvailableStatus.Personal
  );

  if (!personalAppData) {
    return <ConnectButton onClick={installCallback} />;
  }

  if (disabling) {
    return disablingState;
  }

  switch (personalAppData.statusCode) {
    case AppState.PartiallyConnected: {
      return <PartiallyConnectedContent />;
    }

    case AppState.Connecting:
    case AppState.Indexing:
    case AppState.IndexingFailed:
    case AppState.IndexingComplete: {
      if (
        isInstant ||
        personalAppData.statusCode === AppState.IndexingComplete
      ) {
        // hack to enable frontend to show connected status for instant apps

        return (
          <div className="flipHover">
            <span className="flipNormal">
              <ConnectedContent />
            </span>
            <DisableButton onClick={uninstallCallback} />
          </div>
        );
      }

      return <IndexingButton isOrg={false} shortname={appShortname} />;
    }

    case AppState.NotConnected:
    case AppState.ErrorDoNotReconnect:
      return notIndexingState;
    case AppState.ErrorReconnect: {
      return (
        <UIButton
          className="connectButton"
          onClick={installCallback}
          size="small"
          type="caution"
        >
          <UndoOutlinedIcon sx={{ fontSize: 16 }} />
          <span className="ml-2">Reconnect</span>
        </UIButton>
      );
    }

    case AppState.Disconnecting: {
      return disablingState;
    }
  }
};
