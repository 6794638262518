import React, { ReactNode } from 'react';
import './EmptyResults.scss';
import { QuerySpellCorrectionView } from '../../pageSearch/results/misc/QuerySpellCorrectionView';
import { UIIcon } from '../ui/UIIcon/UIIcon';

interface EmptyResultsProps {
  message: ReactNode;
  suggestions: string[];
}

export const EmptyResults: React.FC<EmptyResultsProps> = ({
  message,
  suggestions,
}) => {
  return (
    <div className="emptyResults">
      <UIIcon name="sad-face" size={32} />
      {message}
      <QuerySpellCorrectionView />
      <div className="suggestions">
        Suggestions:
        <ul>
          {suggestions.map((suggestion) => (
            <li key={suggestion}>{suggestion}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
