import React, { FC, useCallback } from 'react';
import {
  FileType,
  FilterDefaults,
  ObjectType,
} from '../../../../../scripts/constants/filters';
import {
  isCustomSecondaryModifier,
  useAllFilters,
} from '../../../../../scripts/hooks';
import { UIButton } from '../../../../controls/ui/UIButton/UIButton';

export const ResetFilters: FC = () => {
  const [query, setQuery] = useAllFilters();

  const handleClickReset = useCallback(() => {
    setQuery({
      app: FilterDefaults.Apps,
      person: FilterDefaults.Person,
      modified: FilterDefaults.Modified,
      sort: FilterDefaults.Sort,
      filetype: query.filter === ObjectType.Files ? FileType.Any : undefined,
    });
  }, [query.filter, setQuery]);

  return isCustomSecondaryModifier(query) ? (
    <UIButton onClick={handleClickReset} size="small" type="ghost">
      Reset
    </UIButton>
  ) : null;
};
