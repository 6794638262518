import React from 'react';
import { unSparse } from '../../../../../../scripts/utils';
import { UIIcon } from '../../../../../controls/ui/UIIcon/UIIcon';
import { PageSearchResultItem } from '../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../resultProps';

export const SalesforceLeadResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Salesforce Lead"
      breadcrumbs={unSparse(result.company_name, result.email)}
      hideModified
      icon={<UIIcon className="resultPerson" name="person" size={20} />}
      result={result}
      showLastItemBreadcrumb
    />
  );
};
