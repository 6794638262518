import React, { useEffect } from 'react';
import { useQueryParam } from 'use-query-params';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { bookCallUrl } from '../../components/links/links';
import { AuthMessageType } from '../../pages/auth/LoginPage/AuthWindow';
import { AuthMessage, UnauthorizedAuthMessage } from '../NotifyAuth';
import {
  getLoginTokenFromLocalStorage,
  refreshAllTokens,
  softLogout,
  tryPopulateLocalStorageFromAuthRedirect,
  useSyncAuthDataFromLocalStorage,
} from '../authentication';
import { inExtension, isChrome, logError } from '../utils';
import { useHistoryPush } from './navigation';
import {
  useSSOConfiguredParam,
  useUnauthorizedMessageParam,
  useUnauthorizedParam,
} from './query';
import { useBoolState } from './state';
import { useToaster } from './toast';

const chromeExtensionDataKey = 'dashworksChromeExtension';

/**
 * This hook gets notified when a login was successful/fails, will populate redux and navigate to main page when successful.
 */
export const useAuthListener = (): boolean => {
  const toaster = useToaster();
  const reduxPopulator = useSyncAuthDataFromLocalStorage();

  // add existing query params to history push
  const goToRoot = useHistoryPush(`/${window.location.search}`);

  const [isForcedSSO, setForcedSSO] = useBoolState(false);

  useEffect(() => {
    const listener = (ev: MessageEvent<AuthMessage>) => {
      switch (ev.data.type) {
        case AuthMessageType.SSO_CONFIGURED_ERR: {
          setForcedSSO();
          return;
        }

        case AuthMessageType.AUTH_UNAUTHORIZED:
          // eslint-disable-next-line no-case-declarations
          const msg = (ev.data as UnauthorizedAuthMessage).payload?.message;

          toaster.failure(
            <div>
              {msg && (
                <p className="m-0 mb-2">
                  <strong>{msg}</strong>
                </p>
              )}
              {msg?.includes('not configured') && (
                <UIButton href={bookCallUrl}>Book a demo</UIButton>
              )}
            </div>
          );

          return;

        case AuthMessageType.LOGGED_IN:
          reduxPopulator();
          goToRoot();
      }
    };

    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  });

  useEffect(() => {
    document.body.classList.add('inLogin');

    // Special copy and styling for Chrome extension
    if (inExtension() && isChrome) {
      document.body.dataset[chromeExtensionDataKey] = 'true';
    }

    return () => {
      // Ok to delete here since this is in API spec
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete document.body.dataset[chromeExtensionDataKey];
      document.body.classList.remove('inLogin');
    };
  }, []);

  return isForcedSSO;
};

/**
 * This hook notifies `useAuthListener` hooks of login events
 */
export const useAuthNotifier = (
  target: Window | undefined,
  loadingStateChange: (loading: boolean) => void
): void => {
  const [ssoConfigured] = useSSOConfiguredParam();
  const [unauthorized] = useUnauthorizedParam();
  const [unauthorizedMessage] = useUnauthorizedMessageParam();
  const [idTokenParam] = useQueryParam('id_token');

  useEffect(() => {
    // Either localStorage tokens exists or cookie exists (first login)

    const doClose = target !== window;

    const wasLoggedIn = !!getLoginTokenFromLocalStorage();

    const isLoggedIn = wasLoggedIn || tryPopulateLocalStorageFromAuthRedirect();

    if (!target) {
      logError(new Error('auth window mode without parent window??'));
    } else if (isLoggedIn) {
      /*
       * If we are here as the consequence of an OAuth login, we let the parent window know that the cookies are available
       * for the Dashworks domain.
       */
      if (doClose) {
        target.postMessage({ type: AuthMessageType.LOGGED_IN });
      } else {
        if (!wasLoggedIn) {
          loadingStateChange(true);
        }

        refreshAllTokens()
          .catch((error: Error) => {
            logError(error);
            target.postMessage({
              type: AuthMessageType.AUTH_UNAUTHORIZED,
              payload: {
                message: 'Invalid or expired credentials.',
              },
            });

            setTimeout(() => {
              softLogout().catch(logError);
            }, 1000);
          })
          .finally(() => {
            loadingStateChange(false);
          })
          .catch(logError);

        return;
      }
    } else if (ssoConfigured) {
      target.postMessage({ type: AuthMessageType.SSO_CONFIGURED_ERR });
    } else if (unauthorized) {
      target.postMessage({
        type: AuthMessageType.AUTH_UNAUTHORIZED,
        payload: {
          message: unauthorizedMessage,
        },
      });
    }

    if (doClose) {
      window.close();
    }
  }, [
    ssoConfigured,
    target,
    unauthorized,
    unauthorizedMessage,
    idTokenParam,
    loadingStateChange,
  ]);
};
