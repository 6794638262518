import { clear as clearIdbStore, set as setIdbStore } from 'idb-keyval';
import { logError } from './utils';

interface VersionContainer<T> {
  version: string;
  data: T;
}

export const enum StorageKey {
  SidebarOpen = 'user_sidebar_open',
  GraphQLData = 'user_data',
  recentVisitsAutosuggest = 'recent_visits_suggestion',
  LoginTokens = 'user_login_tokens',
  AwsTokens = 'user_aws_tokens',
  UserMetadata = 'user_metadata',
  SearchHistory = 'searchHistory',
  UserHomepageLinks = 'user_homepage_links',
  OrgHomepageLinks = 'org_homepage_links',
  AccessDiscoveryRequests = 'access_discovery_requests',
}

const isVersionContainer = (obj: unknown): obj is VersionContainer<unknown> =>
  !!obj && typeof obj === 'object' && 'version' in obj && 'data' in obj;

export function getStorageItem(
  key: StorageKey,
  clearOnInvalid = true,
  verifyVersion = false
): object | null {
  const value = localStorage.getItem(key);
  if (!value) {
    return null;
  }

  const parsed = JSON.parse(value) as unknown;
  if (typeof parsed !== 'object' || parsed === null) {
    if (clearOnInvalid) {
      removeStorageItem(key);
      return null;
    }

    throw new Error(`Malformed ${key} in local storage`);
  }

  if (!verifyVersion) {
    return parsed;
  }

  if (!isVersionContainer(parsed) || parsed.version !== APP_VERSION) {
    removeStorageItem(key);
    return null;
  }

  return parsed.data as object;
}

export function setStorageItem(
  key: StorageKey,
  value: unknown,
  withVersion = false
): void {
  if (withVersion) {
    value = {
      version: APP_VERSION,
      data: value,
    };
  }

  setIdbStore(key, value).catch(logError);
  localStorage.setItem(key, JSON.stringify(value));
}

function removeStorageItem(key: string) {
  localStorage.removeItem(key);
}

export function clearStorage(): void {
  localStorage.clear();
  clearIdbStore().catch(logError);
}
