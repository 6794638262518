import React, { FC, ReactNode } from 'react';
import { UIIcon, UIIcons } from '../ui/UIIcon/UIIcon';
import './DataPlaceholder.scss';

interface PageHeaderProps {
  title: string;
  content: ReactNode;
  icon: UIIcons;
}

export const DataPlaceholder: FC<PageHeaderProps> = ({
  title,
  content,
  icon,
}) => {
  return (
    <div className="dataPlaceholder">
      <UIIcon className="icon" name={icon} size={24} />
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  );
};
