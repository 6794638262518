import React, { forwardRef } from 'react';
import { sanitize } from '../../../../../scripts/html';
import { HighlightedText } from '../../../../controls/HighlightedText/HighlightedText';

export const Title = forwardRef<HTMLDivElement, { content: string }>(
  ({ content }, ref) => {
    const sanitizedContent = sanitize(content);
    const isEmpty = sanitizedContent.trim().length === 0;
    return isEmpty ? (
      <div className="title" ref={ref}>
        <i>Untitled</i>
      </div>
    ) : (
      <div className="title" ref={ref}>
        <HighlightedText text={content} />
      </div>
    );
  }
);

Title.displayName = 'Title';
