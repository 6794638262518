import { useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { trackEvent } from '../../extra/sharedMethods';
import { AnalyticsEvent } from '../constants/analytics-event';
import { useAllSearchRelatedParams, usePageSearch } from '../hooks';

/**
 * Executes a callback whenever an observed element comes within view. Callbacks are called once per search.
 */
export const useTrackImpression = (
  callback: () => void
): ((node: HTMLElement | null) => void) => {
  const { ref, entry } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  const viewed = useRef(false);

  if (entry?.isIntersecting && !viewed.current) {
    viewed.current = true;
    callback();
  }

  return ref;
};

export const useTrackSearchImpression = (
  event: AnalyticsEvent,
  extraEventParams?: object
): ((node: HTMLElement | null) => void) => {
  const [searchState] = useAllSearchRelatedParams();
  const version = usePageSearch((pageSearch) => pageSearch.version);
  const queryId = usePageSearch((pageSearch) => pageSearch.queryId);

  return useTrackImpression(
    useCallback(() => {
      trackEvent(event, {
        query: searchState.q,
        ...searchState,
        queryId,
        version,
        ...extraEventParams,
      });
    }, [event, searchState, queryId, version, extraEventParams])
  );
};

export const useTrackMouseEnter = (
  callback: () => void
): ((node: HTMLElement | null) => void) => {
  const ref = useRef<HTMLElement | null>(null);
  const entered = useRef(false);

  return useCallback(
    (node) => {
      if (!node) {
        return;
      }

      const onMouseenter = () => {
        if (!entered.current) {
          entered.current = true;
          callback();
        }
      };

      const { current } = ref;

      if (current) {
        current.removeEventListener('mouseenter', onMouseenter);
      }

      node.addEventListener('mouseenter', onMouseenter, { passive: true });
      ref.current = node;
    },
    [callback]
  );
};
