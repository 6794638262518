import {
  highlightStartCodeRe,
  highlightToHTML,
} from '../components/controls/HighlightedText/HighlightedText';
import {
  MetadataMatchOwner,
  PageSearchResult,
  RawPageResult,
  SearchHighlight,
  SearchHit,
} from '../scripts/models/page-search-result';
import { relativeLinkToAbsoluteLocal } from '../scripts/utils';

interface TransformOptions {
  hideContent?: boolean;
  index: number;
}

const countHighlights = (value: string): number => {
  return new RegExp(highlightStartCodeRe).exec(value)?.length ?? 0;
};

export class ResultTransformer {
  public transform(
    hit: SearchHit,
    { hideContent, index }: TransformOptions
  ): PageSearchResult {
    const { _source, highlight, collated, missing_terms, log } = hit;

    const out: PageSearchResult = {
      ...this.transformSource(_source, index),
      missingTerms: missing_terms,
      highlight,
      collated: collated?.map((collatedHit, subIndex) =>
        this.transform(collatedHit, { index: subIndex })
      ),
    };

    out.log = log;

    if (highlight) {
      this.setHighlights(out, highlight);
    }

    if (hideContent) {
      out.content_snippet = '';
      delete out.raw_content;
    }

    return out;
  }

  private transformSource(
    source: RawPageResult,
    index: number
  ): PageSearchResult {
    const out: PageSearchResult = {
      ...source,
      // Used for search result history only (unhighlighted name)
      unhighlighted_name: source.name ?? '',
      collated: [],
      name: source.name ?? '',
      content_snippet: '',
      owners: source.owners ?? [],
      company_name: this.normalizeCompanyName(source.company_name),
      file_path: source.file_path ?? [],
      webview_link: relativeLinkToAbsoluteLocal(source.webview_link),
      status:
        typeof source.status === 'object' && source.status
          ? source.status.name
          : source.status ?? undefined,
      backLinks: source.inbound_objects ?? [],
      index,
    };

    if (source.raw_content) {
      out.content_snippet = source.raw_content;
      if (
        out.content_snippet.startsWith('<p>') &&
        out.content_snippet.endsWith('</p>')
      ) {
        out.content_snippet = out.content_snippet.slice(3, -4);
      }
    } else if (source.content) {
      // This is a stopgap measure until `pins` results have `highlight` or `raw_content`
      out.content_snippet = source.content;
    }

    return out;
  }

  private normalizeCompanyName(
    companyName?: string[] | string
  ): string | undefined {
    // Could be either an string array or a string
    if (!companyName) {
      return;
    }

    if (Array.isArray(companyName)) {
      return companyName.join(', ');
    }

    return companyName;
  }

  private setHighlights(out: PageSearchResult, highlight: SearchHighlight) {
    if (highlight.name?.length) {
      [out.name] = highlight.name;
    }

    if (highlight.content?.[0]) {
      [out.content_snippet] = highlight.content;
    }

    const ownerEmail = highlight['s_owners.email']?.[0];
    const ownerName = highlight['s_owners.name']?.[0];

    if (ownerEmail || ownerName) {
      const owner: MetadataMatchOwner = {};
      if (ownerEmail) {
        owner.email = ownerEmail;
      }

      if (ownerName) {
        owner.name = ownerName;
      }

      out.metadataMatches = { owner };
    }

    // TODO: WTF
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const domain = highlight.domain?.[0];
    if (domain) {
      out.metadataMatches = {
        ...out.metadataMatches,
        domain,
      };
    }

    const attachmentHighlights = highlight['attachments.name'];
    if (attachmentHighlights && out.attachments) {
      for (const attachment of out.attachments) {
        const highlighted = attachmentHighlights.find(
          (item) => highlightToHTML(item, '', '') === attachment.name
        );

        if (highlighted) {
          attachment.name = highlighted;
          attachment.highlightCount = countHighlights(highlighted);
        }
      }
    }
  }
}
