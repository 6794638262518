import React, { FC, useCallback, useMemo } from 'react';
import {
  AppConnectionType,
  AppDefinition,
  useAppDefinitions,
} from '../../../apps/definition';
import { trackEvent } from '../../../extra/sharedMethods';
import { isIndexable } from '../../../redux/selectors';
import { batch } from '../../../redux/store';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { FilterDefaults } from '../../../scripts/constants/filters';
import {
  useAppParam,
  useSearchParam,
  useUserSafe,
} from '../../../scripts/hooks';
import { isSafari, wrapStringToRegex } from '../../../scripts/utils';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { SearchSuggestionContainer } from './SearchSuggestionContainer';

/*
 * specifically only match word boundaries except if preceded with `@` for email.
 * safari does not support this https://caniuse.com/js-regexp-lookbehind
 */
const escaper = isSafari
  ? (e: string) => `\\b${e}\\b`
  : (e: string) => `\\b(?<!@)${e}\\b`;

const appNameMatchWrapper = (val: string) =>
  wrapStringToRegex(val, escaper, 'gi');

const cleanQuery = (query: string, appDef: AppDefinition): string => {
  query = query.replace(appNameMatchWrapper(appDef.displayName), '');

  query = query.replace(appNameMatchWrapper(appDef.shortname), '');
  query = query.replace(/\s+/, ' ');
  query = query.trim();
  return query;
};

const useMatchingApp = (indexable = false): AppDefinition | undefined => {
  const [query] = useSearchParam();
  const lowerQuery = query.toLowerCase();
  const user = useUserSafe();
  const apps = useAppDefinitions();
  const [appFilter] = useAppParam();

  const matches = useMemo(
    () =>
      Object.values(apps).filter(
        (app) =>
          (indexable ? isIndexable(user, app) : !isIndexable(user, app)) &&
          (appNameMatchWrapper(app.shortname).test(query) ||
            appNameMatchWrapper(app.displayName).test(query))
      ),
    [apps, indexable, query, user]
  );

  const [candidate] = matches;

  if (
    appFilter !== FilterDefaults.Apps ||
    !candidate ||
    !candidate.controlEnabled ||
    matches.length > 1 ||
    (candidate.displayName.toLowerCase() === lowerQuery &&
      candidate.shortname === lowerQuery)
  ) {
    return;
  }

  return candidate;
};

export const AppFilterSuggestion: FC = () => {
  const [query, setQuery] = useSearchParam();
  const [, setAppFilter] = useAppParam();

  const match = useMatchingApp(true);

  const handleClick = useCallback(() => {
    if (match) {
      trackEvent(AnalyticsEvent.SearchNoticeAppFilterSuggestionClick, {
        source: match.shortname,
      });

      batch(() => {
        setAppFilter(match.shortname);
        setQuery(cleanQuery(query, match));
      });
    }
  }, [match, query, setAppFilter, setQuery]);

  if (!match) {
    return null;
  }

  return (
    <SearchSuggestionContainer
      visibleImpression={AnalyticsEvent.SearchNoticeAppFilterSuggestionView}
    >
      <p>
        Looking for results specifically in{' '}
        <span className="app">
          <UIIcon name={match.shortname} size={16} type="apps" />{' '}
          <strong>{match.displayName}</strong>
        </span>
        ? Try applying an app filter.
      </p>
      <UIButton onClick={handleClick} size="small">
        Apply filter
      </UIButton>
    </SearchSuggestionContainer>
  );
};

const handleClick = () => {
  trackEvent(AnalyticsEvent.SearchNoticeAppInstallSuggestionClick);
};

export const AppInstallSuggestion: FC = () => {
  const user = useUserSafe();

  const match = useMatchingApp();

  if (
    !match ||
    (match.connectionType === AppConnectionType.OrgOnly && !user.admin)
  ) {
    return null;
  }

  return (
    <SearchSuggestionContainer
      visibleImpression={AnalyticsEvent.SearchNoticeAppInstallSuggestionView}
    >
      <p>
        Looks like you don&lsquo;t have{' '}
        <span className="app">
          <UIIcon name={match.shortname} size={16} type="apps" />{' '}
          <strong>{match.displayName}</strong>
        </span>{' '}
        connected yet. Connect {match.displayName} now for more results.
      </p>
      <UIButton
        link={`/apps/${user.admin ? 'org' : 'personal'}/${match.shortname}`}
        onClick={handleClick}
        size="small"
      >
        Connect app
      </UIButton>
    </SearchSuggestionContainer>
  );
};
