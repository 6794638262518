import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Feature, useFeatureAccess } from '../../hooks/subscriptionHooks';
import { Bot, Feedback } from '../../models/Bots';
import { UploadedFile } from '../../models/File';
import { SupportedLlm, UserApp } from '../../models/User';
import {
  useFlag,
  useGlobalState,
  usePreferredLlm,
  useToaster,
  useUserSafe,
} from '../../scripts/hooks';
import { UpsertBotParams } from '../../scripts/hooks/bots';
import { ConnectedApps } from '../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../scripts/models/answers';
import {
  getFiltersFromBotSources,
  setFiltersFromBotSources,
} from '../../scripts/sources/bot';
import {
  checkAiOnlyModeFromSources,
  checkWebModeFromSources,
  getSourcesFromFilters,
} from '../../scripts/sources/common';
import { logError } from '../../scripts/utils';
import { llms } from '../LLMSelect/LLMSelectList';
import { LLMSelectPopoverBtn } from '../LLMSelect/LLMSelectPopoverBtn';
import { SourcesFilterSwitch } from '../SourcesFilterSwitch/SourcesFilterSwitch';
import {
  EnterpriseIcon,
  PaywallModal,
} from '../admin/EnterprisePaywall/EnterprisePaywall';
import { CopyButton } from '../controls/ui/CopyButton/CopyButton';
import { Modal } from '../controls/ui/Modal/Modal';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITextArea } from '../controls/ui/UITextArea/UITextArea';
import { UITextbox } from '../controls/ui/UITextbox/UITextbox';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import { useQATextInputBoxStates } from '../jit-qa/textInputBox/QATextInputBoxStates';
import { Table, TableBody } from '../shadcn/lib/components/table';
import { BotAdminsSelect } from './BotAdminsSelect';
import { FeedbackListItem } from './FeedbackListItem';
import { EmojiPicker } from './emojiPicker';

interface IBotsModalProps {
  type: 'create' | 'edit';
  bot?: Bot;
  botsEnabledApps: ConnectedApps[];
  botsEnabledUserApps: UserApp[];
  isInSlackChannel?: boolean;
  closeModal: () => void;
  createBot: (bot: UpsertBotParams) => Promise<void>;
  updateBot: (params: UpsertBotParams) => Promise<void>;
  deleteBot: (id: string) => Promise<void>;
  setOpenPaywallModal(): void;
}

// eslint-disable-next-line max-lines-per-function, complexity
export const BotsModal: FC<IBotsModalProps> = ({
  type,
  bot,
  botsEnabledApps: allSkills,
  botsEnabledUserApps: allSkillsUserApps,
  isInSlackChannel,
  closeModal,
  createBot,
  updateBot,
  deleteBot,
  setOpenPaywallModal,
}) => {
  const user = useUserSafe();
  const { userDisplayName, userPhotoUrl } = useGlobalState(
    (state) => state.meta
  );

  const preferred_llm = usePreferredLlm();
  const toaster = useToaster();
  const hasFeedbackFeature = useFlag('displayLearnableBotsFeedback');
  const multiplayerEnabled = useFlag('multiplayerEnabled');

  const [icon, setIcon] = useState(bot?.icon ?? '🤖');
  const [botName, setBotName] = useState(bot?.bot_name ?? '');
  const [description, setDescription] = useState(bot?.description ?? '');
  const [preferredLlm, setPreferredLlm] = useState<SupportedLlm | null>(
    bot?.preferred_llm ?? preferred_llm
  );

  const [isManaged, setIsManaged] = useState(bot?.is_managed ?? false);
  const [admins, setAdmins] = useState(
    bot?.admins ?? [
      {
        icon: userPhotoUrl,
        display_name: userDisplayName,
        email: user.email,
      },
    ]
  );

  const [customInstructions, setCustomInstructions] = useState(
    bot?.custom_instructions ?? ''
  );

  const { allFiles, allAnswers } = useQATextInputBoxStates();

  const [isBaseLLM, setIsBaseLLM] = useState(false);
  const [skillFilters, setSkillFilters] = useState<ConnectedApps[]>(allSkills);
  const [fileFilters, setFileFilters] = useState<UploadedFile[]>(allFiles);
  const [answerFilters, setAnswerFilters] = useState<Answer[]>(allAnswers);
  const [useContextualFilesOnly, setUseContextualFilesOnly] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedFeedbackIds, setDeletedFeedbackIds] = useState<string[]>([]);

  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const [feedbackToDelete, setFeedbackToDelete] = useState<Feedback | null>(
    null
  );

  const hasCustomBotsFeature = useFeatureAccess(Feature.CUSTOM_BOTS);

  const hasEditPermissions = useMemo(() => {
    if (type === 'create') return true;

    if (user.admin) return true;

    return bot?.admins.some((admin) => admin.email === user.email) ?? false;
  }, [bot?.admins, type, user.admin, user.email]);

  const headerCopy = useMemo(() => {
    return type === 'edit' ? 'Edit Bot' : 'Create Bot';
  }, [type]);

  const handleFeedbackClick = useCallback((feedback: Feedback) => {
    setFeedbackToDelete(feedback);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!hasCustomBotsFeature) {
      setPaywallModalOpen(true);
      return;
    }

    setLoading(true);

    if (
      !botName ||
      (!isBaseLLM &&
        skillFilters.length === 0 &&
        fileFilters.length === 0 &&
        answerFilters.length === 0)
    ) {
      setLoading(false);

      setShowErrors(true);
      return;
    }

    const sources = getSourcesFromFilters(
      isBaseLLM,
      skillFilters,
      fileFilters,
      answerFilters,
      allSkills,
      allFiles,
      allAnswers,
      []
    );

    const body: UpsertBotParams = {
      id: bot?.id,
      icon,
      bot_name: botName,
      description,
      sources,
      preferred_llm: preferredLlm,
      custom_instructions: customInstructions,
      is_managed: isManaged,
      admins,
      deleted_feedback_ids: deletedFeedbackIds,
    };

    await (type === 'create'
      ? createBot({
          ...body,
        })
      : updateBot({
          ...body,
        }));

    setLoading(false);

    closeModal();
  }, [
    hasCustomBotsFeature,
    botName,
    isBaseLLM,
    skillFilters,
    fileFilters,
    answerFilters,
    allSkills,
    allFiles,
    allAnswers,
    bot?.id,
    icon,
    description,
    preferredLlm,
    customInstructions,
    isManaged,
    admins,
    deletedFeedbackIds,
    type,
    createBot,
    updateBot,
    closeModal,
  ]);

  useEffect(() => {
    if (bot?.sources) {
      setFiltersFromBotSources(
        setIsBaseLLM,
        setSkillFilters,
        setFileFilters,
        setAnswerFilters,
        bot.sources,
        allSkills,
        allFiles,
        allAnswers
      );
    }
  }, [allAnswers, allFiles, allSkills, bot?.sources]);

  useEffect(() => {
    if (!bot?.sources) {
      setFileFilters(allFiles);
    }
  }, [allFiles, bot?.sources]);

  useEffect(() => {
    if (!bot?.sources) {
      setAnswerFilters(allAnswers);
    }
  }, [allAnswers, bot?.sources]);

  return (
    <Modal modalClasses="pr-0" onClose={closeModal}>
      <div className="flex flex-col gap-6 max-h-[80vh] overflow-y-auto pr-4 mr-2 custom-scrollbar">
        <div className="flex justify-between gap-2 items-center">
          <div className="flex gap-2 items-center">
            <UIIcon name="bot" size={32} />
            <h2 className="text-2xl m-0 font-bold">{headerCopy}</h2>
          </div>
          {type === 'edit' && (
            <div className="flex gap-2">
              <UITooltip title="Copy Bot ID">
                <CopyButton size={20} subject="Bot ID" value={bot?.id} />
              </UITooltip>
              {hasEditPermissions && (
                <UITooltip
                  title={
                    isInSlackChannel
                      ? 'The bot is currently being used in 1 or more Slack Channels.'
                      : 'Delete Bot'
                  }
                >
                  <div
                    className={`flex ${
                      isInSlackChannel
                        ? 'opacity-50 cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    onClick={() => {
                      if (isInSlackChannel) {
                        return;
                      }

                      setShowDeleteModal(true);
                    }}
                  >
                    <UIIcon name="trash-can" size={20} />
                  </div>
                </UITooltip>
              )}
            </div>
          )}
        </div>

        <div className="flex gap-6">
          <div className="flex flex-col gap-2">
            <div className="font-bold">Icon</div>
            <EmojiPicker
              disabled={!hasEditPermissions}
              emoji={icon}
              onSelect={setIcon}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-bold">Name</div>
            <UITextbox
              error={
                showErrors && !botName ? 'Bot name is required' : undefined
              }
              onChange={setBotName}
              placeholder="Enter Name"
              readonly={!hasEditPermissions}
              value={botName}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div className="font-bold">Description</div>
          <UITextbox
            maxLength={256}
            onChange={setDescription}
            placeholder="Use this bot for XYZ"
            readonly={!hasEditPermissions}
            value={description}
          />
        </div>

        <div
          className={classNames('flex gap-6', {
            'flex-col': !hasEditPermissions,
          })}
        >
          <div className="flex flex-col gap-2">
            <div className="flex gap-1 items-center">
              <div className="font-bold">Sources</div>
              <UITooltip title="Sources include all app connections marked as Bot connections. To add more, mark additional app connections as Bot connections.">
                <UIIcon name="info" />
              </UITooltip>
            </div>
            {hasEditPermissions ? (
              <SourcesFilterSwitch
                allAnswers={allAnswers}
                allFiles={allFiles}
                allSkills={allSkills}
                allSkillsUserApps={allSkillsUserApps}
                answerFilters={answerFilters}
                error={
                  showErrors &&
                  !isBaseLLM &&
                  skillFilters.length === 0 &&
                  fileFilters.length === 0 &&
                  answerFilters.length === 0
                    ? 'At least one source is required, if Apps tab is selected'
                    : undefined
                }
                fileFilters={fileFilters}
                isBaseLLM={isBaseLLM}
                setAnswerFilters={setAnswerFilters}
                setFileFilters={setFileFilters}
                setIsBaseLLM={setIsBaseLLM}
                setSkillFilters={setSkillFilters}
                setUseContextualFilesOnly={setUseContextualFilesOnly}
                showConfigureAppSettings
                skillFilters={skillFilters}
                useContextualFilesOnly={useContextualFilesOnly}
              />
            ) : // eslint-disable-next-line unicorn/no-nested-ternary
            bot?.sources ? (
              <div>
                {checkAiOnlyModeFromSources(bot.sources) ? (
                  <div>AI only</div>
                ) : // eslint-disable-next-line unicorn/no-nested-ternary
                checkWebModeFromSources(bot.sources) ? (
                  <div>Web</div>
                ) : (
                  <div className="flex items-center gap-2 flex-wrap">
                    {[
                      ...new Set(
                        getFiltersFromBotSources(
                          bot.sources,
                          allSkills,
                          allFiles,
                          allAnswers
                        )[0].map((skill) => skill.appName)
                      ),
                    ].map((app) => (
                      <div key={app}>
                        <UIIcon name={app} type="apps" />
                      </div>
                    ))}
                    {getFiltersFromBotSources(
                      bot.sources,
                      allSkills,
                      allFiles,
                      allAnswers
                    )[1].length > 0 && (
                      <div>
                        <UIIcon name="doc" size={20} />
                      </div>
                    )}
                    {getFiltersFromBotSources(
                      bot.sources,
                      allSkills,
                      allFiles,
                      allAnswers
                    )[2].length > 0 && (
                      <div>
                        <UIIcon name="answer" size={20} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-2">
            <div className="font-bold">AI Model</div>
            {hasEditPermissions ? (
              <LLMSelectPopoverBtn
                selectedLlm={preferredLlm}
                setOpenPaywallModal={setOpenPaywallModal}
                setSelectedLlm={setPreferredLlm}
              />
            ) : (
              <div className="flex gap-2 items-center">
                {llms.find((llm) => llm.value === preferredLlm)?.icon}
                {llms.find((llm) => llm.value === preferredLlm)?.displayName}
              </div>
            )}
          </div>
        </div>

        {multiplayerEnabled && (
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <div className="flex gap-1 items-center">
                <div className="font-bold">Bot Admins</div>
                <UITooltip title="Only Bot Admins can edit this bot and monitor Managed bots' user conversations.">
                  <UIIcon name="info" />
                </UITooltip>
              </div>
              <BotAdminsSelect
                admins={admins}
                hasEditPermissions={hasEditPermissions}
                setAdmins={setAdmins}
              />
            </div>

            <div className="w-[55%] flex gap-2 items-start">
              <input
                checked={isManaged}
                className="mt-1.5"
                disabled={!hasEditPermissions}
                onChange={() => {
                  setIsManaged(!isManaged);
                }}
                onClick={stopPropagation}
                type="checkbox"
              />
              <div>
                <div className="font-bold">Managed Bot</div>
                <div className="text-gray-50 text-sm">
                  Marking a bot as managed allows Bot Admins to monitor and join
                  its user conversations.
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          {hasEditPermissions ? (
            hasCustomBotsFeature ? (
              <div className="font-bold mb-2">Instructions</div>
            ) : (
              <div className="flex justify-between items-center mb-2">
                <div className="items-center flex gap-2">
                  <div className="font-bold">Instructions</div>
                </div>
                <UIButton
                  onClick={() => {
                    setPaywallModalOpen(true);
                  }}
                  type="secondary"
                >
                  <div className="flex gap-1 items-center">
                    <EnterpriseIcon />
                    Upgrade
                  </div>
                </UIButton>
              </div>
            )
          ) : (
            <div className="font-bold mb-2">Instructions</div>
          )}
          <UITextArea
            disabled={!hasEditPermissions || !hasCustomBotsFeature}
            minRows={4}
            multilineExpandable
            onChange={setCustomInstructions}
            placeholder={`For questions about support tickets, prioritize answers from Intercom help center and tickets.

Respond with short answers with no more than 200 words.

If you don't know the answer, ask the user to create a ticket in Linear.`}
            value={
              hasEditPermissions && hasCustomBotsFeature
                ? customInstructions
                : ''
            }
          />
          <div className="mt-1 text-smoke-10 text-xs">
            Tip: Learn best practices for adding Instructions{' '}
            <a
              href="https://support.dashworks.ai/slackbot/slackbot-in-channels/instructions"
              rel="noreferrer"
              target="_blank"
            >
              here
            </a>
            .
          </div>
        </div>

        {hasFeedbackFeature &&
        hasEditPermissions &&
        bot?.feedbacks?.length &&
        bot.feedbacks.some((f) => !deletedFeedbackIds.includes(f.id)) ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <div className="font-bold">Learned Instructions</div>
              <div className="text-gray-50 text-sm">
                View and manage instructions learned from feedback for this bot
              </div>
            </div>

            <Table>
              <TableBody>
                {bot.feedbacks
                  .filter((f) => !deletedFeedbackIds.includes(f.id))
                  .map((feedback) => (
                    <FeedbackListItem
                      feedback={feedback}
                      key={feedback.id}
                      onDelete={handleFeedbackClick}
                    />
                  ))}
              </TableBody>
            </Table>
          </div>
        ) : null}
      </div>

      <footer className="flex justify-between mt-6 pr-6">
        <UIButton onClick={closeModal} type="secondary">
          Cancel
        </UIButton>
        <UIButton
          disabled={!hasEditPermissions}
          onClick={handleSubmit}
          processing={loading}
          type={hasCustomBotsFeature ? 'primary' : 'secondary'}
        >
          {!hasCustomBotsFeature && <EnterpriseIcon />}
          Save
        </UIButton>
      </footer>

      {paywallModalOpen && (
        <PaywallModal
          allowUpgrade
          closeModal={() => {
            setPaywallModalOpen(false);
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteBotModal
          bot={bot!}
          closeModal={() => {
            setShowDeleteModal(false);
          }}
          closeParentModal={closeModal}
          deleteBot={deleteBot}
        />
      )}
      {feedbackToDelete && (
        <Modal
          modalClasses="!w-[480px]"
          onClose={() => {
            setFeedbackToDelete(null);
          }}
        >
          <div className="text-lg font-semibold">Delete Feedback</div>
          <div className="mt-2">
            This learned instruction will be removed from the bot.
          </div>
          <div className="flex mt-4 gap-4 justify-between">
            <UIButton
              onClick={() => {
                setFeedbackToDelete(null);
              }}
              type="secondary"
            >
              Cancel
            </UIButton>
            <UIButton
              onClick={() => {
                setLoading(true);
                try {
                  setDeletedFeedbackIds((prev) => [
                    ...prev,
                    feedbackToDelete.id,
                  ]);

                  toaster.success('Feedback will be deleted on Save');
                  setFeedbackToDelete(null);
                } catch (error) {
                  toaster.failure('Error deleting feedback');
                  logError(error);
                }

                setLoading(false);
              }}
              processing={loading}
              type="delete"
            >
              Delete
            </UIButton>
          </div>
        </Modal>
      )}
    </Modal>
  );
};

const DeleteBotModal: FC<{
  bot: Bot;
  deleteBot: (id: string) => Promise<void>;
  closeModal: () => void;
  closeParentModal: () => void;
}> = ({ bot, deleteBot, closeModal, closeParentModal }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await deleteBot(bot.id);
    closeParentModal();
    setLoading(false);
  }, [bot.id, deleteBot, closeParentModal]);

  return (
    <Modal modalClasses="!w-[480px]" onClose={closeModal}>
      <div className="text-lg font-semibold">Delete this Bot</div>
      <div className="mt-2">You and the team will lose access to this bot.</div>
      <div className="flex mt-4 gap-4 justify-between">
        <UIButton onClick={closeModal} type="secondary">
          Cancel
        </UIButton>
        <UIButton onClick={handleDelete} processing={loading} type="delete">
          Delete
        </UIButton>
      </div>
    </Modal>
  );
};

const stopPropagation = (event: React.SyntheticEvent) => {
  event.stopPropagation();
};
