import { ReactNode } from 'react';
import { invokeFastApi } from '../apis/fastapi';
import { logError } from '../utils';
import { useToaster } from './toast';

export interface AutoJoinChannelBody {
  user_slack_app_id: number;
  org_id: string;
  team_id: string;
  channel_id: string;
}

interface RecommendedSlackbotChannelsResponse {
  autoJoinRecommendedSlackbotChannel: (
    body: AutoJoinChannelBody,
    autoJoinFailureToastMessage: ReactNode
  ) => Promise<void>;
}

export const useRecommendedSlackbotChannels =
  (): RecommendedSlackbotChannelsResponse => {
    const toaster = useToaster();

    const autoJoinRecommendedSlackbotChannel = async (
      body: AutoJoinChannelBody,
      autoJoinFailureToastMessage: ReactNode
    ) => {
      try {
        await invokeFastApi({
          // eslint-disable-next-line @cspell/spellchecker
          path: '/bots/channels/recommended/autojoin',
          method: 'POST',
          body,
        });

        toaster.success('Slackbot added successfully. Opening channel...');
      } catch (fetchError) {
        logError(fetchError);
        toaster.failure(autoJoinFailureToastMessage);
      }
    };

    return {
      autoJoinRecommendedSlackbotChannel,
    };
  };
