import React from 'react';
import { UIIcon } from '../../../../../../controls/ui/UIIcon/UIIcon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';
import './ConfluencePageResult.scss';

export const ConfluencePageResult: React.FC<ResultProps> = ({ result }) => {
  // Can be either a page or a blog
  const appName = result.image_url!.includes('blog')
    ? 'Confluence Blog'
    : 'Confluence Page';

  return (
    <PageSearchResultItem
      appName={appName}
      icon={<UIIcon className="resultConfluenceDoc" name="doc" size={20} />}
      result={result}
    />
  );
};
