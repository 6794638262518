import { useMemo } from 'react';
import { App } from '../scripts/app';
import { useAllFlags } from '../scripts/hooks';
import { appsFactory } from '.';

export const useApps = (): App[] => {
  const flags = useAllFlags();

  return useMemo(() => appsFactory(flags), [flags]);
};
