import React, { FC } from 'react';
import {
  useCustomDomainEmailParam,
  useUnauthorizedParam,
} from '../../../scripts/hooks';
import { useAuthListener } from '../../../scripts/hooks/auth';
import { AuthContainer } from '../AuthContainer/AuthContainer';
import { LoginMethodStep } from './steps/LoginMethodStep';

export const LoginPage: FC = () => {
  const [unauthorized] = useUnauthorizedParam();
  const [customDomainEmail] = useCustomDomainEmailParam();
  const isForcedSSO = useAuthListener();

  return (
    <AuthContainer unauthorized={unauthorized}>
      <LoginMethodStep
        customDomainEmail={customDomainEmail}
        forcedSSO={isForcedSSO}
      />
    </AuthContainer>
  );
};
