import React from 'react';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

export const FreshdeskTicketResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Freshdesk Ticket"
      result={result}
      showLastItemBreadcrumb
    />
  );
};
