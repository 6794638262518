import { Box, Tab, Tabs } from '@mui/material';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { trackEvent } from '../../extra/sharedMethods';
import { Workflow } from '../../models/Workflows';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useUserSafe } from '../../scripts/hooks';
import { useWorkflows } from '../../scripts/hooks/workflows';
import { setFiltersFromWorkflowSources } from '../../scripts/sources/workflow';
import { inSidePanel, logError } from '../../scripts/utils';
import { AuthorDetails } from '../controls/AuthorDetails/AuthorDetails';
import { Loading } from '../controls/Loading/Loading';
import { UITextbox } from '../controls/ui/UITextbox/UITextbox';
import { useQATextInputBoxStates } from '../jit-qa/textInputBox/QATextInputBoxStates';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../shadcn/lib/components/table';
import { IInnerComponent } from './QAWorkflowTemplateModal';

// eslint-disable-next-line max-lines-per-function
export const WorkflowTemplateTable: React.FC<IInnerComponent> = ({
  onClose,
}: IInnerComponent) => {
  const user = useUserSafe();
  const history = useHistory();
  const { fetchWorkflows, workflows, loading } = useWorkflows();
  const {
    setSearchQuery,
    setSkillFilters,
    setFileFilters,
    setAnswerFilters,
    setIsBaseLLM,
    setSelectedLlm,
    allSkills,
    allFiles,
    allAnswers,
  } = useQATextInputBoxStates();

  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [filteredWorkflows, setFilteredWorkflows] =
    useState<Workflow[]>(workflows);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchWorkflows = useCallback(
    debounce((value?: string) => {
      fetchWorkflows(value === '' ? undefined : value);
    }, 300),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchText(value);
      debouncedFetchWorkflows(value === '' ? undefined : value);
    },
    [debouncedFetchWorkflows]
  );

  const handleWorkflowClick = useCallback(
    (workflow: Workflow) => {
      try {
        invokeFastApi({
          path: `/prompts/prompt_used/${workflow.id}`,
          method: 'POST',
        });

        trackEvent(AnalyticsEvent.WorkflowUsage, { promptId: workflow.id });
      } catch (fetchError) {
        logError(fetchError);
      }

      setSearchQuery(workflow.template);
      setSelectedLlm(workflow.preferred_llm);
      setFiltersFromWorkflowSources(
        setIsBaseLLM,
        setSkillFilters,
        setFileFilters,
        setAnswerFilters,
        workflow,
        user.email,
        allSkills,
        allFiles,
        allAnswers
      );

      if (!inSidePanel()) {
        history.push(SidebarRoutes.JitQA);
      }

      onClose();
    },
    [
      allAnswers,
      allFiles,
      allSkills,
      history,
      onClose,
      setAnswerFilters,
      setFileFilters,
      setIsBaseLLM,
      setSearchQuery,
      setSelectedLlm,
      setSkillFilters,
      user.email,
    ]
  );

  const handleTabChange = useCallback(
    (_, newValue: number) => {
      setActiveTab(newValue);
      switch (newValue) {
        case 0: {
          setFilteredWorkflows(workflows);
          break;
        }

        case 1: {
          setFilteredWorkflows(
            workflows.filter((workflow) => workflow.author.email === user.email)
          );

          break;
        }

        // No default
      }
    },
    [workflows, user]
  );

  useEffect(() => {
    setFilteredWorkflows(workflows);
  }, [workflows]);

  return (
    <div className="h-[560px] overflow-y-auto">
      <Box
        className="uiTabs MuiTabs-root Mui-selected"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tabs onChange={handleTabChange} value={activeTab} variant="scrollable">
          <Tab
            label="All"
            sx={{
              textTransform: 'none',
            }}
          />
          <Tab
            label="Personal"
            sx={{
              textTransform: 'none',
            }}
          />
        </Tabs>
      </Box>
      <div className="my-4">
        <UITextbox
          defaultWhiteInputField
          onChange={handleSearch}
          placeholder="Search workflows ..."
          value={searchText}
        />
      </div>
      {!loading && filteredWorkflows.length === 0 && activeTab === 1 ? (
        <BlankStatePersonalTab />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-3/5">Name</TableHead>
              <TableHead className="w-3/10">Created By</TableHead>
              <TableHead className="w-1/10">Uses</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredWorkflows.map((workflow) => (
              <TableRow
                className="cursor-pointer"
                key={workflow.id}
                onClick={() => {
                  handleWorkflowClick(workflow);
                }}
              >
                <TableCell>
                  <div className="flex flex-col gap-2">
                    <div className="font-medium">{workflow.title}</div>
                    <div className="font-normal text-cloud-30">
                      <div className="font-normal text-cloud-30">
                        {workflow.description &&
                        workflow.description.length > 90
                          ? `${workflow.description.slice(0, 90)}...`
                          : workflow.description}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <AuthorDetails
                    displayName={workflow.author.display_name}
                    icon={workflow.author.icon}
                    isDefault={workflow.default}
                  />
                </TableCell>
                <TableCell>{workflow.default ? '-' : workflow.uses}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {loading && <Loading />}
    </div>
  );
};

export const BlankStatePersonalTab: React.FC = () => (
  <div className="flex flex-col justify-center items-center mt-16">
    <div className="text-2xl font-bold">No personal workflows created</div>
    <div>Workflows you create will show up here on this tab</div>
  </div>
);
