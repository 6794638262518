import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Timestamp } from '../../components/controls/Timestamp';
import { MetaPair } from '../../components/pageSearch/previews/PreviewItem/PreviewItem';
import { PersonAvatar } from '../../components/pageSearch/results/PersonAvatar/PersonAvatar';
import {
  SearchLoadState,
  setHasMore,
  setMessages,
  setPersonResult,
  setPinnedResults,
  setResults,
  setSearchLoad,
} from '../../redux/pageSearch/actions';
import { batch, useDispatch } from '../../redux/store';
import { PageSearchResult } from '../models/page-search-result';
import { ResultOwner } from '../models/result-owner';

/**
 * When navigating to another route away from search UI, clear the search state.
 */
export const useResetHistoryPush = (): ((route: string) => void) => {
  const dispatch = useDispatch();
  const history = useHistory();
  return useCallback(
    (route: string) => {
      batch(() => {
        dispatch(setResults([]));
        dispatch(setPinnedResults([]));
        dispatch(setSearchLoad(SearchLoadState.Loaded));
        dispatch(setHasMore(false));
        dispatch(setPersonResult());
        dispatch(setMessages([]));
      });

      history.push(route);
    },
    [dispatch, history]
  );
};

interface TimeMetaProps {
  time: number;
  user?: ResultOwner | string;
}

const UserSnippet: FC<{ user: ResultOwner }> = ({ user }) => {
  return (
    <span>
      <PersonAvatar imageUrl={user.photo} size={16} />
      &nbsp;
      <span className="heavier">{user.name}</span>
    </span>
  );
};

const TimeMeta: React.FC<TimeMetaProps> = ({ time, user }) => {
  user =
    typeof user === 'string'
      ? {
          name: user,
        }
      : user;

  return (
    <>
      {user && <UserSnippet user={user} />}
      <span className="lighter">
        <Timestamp unixTime={time} />
      </span>
    </>
  );
};

export const createdModifiedMetaPairs = (
  result: PageSearchResult
): MetaPair[] => {
  const pairs: MetaPair[] = [];

  // Don't display any time
  if (!isValidTimeStamp(result.modified_time)) {
    if (result.owners[0]) {
      pairs.push({
        name: 'Owner',
        value: <UserSnippet user={result.owners[0]} />,
      });
    }

    return pairs;
  }

  const isValidOwner = !!result.owners[0]?.name?.length;

  const createdEqualsModified = result.created_time === result.modified_time;

  if (isValidOwner) {
    pairs.push({
      name: createdEqualsModified ? 'Modifier' : 'Creator',
      value: <TimeMeta time={result.created_time} user={result.owners[0]} />,
    });
  } else {
    pairs.push({
      name: createdEqualsModified ? 'Modified' : 'Created',
      value: <TimeMeta time={result.created_time} />,
    });
  }

  if (!createdEqualsModified) {
    pairs.push({
      name: 'Modified',
      value: <TimeMeta time={result.modified_time} />,
    });
  }

  if (isValidTimeStamp(result.completed_time)) {
    pairs.push({
      name: result.completed_by ? 'Completed by' : 'Completed',
      value: (
        <TimeMeta time={result.completed_time} user={result.completed_by} />
      ),
    });
  }

  return pairs;
};

/**
 * Create a safe anchor to new window.
 */
export const newWindowLink = (url: string, display?: string): JSX.Element => {
  return (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {display ?? url}
    </a>
  );
};

/**
 * Push properly formatted url (either web or email) and allow user to
 * open new window.
 */
export const pushResultMetaUrl = (
  result: PageSearchResult,
  metaPairs: MetaPair[],
  name: string,
  key: keyof PageSearchResult,
  isEmail?: boolean
): void => {
  const val = result[key];
  if (!val || typeof val !== 'string') {
    return;
  }

  if (isEmail) {
    metaPairs.push({
      name,
      value: newWindowLink(`mailto:${result[key] as string}`, val),
    });
  } else {
    const url = val.startsWith('http') ? val : `https://${val}`;

    metaPairs.push({
      name,
      value: newWindowLink(url, val),
    });
  }
};

/**
 * Add to `metaPairs` if string attribute is present on `result`.
 */
export const pushResultMeta = (
  result: PageSearchResult,
  metaPairs: MetaPair[],
  name: string,
  key: keyof PageSearchResult
): void => {
  // ASSERTION: To be used in good faith for now
  customResultMeta(metaPairs, name, result[key] as number | string | undefined);
};

export const customResultMeta = (
  metaPairs: MetaPair[],
  name: string,
  value?: number | string
): void => {
  if (!value) {
    return;
  }

  metaPairs.push({
    name,
    value: <span className="heavier">{value}</span>,
  });
};

export const calendarTimeRange = (result: PageSearchResult): string => {
  const startTimeMoment = moment.utc(
    result.start_time ? result.start_time * 1000 : 0
  );

  const endTimeMoment = moment.utc(
    result.end_time ? result.end_time * 1000 : 0
  );

  const isSameDay = startTimeMoment.isSame(endTimeMoment, 'day');
  const startTime = startTimeMoment.local().format('M/DD/YYYY h:mma');

  // Only show full date if we are straddling days
  const endTime = isSameDay
    ? endTimeMoment.local().format('h:mma')
    : endTimeMoment.local().format('M/DD/YYYY h:mma');

  const timezone = moment().tz(moment.tz.guess()).format('z');
  return `${startTime} - ${endTime} (${timezone})`;
};

/**
 * Checks if a time value is a special "default" timestamp that indicates that
 * we don't have an actual one on record.
 * This is currently only the case for dropbox folders.
 */
export function isValidTimeStamp(value?: number): value is number {
  return typeof value === 'number' && value > 100;
}

/**
 * Strips all html tags, useful for showing plain text content.
 */
export const stripHtml = (input: string): string =>
  input.replace(/<\/?[^>]+(>|$)/g, ' ');
