import { Action } from '../actions';

export enum MetaAction {
  SET_USER_DISPLAY_NAME = 'META_SET_USER_DISPLAY_NAME',
  SET_USER_PHOTO_URL = 'META_SET_USER_PHOTO_URL',
}

export function setUserDisplayName(payload?: string): Action {
  return {
    type: MetaAction.SET_USER_DISPLAY_NAME,
    payload,
  };
}

export function setUserPhotoUrl(payload?: string): Action {
  return {
    type: MetaAction.SET_USER_PHOTO_URL,
    payload,
  };
}
