import './Dialog.scss';
import { DialogContent, Dialog as MuiDialog } from '@mui/material';
import classNames from 'classnames';
import React, { FC, ReactNode, Ref, useCallback } from 'react';
import { useUnbind } from '../../pageSearch/results/misc/hooks';
import { Loading, LoadingSize } from '../Loading/Loading';
import { UIButton } from '../ui/UIButton/UIButton';
import { UIIconButton } from '../ui/UIIconButton/UIIconButton';

interface DialogProps {
  open: boolean;
  onClose: () => void;
  showClose?: boolean;
  title?: ReactNode;
  titleActions?: ReactNode;
  content: ReactNode;
  className?: string;
  footer?: ReactNode;
  footerFirst?: boolean;
  loading?: boolean;
  ref?: Ref<HTMLDivElement>;
  showCancel?: boolean;
  cancelButtonTitle?: string;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
}

export const Dialog: FC<DialogProps> = ({
  open,
  onClose,
  title,
  titleActions,
  content,
  className,
  showClose = true,
  footer,
  footerFirst = false,
  loading,
  showCancel,
  ref,
  disableBackdropClick,
  disableEscapeKeyDown,
  cancelButtonTitle = 'Cancel',
}) => {
  const closeAction = useUnbind(onClose);

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, reason: string) => {
      if (
        (disableBackdropClick && reason === 'backdropClick') ||
        (disableEscapeKeyDown && reason === 'escapeKeyDown')
      ) {
        event.stopPropagation();
        return;
      }

      closeAction();
    },
    [closeAction, disableBackdropClick, disableEscapeKeyDown]
  );

  const showFooter = !loading && (footer || showCancel);

  const nativeFooter = showCancel && (
    <UIButton onClick={closeAction} type="secondary">
      {cancelButtonTitle}
    </UIButton>
  );

  return (
    <MuiDialog className={className} onClose={handleClose} open={open}>
      <DialogContent ref={ref}>
        {title && (
          <div className="header">
            {showClose && (
              <UIIconButton
                className="closeDialog"
                name="cross"
                onClick={closeAction}
                size={24}
                square
              />
            )}
            <span className="title">{title}</span>
            {!loading && titleActions}
          </div>
        )}
        <div className="content">
          {loading ? <Loading size={LoadingSize.Large} /> : content}
        </div>

        {showFooter && (
          <div
            className={classNames('footer modalActions', {
              reverse: footerFirst,
            })}
          >
            {nativeFooter}
            {footer}
          </div>
        )}
      </DialogContent>
    </MuiDialog>
  );
};
