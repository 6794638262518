import {
  AccessDiscoveryResult,
  PageSearchResult,
} from '../../scripts/models/page-search-result';
import { PersonResult } from '../../scripts/models/person-result';
import {
  AutosuggestResultEntry,
  HitCounts,
  RecentVisitsAutosuggestResultEntry,
  SearchPayload,
} from '../../search/CloudIndex';
import { Action } from '../actions';
import { PageSearchPreviewState } from './state';

// Note that ALL actions must start with `PAGE_SEARCH_` in order for the action to be routed correctly.

export const enum PageSearchAction {
  SET_CLOUD_INDEX = 'PAGE_SEARCH_SET_CLOUD_INDEX',
  SET_RESULTS = 'PAGE_SEARCH_SET_RESULTS',
  SET_ACCESS_DISCOVERY_RESULTS = 'PAGE_SEARCH_SET_ACCESS_DISCOVERY_RESULTS',
  SET_PINNED_RESULTS = 'PAGE_SEARCH_SET_PINNED_RESULTS',
  SET_PERSON_RESULT = 'PAGE_SEARCH_SET_PERSON_RESULT',
  SET_LOAD = 'PAGE_SEARCH_SET_LOAD',
  SET_HAS_MORE = 'PAGE_SEARCH_SET_HAS_MORE',
  SET_VERSION = 'PAGE_SEARCH_SET_VERSION',
  SET_PREVIEW = 'PAGE_SEARCH_SET_PREVIEW',
  SET_SHOW_FEEDBACK = 'PAGE_SEARCH_SET_SHOW_FEEDBACK',
  SET_FEEDBACK_SHOWN = 'PAGE_SEARCH_SET_FEEDBACK_SHOWN',
  SET_SUGGESTIONS = 'PAGE_SEARCH_SET_SUGGESTIONS',
  SET_QUERY_SUGGESTIONS = 'PAGE_SEARCH_SET_QUERY_SUGGESTIONS',
  SET_RECENT_VISITS_SUGGESTIONS = 'PAGE_SEARCH_SET_RECENT_VISITS_SUGGESTIONS',
  CLEAR_HIT_COUNTS = 'PAGE_SEARCH_CLEAR_HIT_COUNTS',
  UPDATE_HIT_COUNTS = 'PAGE_SEARCH_UPDATE_HIT_COUNTS',
  SET_SEMANTIC_SEARCH = 'PAGE_SEARCH_SET_SEMANTIC_SEARCH',
  SET_PAGE = 'PAGE_SEARCH_SET_PAGE',
  SET_MESSAGES = 'PAGE_SEARCH_SET_MESSAGES',
  ADD_MESSAGES = 'PAGE_SEARCH_ADD_MESSAGES',
  ADD_RESULTS = 'PAGE_SEARCH_ADD_RESULTS',
  SET_HAS_MORE_MESSAGES = 'PAGE_SEARCH_SET_HAS_MORE_MESSAGES',
  SET_QUERY_SPELL_CORRECT = 'PAGE_SEARCH_QUERY_SPELL_CORRECT',
  SET_QUERY_ID = 'PAGE_SEARCH_SET_QUERY_ID',
  SET_SEARCH_REQUEST = 'PAGE_SEARCH_SET_SEARCH_REQUEST',
}

export const enum SearchLoadState {
  None,
  Loaded,
  Loading,
  LoadingMore,
}

export function setResults(payload: PageSearchResult[]): Action {
  return {
    type: PageSearchAction.SET_RESULTS,
    payload,
  };
}

export function addResults(payload: PageSearchResult[]): Action {
  return {
    type: PageSearchAction.ADD_RESULTS,
    payload,
  };
}

export function setPinnedResults(payload: PageSearchResult[]): Action {
  return {
    type: PageSearchAction.SET_PINNED_RESULTS,
    payload,
  };
}

export function setAccessDiscoveryResults(
  payload: AccessDiscoveryResult[]
): Action {
  return {
    type: PageSearchAction.SET_ACCESS_DISCOVERY_RESULTS,
    payload,
  };
}

export function setPersonResult(payload?: PersonResult): Action {
  return {
    type: PageSearchAction.SET_PERSON_RESULT,
    payload,
  };
}

export function setPage(payload: number): Action {
  return {
    type: PageSearchAction.SET_PAGE,
    payload,
  };
}

export function clearHitCounts(): Action {
  return {
    type: PageSearchAction.CLEAR_HIT_COUNTS,
  };
}

export function updateHitCounts(payload?: HitCounts): Action {
  return {
    type: PageSearchAction.UPDATE_HIT_COUNTS,
    payload,
  };
}

export function setSearchLoad(payload: SearchLoadState): Action {
  return {
    type: PageSearchAction.SET_LOAD,
    payload,
  };
}

export function setHasMore(payload: boolean): Action {
  return {
    type: PageSearchAction.SET_HAS_MORE,
    payload,
  };
}

export function setVersion(payload: string): Action {
  return {
    type: PageSearchAction.SET_VERSION,
    payload,
  };
}

export function setPreview(payload?: PageSearchPreviewState): Action {
  return {
    type: PageSearchAction.SET_PREVIEW,
    payload,
  };
}

export function setShowFeedback(payload: boolean): Action {
  return {
    type: PageSearchAction.SET_SHOW_FEEDBACK,
    payload,
  };
}

export function setFeedbackShown(payload: boolean): Action {
  return {
    type: PageSearchAction.SET_FEEDBACK_SHOWN,
    payload,
  };
}

export function setSuggestions(payload: AutosuggestResultEntry[]): Action {
  return {
    type: PageSearchAction.SET_SUGGESTIONS,
    payload,
  };
}

export function setRecentVisitsSuggestions(
  payload: RecentVisitsAutosuggestResultEntry[]
): Action {
  return {
    type: PageSearchAction.SET_RECENT_VISITS_SUGGESTIONS,
    payload,
  };
}

export function setMessages(payload: PageSearchResult[]): Action {
  return {
    type: PageSearchAction.SET_MESSAGES,
    payload,
  };
}

export function setHasMoreMessages(payload: boolean): Action {
  return {
    type: PageSearchAction.SET_HAS_MORE_MESSAGES,
    payload,
  };
}

export function addMessages(payload: PageSearchResult[]): Action {
  return {
    type: PageSearchAction.ADD_MESSAGES,
    payload,
  };
}

export function setQuerySpellCorrection(payload?: string): Action {
  return {
    type: PageSearchAction.SET_QUERY_SPELL_CORRECT,
    payload,
  };
}

export function setQueryId(payload: string): Action {
  return {
    type: PageSearchAction.SET_QUERY_ID,
    payload,
  };
}

export function setSearchRequest(payload?: SearchPayload): Action {
  return {
    type: PageSearchAction.SET_SEARCH_REQUEST,
    payload,
  };
}
