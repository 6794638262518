import { useCallback, useEffect, useRef } from 'react';

/**
 * Returns a function that behaves exactly as `setTimeout` with the difference that all timeouts created by the component will automatically
 * be cleared when the component unmounts.
 * Therefor this function allows for safe delayed state updates.
 */
export function useTimeoutCreator(): (
  callback: () => void,
  ms: number
) => void {
  const timeouts = useRef<number[]>([]);

  useEffect(() => {
    return () => {
      // The ref contents here are mutable
      // eslint-disable-next-line react-hooks/exhaustive-deps
      for (const timeout of timeouts.current) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const cb = useCallback((callback: () => void, ms: number) => {
    timeouts.current.push(setTimeout(callback, ms) as unknown as number);
  }, []);

  return cb;
}
