import React, { FC } from 'react';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { IntegrationInstallStep } from '../generic/IntegrationInstallStep';
import { OAuthRequirementsDialog } from '../generic/OAuthRequirementsDialog';

const requirements = [
  'You will grant Dashworks read-only access to issues and pull requests',
  'Make sure that the account you connect is added to all teams and repositories in the organization',
  'Your organization needs to be on Enterprise Plan and have SAML single sign-on enabled for this integration to work',
];

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <OAuthRequirementsDialog
      app={app}
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      requirements={requirements}
    >
      <IntegrationInstallStep
        app={app}
        step={1}
        url="https://github.com/apps/dashworks-admin/installations/new"
      />
      <ConnectStep step={2} title="Connect Dashworks">
        <p>Click the button below to complete the connection.</p>
      </ConnectStep>
    </OAuthRequirementsDialog>
  );
};
