import * as Sentry from '@sentry/browser';
import { AwsTokens } from '../models/aws-tokens';
import { CommonTokens, isGoogleTokens } from '../models/login-tokens';
import { logError } from '../utils';
import { apiBase, baseRequest, RequestError } from './request';

interface TokenRefreshResponse extends CommonTokens {
  aws_tokens: AwsTokens;
}

export enum RefreshResultCode {
  Ok,
  Unauthenticated,
  Error,
}

export interface FailedRefreshResult {
  code: RefreshResultCode.Error | RefreshResultCode.Unauthenticated;
}

export interface SuccessfulResult {
  code: RefreshResultCode.Ok;
  commonTokens: CommonTokens;
  awsTokens: AwsTokens;
}

export const refreshLoginTokens = async (
  loginTokens: CommonTokens | null,
  shouldRetry = true
): Promise<FailedRefreshResult | SuccessfulResult> => {
  try {
    const { aws_tokens: awsTokens, ...commonTokens } =
      await baseRequest<TokenRefreshResponse>({
        method: 'POST',
        path: 'auth/token-refresh',
        json: loginTokens,
        mode: 'cors',
      });

    return {
      code: RefreshResultCode.Ok,
      awsTokens,
      commonTokens,
    };
  } catch (error) {
    logError(error);
    if (shouldRetry) {
      return await refreshLoginTokens(loginTokens, false);
    }

    if (
      error instanceof RequestError &&
      (!error.statusCode || error.statusCode >= 500)
    ) {
      return {
        code: RefreshResultCode.Error,
      };
    }

    return {
      code: RefreshResultCode.Unauthenticated,
    };
  }
};

export const getGoogleOAuthURL = (): URL => {
  const url = new URL(`${apiBase}/google/auth-url`);
  url.searchParams.set('origin', location.host);
  return url;
};

export const logoutUser = async (
  loginTokens: CommonTokens
): Promise<boolean> => {
  try {
    const awsCognitoLogoutUrl = new URL(
      `https://${AWS_USER_POOL_DOMAIN}.auth.${AWS_REGION}.amazoncognito.com/logout?`
    );

    awsCognitoLogoutUrl.searchParams.set('client_id', AWS_USER_POOL_CLIENT_ID);
    awsCognitoLogoutUrl.searchParams.set(
      'logout_uri',
      'https://www.dashworks.ai'
    );

    await (isGoogleTokens(loginTokens)
      ? baseRequest<undefined>({
          path: 'auth/logout',
          json: loginTokens,
          method: 'POST',
          credentials: 'include',
          mode: 'no-cors',
        })
      : Promise.all([
          baseRequest<undefined>({
            path: 'auth/logout',
            json: loginTokens,
            method: 'POST',
            credentials: 'include',
            mode: 'no-cors',
          }),
          baseRequest<undefined>({
            url: awsCognitoLogoutUrl.href,
            mode: 'no-cors',
          }),
        ]));

    return true;
  } catch {
    Sentry.captureMessage('Failed to logout');
    return false;
  }
};

type AuthProvider = 'email' | 'google' | 'sso';

interface AuthProviderData {
  provider?: AuthProvider;
  error?: string;
  domain_exists: boolean;
}

/**
 * Determine whether email is Google, SSO or not in system.
 */
export const getAuthProvider = async (
  email: string
): Promise<AuthProviderData | undefined> => {
  try {
    return await baseRequest<AuthProviderData>({
      method: 'GET',
      path: `auth/provider?email=${encodeURIComponent(email)}`,
    });
  } catch (error) {
    if (error instanceof RequestError && error.statusCode === 404) {
      return;
    }

    throw error;
  }
};

interface UserCreationData {
  email: string;
  firstName: string;
  lastName: string;
  verification: string;
}

export const enum InitUserCreationResult {
  Success,
  AlreadyExists,
  Failed,
}

export const initUserCreation = async ({
  email,
  firstName,
  lastName,
  verification,
}: UserCreationData): Promise<InitUserCreationResult> => {
  try {
    await baseRequest({
      method: 'POST',
      path: 'auth/user',
      json: {
        email,
        first_name: firstName,
        last_name: lastName,
        verification,
        origin: location.host,
      },
    });
  } catch (error) {
    if (error instanceof RequestError && error.statusCode === 409) {
      return InitUserCreationResult.AlreadyExists;
    }

    return InitUserCreationResult.Failed;
  }

  return InitUserCreationResult.Success;
};

export const sendConfirmationCode = async (code: string): Promise<string> => {
  return (
    await baseRequest<{ loginUrl: string }>({
      method: 'POST',
      path: 'auth/confirm',
      json: {
        code,
        origin: location.host,
      },
    })
  ).loginUrl;
};

export const requestConfirmationCode = async (email: string): Promise<void> => {
  await baseRequest({
    method: 'POST',
    path: 'auth/code-request',
    json: {
      email,
      origin: location.host,
    },
  });
};
