export enum FilterDefaults {
  ObjectType = 'all',
  Apps = 'all_apps',
  Modified = 'any',
  Person = 'everyone',
  Sort = 'relevance',
  // intentionally duplicate
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  FileType = 'any',
}

export enum ObjectType {
  All = 'all',
  Answers = 'answers',
  Articles = 'articles',
  Calendar = 'calendar',
  Contacts = 'contacts',
  Files = 'files',
  Messages = 'messages',
  Tasks = 'tasks',
}

export enum FileType {
  Any = 'any',
  Document = 'document',
  Presentation = 'presentation',
  Spreadsheet = 'spreadsheet',
  PDF = 'pdf',
  Image = 'image',
  Other = 'other',
  Folder = 'folder',
}

export enum Sort {
  Relevance = 'relevance',
  ModifiedNewest = 'modified_newest',
  ModifiedOldest = 'modified_oldest',
  CreatedNewest = 'created_newest',
  CreatedOldest = 'created_oldest',
}
