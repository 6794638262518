import React from 'react';
import { UIIcon } from '../../../../../../controls/ui/UIIcon/UIIcon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

export const IntercomConversationResult: React.FC<ResultProps> = ({
  result,
}) => {
  return (
    <PageSearchResultItem
      appName="Intercom Conversation"
      hideModified
      icon={<UIIcon className="resultPerson" name="person" size={20} />}
      result={result}
    />
  );
};
