import React, { useCallback, useMemo } from 'react';
import { useToaster, useUserSafe } from '../../../scripts/hooks';
import { OrgMember } from '../../../scripts/models/org-member';
import { UserImage } from '../../controls/UserImage/UserImage';
import { UISelect, UISelectItem } from '../../controls/ui/UISelect/UISelect';
import {
  handleMemberAction,
  MemberActionButtonsRow,
  MemberActions,
} from '../MemberActionButtonsRow/MemberActionButtonsRow';

export const ROLE_OPTIONS: UISelectItem[] = [
  {
    label: 'Member',
    value: 'member',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
];

interface MembersTableRowProps {
  checked: boolean;
  member: OrgMember;
  onMemberChange(member: OrgMember, newMember: Partial<OrgMember>): void;
  onCheckedChange(email: string, checked: boolean): void;
}

export const MembersTableRow: React.FC<MembersTableRowProps> = ({
  member,
  onMemberChange,
  checked,
  onCheckedChange,
}) => {
  const authenticatedUser = useUserSafe();
  const toaster = useToaster();

  const handleOnSelect = useCallback(
    (item: UISelectItem) => {
      const admin = item.value === 'admin';
      const body = { emails: [member.email], is_admin: admin };
      // do optimistic UI update, revert UI to old state if there is an error
      onMemberChange(member, { admin });
      handleMemberAction(MemberActions.CHANGE_ROLE, body).catch(() => {
        toaster.failure();
        onMemberChange(member, member);
      });
    },
    [onMemberChange, toaster, member]
  );

  const handleOnCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onCheckedChange(member.email, e.target.checked);
    },
    [onCheckedChange, member.email]
  );

  // Own user cannot change role
  const roleSelect =
    member.user_id === authenticatedUser.userId || !authenticatedUser.admin ? (
      <span className="ownRole">{member.admin ? 'Admin' : 'Member'}</span>
    ) : (
      <UISelect
        disableSelectionState
        dropdownWidth={120}
        items={ROLE_OPTIONS}
        onSelect={handleOnSelect}
        selectedValue={member.admin ? 'admin' : 'member'}
      />
    );

  const emails = useMemo(() => [member.email], [member.email]);

  return (
    <tr>
      <td className="colSelect">
        {member.email !== authenticatedUser.email &&
          authenticatedUser.admin && (
            <input
              checked={checked}
              onChange={handleOnCheckboxChange}
              type="checkbox"
            />
          )}
      </td>
      <td className="colName flex items-center gap-2">
        <UserImage
          displayName={member.name}
          fontSize={12}
          imageUrl={member.icon}
          size={24}
        />
        <span>{member.name}</span>
      </td>
      <td className="colEmail">{member.email}</td>
      <td className="colRole">{roleSelect}</td>
      <td className="colAction">
        {member.email !== authenticatedUser.email && (
          <MemberActionButtonsRow emails={emails} status={member.status} />
        )}
      </td>
    </tr>
  );
};
