import moment from 'moment';
import React from 'react';
import { UITooltip } from './ui/UIToolTip/UIToolTip';

function resultTimeString(unixTime: number): string {
  // We can assume that these are all invalid
  if (unixTime < 100) {
    return 'Invalid date';
  }

  const timestamp = moment.unix(unixTime);
  const now = moment();
  const diff = now.diff(timestamp, 'days');

  if (now.isSame(timestamp, 'day')) {
    return `Today ${timestamp.format('h:mm a')}`;
  }

  if (now.subtract(1, 'day').isSame(timestamp, 'day')) {
    return `Yesterday ${timestamp.format('h:mm a')}`;
  }

  if (diff <= 7 && diff > 0) {
    return `${diff + 1} days ago`;
  }

  return timestamp.format('MMM D, YYYY');
}

export const enum DateFormat {
  DateOnly = 'MMM DD, YYYY',
  Full = 'MMM DD, YYYY h:mm:ssa',
}

interface TimestampProps {
  unixTime: number;
  format?: DateFormat;
  className?: string;
}

export const Timestamp: React.FC<TimestampProps> = ({
  unixTime,
  format = DateFormat.Full,
  className,
}) => {
  const momentInstance = moment.unix(unixTime);
  return (
    <UITooltip title={momentInstance.format(format)}>
      <time
        className={className}
        dateTime={momentInstance.toISOString()}
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {resultTimeString(unixTime)}
      </time>
    </UITooltip>
  );
};
