import React, { FC } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

interface QueryPreservingLinkProps extends LinkProps {
  to: string;
}

export const QueryPreservingLink: FC<QueryPreservingLinkProps> = ({
  to,
  children,
  ...props
}) => {
  const { search } = useLocation();
  let newTo = to;
  if (search) {
    newTo += to.includes('?') ? `&${search.slice(1)}` : search;
  }

  return (
    <Link
      className="font-medium text-amethys-10 hover:text-amethys-20"
      {...props}
      to={newTo}
    >
      {children}
    </Link>
  );
};
