import React, { FC } from 'react';
import { InstallDialogProps } from '../../scripts/app';
import { ConnectStep } from '../generic/Components';
import { InformationOnlyDialog } from '../generic/InformationOnlyDialog';
import { IntegrationInstallStep } from '../generic/IntegrationInstallStep';

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  return (
    <InformationOnlyDialog
      app={app}
      isOrg={isOrg}
      onClose={onClose}
      open={open}
    >
      <IntegrationInstallStep
        app={app}
        step={1}
        url={`https://auth.monday.com/oauth2/authorize?client_id=${MONDAY_CLIENT_ID}&response_type=install`}
      />

      <ConnectStep step={2} title="Connect Dashworks">
        <p>
          Click the button below to complete the connection.
          <br />
          <br />
          <strong>
            You must be an Admin in Monday. Dashworks will request read-only
            access to boards, workspaces, and assets.
          </strong>
        </p>
      </ConnectStep>
    </InformationOnlyDialog>
  );
};
