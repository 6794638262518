import { BackgroundType } from '../../models/User';
import { HomepageLink } from '../models/homepage-link';
import { invokeAWSGatewayAPI } from './aws';

/**
 * Pin/unpin a search result.
 */
export interface PinResultArgs {
  resultObjectId: string;
  searchQuery: string;
  unpin: boolean;
}

export const pinResult = async (args: PinResultArgs): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: '/pin',
    method: 'POST',
    data: {
      object_id: args.resultObjectId,
      q: args.searchQuery,
      // In epoch seconds
      timestamp: Math.floor(Date.now() / 1000),
      unpin: args.unpin,
    },
  });
};

/**
 * Update org name as part of onboarding process.
 */
interface UpdateOrgArgs {
  backgroundColor?: string;
  backgroundImage?: string | null;
  logo?: string;
  companyName?: string;
  homepageLinks?: HomepageLink[];
  backgroundType?: BackgroundType;
  backgroundAllowUser?: boolean;
  answers?: boolean;
  autoInviteMembers?: boolean;
  overrideNewTabPage?: boolean;
}

export const updateOrg = async (args: UpdateOrgArgs): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: '/orgs',
    method: 'PATCH',
    data: {
      company_name: args.companyName,
      background_color: args.backgroundColor,
      logo: args.logo,
      homepage_links: args.homepageLinks,
      background_image: args.backgroundImage,
      background_type: args.backgroundType,
      background_allow_user: args.backgroundAllowUser,
      answers: args.answers,
      auto_invite_members: args.autoInviteMembers,
      override_new_tab_page: args.overrideNewTabPage,
    },
  });
};

export const updateOrgBookmarks = async (
  homepageLinks: HomepageLink[]
): Promise<void> => {
  await invokeAWSGatewayAPI({
    path: '/orgs/bookmarks',
    method: 'PATCH',
    data: { bookmarks: homepageLinks },
  });
};
