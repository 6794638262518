import { Action } from '../actions';
import { GlobalState } from '../state';
import {
  WorkflowActionType,
  WorkflowStateAction,
  WorkflowStepAction,
} from './workflowActions';
import { WorkflowStoreState } from './workflowState';

export const workflowReducer = <S extends object, U extends string>(
  state: GlobalState,
  action: Action
): GlobalState => {
  const workflowStore = {
    ...state.workflows,
  };

  switch (action.type) {
    case WorkflowActionType.SET_STATE: {
      const workflowAction = action as WorkflowStateAction<S, U>;
      const existingWorkflowData = workflowStore.data[
        workflowAction.workflowType
      ] as WorkflowStoreState<S, U>;

      workflowStore.data = {
        ...workflowStore.data,
      };

      (workflowStore.data[workflowAction.workflowType] as WorkflowStoreState<
        S,
        U
      >) = {
        ...existingWorkflowData,
        state: workflowAction.workflowState ?? existingWorkflowData.state,
        currentStep: workflowAction.step ?? existingWorkflowData.currentStep,
      };

      break;
    }

    case WorkflowActionType.SET_STEP: {
      const workflowAction = action as WorkflowStepAction<U>;
      const existingWorkflowData = workflowStore.data[
        workflowAction.workflowType
      ] as WorkflowStoreState<S, U>;

      workflowStore.data = {
        ...workflowStore.data,
      };

      (workflowStore.data[workflowAction.workflowType] as WorkflowStoreState<
        S,
        U
      >) = {
        ...existingWorkflowData,
        currentStep: workflowAction.step,
      };

      break;
    }
  }

  return {
    ...state,
    workflows: workflowStore,
  };
};
