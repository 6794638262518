import './UIInfoTip.scss';
import { Popover } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { UIIcon } from '../../ui/UIIcon/UIIcon';

interface UIInfoTipProps {
  title?: string;
  eventName?: AnalyticsEvent;
}

export const UIInfoTip: React.FC<UIInfoTipProps> = ({
  children,
  title,
  eventName,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const onEnter = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(evt.currentTarget);
      if (eventName) {
        trackEvent(eventName);
      }
    },
    [eventName]
  );

  const onLeave = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div className="uiInfoTip" onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <UIIcon name="info" size={12} />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className="uiInfoTipPopover"
        disableScrollLock
        keepMounted
        open={!!anchorEl}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {title && <div className="title">{title}</div>}
        <div className={children ? 'content' : ''}>{children}</div>
      </Popover>
    </div>
  );
};
