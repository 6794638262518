import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { UIIcon } from '../UIIcon/UIIcon';

interface PaginationProps {
  page: number;
  totalPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  eventName: AnalyticsEvent;
  eventParams: object;
  eventParamsSensitive: object;
}

export const Pagination: FC<PaginationProps> = ({
  page,
  totalPages,
  setPage,
  eventName,
  eventParams,
  eventParamsSensitive,
}) => {
  const handlePageChange = useCallback(
    (newPage: number) => {
      trackEvent(
        eventName,
        {
          ...eventParams,
          direction: newPage > page ? 1 : -1,
          page: newPage,
        },
        eventParamsSensitive
      );

      setPage(newPage);
    },
    [eventName, eventParams, eventParamsSensitive, page, setPage]
  );

  return (
    <div className="flex justify-between items-center">
      <div
        className={classNames(
          'px-4 py-2 rounded text-gray-40 flex items-center gap-2',
          {
            '!text-black cursor-pointer hover:bg-cloud-10': page !== 1,
          }
        )}
        onClick={() => {
          if (page !== 1) {
            handlePageChange(page - 1);
          }
        }}
      >
        <UIIcon name="arrow-left" stroke={page !== 1} />
        Previous
      </div>

      <div className="flex gap-2 text-sm">
        {totalPages > 2 && page === totalPages && (
          <div
            className="p-1 rounded cursor-pointer hover:bg-cloud-10"
            onClick={() => {
              handlePageChange(page - 2);
            }}
          >
            {page - 2}
          </div>
        )}
        {page !== 1 && (
          <div
            className="p-1 rounded cursor-pointer hover:bg-cloud-10"
            onClick={() => {
              handlePageChange(page - 1);
            }}
          >
            {page - 1}
          </div>
        )}
        <div className="p-1 underline text-amethys-10">{page}</div>
        {page !== totalPages && (
          <div
            className="p-1 rounded cursor-pointer hover:bg-cloud-10"
            onClick={() => {
              handlePageChange(page + 1);
            }}
          >
            {page + 1}
          </div>
        )}
        {totalPages > 2 && page === 1 && (
          <div
            className="p-1 rounded cursor-pointer hover:bg-cloud-10"
            onClick={() => {
              handlePageChange(page + 2);
            }}
          >
            {page + 2}
          </div>
        )}
        {totalPages > 3 && page < totalPages - 1 && (
          <div className="p-1">...</div>
        )}
      </div>

      <div
        className={classNames(
          'px-4 py-2 rounded text-gray-40 flex items-center gap-2',
          {
            '!text-black cursor-pointer hover:bg-cloud-10': page !== totalPages,
          }
        )}
        onClick={() => {
          if (page !== totalPages) {
            handlePageChange(page + 1);
          }
        }}
      >
        Next
        <UIIcon name="arrow-right" stroke={page !== totalPages} />
      </div>
    </div>
  );
};
