import React, { ReactNode } from 'react';
import { UIIcon } from '../../components/controls/ui/UIIcon/UIIcon';
import { ObjectType } from './filters';

interface SearchObjectType {
  title: string;
  key: ObjectType;
  icon: ReactNode;
}

export const all: SearchObjectType = {
  key: ObjectType.All,
  title: 'All',
  icon: <UIIcon name="globe" />,
};

export const searchObjectTypes: readonly SearchObjectType[] = [
  all,
  {
    key: ObjectType.Files,
    title: 'Files',
    icon: <UIIcon name="file" />,
  },
  {
    key: ObjectType.Tasks,
    title: 'Tasks',
    icon: <UIIcon name="task" />,
  },
  {
    key: ObjectType.Messages,
    title: 'Messages',
    icon: <UIIcon name="message" />,
  },
  {
    key: ObjectType.Contacts,
    title: 'Contacts',
    icon: <UIIcon name="person" />,
  },
  {
    key: ObjectType.Calendar,
    title: 'Events',
    icon: <UIIcon name="calendar" />,
  },
  {
    title: 'Answers',
    key: ObjectType.Answers,
    icon: <UIIcon name="answer" />,
  },
];
