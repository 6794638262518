import { gql } from 'graphql-tag';
import { userFields } from './fragments';

/* eslint-disable @cspell/spellchecker */

export const subscribeUserUpdate = gql`
  subscription ($userid: String!) {
    result: user(userId: $userid) {
      ...UserFields
    }
  }
  ${userFields}
`;
