import {
  AccessDiscoveryResult,
  PageSearchResult,
} from '../../scripts/models/page-search-result';
import { PersonResult } from '../../scripts/models/person-result';
import { updateArrayByKey } from '../../scripts/utils';
import {
  AutosuggestResultEntry,
  CloudIndex,
  HitCounts,
  QueryAutosuggestResultEntry,
  RecentVisitsAutosuggestResultEntry,
  SearchPayload,
} from '../../search/CloudIndex';
import { Action } from '../actions';
import { GlobalState } from '../state';
import { PageSearchAction, SearchLoadState } from './actions';
import { PageSearchPreviewState, PageSearchState } from './state';

// eslint-disable-next-line complexity
export const pageSearchReducer = (
  state: GlobalState,
  { type, payload }: Action
): GlobalState => {
  const newPageSearch: PageSearchState = {
    ...state.pageSearch,
  };

  switch (type) {
    case PageSearchAction.SET_CLOUD_INDEX: {
      newPageSearch.cloudIndex = payload as CloudIndex;
      break;
    }

    case PageSearchAction.SET_RESULTS: {
      newPageSearch.results = payload as PageSearchResult[];
      break;
    }

    case PageSearchAction.ADD_RESULTS: {
      const existing = new Set(newPageSearch.results.map((r) => r.objectID));
      const addition = (payload as PageSearchResult[]).filter(
        (r) => !existing.has(r.objectID)
      );

      newPageSearch.results = newPageSearch.results.concat(addition);

      break;
    }

    case PageSearchAction.SET_ACCESS_DISCOVERY_RESULTS: {
      newPageSearch.accessDiscoveryResults = payload as AccessDiscoveryResult[];
      break;
    }

    case PageSearchAction.SET_PINNED_RESULTS: {
      newPageSearch.pinnedResults = payload as PageSearchResult[];
      break;
    }

    case PageSearchAction.SET_PERSON_RESULT: {
      newPageSearch.personResult = payload as PersonResult;
      break;
    }

    case PageSearchAction.CLEAR_HIT_COUNTS: {
      newPageSearch.hitCounts = {};
      break;
    }

    case PageSearchAction.UPDATE_HIT_COUNTS: {
      const { apps, types } = payload as HitCounts;
      const { hitCounts } = newPageSearch;

      const newHitCounts = {
        apps: updateArrayByKey(
          hitCounts?.apps ?? [],
          apps ?? [],
          'name',
          (prev, next) => (next.count += prev.count)
        ),
        types: updateArrayByKey(
          hitCounts?.types ?? [],
          types ?? [],
          'name',
          (prev, next) => (next.count += prev.count)
        ),
      };

      newPageSearch.hitCounts = newHitCounts;

      break;
    }

    case PageSearchAction.SET_LOAD: {
      newPageSearch.load = payload as SearchLoadState;
      break;
    }

    case PageSearchAction.SET_HAS_MORE: {
      newPageSearch.hasMore = payload as boolean;
      break;
    }

    case PageSearchAction.SET_VERSION: {
      newPageSearch.version = payload as string;
      break;
    }

    case PageSearchAction.SET_PREVIEW: {
      newPageSearch.preview = payload as PageSearchPreviewState;
      break;
    }

    case PageSearchAction.SET_SHOW_FEEDBACK: {
      newPageSearch.showFeedback = payload as boolean;
      break;
    }

    case PageSearchAction.SET_FEEDBACK_SHOWN: {
      newPageSearch.feedbackShown = payload as boolean;
      break;
    }

    case PageSearchAction.SET_SUGGESTIONS: {
      newPageSearch.suggestions = payload as AutosuggestResultEntry[];
      break;
    }

    case PageSearchAction.SET_QUERY_SUGGESTIONS: {
      newPageSearch.querySuggestions = payload as QueryAutosuggestResultEntry[];

      break;
    }

    case PageSearchAction.SET_RECENT_VISITS_SUGGESTIONS: {
      newPageSearch.recentVisitsSuggestions =
        payload as RecentVisitsAutosuggestResultEntry[];

      break;
    }

    case PageSearchAction.SET_PAGE: {
      newPageSearch.page = payload as number;
      break;
    }

    case PageSearchAction.SET_MESSAGES: {
      newPageSearch.messages = payload as PageSearchResult[];
      break;
    }

    case PageSearchAction.SET_HAS_MORE_MESSAGES: {
      newPageSearch.hasMoreMessages = payload as boolean;
      break;
    }

    case PageSearchAction.ADD_MESSAGES: {
      newPageSearch.messages = newPageSearch.messages.concat(
        payload as PageSearchResult[]
      );

      break;
    }

    case PageSearchAction.SET_QUERY_SPELL_CORRECT: {
      newPageSearch.querySpellCorrection = payload as string;
      break;
    }

    case PageSearchAction.SET_QUERY_ID: {
      newPageSearch.queryId = payload as string;
      break;
    }

    case PageSearchAction.SET_SEARCH_REQUEST: {
      newPageSearch.searchRequest = payload as SearchPayload | undefined;
      break;
    }
  }

  return {
    ...state,
    pageSearch: newPageSearch,
  };
};
