import { useCallback, useRef } from 'react';

export const useTrackMouseEnter = (
  callback: () => void
): ((node: HTMLElement | null) => void) => {
  const ref = useRef<HTMLElement | null>(null);
  const viewed = useRef(false);

  return useCallback(
    (node) => {
      if (!node) {
        return;
      }

      const onMouseenter = () => {
        if (!viewed.current) {
          viewed.current = true;
          callback();
        }
      };

      const { current } = ref;

      if (current) {
        current.removeEventListener('mouseenter', onMouseenter);
      }

      node.addEventListener('mouseenter', onMouseenter, { passive: true });
      ref.current = node;
    },
    [callback]
  );
};
