import classNames from 'classnames';
import React, { FC } from 'react';
import './UISquareButtonRow.scss';

export const UISquareButtonRow: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <span className={classNames('uiSquareButtonRow', className)}>
      {children}
    </span>
  );
};
