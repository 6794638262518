import React from 'react';
import { PageSearchResultItem } from '../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../resultProps';

export const SharepointSiteResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="SharePoint Site"
      modifiedLabel="Last Modified"
      result={result}
    />
  );
};
