import { makeLazyComponent } from '../../../scripts/utils';

export const LazyUserAiControlsPage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "user-ai-controls-page" */ './UserAiControlsPage'
      )
    ).UserAiControlsPage
);
