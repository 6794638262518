import React, { FC, useCallback } from 'react';
import './DashAiSuggestion.scss';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { trackEvent } from '../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import {
  QueryParams,
  useHistoryPush,
  useSearchParam,
} from '../../../scripts/hooks';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { SearchSuggestionContainer } from './SearchSuggestionContainer';

export const DashAiSuggestion: FC = () => {
  const [query] = useSearchParam();

  const push = useHistoryPush(
    `${SidebarRoutes.JitQA}?${QueryParams.Search}=${encodeURIComponent(query)}`
  );

  const handleClick = useCallback(() => {
    trackEvent(AnalyticsEvent.SearchNoticeDashAiSuggestionClick, { query });
    push();
  }, [query, push]);

  return (
    <div className="dashAiSuggestionContainer">
      <SearchSuggestionContainer
        visibleImpression={AnalyticsEvent.SearchNoticeDashAiSuggestionView}
      >
        <UIButton onClick={handleClick} size="small">
          <UIIcon name="messages" />
          Ask Dash AI
        </UIButton>
        <p>Looking for an AI-powered answer? Try asking Dash AI.</p>
      </SearchSuggestionContainer>
    </div>
  );
};
