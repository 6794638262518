import React, { FC } from 'react';
import { UIButton } from '../../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../components/controls/ui/UIIcon/UIIcon';
import { getGoogleOAuthURL } from '../../../scripts/apis';
import { openLoginPopup } from '../../../scripts/authentication';

export const handleLoginWithGoogle = (): void => {
  openLoginPopup(getGoogleOAuthURL().href);
};

export const GoogleAuthButton: FC = ({ children }) => {
  return (
    <UIButton onClick={handleLoginWithGoogle} size="large" type="secondary">
      <UIIcon name="google" type="apps" />
      <span>{children}</span>
    </UIButton>
  );
};
