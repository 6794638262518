import { useCallback } from 'react';
import { useAppConnectionStatus } from '../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';

export const useAppName = (): {
  getAppName: (id?: number) => string | undefined;
} => {
  const userApps = useAppConnectionStatus();

  const getAppName = useCallback(
    (id?: number): string | undefined => {
      if (!id) {
        return undefined;
      }

      const app = userApps.find((a) => a.id === id);
      if (!app) {
        return undefined;
      }

      if (app.appName === 'website' || app.appName === 'custom-api') {
        return app.workspaceName;
      }

      const appsWithSameAppName = userApps.filter(
        (a) => a.appName === app.appName
      );

      appsWithSameAppName.sort((a, b) => {
        return a.id - b.id;
      });

      if (appsWithSameAppName.length > 1) {
        if (app.isOrg) {
          const orgAppsWithSameAppName = appsWithSameAppName.filter(
            (a) => a.isOrg
          );

          const index = orgAppsWithSameAppName.findIndex((a) => a.id === id);

          if (index > 0) {
            return `${app.displayName} Org ${index}`;
          }

          return `${app.displayName} Org`;
        }

        const personalAppsWithSameAppName = appsWithSameAppName.filter(
          (a) => !a.isOrg
        );

        const index = personalAppsWithSameAppName.findIndex((a) => a.id === id);

        if (index > 0) {
          return `${app.displayName} Individual ${index}`;
        }

        return `${app.displayName} Individual`;
      }

      return app.displayName;
    },
    [userApps]
  );

  return {
    getAppName,
  };
};
