import './SearchInput.scss';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { SearchLoadState } from '../../../../redux/pageSearch/actions';
import { composeRefs } from '../../../../scripts/compose-refs';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import {
  all,
  searchObjectTypes,
} from '../../../../scripts/constants/search-object-types';
import {
  useAllSearchRelatedParams,
  useFilterParam,
  useGlobalKeyPress,
  usePageSearch,
  useUserSafe,
} from '../../../../scripts/hooks';
import { inExtension, isMobile } from '../../../../scripts/utils';
import { HomePageSearchInput } from '../../../controls/ui/UITextbox/HomePageSearch';
import { AutoSuggest, AutoSuggestContext } from '../AutoSuggest/AutoSuggest';

interface SearchInputProps {
  showHint?: boolean;
  tabIndex?: number;
}

export const SearchInput = forwardRef<HTMLTextAreaElement, SearchInputProps>(
  ({ showHint, tabIndex }, ref) => {
    const [query, setQuery] = useAllSearchRelatedParams();

    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const autoSuggestRef = useRef<AutoSuggestContext>(null);

    // On load, if query arg is already in url, we should populate the input
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [searchQuery, setSearchQuery] = useState(query.q);
    const isLoading = usePageSearch(
      ({ load }) =>
        load === SearchLoadState.Loading || load === SearchLoadState.LoadingMore
    );

    useEffect(() => {
      if (!inExtension() && !isMobile()) {
        inputRef.current?.focus();
      }

      setTimeout(() => {
        setIsFirstRender(false);
      }, 1000);
    }, []);

    const [autoSuggestAnchorEl, setAutoSuggestAnchorEl] = useState<
      HTMLElement | undefined
    >();

    const [autoSuggestOpen, setAutoSuggestOpen] = useState(false);

    const [rawFilter] = useFilterParam();

    const filter = searchObjectTypes.find((f) => f.key === rawFilter) ?? all;

    const companyName = useUserSafe((user) => user.orgByOrgId.name);

    const handleBlur = useCallback((ev: React.FocusEvent<HTMLDivElement>) => {
      if (ev.currentTarget.contains(ev.relatedTarget)) {
        return;
      }

      setAutoSuggestOpen(false);
    }, []);

    const searchAll = useCallback(() => {
      if (!searchQuery || searchQuery.trim() === '') {
        return;
      }

      setQuery({
        q: searchQuery,
        suggested: false,
      });

      setAutoSuggestOpen(false);
    }, [searchQuery, setQuery]);

    useGlobalKeyPress('/', () => {
      if (!isMobile()) {
        inputRef.current?.focus();
      }
    });

    const triggerSearch = useCallback(() => {
      autoSuggestRef.current?.triggerSearch(searchAll, () => {
        setAutoSuggestOpen(false);
      });
    }, [searchAll]);

    useEffect(() => {
      setSearchQuery(query.q);
    }, [query]);

    const openAutoSuggest = useCallback(
      (evt: React.SyntheticEvent) => {
        if (
          isFirstRender ||
          autoSuggestOpen ||
          !evt.currentTarget.parentElement
        ) {
          return;
        }

        // Popover open determined by setting anchor element
        setAutoSuggestAnchorEl(evt.currentTarget.parentElement);
        setAutoSuggestOpen(true);
      },
      [autoSuggestOpen, isFirstRender]
    );

    const onKeyDown = useCallback(
      (evt: React.KeyboardEvent) => {
        openAutoSuggest(evt);

        switch (evt.key.toLowerCase()) {
          case 'arrowdown':
            autoSuggestRef.current?.nextSelection();
            evt.preventDefault();

            trackEvent(AnalyticsEvent.AutosuggestKeyArrowDown);
            return;

          case 'arrowup':
            autoSuggestRef.current?.previousSelection();
            evt.preventDefault();

            trackEvent(AnalyticsEvent.AutosuggestKeyArrowUp);
            return;

          case 'escape':
            if (autoSuggestOpen) {
              setAutoSuggestOpen(false);
            }

            return;

          case 'enter':
            if (evt.shiftKey) {
              return;
            }

            triggerSearch();
            break;

          default: {
            // Does not match any shortcuts
          }
        }
      },
      [autoSuggestOpen, openAutoSuggest, triggerSearch]
    );

    const textboxClasses = classNames(
      'topSearchInput',
      'withAutoSuggest bg-white',
      {
        autoSuggestOpen: searchQuery.trim() !== '' && autoSuggestOpen,
      }
    );

    const placeholderSuffix = companyName ? ` in ${companyName}...` : '...';
    let placeholder = `Ask anything${placeholderSuffix}`;

    /*
     * Set placeholder when user either selects a Autosuggest filter
     * or is on search results page with filter set.
     */
    if (filter.key !== 'all') {
      placeholder = `Search ${filter.title.toLowerCase()}${placeholderSuffix}`;
    }

    return (
      <div
        className="searchInput items-start"
        onBlur={handleBlur}
        ref={containerRef}
      >
        <HomePageSearchInput
          autoComplete="off"
          className={textboxClasses}
          inputClassName="searchInputBox"
          isWorking={isLoading}
          onChange={setSearchQuery}
          onClick={openAutoSuggest}
          onFocus={openAutoSuggest}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          ref={composeRefs(inputRef, ref)}
          size="large"
          tabIndex={tabIndex ?? -1}
          useClear
          value={searchQuery}
        />
        {showHint &&
          !autoSuggestOpen &&
          searchQuery === '' &&
          containerRef.current !== document.activeElement && (
            <div className="hidden sm:block absolute top-[18px] right-4 text-sm text-cloud-20">
              <span className="border border-solid border-cloud-20 py-0.5 px-1 rounded">
                Tab
              </span>{' '}
              to focus
            </div>
          )}
        <AutoSuggest
          anchorEl={autoSuggestAnchorEl}
          container={containerRef.current}
          filterKey={filter.key}
          isOpen={searchQuery.trim() !== '' && autoSuggestOpen}
          ref={autoSuggestRef}
          searchQuery={searchQuery}
        />
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';
