import React, { FC } from 'react';
import { useUserId } from '../../../../../scripts/hooks';
import { ResultPin } from '../../../../../scripts/models/result-pin';
import { isValidTimeStamp } from '../../../../../scripts/page-search/result-utils';
import { Timestamp } from '../../../../controls/Timestamp';
import { UIIcon } from '../../../../controls/ui/UIIcon/UIIcon';
import { CountIndicator } from '../CountIndicator/CountIndicator';
import { MissingWords } from '../MissingWords/MissingWords';
import './Footer.scss';

interface FooterProps {
  backLinkCount: number;
  commentCount: number;
  hideModified?: boolean;
  missingTerms?: string[];
  modifiedLabel?: string;
  modifiedTime?: number;
  pin?: ResultPin;
  sentTime?: number;
  viewCount: number;
}

export const Footer: FC<FooterProps> = ({
  modifiedTime,
  sentTime,
  hideModified,
  pin,
  modifiedLabel = 'Last modified',
  commentCount,
  viewCount,
  backLinkCount,
  missingTerms,
}) => {
  const userId = useUserId();

  const showModifiedTime = !hideModified && isValidTimeStamp(modifiedTime);
  const showSentTime = isValidTimeStamp(sentTime);
  const showTimes = showModifiedTime || showSentTime;

  let pinnedMessage: JSX.Element | null = null;
  if (pin) {
    const pinnerName = pin.pinner.id === userId ? 'you' : pin.pinner.name;

    pinnedMessage = (
      <div className="pinnedMessage">
        Pinned for <strong>&quot;{pin.query}&quot;</strong> by{' '}
        <strong>{pinnerName}</strong>
      </div>
    );
  }

  return (
    <div className="resultItemFooter">
      {pinnedMessage ??
        (showTimes && (
          <div className="times">
            {showModifiedTime && (
              <div className="timestamp">
                <UIIcon name="update" size={12} />
                <div className="timeLabel">
                  {modifiedLabel}: <Timestamp unixTime={modifiedTime} />
                </div>
              </div>
            )}
            {showSentTime && (
              <div className="timestamp">
                <UIIcon name="message" size={12} />
                <div className="timeLabel">
                  Sent: <Timestamp unixTime={sentTime} />
                </div>
              </div>
            )}
          </div>
        ))}
      {!!missingTerms?.length && (
        <MissingWords missing={missingTerms} showRequire={false} />
      )}
      <CountIndicator count={commentCount} icon="comment" name="comment" />
      <CountIndicator count={viewCount} icon="eye" name="view" />
      <CountIndicator
        count={backLinkCount}
        icon="arrow-down-left-long"
        name="mention"
      />
    </div>
  );
};
