export const enum AppCuesIds {
  MyAppsLink = 'my-apps-link',
  MySettingsLink = 'my-settings-link',
  AddPersonalBookmarkButton = 'add-personal-bookmark-button',
  AddOrgBookmarkButton = 'add-org-bookmark-button',
}

export const enum Newrelic {
  AuthActionContainer = 'auth-action-container',
}
