import React, { useCallback, useEffect, useState } from 'react';
import { useTimeoutCreator } from '../../../scripts/hooks';
import { safeCreateUrl } from '../../../scripts/utils';
import { UIIcon } from '../ui/UIIcon/UIIcon';

const enum StatusStep {
  Hostname,
  Error,
  Loaded,
}

// eslint-disable-next-line unicorn/no-unsafe-regex
const ipRegExp = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

function isIP(host: string): boolean {
  return ipRegExp.test(host);
}

function removeSubdomain(url: URL): URL {
  // Note that url.href ALWAYS has a trailing slash unless it has a file extension
  if (isIP(url.host)) {
    return url;
  }

  const firstDot = url.host.indexOf('.');
  const lastDot = url.host.lastIndexOf('.');
  if (firstDot === lastDot) {
    return url;
  }

  const secondLastDot = url.host.lastIndexOf('.', lastDot - 1);
  const out = new URL(url);
  out.host = url.host.slice(secondLastDot + 1);
  return out;
}

export const LinkFavicon: React.FC<{ link: string; size?: number }> = ({
  link,
  size = 16,
}) => {
  const url = safeCreateUrl(link);
  const setTimeout = useTimeoutCreator();
  const [step, setStep] = useState(StatusStep.Hostname);
  const [timeoutReached, setTimeoutReached] = useState(false);

  useEffect(() => {
    setTimeoutReached(false);
    // Timeout after 5000ms to avoid not loading images at all
    setTimeout(() => {
      setTimeoutReached(true);
    }, 5000);
  }, [link, setTimeout]);

  const onImageLoaded = useCallback(() => {
    setStep(StatusStep.Loaded);
  }, []);

  const onImageMissing = useCallback(() => {
    if (step === StatusStep.Error) {
      return;
    }

    // Otherwise, we just show the default icon
    setStep(StatusStep.Error);
  }, [step]);

  if (
    !url ||
    step === StatusStep.Error ||
    (timeoutReached && step !== StatusStep.Loaded)
  ) {
    return <UIIcon name="bookmark" size={size} />;
  }

  // Assume the root-level domain has the definitive favicon
  const { hostname } = removeSubdomain(url);
  return (
    <>
      <img
        className="homepageLinkIcon"
        onError={onImageMissing}
        onLoad={onImageLoaded}
        src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${hostname}&size=128`}
        style={{ display: step === StatusStep.Loaded ? 'block' : 'none' }}
        width={size}
      />
      {step === StatusStep.Hostname && (
        <UIIcon name="spinner-animated" size={size} />
      )}
    </>
  );
};
