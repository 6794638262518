import { useEffect, useState } from 'react';
import { isExtensionInstalled } from '../web-extension';

/**
 * Observe if extension is installed.
 */
export const useExtensionObserver = (): boolean => {
  const [installed, setInstalled] = useState(false);

  const checkExtensionInstallation = async () => {
    const extensionInstalled = await isExtensionInstalled();
    setInstalled(extensionInstalled);
  };

  useEffect(() => {
    const handleFocus = () => {
      checkExtensionInstallation();
    };

    window.addEventListener('focus', handleFocus);

    const observer = new MutationObserver(() => {
      checkExtensionInstallation();
    });

    observer.observe(document.body, { attributes: true });

    checkExtensionInstallation();

    return () => {
      observer.disconnect();
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return installed;
};
