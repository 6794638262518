import classNames from 'classnames';
import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { setSidebarNotificationsOpen } from '../../redux/sidebar/actions';
import { useDispatch } from '../../redux/store';
import { NotificationsFeed } from './NotificationsFeed';

import './Notifications.scss';

interface NotificationsSidebarProps {
  isOpen: boolean;
}

export const NotificationsSidebar = ({
  isOpen,
}: NotificationsSidebarProps): JSX.Element => {
  const dispatch = useDispatch();

  const closeNotificationsSidebar = useCallback(() => {
    dispatch(setSidebarNotificationsOpen(false));
  }, [dispatch]);

  return createPortal(
    <div>
      <div
        className={classNames(
          'fixed top-0 bottom-0 right-0 sm:left-[var(--sidebar-width)] sm:z-[138] left-0 z-[140] bg-gray-40/50',
          { hidden: !isOpen }
        )}
        onClick={() => {
          closeNotificationsSidebar();
        }}
      />
      <div
        className={classNames(
          'notificationSidebar fixed overflow-y-auto custom-scrollbar h-full sm:w-[400px] w-auto sm:z-[139]  z-[141] bg-cloud-5 pt-4 shadow-2xl',
          { open: isOpen },
          { close: !isOpen }
        )}
      >
        <NotificationsFeed />
      </div>
    </div>,
    document.body
  );
};
