import React, { FC, useCallback, useState } from 'react';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { InstallDialogProps } from '../../scripts/app';
import { useExtensionObserver } from '../../scripts/hooks/extension';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

interface CodaCookie {
  token: string;
  authFlag: string;
}

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  const [cookie, setCookie] = useState<CodaCookie | undefined>();

  const extensionInstalled = useExtensionObserver();

  const webAuth = useCallback(() => {
    const onSetCodaToken = (
      event: MessageEvent<{
        action: string;
        payload: { token: string; authFlag: string };
      }>
    ) => {
      if (event.data.action !== 'dash-set-coda-token') {
        return;
      }

      // User has logged into Coda, pass token to backend auth
      const { token, authFlag } = event.data.payload;

      if (authFlag && token) {
        setCookie({ token, authFlag });
      }

      window.removeEventListener('message', onSetCodaToken);
    };

    window.removeEventListener('message', onSetCodaToken);
    if (extensionInstalled) {
      window.addEventListener('message', onSetCodaToken);
    }

    window.open('https://coda.io/account', '_blank');
  }, [extensionInstalled]);

  let stepCount = 0;
  return (
    <SimpleAPIKeyInstall
      app={app}
      authFlag={cookie?.authFlag}
      authSession={cookie?.token}
      instructionSteps={
        <ConnectStep step={++stepCount} title="Generate API Token">
          <p>
            Sign in with the Coda account you want to connect with Dashworks.
            You may grant Dashworks read-only access.
          </p>
          <div className="actionContainer">
            <UIButton onClick={webAuth}>Sign into Coda</UIButton>
          </div>
          <p>
            Go to <strong>Account Settings</strong>, click on{' '}
            <strong>Generate API token</strong> and copy the{' '}
            <strong>Generated Token</strong>.
          </p>
        </ConnectStep>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={stepCount}
    />
  );
};
