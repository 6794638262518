import { Switch } from '@mui/material';
import React, { FC, useCallback } from 'react';
import './UISwitch.scss';

export const UISwitch: FC<{
  className?: string;
  checked: boolean;
  disabled?: boolean;
  onChange(newChecked: boolean): void;
}> = ({ checked, onChange, className, disabled }) => {
  const handleChange = useCallback(
    (_ev: unknown, newChecked: boolean) => {
      onChange(newChecked);
    },
    [onChange]
  );

  return (
    <Switch
      checked={checked}
      className={`uiSwitch ${className ?? ''}`}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};
