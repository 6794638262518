import './GridItem.scss';
import React, { FC, RefObject, useEffect, useState } from 'react';
import { getPreviewData } from '../../../../../scripts/apis';
import { useSearchParam } from '../../../../../scripts/hooks';
import { PageSearchResult } from '../../../../../scripts/models/page-search-result';
import { createdModifiedMetaPairs } from '../../../../../scripts/page-search/result-utils';
import { logError } from '../../../../../scripts/utils';
import { Actions } from '../../PageSearchResultItem/actions/Actions';
import { ContentSnippet } from '../../misc/ContentSnippet';
import { MetaPairs } from '../../misc/MetaPairs';
import {
  PositionSelector,
  useInstrumentedResultActions,
} from '../../misc/hooks';

interface GridItemsProps {
  result: PageSearchResult;
}

export const GridItem: FC<GridItemsProps> = ({ result }) => {
  const { objectID, unhighlighted_name, content_snippet } = result;

  const [content, setContent] = useState('');

  const metaPairs = createdModifiedMetaPairs(result);
  const [searchQuery] = useSearchParam();

  // False positive here...
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleNav, onCopy, elementRefFn, visibleImpression } =
    useInstrumentedResultActions(result, PositionSelector.Grid);

  useEffect(() => {
    getPreviewData({
      objectId: objectID,
    })
      .then((data) => {
        // Handles race condition where user may have moved onto another result
        // eslint-disable-next-line unicorn/consistent-destructuring
        if (objectID !== result.objectID) {
          return;
        }

        if (data.type === 'base64') {
          setContent(data.content);
        }
      })
      .catch(logError);
  }, [objectID, result]);

  return (
    <div
      className="gridItem"
      onClick={handleNav}
      onKeyDown={handleNav}
      ref={elementRefFn as RefObject<HTMLDivElement>}
      tabIndex={0}
    >
      {content && <img src={content} />}
      <div className="details">
        <h4 ref={visibleImpression}>{unhighlighted_name}</h4>
        <ContentSnippet content={content_snippet} />
        <MetaPairs pairs={metaPairs} />
      </div>

      <Actions onCopy={onCopy} result={result} searchQuery={searchQuery} />
    </div>
  );
};
