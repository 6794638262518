import React from 'react';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';

interface JiraIssueResultProps extends ResultProps {
  appName: string;
}

export const JiraIssueResult: React.FC<JiraIssueResultProps> = ({
  appName,
  result,
}) => {
  return (
    <PageSearchResultItem
      appName={appName}
      result={result}
      showLastItemBreadcrumb
      status={result.status}
    />
  );
};
