import './MembersTableDialog.scss';
import React, { FC } from 'react';
import { Dialog } from '../../controls/Dialog/Dialog';
import { MembersTable } from '../MembersTable/MembersTable';

interface MembersTableDialogProps {
  open: boolean;
  onClose(): void;
}

export const MembersTableDialog: FC<MembersTableDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog
      className="membersTableDialog"
      content={<MembersTable filterStatus={undefined} />}
      onClose={onClose}
      open={open}
      showCancel
    />
  );
};
