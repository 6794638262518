import React, { useCallback, useEffect, useState } from 'react';
import { SortProperty } from '../../../../../redux/state';
import { FilterDefaults, Sort } from '../../../../../scripts/constants/filters';
import { useSortParam } from '../../../../../scripts/hooks';
import { UIIcon } from '../../../../controls/ui/UIIcon/UIIcon';
import {
  UISelect,
  UISelectItem,
} from '../../../../controls/ui/UISelect/UISelect';

const SORT_OPTIONS: UISelectItem[] = [
  {
    label: 'Most relevant',
    value: FilterDefaults.Sort,
    isDefault: true,
  },
  {
    label: 'Last modified: Newest first',
    value: Sort.ModifiedNewest,
  },
  {
    label: 'Last modified: Oldest first',
    value: Sort.ModifiedOldest,
  },
  {
    label: 'Created: Newest first',
    value: Sort.CreatedNewest,
  },
  {
    label: 'Created: Oldest first',
    value: Sort.CreatedOldest,
  },
];

const validValues = new Set(SORT_OPTIONS.map((option) => option.value));

export const SortFilter: React.FC = () => {
  const [sortParam, setSortParam] = useSortParam();

  const [selectedValue, setSelectedValue] = useState(
    SortProperty.Relevance as string
  );

  useEffect(() => {
    // If param isn't valid on load, set to default
    if (!sortParam || !validValues.has(sortParam)) {
      setSortParam(SortProperty.Relevance);
    }
  }, [setSortParam, sortParam]);

  useEffect(() => {
    setSelectedValue(sortParam);
  }, [sortParam]);

  const handleOnSelect = useCallback(
    (item: UISelectItem) => {
      setSortParam(item.value);
    },
    [setSortParam]
  );

  return (
    <UISelect
      dropdownWidth={250}
      items={SORT_OPTIONS}
      onSelect={handleOnSelect}
      selectLabelPrefix={<UIIcon name="up-down" />}
      selectedValue={selectedValue}
    />
  );
};
