import React, { useCallback } from 'react';
import { withInstallModal } from '../../hoc/withInstallModal';

export const AppInstallModal = withInstallModal(
  ({ connectInProgress, handleOAuthInstall, setInProgress }) => {
    const clickConnect = useCallback(async () => {
      setInProgress(true);
      return handleOAuthInstall({});
    }, [handleOAuthInstall, setInProgress]);

    return (
      <>
        <p className="firstNotice">
          Requirement to connect Asana:
          <ul>
            <li>
              <strong>
                This integration is only available for Asana users on a paid
                plan. Click the Connect button below to grant consent.
              </strong>
            </li>
          </ul>
        </p>

        {connectInProgress(clickConnect)}
      </>
    );
  }
);
