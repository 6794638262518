/*
 * Open native app deeplink if available, otherwise fallback to webview
 * From: https://gist.github.com/diachedelic/0d60233dab3dcae3215da8a4dfdcd434
 */
class DeepLinker {
  private hasFocus = true;
  private didHide = false;
  private readonly onBlur: () => void;
  private readonly onVisibilityChange: (event: Event) => void;
  private readonly onFocus: () => void;

  public constructor(private readonly onIgnored: () => void) {
    // Window is blurred when dialogs are shown

    this.onBlur = () => {
      this.hasFocus = false;
    };

    // Document is hidden when native app is shown or browser is in the background
    this.onVisibilityChange = (e: Event) => {
      if ((e.currentTarget as Document).visibilityState === 'hidden') {
        this.didHide = true;
      }
    };

    // Window is focused when dialogs are hidden, or browser comes into view
    this.onFocus = () => {
      if (this.didHide) {
        // Reset
        this.didHide = false;
      }

      this.hasFocus = true;
    };

    window.addEventListener('blur', this.onBlur);
    document.addEventListener('visibilitychange', this.onVisibilityChange);
    window.addEventListener('focus', this.onFocus);
  }

  public openURL(url: string) {
    // It can take a while for the dialog to appear
    const dialogTimeout = 500;

    setTimeout(() => {
      if (this.hasFocus) {
        this.onIgnored();
      }
    }, dialogTimeout);

    window.location.href = url;
  }

  public disable() {
    window.removeEventListener('blur', this.onBlur);
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
    window.removeEventListener('focus', this.onFocus);
  }
}

export function openDeeplink(deeplinkUrl: string, webviewUrl: string): void {
  const linker = new DeepLinker(() => {
    window.open(webviewUrl);
    linker.disable();
  });

  linker.openURL(deeplinkUrl);
}
