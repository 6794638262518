import React from 'react';
import { UIIcon } from '../../../../../../controls/ui/UIIcon/UIIcon';
import { PageSearchResultItem } from '../../../../PageSearchResultItem/PageSearchResultItem';
import { ResultProps } from '../../../resultProps';
import './GDriveSheetResult.scss';

export const GDriveSheetResult: React.FC<ResultProps> = ({ result }) => {
  return (
    <PageSearchResultItem
      appName="Google Drive Sheet"
      icon={<UIIcon className="resultGDriveSheet" name="sheet" size={20} />}
      result={result}
    />
  );
};
