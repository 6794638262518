import React, { FC, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { trackEvent } from '../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useAllSearchRelatedParams } from '../../../scripts/hooks';
import './SearchSuggestionContainer.scss';

interface SearchSuggestionContainerProps {
  visibleImpression: AnalyticsEvent;
}

export const SearchSuggestionContainer: FC<SearchSuggestionContainerProps> = ({
  children,
  visibleImpression,
}) => {
  // TODO(Simon): Should add a `searchId` to search state rather than checking changes to filters
  const [searchState] = useAllSearchRelatedParams();

  const { ref, entry } = useInView({
    threshold: 1,
    triggerOnce: true,
    delay: 100,
  });

  useEffect(() => {
    if (entry) {
      trackEvent(visibleImpression);
    }
  }, [searchState, entry, visibleImpression]);

  return (
    <div className="searchSuggestionContainer" ref={ref}>
      {children}
    </div>
  );
};
