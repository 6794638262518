import { logError } from '../utils';
import { invokeFastApi } from './fastapi';

export const getPylonEmailHash: () => Promise<string> = async () => {
  try {
    const response = await invokeFastApi({
      method: 'GET',
      path: '/pylon-email-hash',
    });

    const { emailHash } = response as { emailHash: string };
    return emailHash;
  } catch (error) {
    logError(error);
  }

  return '';
};
