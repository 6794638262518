import React from 'react';
import { QAFileCard } from './QAFileCard';
import { QAFile } from './QAFilesContext';

interface Props {
  files: QAFile[];
  canBeDeleted?: boolean;
}

export const QAAttachedFiles = ({
  files,
  canBeDeleted = false,
}: Props): JSX.Element => {
  return (
    <div className="pb-2 flex gap-2 overflow-x-auto custom-scrollbar">
      {files.map((file) => (
        <QAFileCard canBeDeleted={canBeDeleted} file={file} key={file.id} />
      ))}
    </div>
  );
};
