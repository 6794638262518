import React from 'react';
import {
  createdModifiedMetaPairs,
  customResultMeta,
  pushResultMeta,
} from '../../../../../../scripts/page-search/result-utils';
import { MetaPair } from '../../../PreviewItem/PreviewItem';
import { PreviewProps } from '../../previewProps';
import { GeneralTaskPreview } from '../general/GeneralTaskPreview/GeneralTaskPreview';

const priorityNames = ['Critical', 'High', 'Moderate', 'Low'];

export const ServiceNowCasePreview: React.FC<PreviewProps> = ({ result }) => {
  const metaPairs: MetaPair[] = [];

  pushResultMeta(result, metaPairs, 'Account', 'company_name');
  pushResultMeta(result, metaPairs, 'Reporter', 'reporter');
  customResultMeta(
    metaPairs,
    'Priority',
    priorityNames[Number.parseInt(result.priority ?? '', 10) - 1]
  );

  pushResultMeta(result, metaPairs, 'Resolved', 'completed_time');

  return (
    <GeneralTaskPreview
      metaPairs={[...metaPairs, ...createdModifiedMetaPairs(result)]}
      result={result}
    />
  );
};
