import {
  SubscriptionPlanType,
  SubscriptionStatus,
  SubscriptionSummary,
} from '../models/User';
import { WorkflowType } from '../models/workflow';
import { workflowAPI } from '../scripts/apis';
import { useFlag, useUserSafe } from '../scripts/hooks';
import { logError } from '../scripts/utils';

export function hasSubscriptionTrialEnded(
  subscriptionSummary: SubscriptionSummary
): boolean {
  const now_ts = Date.now();
  return (
    subscriptionSummary.status === SubscriptionStatus.TRIAL &&
    subscriptionSummary.trialEndAt !== null &&
    subscriptionSummary.trialEndAt !== undefined &&
    now_ts - new Date(subscriptionSummary.trialEndAt).getTime() > 0
  );
}

export function useSubscriptionSummary(): SubscriptionSummary | undefined {
  return useUserSafe((user) =>
    user.orgByOrgId.dashSubscriptionsByOrgDomain.nodes.find(
      (s) => s.status !== SubscriptionStatus.CANCELLED
    )
  );
}

export function useHasValidSubscriptionPlan(): boolean {
  const subscription = useSubscriptionSummary();
  const hasInvalidSub =
    !subscription ||
    subscription.status === SubscriptionStatus.INACTIVE ||
    hasSubscriptionTrialEnded(subscription);

  return !hasInvalidSub;
}

export const useIsWorkspaceSetupNeeded = (): boolean => {
  const workspaceSetupDisabled = useFlag('workspaceSetupDisabled');
  const isWorkspaceSetup = useUserSafe(
    (user) => user.orgByOrgId.isWorkspaceSetup
  );

  if (workspaceSetupDisabled) {
    if (!isWorkspaceSetup) {
      workflowAPI.markAsDone(WorkflowType.WORKSPACE_ONBOARDING).catch(logError);
    }

    return false;
  }

  return !isWorkspaceSetup;
};

export enum Feature {
  AI_MODEL_SELECTION = 'AI_MODEL_SELECTION',
  ANALYTICS_ALL_TABS = 'ANALYTICS_ALL_TABS',
  API_ACCESS = 'API_ACCESS',
  CUSTOM_BOTS = 'CUSTOM_BOTS',
  HRIS = 'HRIS',
  MULTIPLE_APP_ACCOUNTS = 'MULTIPLE_APP_ACCOUNTS',
  MULTIPLE_DOMAINS = 'MULTIPLE_DOMAINS',
  ORG_WIDE_APPS = 'ORG_WIDE_APPS',
  ORG_WIDE_INSTRUCTIONS = 'ORG_WIDE_INSTRUCTIONS',
  SCIM = 'SCIM',
  SSO = 'SSO',
  // Add other features as needed
}

// Map features to minimum required plan level
const FEATURE_ACCESS_MAP: Record<Feature, SubscriptionPlanType> = {
  [Feature.AI_MODEL_SELECTION]: SubscriptionPlanType.BUSINESS,
  [Feature.API_ACCESS]: SubscriptionPlanType.ENTERPRISE,
  [Feature.ANALYTICS_ALL_TABS]: SubscriptionPlanType.ENTERPRISE,
  [Feature.CUSTOM_BOTS]: SubscriptionPlanType.BUSINESS,
  [Feature.HRIS]: SubscriptionPlanType.ENTERPRISE,
  [Feature.MULTIPLE_APP_ACCOUNTS]: SubscriptionPlanType.BUSINESS,
  [Feature.MULTIPLE_DOMAINS]: SubscriptionPlanType.ENTERPRISE,
  [Feature.ORG_WIDE_APPS]: SubscriptionPlanType.BUSINESS,
  [Feature.ORG_WIDE_INSTRUCTIONS]: SubscriptionPlanType.BUSINESS,
  [Feature.SCIM]: SubscriptionPlanType.ENTERPRISE,
  [Feature.SSO]: SubscriptionPlanType.ENTERPRISE,
  // Add other feature mappings
};

// Helper to check if a plan meets minimum required level
const meetsMinimumPlan = (
  userPlan: SubscriptionPlanType,
  requiredPlan: SubscriptionPlanType
): boolean => {
  const planOrder = [
    SubscriptionPlanType.TEAM,
    SubscriptionPlanType.BUSINESS,
    SubscriptionPlanType.ENTERPRISE,
  ];

  return planOrder.indexOf(userPlan) >= planOrder.indexOf(requiredPlan);
};

// hooks/useFeatureAccess.ts
export const useFeatureAccess = (feature: Feature): boolean => {
  const subscription = useSubscriptionSummary();

  if (!subscription) return false;

  const isActiveOrValidTrial =
    subscription.status === SubscriptionStatus.ACTIVE ||
    (subscription.status === SubscriptionStatus.TRIAL &&
      !hasSubscriptionTrialEnded(subscription));

  if (!isActiveOrValidTrial) return false;

  const requiredPlan = FEATURE_ACCESS_MAP[feature];
  return meetsMinimumPlan(subscription.planType, requiredPlan);
};
