import './AutoSuggest.scss';
import { Popper } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { SidebarRoutes } from '../../../../containers/SidebarContainer';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { FilterDefaults } from '../../../../scripts/constants/filters';
import { QueryParams } from '../../../../scripts/hooks';
import { AutoSuggestDashAiSearch } from './AutoSuggestDashAiSearch';

interface AutoSuggestProps {
  searchQuery: string;
  anchorEl?: HTMLElement;
  isOpen: boolean;
  filterKey: string;
  container?: Element | null;
}

export interface AutoSuggestContext {
  previousSelection(): void;
  nextSelection(): void;
  triggerSearch(searchAll: () => void, onSearchHistoryItem: () => void): void;
}

enum SuggestType {
  Search,
  DashAiApps,
  DashAiWeb,
  DashAiBaseChat,
  RecentSearch,
  SuggestedItem,
  CuratedSearch,
  BrowserHistoryItem,
}

export const AutoSuggest = React.forwardRef<
  AutoSuggestContext,
  AutoSuggestProps
>(
  // eslint-disable-next-line max-lines-per-function,complexity
  ({ searchQuery, anchorEl, isOpen, filterKey, container }, ref) => {
    const urlHistory = useHistory();

    /*
     * Which item in autosuggest is selected
     */
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    // Only show filters when user hasn't typed anything
    const shouldShowSuggestions =
      searchQuery.trim().length > 0 || filterKey !== FilterDefaults.ObjectType;

    const popoverWidth = anchorEl ? anchorEl.offsetWidth : 0;

    const sendSearchQueryToDashAi = useCallback(
      ({ isBaseLLM, isWeb }: { isBaseLLM: boolean; isWeb: boolean }) => {
        urlHistory.push(
          `${SidebarRoutes.JitQA}?${QueryParams.Search}=${encodeURIComponent(
            searchQuery
          )}&${QueryParams.IsWeb}=${isWeb ? '1' : '0'}&${
            QueryParams.IsBaseLLM
          }=${isBaseLLM ? '1' : '0'}`
        );
      },
      [searchQuery, urlHistory]
    );

    const handleDashAiAppsClick = useCallback(() => {
      trackEvent(AnalyticsEvent.AutosuggestDashAiSearch, {
        searchQuery,
        filter: 'apps',
      });

      sendSearchQueryToDashAi({ isBaseLLM: false, isWeb: false });
    }, [searchQuery, sendSearchQueryToDashAi]);

    const handleDashAiWebClick = useCallback(() => {
      trackEvent(AnalyticsEvent.AutosuggestDashAiSearch, {
        searchQuery,
        filter: 'web',
      });

      sendSearchQueryToDashAi({ isBaseLLM: false, isWeb: true });
    }, [searchQuery, sendSearchQueryToDashAi]);

    const handleDashAiBaseChatClick = useCallback(() => {
      trackEvent(AnalyticsEvent.AutosuggestDashAiSearch, {
        searchQuery,
        filter: 'BaseChat',
      });

      sendSearchQueryToDashAi({ isBaseLLM: true, isWeb: false });
    }, [searchQuery, sendSearchQueryToDashAi]);

    /**
     * Each element in this array represents the type and item that is ultimately rendered in the suggestions.
     * This is used to infer the correct action based on keyboard selection when we do not receive an event from
     * the component itself.
     *
     * This is *somewhat* hacky but works rather well.
     */
    const itemRegistry = useRef(
      [] as {
        item?: unknown;
        type: SuggestType;
      }[]
    );

    itemRegistry.current.length = 0;

    let runningIndex = 0;

    const dashAiAppsSuggestionIndex = shouldShowSuggestions
      ? runningIndex++
      : -1;

    if (dashAiAppsSuggestionIndex !== -1) {
      itemRegistry.current[dashAiAppsSuggestionIndex] = {
        type: SuggestType.DashAiApps,
      };
    }

    const dashAiWebSuggestionIndex = shouldShowSuggestions
      ? runningIndex++
      : -1;

    if (dashAiWebSuggestionIndex !== -1) {
      itemRegistry.current[dashAiWebSuggestionIndex] = {
        type: SuggestType.DashAiWeb,
      };
    }

    const dashAiBaseChatSuggestionIndex = shouldShowSuggestions
      ? runningIndex++
      : -1;

    if (dashAiBaseChatSuggestionIndex !== -1) {
      itemRegistry.current[dashAiBaseChatSuggestionIndex] = {
        type: SuggestType.DashAiBaseChat,
      };
    }

    const resetSelectedIndex = useCallback(() => {
      setSelectedIndex(0);
    }, []);

    useEffect(() => {
      // On search query change, we always select the "Search for" item
      resetSelectedIndex();
    }, [resetSelectedIndex, searchQuery]);

    // Expose previous/next selection to parent
    useImperativeHandle(
      ref,
      () => ({
        // the range is 0-runningIndex
        previousSelection: () => {
          setSelectedIndex((prev) =>
            selectedIndex === 0 ? runningIndex : --prev
          );
        },
        nextSelection: () => {
          setSelectedIndex((prev) =>
            selectedIndex === runningIndex ? 0 : ++prev
          );
        },
        triggerSearch: () => {
          const regItem = itemRegistry.current[selectedIndex];
          if (!regItem) {
            return;
          }

          if (
            regItem.type !== SuggestType.DashAiWeb &&
            regItem.type !== SuggestType.DashAiApps &&
            regItem.type !== SuggestType.DashAiBaseChat
          ) {
            return;
          }

          switch (regItem.type) {
            case SuggestType.DashAiApps:
              handleDashAiAppsClick();
              return;
            case SuggestType.DashAiWeb:
              handleDashAiWebClick();
              return;
            case SuggestType.DashAiBaseChat:
              handleDashAiBaseChatClick();
          }
        },
      }),
      [
        handleDashAiAppsClick,
        handleDashAiBaseChatClick,
        handleDashAiWebClick,
        runningIndex,
        selectedIndex,
      ]
    );

    return (
      <Popper
        anchorEl={anchorEl}
        className="autoSuggestPopover"
        container={container}
        modifiers={[
          {
            name: 'hide',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: false,
          },
          {
            name: 'flip',
            enabled: false,
          },
        ]}
        open={isOpen}
        placement="bottom"
        style={{ width: `${popoverWidth}px` }}
        tabIndex={-1}
      >
        {shouldShowSuggestions && (
          <>
            <AutoSuggestDashAiSearch
              index={dashAiAppsSuggestionIndex}
              onClick={handleDashAiAppsClick}
              onMouseEnter={setSelectedIndex}
              searchQuery={searchQuery}
              selectedIndex={selectedIndex}
              text="Ask Dash AI with Apps search"
            />
            <AutoSuggestDashAiSearch
              index={dashAiWebSuggestionIndex}
              onClick={handleDashAiWebClick}
              onMouseEnter={setSelectedIndex}
              searchQuery={searchQuery}
              selectedIndex={selectedIndex}
              text="Ask Dash AI with Web search"
            />
            <AutoSuggestDashAiSearch
              index={dashAiBaseChatSuggestionIndex}
              onClick={handleDashAiBaseChatClick}
              onMouseEnter={setSelectedIndex}
              searchQuery={searchQuery}
              selectedIndex={selectedIndex}
              text="Ask Dash AI without search"
            />
          </>
        )}
      </Popper>
    );
  }
);

AutoSuggest.displayName = 'AutoSuggest';
