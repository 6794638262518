import { useCallback, useRef } from 'react';

/**
 * Used for elements that use `position: sticky` to apply additional styling and positioning offsets.
 * This should be used very sparingly due to high performance requirements.
 */
export const useSticky = (): ((node: HTMLElement | null) => void) => {
  const ref = useRef<HTMLElement | null>(null);
  const observerRef = useRef<IntersectionObserver | undefined>();
  const setRef = useCallback((node: HTMLElement | null) => {
    const { current } = ref;
    if (current) {
      // Reset the element and destroy the observer.
      current.classList.remove('sticky', 'stickyActive');
      current.style.top = '0';
      observerRef.current?.disconnect();
    }

    if (node) {
      // Get the height of the header to calculate both sticky offset and intersection margin
      const [header] = document.getElementsByClassName('topHeader');
      const offset = (header as HTMLElement | null)?.offsetHeight ?? 0;

      observerRef.current = new IntersectionObserver(
        ([e]) => {
          e?.target.classList.toggle('stickyActive', e.intersectionRatio < 1);
        },
        {
          threshold: [1],
          // We need a *tiny* offset in order to trigger the intersection
          rootMargin: `${-(offset + 1)}px 0px 0px 0px`,
        }
      );

      node.classList.add('sticky');
      // Take header into account
      node.style.top = `${offset}px`;
      observerRef.current.observe(node);
    }

    ref.current = node;
  }, []);

  return setRef;
};
