import { useEffect, useState } from 'react';
import { answersAPI } from '../apis';
import { Answer } from '../models/answers';
import { logError } from '../utils';

const answersProvider = async () => answersAPI.getAll();

interface AnswersResponse {
  answers: Answer[];
  loading: boolean;
}

export const useAnswers = (): AnswersResponse => {
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [loading, setLoading] = useState(false);

  const getAnswers = async () => {
    setLoading(true);
    setAnswers([]);
    try {
      const res = await answersProvider();
      setAnswers(res);
    } catch (fetchError) {
      logError(fetchError);
    }

    setLoading(false);
  };

  useEffect(() => {
    getAnswers();
  }, []);

  return { answers, loading };
};
