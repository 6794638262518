import { useCallback } from 'react';
import { updateOnboardingState } from '../apis';
import { OnboardingStep } from '../constants/onboarding-step';
import { logError } from '../utils';
import { useUserId } from './redux';
import { useBoolState } from './state';

/**
 * Used to update onboarding step for current user.
 */

export const useOnboardingStepUpdater = (
  onboardingStep: OnboardingStep
): [() => void, boolean] => {
  const userId = useUserId();
  const [isWorking, setIsWorking, setNotWorking] = useBoolState(false);

  return [
    useCallback(() => {
      setIsWorking();

      updateOnboardingState(userId, {
        onboardingStep,
      }).catch((error) => {
        logError(error);
        setNotWorking();
      });
    }, [onboardingStep, setIsWorking, setNotWorking, userId]),
    isWorking,
  ];
};
