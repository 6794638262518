import {
  FeedItem,
  FeedMetadata,
  MarkdownContentBlock,
  User,
} from '@knocklabs/client';
import { BotMinimal } from '../../models/Bots';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { logError } from '../../scripts/utils';
import { RadioListOption } from '../controls/RadioList/RadioList';

export enum NotificationsFilter {
  'All' = 'All',
  'Unread' = 'Unread',
  'Read' = 'Read',
}

export const NOTIFICATION_FILTERS_OPTIONS: RadioListOption<string>[] =
  Object.values(NotificationsFilter).map((filterType) => ({
    value: filterType,
    displayName: filterType,
  }));

export enum NotificationType {
  NOTIFY_TAGGED_USERS = 'notify-tagged-users',
  NOTIFY_BOT_ADMINS = 'notify-bot-admins',
  NOTIFY_NEW_MSGS_IN_SHARED_TOPICS = 'notify-new-msgs-in-shared-topics',
}

export interface NotificationsData {
  topic_id: string;
  message_id: string;
  topic_title?: string;
  managed_bot_details: BotMinimal | null;
}

export interface NotificationsFeedItem {
  knockItem: FeedItem;
  markdown: string;
  sender: {
    avatar?: string | null;
    name: string;
  };
  createdAt: string;
  read: boolean;
  id: string;
  topic_id: string;
  message_id: string;
  topic_title: string;
  managed_bot_details: BotMinimal | null;
  notificationType: NotificationType;
}

export const getKnockNotificationsToken = async (): Promise<string | null> => {
  try {
    const res = await invokeFastApi<{ token: string }>({
      path: '/notifications/token',
    });

    return res.token;
  } catch (error) {
    logError(error);
    return null;
  }
};

const processFeedItem = (feedItem: FeedItem): NotificationsFeedItem => {
  const markDownBlock = feedItem.blocks.find(
    (item) => item.type === 'markdown'
  ) as MarkdownContentBlock | undefined;

  const markdown = markDownBlock?.rendered ?? '';
  const sender = feedItem.actors[0] as User | undefined;
  const data = feedItem.data as NotificationsData;

  return {
    id: feedItem.id,
    knockItem: feedItem,
    markdown,
    sender: {
      avatar: sender?.avatar,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      name: sender?.name || 'Your teammate',
    },
    read: !!feedItem.read_at,
    topic_id: data.topic_id,
    message_id: data.message_id,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    topic_title: data.topic_title || 'a topic',
    managed_bot_details: data.managed_bot_details,
    notificationType: feedItem.source.key as NotificationType,
    createdAt: feedItem.inserted_at,
  };
};

export const processFeedItems = (
  feedItems: FeedItem[]
): NotificationsFeedItem[] => {
  return feedItems.map((item) => processFeedItem(item));
};

export const markFeedItemsAsRead = (
  items: FeedItem[]
): NotificationsFeedItem[] => {
  return items.map((item) => {
    const newKnockItem = {
      ...item,
      read_at: new Date().toISOString(),
    };

    return processFeedItem(newKnockItem);
  });
};

export const mergeFeedItems = (
  oldItems: NotificationsFeedItem[],
  newItems: NotificationsFeedItem[]
): NotificationsFeedItem[] => {
  return oldItems
    .map((oldItem) => {
      const newItem = newItems.find((item) => oldItem.id === item.id);
      return newItem ?? oldItem;
    })
    .sort((a, b) =>
      a.knockItem.inserted_at > b.knockItem.inserted_at ? -1 : 1
    );
};

export const updateMetaDataAfterNotificationsRead = (
  metaData: FeedMetadata | null,
  items: FeedItem[]
): FeedMetadata | null => {
  if (!metaData) return null;
  return {
    ...metaData,
    unread_count: Math.max(0, metaData.unread_count - items.length),
  };
};

export function getDisplayableTime(timestamp: string): string {
  const now = new Date();
  const past = new Date(timestamp);
  const secondsAgo = Math.floor((now.getTime() - past.getTime()) / 1000);

  if (secondsAgo < 60) {
    return 'just now';
  }

  const intervals = [
    { label: 'year', seconds: 31_536_000 },
    { label: 'month', seconds: 2_592_000 },
    { label: 'week', seconds: 604_800 },
    { label: 'day', seconds: 86_400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(secondsAgo / interval.seconds);
    if (count >= 1) {
      return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
}
