import * as Sentry from '@sentry/browser';
import { AxiosResponse } from 'axios';

export class RequestError extends Error {
  public override readonly name: string = 'RequestError';

  public constructor(
    public readonly path: string,
    public readonly method: string,
    public readonly statusCode?: number,
    public readonly response?: object | string,
    public override readonly cause?: Error
  ) {
    super(
      `Request ${method} ${path} failed with ${statusCode ?? 'network error'}`
    );
  }
}

export const enum ErrorCode {
  AppPermissionMismatch = 'APP_PERMISSION_MISMATCH',
}

export class BadRequestError extends RequestError {
  public override readonly name = 'BadRequestError';

  public constructor(
    public readonly code: ErrorCode,
    path: string,
    method: string,
    statusCode: number,
    response: string
  ) {
    // eslint-disable-next-line unicorn/custom-error-definition
    super(path, method, statusCode, response);
    this.message = response;
  }
}

export class NotFoundRequestError extends RequestError {
  public override readonly name = 'NotFoundRequestError';

  public constructor(
    path: string,
    method: string,
    statusCode: number,
    response: string
  ) {
    // eslint-disable-next-line unicorn/custom-error-definition
    super(path, method, statusCode, response);
    this.message = response;
  }
}

interface BaseRequestOptions extends RequestInit {
  url?: string;
  path?: string;
  json?: object | null;
}

export const apiBase = `https://${API_ORIGIN}/v1`;

interface ErrorPayload {
  code: ErrorCode;
  message: string;
}

const isCodePayload = (obj: unknown): obj is ErrorPayload => {
  return (
    typeof obj === 'object' && obj !== null && 'code' in obj && 'message' in obj
  );
};

const isAxiosResponse = (response: unknown): response is AxiosResponse => {
  return (
    response !== null &&
    typeof response === 'object' &&
    'config' in response &&
    'data' in response
  );
};

export const deriveErrorFromFailedRequest = (
  method: string | undefined,
  path: string,
  response?: AxiosResponse | Response,
  responseJson?: unknown
): RequestError | undefined => {
  if (!response) {
    throw new RequestError(path, method ?? 'GET');
  }

  if (response.status < 300) {
    return;
  }

  const data: unknown = isAxiosResponse(response)
    ? response.data
    : responseJson;

  if (response.status === 404) {
    throw new NotFoundRequestError(
      path,
      method ?? 'GET',
      response.status,
      '404 NOT found'
    );
  }

  if (response.status === 400 && isCodePayload(data)) {
    throw new BadRequestError(
      data.code,
      path,
      method ?? 'GET',
      response.status,
      data.message
    );
  }

  throw new RequestError(
    path,
    method ?? 'GET',
    response.status,
    data as object
  );
};

/**
 * Performs arbitrary unauthenticated requests, used for unprotected lambdas and other.
 */
export async function baseRequest<T extends object | undefined>(
  cfg: BaseRequestOptions
): Promise<T> {
  const url = cfg.url ?? `${apiBase}/${cfg.path ?? ''}`;
  if (cfg.json) {
    cfg.body = JSON.stringify(cfg.json);
  }

  try {
    const response = await fetch(url, cfg);
    // Do not parse no content responses
    if (response.status === 204) {
      return undefined as T;
    }

    const data: unknown = await response.json();
    const err = deriveErrorFromFailedRequest(
      cfg.method,
      new URL(url).pathname,
      response,
      data
    );

    if (err) {
      throw err;
    }

    return data as T;
  } catch (error) {
    if (error instanceof RequestError) {
      throw error;
    }

    if (
      error instanceof TypeError &&
      error.message.includes('Failed to fetch')
    ) {
      throw new RequestError(new URL(url).pathname, cfg.method ?? 'GET');
    }

    Sentry.captureException(
      new RequestError(
        new URL(url).pathname,
        cfg.method ?? 'GET',
        undefined,
        undefined,
        error as Error
      )
    );

    throw error;
  }
}
